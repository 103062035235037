<template>
  <!-- eslint-disable  max-len -->
  <b-modal :active.sync="modalVisible">
    <div class="modal-wrapper">
      <h2
        class="modal-title"
        v-html="title"
      />
      <div class="modal-body">
        <slot />
      </div>
      <div class="modal-footer">
        <slot name="footer" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  background-color: white;
  padding: 2em;
  font-size: 16px;
}

.modal-title {
  text-transform: uppercase;
}

.modal-body {
  padding: 2em;
}
</style>
