<template>
  <div class="lll_form_container">
    <spinner v-if="isFetchingLabourLaw" />
    <div
      v-else
      class="lll_form"
    >
      <div class="lll_form_section">
        <div
          v-if="showStatusButton"
          class="lll_form__line"
        >
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.status_label") }}
          </div>
          <SkSwitch
            :value="enabled"
            width="542px"
            @input="showConfirmationModal"
          />
        </div>
        <div class="lll_form__line">
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.country") }}
          </div>
          <SkSelectV2
            v-model="country"
            :options="availableCountries"
            :label="$t('super_admin_js.labour_laws.form.labels.country')"
            data-test="lll-form__country"
            width="542px"
            :disabled="isEditMode"
          />
        </div>
        <div class="lll_form__line">
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.scope") }}
          </div>
          <SkSelectV2
            v-model="scope"
            :options="availableScopes"
            :label="$t('super_admin_js.labour_laws.form.labels.scope')"
            data-test="lll-form__scope"
            width="542px"
            :disabled="isEditMode"
          />
        </div>
        <div class="lll_form__line">
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.key") }}
          </div>
          <SkInput
            v-model="key"
            :label="$t('super_admin_js.labour_laws.form.labels.key_detailed')"
            :errored="!isKeyInputValid"
            :error-message="keyErrorMessage"
            data-test="lll-form__key"
            :disabled="isEditMode"
          />
        </div>
        <!-- This field might be used in a later version: for now, sector is in rules -->
        <!-- <div class="lll_form__line">
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.sector") }}
          </div>
          <SkSelectV2
            v-model="sector"
            :options="availableSectors"
            :label="$t('super_admin_js.labour_laws.form.labels.sector')"
            data-test="lll-form__sector"
            width="542px"
          />
        </div> -->
        <div class="lll_form__line">
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.alerts") }}
          </div>
          <SkSelectV2
            v-model="alerts"
            :options="availableAlerts"
            :label="$t('super_admin_js.labour_laws.form.labels.alerts')"
            multi
            data-test="lll-form__alerts"
            width="542px"
          >
            <template #selected-option>
              {{ selectedAlertsText }}
            </template>
          </SkSelectV2>
        </div>
        <div class="lll_form__line">
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.absences") }}
          </div>
          <SkSelectV2
            v-model="absencesWithOwedMeal"
            :options="availableAbsences"
            :label="$t('super_admin_js.labour_laws.form.labels.absences')"
            multi
            data-test="lll-form__absencesWithOwedMeal"
            width="542px"
          >
            <template #selected-option>
              {{ selectedAbsencesText }}
            </template>
          </SkSelectV2>
        </div>
        <div class="lll_form__line">
          <div class="lll_form__field_label">
            {{ $t("super_admin_js.labour_laws.form.labels.rules") }}
          </div>
          <div>
            <SkTextarea
              v-model="rules"
              :label="$t('super_admin_js.labour_laws.form.labels.rules_detailed')"
              :errored="!isRulesInputValid"
              :error-message="rulesErrorMessage"
              :max-height="4000"
              :min-height="350"
              auto-grow
              data-test="lll-form__rules"
            />
            <div
              v-if="!isRulesInputValid"
              class="lll_form__field_error_message"
            >
              {{ rulesErrorMessage }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isFetchingLabourLaw"
        class="lll_form__butons"
      >
        <SkButton
          :disabled="isLoading"
          data-test="lll-form__cancel"
          fill="outlined"
          @click="handleClose"
        >
          {{ $t("super_admin_js.labour_laws.buttons.cancel") }}
        </SkButton>
        <SkButton
          :spinner="isLoading"
          :disabled="isLoading || !isFormValid"
          data-test="lll-form__valid"
          @click="handleSubmit"
        >
          {{ $t("super_admin_js.labour_laws.buttons.submit") }}
        </SkButton>
      </div>

      <LabourLawEnableConfirmationModal
        :enabled-value="enabled"
        @validate="handleStatusChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
 MODAL_SHOW_EVENT, skListenersMixin, SkInput, SkButton, SkSelectV2, SkTextarea, SkSwitch,
} from '@skelloapp/skello-ui';
import { ALERT_KEYS } from '~/shared/utils/alert-keys.js';
import { SECTOR_KEYS } from '~/shared/utils/sector-keys.js';
import Spinner from '~/components/Spinner';
import { SCOPES, ABSENCES_WITH_OWED_MEALS_FR, AVAILABLE_COUNTRIES } from '../lll-constants.js';
import LabourLawEnableConfirmationModal from '../modals/LabourLawEnableConfirmationModal.vue';

export default {
  name: 'LabourLawForm',
  components: {
    SkInput,
    SkButton,
    SkSelectV2,
    SkTextarea,
    Spinner,
    SkSwitch,
    LabourLawEnableConfirmationModal,
  },
  mixins: [skListenersMixin],
  props: {
    labourLawParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      country: 'FR',
      scope: 'agreement',
      key: '',
      alerts: [],
      absencesWithOwedMeal: [],
      rules: '',
      errors: {},
      isLoading: false,
      isFetchingLabourLaw: false,
      enabled: false,
    };
  },
  computed: {
    availableCountries() {
      return AVAILABLE_COUNTRIES
        .map((countryKey) => ({ id: countryKey, text: this.$t(`countries.${countryKey}`) }))
        .sort((countryA, countryB) => countryA.text.localeCompare(countryB.text));
    },
    availableScopes() {
      return SCOPES.map((scopeKey) => ({ id: scopeKey, text: this.$t(`super_admin_js.labour_laws.scopes.${scopeKey}`) }));
    },
    availableSectors() {
      return SECTOR_KEYS.map((sectorKey) => ({ id: sectorKey, text: this.$t(`super_admin_js.sectors.${sectorKey}`) }));
    },
    availableAlerts() {
      return ALERT_KEYS.map((alertKey) => ({ id: alertKey, text: this.$t(`super_admin_js.alerts.${alertKey}`) }));
    },
    availableAbsences() {
      return ABSENCES_WITH_OWED_MEALS_FR.map((absenceKey) => (
        { id: absenceKey, text: this.$t(`super_admin_js.labour_laws.absences_with_owed_meals.${absenceKey}`) }
      ));
    },
    countryLowerCased() {
      return this.country.toLowerCase();
    },
    selectedAlertsText() {
      return this.alerts
        .map((alertKey) => (
          this.availableAlerts.find((availableAlert) => availableAlert.id === alertKey)?.text
        )).join('; ');
    },
    selectedAbsencesText() {
      return this.absencesWithOwedMeal
        .map((absenceKey) => (
          this.availableAbsences
            .find((availableAbsence) => availableAbsence.id === absenceKey)?.text
        )).join('; ');
    },
    isFormValid() {
      return this.isKeyInputValid && this.isRulesInputValid;
    },
    isKeyInputValid() {
      return this.key !== '' && /^[a-z_]+$/.test(this.key);
    },
    isRulesInputValid() {
      try {
        const content = JSON.parse(this.rules);

        if (this.rules === '' || (Object.keys(content).length === 0)) return false;
      } catch {
        return false;
      }

      return true;
    },
    mandatoryField() {
      return this.$t('super_admin_js.labour_laws.errors.mandatory_field');
    },
    keyErrorMessage() {
      return this.key === ''
        ? this.mandatoryField
        : this.$t('super_admin_js.labour_laws.errors.invalid_key');
    },
    rulesErrorMessage() {
      return this.rules === '' || this.rules === '{}'
        ? this.mandatoryField
        : this.$t('super_admin_js.labour_laws.errors.invalid_rules');
    },
    showStatusButton() {
      return this.scope === 'agreement';
    },
    isEditMode() {
      return !!this.labourLawParams;
    },
  },
  mounted() {
    if (this.isEditMode) {
      this.fetchLabourLawData();
    } else {
      this.setFormDefaultValues();
    }
  },
  methods: {
    ...mapActions(['upsertLabourLaw', 'fetchOneLabourLaw']),
    fetchLabourLawData() {
      this.isFetchingLabourLaw = true;
      this.fetchOneLabourLaw({
        key: this.labourLawParams.key,
        scope: this.labourLawParams.scope,
        country: this.labourLawParams.country,
      })
        .then((payload) => {
          const result = payload.data;
          this.country = result.country.toUpperCase();
          this.enabled = result.enabled;
          this.scope = result.scope;
          this.key = result.key;
          this.alerts = result.alerts;
          this.absencesWithOwedMeal = result.absencesWithOwedMeal;
          this.rules = JSON.stringify(result.rules, null, 2);
        })
        .catch(() => {
          this.displayErrorToaster();
        })
        .finally(() => { this.isFetchingLabourLaw = false; });
    },
    handleSubmit() {
      if (this.isLoading) return;

      this.isLoading = true;

      const params = {
        country: this.countryLowerCased,
        scope: this.scope,
        key: this.key,
        alerts: this.alerts,
        absencesWithOwedMeal: this.absencesWithOwedMeal,
        rules: JSON.parse(this.rules),
        enabled: this.enabled,
      };

      this.upsertLabourLaw(params)
        .then(() => {
          this.displaySuccessToaster();
          this.handleClose();
        })
        .catch(() => {
          this.displayErrorToaster();
        })
        .finally(() => { this.isLoading = false; });
    },
    handleClose() {
      this.$emit('close-form');
    },
    displaySuccessToaster() {
      this.makeToast(
        'is-success',
        this.$t('super_admin_js.labour_laws.confirm.submit_success'),
      );
    },
    displayErrorToaster() {
      this.makeToast(
        'is-danger',
        this.$t('super_admin_js.labour_laws.errors.submit_failure'),
      );
    },
    setFormDefaultValues() {
      this.alerts = this.availableAlerts.map((alert) => alert.id);
    },
    showConfirmationModal() {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'lll-enable-confirmation-modal');
    },
    handleStatusChange(params) {
      this.enabled = params.newStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
  .lll_form_container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .lll_form {
    flex-grow: 1;
    overflow-y: auto;
  }

  .lll_form_section {
    margin-bottom: 24px;
  }

  .lll_form__subtitle {
    margin-top: 8px;
    margin-bottom: 16px;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
  }

  .lll_form__line {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  .sk-input,
  .sk-textarea,
  .sk-switch-v3 {
    width: 542px !important;
  }

  .sk-textarea {
    font-size: 12px;
  }

  .lll_form__field_label {
    color: $sk-gray-med-2;
    padding-top: 15px;
  }

  .lll_form__butons {
    flex-grow: 0;
    text-align: right;
    margin-top: 24px;

    .sk-button {
      margin-left: 16px;
    }
  }

  .lll_form__field_error_message {
    font-family: $dm-sans-font;
    font-size: 12px;
    color: $sk-red-dark-1;
  }
</style>
