<template>
  <div id="app" class="app">
    <div v-if="loading" class="app__loading">
      <img src="https://user-images.githubusercontent.com/16536800/189085925-01b78bed-2a99-4e01-953b-e2d3c6231970.gif">
    </div>

    <div v-else>
      <div class="app__navbar-wrapper">
        <Navbar />
      </div>
      <div class="app__body-wrapper">
        <Sidebar v-if="showSidebar" />
        <RouterView class="app__router-wrapper" />
        <div id="toast-portal" />
      </div>
      <SkConfirmationModal />
      <ReviewAppBanner v-if="isReviewApp" :api-host="apiHost" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { updateRootUrl } from '@config/http_client';
import { SkConfirmationModal } from '@skelloapp/skello-ui';
import { security } from '@config/security';

import Navbar from './layout/Navbar';
import Sidebar from './layout/Sidebar';
import ReviewAppBanner from './layout/ReviewAppBanner';

export default {
  components: {
    Navbar,
    Sidebar,
    ReviewAppBanner,
    SkConfirmationModal,
  },
  data() {
    return {
      showSidebar: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(['apiHost']),
    isReviewApp() {
      return this.apiHost && this.apiHost.includes('reviewapp');
    },
  },
  beforeMount() {
    this.loading = true;

    // this set the root url to handle review app applications
    if (this.$route.query.api_host && updateRootUrl(this.$route.query.api_host)) {
      this.setApiHost(this.$route.query.api_host);
    }

    security.verifyTokenFromCookies(this.$route.query.authent_with_token)
      .then(async ({ userId }) => {
        await this.fetchCurrentUser(userId);
        this.resetSidebar();
        await this.fetchFeatureFlags();

        this.loading = false;
      })
      .catch(() => {
        security.logOut();
        this.removeCurrentUser();
      });

    this.loadAnalytics();

    // Vue doesn't call BeforeRouteEnter if outside the router view
    // Add a guard callback here for the same effect
    this.$router.afterEach(this.resetSidebar);
  },
  methods: {
    ...mapActions(['fetchCurrentUser', 'fetchFeatureFlags', 'removeCurrentUser', 'setApiHost']),
    resetSidebar() {
      this.showSidebar = !this.$router.currentRoute.meta.isGuestPage;
    },
    loadAnalytics() {
      const analyticsKey = process.env.VUE_APP_ANALYTICS_KEY;
      if (analyticsKey) analytics.load(analyticsKey);
      analytics.page();
    },
  },
};
</script>

<!-- Unscoped style -->
<style lang="scss">
@import './styles/bulma.scss';

body {
  margin: 0;
}

.wysiwyg {
  .vw-btn-header,
  .vw-btn-link {
    display: none;
  }

  h1 {
    font-size: 1.5em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.25em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.15em;
    font-weight: bold;
  }

  ul {
    list-style: disc outside none;
  }

  span {
    font-size: 1em !important;
  }
}
</style>

<!-- App style -->
<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: white;
  font-family: $product-sans-font;
  font-size: .9em;

  &::v-deep > .columns {
    margin: 52px 0 0;

    > .column {
      padding: 0;
      overflow: hidden;

      &:last-child {
        margin-left: 210px;
      }
    }
  }

  &::v-deep .b-table {
    font-size: .73rem;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  &::v-deep .dropdown-item {
    padding: 0 !important;
  }
}

.app__body-wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  overflow: auto;
  height: calc(100vh - 104px);
}

.app__router-wrapper {
  flex: 1 1 auto;
  padding: 20px;
  overflow: auto;

  @media screen and (min-width: 750px) {
    padding: 35px;
  }
}

.app__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// Don't slide toast from left to right,
// But make it appear slowly
#toast-portal {
  &::v-deep .sk-toast {
    transform: translateX(-50%);
  }
}
</style>
