<template>
  <!-- eslint-disable  max-len -->
  <div>
    <header-content :title="user.name" />
    <spinner v-if="!loaded" />
    <main-content v-else>
      <tabs>
        <tab :name="$t('super_admin_js.users.details')">
          <user-details
            :user="user"
            :refresh-user="fetchData"
          />
        </tab>
        <tab :name="$t('super_admin_js.sent_emails.labels.list_page_title')">
          <UserSentEmailsList
            :user="user"
          />
        </tab>
        <tab :name="$t('super_admin_js.history')">
          <user-history :user="user" />
        </tab>
      </tabs>
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import HeaderContent from '../../components/HeaderContent';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import MainContent from '../../components/MainContent';
import Spinner from '../../components/Spinner';
import UserDetails from './UserDetails';
import UserHistory from './UserHistory';
import UserSentEmailsList from './UserSentEmailsList';

export default {
  components: {
    HeaderContent,
    MainContent,
    Spinner,
    Tabs,
    Tab,
    UserDetails,
    UserHistory,
    UserSentEmailsList,
  },
  beforeRouteUpdate(to, from, next) {
    this.userId = to.params.id;
    this.fetchData();
    next();
  },
  data() {
    return {
      loaded: false,
      user: {},
      userId: this.$route.params.id,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      httpClient
        .get(`/super_admin/api/users/${this.userId}`)
        .then((response) => {
          this.user = response.data.user;
          this.loaded = true;
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.$router.push('/404');
            return;
          }
          this.makeAlertToast(
            this.$t('errors.loading_user'),
          );
        });
    },
  },
};
</script>
