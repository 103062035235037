<template>
  <!-- eslint-disable  max-len -->
  <div>
    <h1 class="sk-header--1">
      <slot>
        {{ title }}
      </slot>
    </h1>
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>
