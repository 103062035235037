<template>
  <div class="fourth-stage-wrapper">
    <div class="new-integration-download-section">
      <h1 class="step-title">
        <b>{{ $t('super_admin_js.modals.new_integration.fourth_stage.step_1') }}</b>
      </h1>

      <div>
        <!-- product request: template file for config is an empty json -->
        <a
          href="data:application/json;charset=utf-8,"
          download="integration_template.json"
        >
          {{ $t('super_admin_js.modals.new_integration.fourth_stage.download_file') }}
        </a>
      </div>
    </div>

    <div>
      <h1 class="step-title">
        <b>{{ $t('super_admin_js.modals.new_integration.fourth_stage.step_2') }}</b>
      </h1>
      <b-field v-if="!file">
        <b-upload
          v-model="file"
          drag-drop
          accept=".json,application/json"
          @input="loadFileContent"
        >
          <section>
            <div class="content has-text-centered">
              <p>
                <b-icon
                  icon="cloud-upload"
                  size="is-large"
                />
              </p>
              <p>{{ $t('super_admin_js.modals.new_integration.fourth_stage.drag_or_click') }}</p>
            </div>
          </section>
        </b-upload>
      </b-field>
      <section v-else>
        <div class="content has-text-centered">
          <p>{{ file.name }}</p>
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile()"
          />
        </div>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    deleteDropFile() {
      this.file = null;
    },
    async loadFileContent() {
      this.$emit('on-file-read-loading', true);
      this.integration.config_json = await this.file.text();
      this.$emit('on-file-read-loading', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.fourth-stage-wrapper {
  margin: 35px 24px;
}

.new-integration-download-section {
  margin-bottom: 45px;
}

.step-title {
  font-size: 16px;
}

.upload-field {
  width: 100%;
}
</style>
<style lang="scss">
// Force full width for Buefy upload field
.upload > .upload-draggable {
  width: 100%;
}
</style>
