<template>
  <div
    :class="colorClass"
    class="license-position-badge"
  >
    <div
      class="license-position-badge__text"
    >
      {{ position }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicensePositionBadge',
  props: {
    position: {
      type: Number,
      required: true,
    },
  },
  computed: {
    colorClass() {
      return `license-position-badge--${this.colorSet}`;
    },
    colorSet() {
      if (this.position < 4) {
        return 'blue';
      }
      if (this.position < 8) {
        return 'green';
      }
      return 'red';
    },
  },
};
</script>

<style lang="scss" scoped>
.license-position-badge {
  height: 36px;
  width: 36px;
  border-radius: 18px;
}

.license-position-badge__text {
  font-family: "ProximaNova", "Helvetica", sans-serif;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  font-weight: 700;
}

.license-position-badge--blue {
  background: rgba(13, 46, 217, .15);

  .license-position-badge__text {
    color: #0d2ed9;
  }
}

.license-position-badge--green {
  background: rgba(72, 191, 51, .15);

  .license-position-badge__text {
    color: #48bf33;
  }
}

.license-position-badge--red {
  background: rgba(244, 178, 165, .15);

  .license-position-badge__text {
    color: #e7a497;
  }
}
</style>
