<template>
  <!-- eslint-disable  max-len -->
  <div class="int-modal__radio-section">
    <div v-if="action !== 'update'" class="int-modal__radio-button md-radio">
      <input
        id="from-scratch"
        v-model="integration.createMethod"
        value="fromScratch"
        type="radio"
      >
      <label for="from-scratch" class="int-modal__radio-label">
        {{ $t('super_admin_js.modals.new_integration.zero_stage.title') }}
      </label>
    </div>
    <div v-if="availableIntegrations.length > 0" class="int-modal__radio-button md-radio">
      <input
        id="from-integration"
        v-model="integration.createMethod"
        value="fromIntegration"
        type="radio"
      >
      <label for="from-integration" class="int-modal__radio-label">
        {{ $t('super_admin_js.modals.new_integration.zero_stage.from_existing') }}
      </label>
    </div>
    <div v-if="integration.createMethod === 'fromIntegration' && availableIntegrations.length > 0">
      <b-select
        v-model="integration.selectedIntegrationId"
        placeholder="Choisir une intégration de référence"
      >
        <option
          v-for="availableIntegration in availableIntegrations"
          :key="availableIntegration.id"
          :value="availableIntegration.id"
        >
          {{ availableIntegration.name }}
        </option>
      </b-select>
    </div>
    <div v-if="integration.createMethod === 'fromIntegration' && availableIntegrations.length === 0">
      {{ $t('super_admin_js.modals.new_integration.zero_stage.integration_needed') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewIntegrationModalFirstStage',
  mixins: [],
  props: {
    action: {
      type: String,
      required: true,
    },
    integration: {
      type: Object,
      required: true,
    },
    availableIntegrations: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.first-stage-wrapper {
  padding: 30px;
}

.int-modal__radio-section {
  padding: 0 10px 0 20px;
  font-size: 14px;
  color: #333;
}

.int-modal__radio-label {
  font-weight: normal;
  cursor: pointer;
}

.int-modal__radio-sub-label {
  font-size: 13px;
  color: #7f858a;
}

$md-radio-checked-color: #1a6efc;
$md-radio-border-color: lightgrey;
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }

  50% {
    box-shadow: 0 0 0 $md-radio-ripple-size rgba(0, 0, 0, .1);
  }

  100% {
    box-shadow: 0 0 0 $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.md-radio {
  margin: 16px 0 32px;

  input[type="radio"] {
    display: none;

    &:checked + label::before {
      // border-color: $md-radio-checked-color;
      animation: ripple .2s linear forwards;
    }

    &:checked + label::after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: $md-radio-size;
    position: relative;
    padding: 9px ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &::before,
    &::after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }

    &::before {
      left: 0;
      top: 10px;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }

    &::after {
      top: calc($md-radio-size / 2 - $md-radio-checked-size / 2 + 10px);
      left: calc($md-radio-size / 2 - $md-radio-checked-size / 2);
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
</style>
