<template>
  <!-- eslint-disable  max-len -->
  <b-table
    ref="table"
    :data="charges"
    :loading="loading"
    class="list-table list-table--invoices"
    striped
    mobile-cards
    backend-sorting
    default-sort="id"
    @sort="(field, order) => sort(field, order)"
  >
    <template slot-scope="props">
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.id')"
        field="id"
        class="charge_column charge_column--id"
        sortable
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.invoice_number')"
        field="invoice_number"
        class="charge_column charge_column--invoice_number"
        sortable
      >
        {{ props.row.invoice_number }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.kind')"
        field="kind"
        class="charge_column charge_column--kind"
        sortable
      >
        {{ props.row.invoice.kind }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.type')"
        field="type"
        class="charge_column charge_column--type"
        sortable
      >
        {{ props.row.invoice.payment_or_refund }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.shop_name')"
        field="shop_name"
        class="charge_column charge_column--shop_name"
        sortable
      >
        <router-link :to="linkForShop(props.row)">
          {{ props.row.shop.name }}
        </router-link>
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.shop_id')"
        field="shop_id"
        class="charge_column charge_column--shop_id"
        sortable
      >
        {{ props.row.shop.id }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.organisation_name')"
        field="organisation_name"
        class="charge_column charge_column--organisation_name"
        sortable
      >
        <router-link :to="linkForOrganisation(props.row)">
          {{ props.row.organisation.name }}
        </router-link>
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.organisation_id')"
        field="organisation_id"
        class="charge_column charge_column--organisation_id"
        sortable
      >
        {{ props.row.organisation.id }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.customer_social_denomination')"
        field="customer_social_denomination"
        class="charge_column charge_column--customer_social_denomination"
        sortable
      >
        {{ props.row.customer_social_denomination }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.ht')"
        field="ht"
        class="charge_column charge_column--ht"
      >
        {{ props.row.charge.ht }}€
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.tva')"
        field="tva"
        class="charge_column charge_column--tva"
      >
        {{ props.row.charge.tva }}€
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.ttc')"
        field="ttc"
        class="charge_column charge_column--ttc"
      >
        {{ props.row.charge.ttc }}€
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.stripe_customer_id')"
        field="stripe_customer_id"
        class="charge_column charge_column--stripe_customer_id"
      >
        <a :href="props.row.stripe_customer_link">
          {{ props.row.stripe_customer_id }}
        </a>
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.created_at')"
        field="created_at"
        class="charge_column charge_column--created_at"
        sortable
      >
        {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.url')"
        field="url"
        class="charge_column charge_column--url"
      >
        <a
          :href="downloadLinkFor(props.row.url)"
          target="_blank"
        >
          <b-icon icon="download" />
        </a>
      </b-table-column>
      <b-table-column
        v-if="canCreateOrUpdateInvoices"
        :label="$t('super_admin_js.stripe_charge.table.fields.edit')"
        field="edit"
        class="charge_column charge_column--edit"
      >
        <a
          role="button"
          @click="handleEditClick(props.row)"
        >
          <b-icon icon="pencil" />
        </a>
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.stripe_charge.table.fields.send_invoice_or_refund')"
        field="send_again"
        class="charge_column charge_column--send_again"
      >
        <a
          v-if="props.row.visible"
          role="button"
          @click="handlePreviewClick(props.row)"
        >
          <b-icon icon="envelope" />
        </a>
        <span
          v-else
          class="invoice_pending"
        >
          {{ $t('super_admin_js.stripe_charge.table.waiting') }}
        </span>
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
import httpClient from '@config/http_client';
import { buildLinkFor } from '@config';

import moment from 'moment';

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    canCreateOrUpdateInvoices: {
      type: Boolean,
      required: true,
    },
    charges: {
      type: Array,
      required: true,
    },
    handleSortChange: {
      type: Function,
      required: true,
    },
    handleEditClick: {
      type: Function,
      required: true,
    },
    handlePreviewClick: {
      type: Function,
      required: true,
    },
  },
  methods: {
    downloadLinkFor(url) {
      return buildLinkFor(url);
    },
    moment(date) {
      return moment(date);
    },
    linkForOrganisation(invoice) {
      if (!invoice.organisation) return {};
      return {
        name: 'organisation',
        params: {
          id: invoice.organisation.id,
        },
      };
    },
    linkForShop(invoice) {
      return {
        name: 'shop',
        params: {
          id: invoice.shop.id,
        },
      };
    },
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;
      this.handleSortChange(field, order);
    },
    sendInvoiceAgain(charge) {
      httpClient
        .post('/super_admin/api/send_invoice_again', {
          stripe_invoice_id: charge.invoice.id,
          stripe_charge_id: charge.id,
        })
        .then((r) => {
          this.makeSuccessToast(this.$t('super_admin_js.stripe_charge.new.toasts.sent'));
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('super_admin_js.stripe_charge.new.toasts.sent'));
        });
    },
    humanReadableDate(date) {
      return new Date(date);
    },
  },
};
</script>

<style lang="scss">
  .charge_column--customer_email,
  .charge_column--address {
    white-space: nowrap;
  }

  .invoice_pending {
    border: 2px solid #ff9600;
    padding: 3px;
    border-radius: 3px;
    background-color: rgba(255, 150, 0, .2);
    color: #ff9600;
    font-weight: 600;
  }
</style>
