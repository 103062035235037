<template>
  <SkModal
    :id="`lll-enable-confirmation-modal`"
    :modal-title="$t(`super_admin_js.modals.lll_enable_confirmation.title_${updateModalVerb}`)"
    modal-title-classes="lll-enable-confirmation-modal-title"
    :submit-button-label="submitButtonLabel"
  >
    <template #body>
      <div class="lll-enable-confirmation-modal__content">
        {{ modalBody }}
      </div>
    </template>
    <template #submit-btn>
      <SkButton
        :variant="submitVariant"
        @click="handleSubmit"
      >
        {{ submitButtonLabel }}
      </SkButton>
    </template>
  </SkModal>
</template>

<script>
import {
  SkModal,
  SkButton,
  skListenersMixin,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

export default {
  components: {
    SkModal,
    SkButton,
  },
  mixins: [skListenersMixin],
  props: {
    enabledValue: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    submitVariant() {
      return this.enabledValue ? 'danger' : 'primary';
    },
    updateModalVerb() {
      return this.enabledValue ? 'disabling' : 'enabling';
    },
    modalBody() {
      return this.$t(`super_admin_js.modals.lll_enable_confirmation.content_${this.updateModalVerb}`);
    },
    submitButtonLabel() {
      return this.enabledValue ? this.$t('super_admin_js.labour_laws.buttons.disable')
        : this.$t('super_admin_js.labour_laws.buttons.activate');
    },
  },
  methods: {
    handleSubmit() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, 'lll-enable-confirmation-modal');
      setTimeout(() => {
        this.$emit('validate', { newStatus: !this.enabledValue });
      }, 100); // timeout is for looks, to avoid seeing the modal button changing value
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header .lll-enable-confirmation-modal-title {
  font-weight: bold;
  font-family: $poppins-font;
  font-size: 16px;
}

.lll-enable-confirmation-modal__content {
  padding: 24px;
  font-family: $product-sans-font;
  font-size: 14px;
}
</style>
