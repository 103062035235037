import Vue from 'vue';
import Router from 'vue-router';

import ErrorPage from '../pages/ErrorPage';
import Simulator from '../pages/Simulator';
import OrganisationLight from '../pages/OrganisationLight';
import Organisations from '../pages/Organisations';
import Organisation from '../pages/Organisation';
import AccountCreation from '../pages/AccountCreation';
import Invoices from '../pages/Invoices';
import Users from '../pages/Users';
import User from '../pages/User';
import Shop from '../pages/Shop';
import Shops from '../pages/Shops';
import SkelloMap from '../pages/SkelloMap';
import FeatureFlags from '../pages/FeatureFlags';
import LabourLaws from '../pages/LabourLaws';
import ManageSuperAdminUsers from '../pages/ManageSuperAdminUsers';

Vue.use(Router);

const routes = [
  {
    path: '*',
    name: 'error-page',
    meta: { isGuestPage: true },
    component: ErrorPage,
  },

  { path: '/', name: 'home', component: Organisations },
  { path: '/organisations_light/:id', name: 'organisation_light', component: OrganisationLight },
  { path: '/simulator', name: 'simulator', component: Simulator },
  { path: '/organisations', name: 'organisations', component: Organisations },
  { path: '/shops', component: Shops },
  { path: '/organisations/:id', name: 'organisation', component: Organisation },
  { path: '/account_creation', component: AccountCreation },
  { path: '/users', name: 'users', component: Users },
  { path: '/users/:id', name: 'user', component: User },
  { path: '/shops/:id', name: 'shop', component: Shop },
  { path: '/invoices', component: Invoices },
  { path: '/skello_map', component: SkelloMap },
  { path: '/feature_flags', component: FeatureFlags },
  { path: '/labour_laws', component: LabourLaws },
  { path: '/manage_super_admin_users', component: ManageSuperAdminUsers },
];

const router = new Router({
  mode: 'history',
  routes,
});

export default router;
