<template>
  <!-- eslint-disable  max-len -->
  <div>
    <tab-body-first-line
      :title="$t('super_admin_js.labels.shop.settings.title')"
      :buttons="buttons"
    />
    <h5 class="title is-5">
      {{ $t('super_admin_js.titles.shop.not_saas.title') }}
    </h5>
    <div class="form-group">
      <h6 class="title is-6">
        {{ $t('super_admin_js.titles.shop.not_saas.optimization') }}
      </h6>
      <sa-form
        :fields="basicsFormFields"
        :object="shopProps"
      />
      <h6 v-if="shopProps.badging" class="title is-6">
        {{ $t('super_admin_js.titles.shop.not_saas.badging') }}
      </h6>
      <sa-form
        :fields="badgingFormFields"
        :object="shopProps"
      />
    </div>

    <h5 class="title is-5">
      {{ $t('super_admin_js.titles.shop.is_saas.title') }}
    </h5>
    <div class="form-group">
      <h6 class="title is-6">
        {{ $t('super_admin_js.titles.shop.is_saas.base_rules') }}
      </h6>
      <sa-form
        :fields="saasBaseFormFields"
        :object="shopProps"
      />
    </div>

    <br>
    <div class="field">
      <div class="field-label is-normal" />
      <div class="field-body">
        <a
          class="button is-primary"
          @click="submitForm()"
        >
          {{ $t('super_admin_js.actions.shop.validate') }}
        </a>
      </div>
    </div>
    <perms-replication-modal
      :visible.sync="permsReplicationModalVisible"
      :organisation-id="shop.organisationId"
      :callback="refreshShop"
    />
    <add-automatic-plannings-modal
      :visible.sync="addAutomaticPlanningsModalVisible"
      :shop="shop"
      :callback="refreshShop"
    />
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import Form from '../../components/Form';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import shopSettingsSaasFormFields from '../../form_fields/shopSettingsSaaSFormFields';
import shopSettingsSuperAdminFormFields from '../../form_fields/shopSettingsSuperAdminFormFields';
import actionButtons from '../../components/Buttons/Shop/ShopSettings';
import ShopProperties from '../../components/Helpers/ShopProperties';
import PermsReplicationModal from '../../components/PermsReplicationModal';
import AddAutomaticPlanningsModal from '../../components/AddAutomaticPlanningsModal';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    TabBodyFirstLine,
    saForm: Form,
    PermsReplicationModal,
    AddAutomaticPlanningsModal,
  },
  props: {
    shop: {
      type: Object,
      required: true,
    },
    canAddAutomaticPlanningCredits: {
      type: Boolean,
      required: true,
    },
    refreshShop: {
      type: Function,
    },
  },
  data() {
    return {
      buttons: actionButtons(this),
      badgingFormFields: shopSettingsSuperAdminFormFields.badging(this),
      basicsFormFields: shopSettingsSuperAdminFormFields.base(this),
      saasBaseFormFields: shopSettingsSaasFormFields.base(this),
      initialShopProps: {},
      shopProps: {},
      shopUpdatedProps: {},
      hoursRange: [],
      paidVacationCalculationTypes: [],
      fetchingData: false,
      permsReplicationModalVisible: false,
      addAutomaticPlanningsModalVisible: false,
    };
  },
  computed: {
    ...ShopProperties.shopComputed(),
    submitData() {
      this.clearUpdatedProps();
      const data = { ...this.shopProps, ...this.shopUpdatedProps };

      return data;
    },
  },
  watch: {
    ...ShopProperties.shopWatchers(),
    shopUpdatedProps: {
      handler() {
        this.clearUpdatedProps();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    clearUpdatedProps() {
      Object.keys(this.shopUpdatedProps).forEach((key) => {
        const prop = this.shopUpdatedProps[key];
        if (prop === null || prop === undefined) {
          delete this.shopUpdatedProps[key];
        }
      });
    },
    fetching() {
      this.fetchingData = true;
    },
    fetched() {
      this.fetchingData = false;
    },
    fetch() {
      this.fetchShop();
      this.fetchPredefinedValues();
    },
    fetchShop() {
      const url = `/super_admin/api/shops/${this.shop.id}?unserialized=true`;

      httpClient
        .get(url)
        .then((r) => {
          this.fetching();
          const shop = r.data;
          this.initialShopProps = { ...shop };
          this.shopProps = shop;
          this.fetched();
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('errors.error'));
        });
    },
    fetchPredefinedValues() {
      const url = '/super_admin/api/shops/predefined_values';
      httpClient
        .get(url)
        .then((r) => {
          this.fetching();
          const result = r.data;

          this.hoursRange = result.hours_range;
          this.paidVacationCalculationTypes = result.paid_vacation_calculation_types;
          this.saasBaseFormFields = shopSettingsSaasFormFields.base(this);
          this.badgingFormFields = shopSettingsSuperAdminFormFields.badging(this);
          this.fetched();
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('errors.error'));
        });
    },
    handleReplicateParamsClick() {
      this.permsReplicationModalVisible = true;
    },
    handleAddAutomaticPlanningsClick() {
      this.addAutomaticPlanningsModalVisible = true;
    },
    submitForm() {
      const url = `/super_admin/api/shops/${this.shop.id}`;
      httpClient
        .patch(url, {
          shop: this.submitData,
        })
        .then((response) => {
          const newShop = response.data.shop;

          this.$emit('update:shop', newShop);
          this.initialShopProps = { ...newShop };
          this.shopUpdatedProps = {};

          this.makeSuccessToast(
            this.$t('super_admin_js.messages.shop.update_success'),
          );
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.messages.shop.update_failure'),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-group {
    margin-bottom: 5em;

    & > * {
      margin-left: 4em;
      margin-bottom: 1.7em;
    }

    h6 {
      margin-left: 0;
      margin-top: .7em;
      // font-size: 1.3em;
      font-weight: normal;
      text-decoration: underline;
    }
  }
</style>
