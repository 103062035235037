import axios from 'axios';

import { AuthWebInterceptor } from '@skelloapp/skello-auth-client/web';

import { security } from './security';
import { ApiConfig } from '.';

const AxiosInstance = axios.create(ApiConfig);

export const UnauthenticatedHttpClient = axios.create(ApiConfig);

export const sendRequestConfig = async (config) => {
  window.activeVueResources = window.activeVueResources || 0;
  window.activeVueResources += 1;

  const authToken = await security.getAuthToken();

  if (authToken) {
    const authClient = security.useSvcUserAuthClient(authToken?.tokenData?.subMail)
    ? security.authClient
    : security.svcUserAuthClient;

    // this automaticly add the accesstoken in authorization header
    return new AuthWebInterceptor(authClient, {
      token: authToken.token,
      refreshToken: authToken.refreshToken,
    }).request(config);
  }

  return config;
};

export const successReceivedRequestConfig = async (config) => {
  window.activeVueResources = window.activeVueResources || 0;
  window.activeVueResources += 1;

  return config;
};

export const errorReceivedRequestConfig = async (error) => {
  window.activeVueResources -= 1;

  const authToken = await security.getAuthToken();

  if (!authToken) throw error;

  const authClient = security.useSvcUserAuthClient(authToken?.tokenData?.subMail)
    ? security.authClient
    : security.svcUserAuthClient;

  // When a expired jwt happen during the request,
  // this method ask for a new one and replay the request again
  await new AuthWebInterceptor(
    authClient,
    {
      token: authToken.token,
      refreshToken: authToken.refreshToken,
    },
  )
    .responseError(error, async (data) => {
      await security.setAuthToken(data);
      error.config.headers.Authorization = `Bearer ${data.token}`;

      return AxiosInstance.request(error.config);
    });

  throw error;
};

AxiosInstance.interceptors.request.use(sendRequestConfig);
AxiosInstance.interceptors.response.use(successReceivedRequestConfig, errorReceivedRequestConfig);

export const updateRootUrl = (newRootUrl) => {
  AxiosInstance.defaults.baseURL = newRootUrl;
  localStorage.setItem('relative-api-root', newRootUrl);

  return true;
};

export default AxiosInstance;
