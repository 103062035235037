import httpClient from '@config/http_client';

export default (number) => ({
    methods: {
      handleSendInviteClick() {
        let dialogTitle;
        let dialogMessage;
        let successMessage;
        let failureMessage;

        if (number === 'singular') {
          dialogTitle = this.$t('super_admin_js.users.send_invite.singular.title');
          dialogMessage = this.$t('super_admin_js.users.send_invite.singular.message');
          successMessage = this.$t('super_admin_js.users.send_invite.singular.success');
          failureMessage = this.$t('super_admin_js.users.send_invite.singular.failure');
        } else {
          dialogTitle = this.$t('super_admin_js.users.send_invite.plural.title');
          dialogMessage = this.$t('super_admin_js.users.send_invite.plural.message');
          successMessage = this.$t('super_admin_js.users.send_invite.plural.success');
          failureMessage = this.$t('super_admin_js.users.send_invite.plural.failure');
        }

        this.$dialog.confirm({
          title: dialogTitle,
          message: dialogMessage,
          confirmText: this.$t('super_admin_js.users.send_invite.send'),
          cancelText: this.$t('super_admin_js.users.send_invite.cancel'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            httpClient
              .post('/super_admin/api/invitations', this.actionParams)
              .then((r) => this.makeToast('is-success', successMessage))
              .catch((e) => this.makeToast('is-danger', failureMessage));
          },
        });
      },
    },
  });
