<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <div
        v-for="(users, index) in separatedValues"
        :key="index"
        class="users-role-column"
      >
        <ul>
          <li v-for="(item, userIndex) in users" :key="userIndex">
            <router-link :to="item.url">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormLinksList',
  mixins: [saFormTypeMixin],
  computed: {
    separatedValues() {
      return _.chunk(this.value, 15);
    },
  },
};
</script>

<style lang="scss">
.users-role-column {
  padding: 1em;
}
</style>
