<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <slot name="label" />
    </div>
    <div class="field-body">
      <slot name="input" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SaFormItemWrapper',
};
</script>
