<template>
  <!-- eslint-disable  max-len -->
  <div
    :class="classes"
    class="spinner"
  >
    <div class="spin-loading loading--double" />
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  props: {
    noMargin: {
      default: false,
    },
    small: {
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'is-small': this.small,
        'no-margin': (this.noMargin || this.small),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  z-index: 1000;
  background-color: white;
  display: flex;
  justify-content: center;
  padding-top: 25vh;

  &.is-small .spin-loading {
    height: 30px;
    width: 30px;
  }

  &.no-margin {
    padding-top: 20px;
  }
}

.spin-loading {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: .25rem solid rgba(0, 186, 218, .2);
  border-top-color: #00bada;
  animation: spin 1s infinite linear;
}

.spin-loading.loading--double {
  border-style: dotted;
  border-width: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
