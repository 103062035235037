<template>
  <div
    v-show="visible"
    class="error-toast"
    :style="offsetStyle"
  >
    <CircledExclamationMarkIcon
      ref="CircledExclamationMarkIcon"
      class="error-toast__icon"
      width="14"
    />
    <div class="error-toast__content">
      <slot name="content" />
    </div>
    <ClosingXIcon
      class="error-toast__closing-x"
      fill=""
      @click.native="close"
    />
  </div>
</template>

<script>
import {
  CircledExclamationMarkIcon,
  ClosingXIcon,
} from '@skelloapp/skello-ui';

export default {
  name: 'UserImportErrorToast',
  components: { CircledExclamationMarkIcon, ClosingXIcon },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    bottomOffset: {
      type: Number,
      default: 30,
    },
  },
  computed: {
    offsetStyle() {
      return {
        bottom: `${this.bottomOffset}px`,
      };
    },
  },
  methods: {
    close() {
      this.$emit('close-toast');
    },
  },
};
</script>

<style lang="scss" scoped>
.error-toast {
  z-index: $z-index-toast;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-family: $poppins-font;
  border-radius: 4px;
  line-height: 22px;
  min-height: 54px;
  min-width: 620px;
  width: 620px;
  background-color: $sk-red-dark-1;
  color: white;

  .error-toast__closing-x {
    position: absolute;
    right: 16px;
    top: 16px;
    fill: white;
    cursor: pointer;
  }

  .error-toast__content {
    flex: 1;
    padding: 0 40px;
    margin: 40px 0;
    max-height: 120px;
    overflow-y: auto;
  }

  .error-toast__icon {
    position: absolute;
    left: 16px;
    top: 24px;
    fill: white;
  }
}
</style>
