<template>
  <tr class="license-row">
    <td class="license-row--data-inline">
      <LicensePositionBadge
        :position="license.position + 1"
        class="license-row--badge"
      />
      <p class="license-row--license-name">
        {{ license.name }}
      </p>
    </td>
    <td class="license-row--small-column">
      <WhiteSkSwitch
        :disabled="disabledLicense"
        :value="license.visible"
        inputClass="licenses__input"
        @input="updateLicenseVisibility"
      />
    </td>
  </tr>
</template>

<script>
import LicensePositionBadge from '../LicensePositionBadge';
import WhiteSkSwitch from '../WhiteSkSwitch';

export default {
  name: 'LicenseRow',
  components: { LicensePositionBadge, WhiteSkSwitch },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disabledLicense() {
      return this.license.original_type === 'system_admin';
    },
  },
  methods: {
    updateLicenseVisibility(checkboxValue) {
      if (this.license.visible === checkboxValue) return;

      this.$emit('updateLicenseVisibility', { licenseId: this.license.id, value: checkboxValue });
    },
  },
};
</script>

<style lang="scss" scoped>
.license-row {
  height: 60px;
  line-height: 60px;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.license-row > td {
  height: 60px;
  line-height: 60px;
  padding-left: 21px;
}

.license-row--small-column {
  width: 180px;
  text-align: center;
}

.license-row--data-inline {
  display: flex;
}

.license-row--license-name {
  font-weight: 700;
  margin-left: 20px;
}

.license-row--badge {
  line-height: 80px;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
