import { render, staticRenderFns } from "./SaFormLink.vue?vue&type=template&id=e41c576c"
import script from "./SaFormLink.vue?vue&type=script&lang=js"
export * from "./SaFormLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_@babel+core@7.22.20__css-loader@6.8.1_webpack@5._dsbxphxapo357or5iovdf5hjkq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports