<template>
  <!-- eslint-disable  max-len -->
  <div class="pb-wrapper">
    <div class="pb-bubbles-wrapper">
      <div class="pb-step-wrapper">
        <div
          class="pb-step-bubble active pointer"
          @click="comeBackToFirstStep"
        >
          <div class="pb-step-number">
            1
          </div>
        </div>
      </div>
      <div
        :class="stepClasses(1)"
        class="pb-step-line"
      />
      <div class="pb-step-wrapper">
        <div
          :class="stepClasses(2)"
          class="pb-step-bubble"
          @click="comeBackToSecondStep"
        >
          <div class="pb-step-number">
            2
          </div>
        </div>
      </div>
      <div
        :class="stepClasses(3)"
        class="pb-step-line"
      />
      <div class="pb-step-wrapper">
        <div
          :class="stepClasses(3)"
          class="pb-step-bubble"
          @click="comeBackToThirdStep"
        >
          <div class="pb-step-number">
            3
          </div>
        </div>
      </div>
      <div
        v-if="currentUser.can_add_integration_config"
        :class="stepClasses(4)"
        class="pb-step-line"
      />
      <div v-if="currentUser.can_add_integration_config" class="pb-step-wrapper">
        <div
          :class="stepClasses(4)"
          class="pb-step-bubble"
          @click="comeBackToFourthStep"
        >
          <div class="pb-step-number">
            4
          </div>
        </div>
      </div>
    </div>
    <div class="pb-label-wrapper">
      <div
        :style="{ 'paddingLeft': firstTextPadding + 'px' }"
        class="pb-step-label active pointer"
        @click="comeBackToFirstStep"
        v-html="firstText"
      />
      <div
        :class="stepClasses(2)"
        :style="{ 'paddingLeft': secondTextPadding + 'px' }"
        class="pb-step-label active pointer"
        @click="comeBackToSecondStep"
        v-html="secondText"
      />
      <div
        :class="stepClasses(3)"
        :style="{ 'paddingLeft': thirdTextPadding + 'px' }"
        class="pb-step-label"
        @click="comeBackToThirdStep"
        v-html="thirdText"
      />
      <div
        v-if="currentUser.can_add_integration_config"
        :class="stepClasses(4)"
        class="pb-step-label"
        @click="comeBackToFourthStep"
        v-html="fourthText"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    changeStep: {
      type: Function,
      required: true,
    },
    firstText: {
      type: String,
      required: false,
      default() {
        return this.$t('super_admin_js.integration_progress_bar.file_format');
      },
    },
    secondText: {
      type: String,
      required: false,
      default() {
        return this.$t('super_admin_js.integration_progress_bar.absences');
      },
    },
    thirdText: {
      type: String,
      required: false,
      default() {
        return this.$t('super_admin_js.integration_progress_bar.pay_variables');
      },
    },
    fourthText: {
      type: String,
      required: false,
      default() {
        return this.$t('super_admin_js.integration_progress_bar.upload');
      },
    },
    firstTextPadding: {
      type: Number,
      required: false,
      default: 0,
    },
    secondTextPadding: {
      type: Number,
      required: false,
      default: 0,
    },
    thirdTextPadding: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState(['currentUser']),
  },
  methods: {
    comeBackToFirstStep() {
      this.changeStep(1);
    },
    comeBackToSecondStep() {
      this.changeStep(2);
    },
    comeBackToThirdStep() {
      this.changeStep(3);
    },
    comeBackToFourthStep() {
      this.changeStep(4);
    },
    stepClasses(targetStepIndex) {
      return {
        active: this.step >= targetStepIndex - 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$bubble-size: 25px;
$active-colour: #1a6efc;
$inactive-colour: #ccc;
$primary-grey: #6d7d8c;

.pb-bubbles-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 2em;
}

.pb-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pb-step-line {
  flex: .45;
  height: 5px;
  margin-top: 13px;
  background-color: $inactive-colour;

  &.active {
    background-color: $active-colour;
  }
}

.pb-step-wrapper {
  position: relative;
  top: 3px;
}

.pb-step-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: $bubble-size;
  width: $bubble-size;
  border-radius: 50%;
  background-color: $primary-grey;
  color: white;
  margin: 0 5px;

  &.active {
    background-color: $active-colour;
  }
}

.pb-step-number {
  margin-top: 2px;
}

.pb-step-label {
  margin-top: 1em;
  min-width: 7em;
  letter-spacing: .03em;
  color: #000;
  font-size: 14px;
}

.pointer {
  cursor: pointer;
}
</style>
