<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.94921 2.69612L13.204 5.95085L4.96528 14.1896L1.71237 10.9348L9.94921 2.69612ZM15.6737 1.91115L14.2222 0.459656C13.6613 -0.101298 12.7504 -0.101298 12.1875 0.459656L10.7971 1.85005L14.0519 5.10481L15.6737 3.48299C16.1088 3.04789 16.1088 2.34623 15.6737 1.91115ZM0.00908774 15.5096C-0.0501447 15.7762 0.190536 16.015 0.457143 15.9502L4.08402 15.0708L0.831112 11.8161L0.00908774 15.5096Z" fill="#3A3A3A" />
  </svg>
</template>

<script>

export default {
  name: 'IntegrationEditSvg',
};
</script>
