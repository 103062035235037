<template>
  <div>
    <b-table :data="users">
      <template slot-scope="props">
        <b-table-column :label="$t('super_admin_js.manage_super_admin_users.table.email')">
          {{ props.row.email }}
        </b-table-column>
        <b-table-column :label="$t('super_admin_js.manage_super_admin_users.table.action')">
          <a
            role="button"
            @click="showConfirmation(props.row.email)"
          >
            <b-icon
              pack="fa"
              icon="trash"
              size="is-small"
              type="is-danger"
            />
          </a>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  skListenersMixin,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';

export default {
  mixins: [skListenersMixin],
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showConfirmation(email) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'delete-super-admin-user-modal', email);
    },
  },
};
</script>
