<template>
  <SkTable
    :columns="tableHeaders"
    filled-arrow
    :loading="loading"
    :is-empty="isEmpty"
    @sort="emitSort"
  >
    <template #empty-state>
      {{ $t('super_admin_js.events_history.empty') }}
    </template>
    <tr
      v-for="event in events"
      :key="event.id"
      class="table__row"
    >
      <td
        v-if="isOrganisationLevel"
        class="table__cell shop__name"
      >
        <div class="table__cell-content text-truncate">
          {{ event.shop }}
        </div>
      </td>

      <td class="table__cell">
        <div class="table__cell-content text-truncate">
          {{ $t(event.source) }}
        </div>
      </td>

      <td class="table__cell type__title">
        <div class="table__cell-content">
          {{ $t(event.type) }}
        </div>
      </td>

      <td class="table__cell user">
        <div class="table__cell-content">
          {{ event.context.user }}
        </div>
      </td>

      <td class="table__cell subtype">
        <div class="table__cell-content">
          {{ $t(event.subtype_text, event.context) }}
        </div>
      </td>

      <td class="table__cell">
        <div class="table__cell-content text-truncate">
          {{ event.visualDate }}
        </div>
      </td>
    </tr>
  </SkTable>
</template>

<script>
import { SkTable } from '@skelloapp/skello-ui';

export default {
  components: {
    SkTable,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    isOrganisationLevel: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
        loaded: true,
        shopNames: {},
      };
  },
  computed: {
    orgHeaders() {
      return [
        {
          name: 'shopId',
          title: this.$t('super_admin_js.events_history.table.shop'),
          class: 'cell__header',
        },
      ];
    },
    headers() {
      return ['source', 'type', 'userId', 'subtype', 'visualDate'].map((name) => ({
        name,
        title: this.$t(`super_admin_js.events_history.table.${name}`),
        sort: name === 'visualDate',
        class: 'cell__header',
      }));
    },
    tableHeaders() {
      return this.isOrganisationLevel ? [...this.orgHeaders, ...this.headers] : this.headers;
    },
    isEmpty() {
      return this.events.length === 0;
    },
  },
  methods: {
    emitSort(_event, column, direction) {
      this.$emit('eventsSort', { direction });
    },
  },
};
</script>

<style lang="scss">
  $primary-grey: #6d7d8c;

  td.table__cell {
    text-align: left;
    height: 60px;
    padding: 0 0 0 30px;
    border-bottom: 1px solid $sk-gray-light-4;
    vertical-align: middle;

    &.shop__name,
    &.type__title {
      min-width: 120px;
      max-width: 150px;
    }

    &.user {
      max-width: 150px;
    }

    &.subtype {
      color: $primary-grey;
      padding: 0 0 0 15px;
      max-width: 200px;
    }
  }

  .cell__header {
    min-width: 100px;
    padding: 0 0 0 15px;

    > div {
      margin-left: 0 !important;
    }
  }
</style>
