import Vue from 'vue';
import Vuex from 'vuex';

import httpClient from '@config/http_client';
import i18n from '../i18n';

Vue.use(Vuex);

const svcLLLUrl = process.env.VUE_APP_LLL_SERVICE_URL;

const initialState = {
  currentUser: {
    id: '',
    firstName: '',
    lastName: '',
  },
  apiHost: localStorage.getItem('relative-api-root'),
  userDataLoading: true,
  productFeatureFlags: [],
  devFeatureFlags: [],
  labourLaws: [],
};

const mutations = {
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
  },
  setApiHost(state, apiHost) {
    state.apiHost = apiHost;
  },
  setUserDataLoading(state, userDataLoading) {
    state.userDataLoading = userDataLoading;
  },
  setProductFeatureFlags(state, productFeatureFlags) {
    state.productFeatureFlags = productFeatureFlags;
  },
  setDevFeatureFlags(state, devFeatureFlags) {
    state.devFeatureFlags = devFeatureFlags;
  },
  setLabourLaws(state, labourLaws) {
    state.labourLaws = labourLaws;
  },
};

const actions = {
  setCurrentUser({ commit }, currentUser) {
    const {
      id,
      email,
      firstName,
      lastName,
    } = currentUser;
    analytics.identify(
      id,
      {
        email,
        firstName,
        lastName,
        anonymous: false,
      },
    );
    commit('setCurrentUser', currentUser);
  },
  setApiHost({ commit }, apiHost) {
    commit('setApiHost', apiHost);
  },
  removeCurrentUser({ commit }) {
    commit('setCurrentUser', { id: '', firstName: '', lastName: '' });
  },
  fetchCurrentUser({ commit }, userId) {
    return httpClient.get(`/super_admin/api/users/${userId}`)
    .then(({ data }) => {
        i18n.locale = data.user.lang;
        actions.setCurrentUser({ commit }, data.user);
        return data;
      });
  },
  async fetchFeatureFlags({ commit }) {
    await httpClient
      .get('/super_admin/api/feature_flags')
      .then(({ data }) => {
        commit('setDevFeatureFlags', data.feature_flags_dev);
        commit('setProductFeatureFlags', data.feature_flags);
      });
  },
  fetchLabourLaws({ commit }, country = 'fr') {
    return httpClient
      .get(
        `${svcLLLUrl}/labour_laws?country=${country}`,
      )
      .then((response) => {
        commit('setLabourLaws', response.data);
      });
  },
  fetchOneLabourLaw({ commit }, params) {
    const url = `${svcLLLUrl}/labour_law?key=${params.key}&scope=${params.scope}&country=${params.country}`;
    return httpClient
      .get(url)
      .then((data) => data);
  },
  upsertLabourLaw({ commit }, params) {
    return httpClient
      .put(`${svcLLLUrl}/labour_law`, params)
      .then((data) => data);
  },
  deleteLabourLaw({ commit }, params) {
    const url = `${svcLLLUrl}/labour_law?key=${params.key}&scope=${params.scope}&country=${params.country}`;
    return httpClient
      .delete(url);
  },
};

function isFeatureFlagActive(featureFlags, flagName) {
  const featureFlag = featureFlags.find(
    (flag) => flag.name === flagName,
  );
  if (!featureFlag) return false;
  return !!featureFlag.active;
}

const getters = {
  isUserLoggedIn: ({ currentUser }) => currentUser.id,
  isDevFeatureFlagActive: (state) => (flagName) => isFeatureFlagActive(
    state.devFeatureFlags, flagName,
  ),
  isProductFeatureFlagActive: (state) => (flagName) => isFeatureFlagActive(
    state.productFeatureFlags, flagName,
  ),
};

export default new Vuex.Store({
  state: initialState,
  mutations,
  actions,
  getters,
});
