<template>
  <!-- eslint-disable  max-len -->
  <div :style="{ 'width': width + 'px' }" class="text-wrap">
    {{ text }}
  </div>
</template>

<script>

export default {
  name: 'IntegrationText',
  components: { },
  mixins: [],
  props: {
    text: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
      default: 200,
    },
  },
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.text-wrap {
  margin: 10px 5px 0 0;
  border-radius: 4px;
  padding: 0 5px 0 10px;
  width: 200px;
  height: 40px;
  background-color: #edf2f6;
  color: rgba(0, 0, 0, .87);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
