import { merge } from 'lodash';
import fr from './fr.yml';
import en from './en.yml';
import es from './es.yml';
import de from './de.yml';
import it from './it.yml';
import events from './events';

export default {
  ...{ fr: merge(fr.fr, events.fr) },
  ...{ en: merge(en.en, events.en) },
  ...{ es: merge(es.es, events.es) },
  ...{ de: merge(de.de, events.de) },
  ...{ it: merge(it.it, events.it) },
};
