<template>
  <SkBanner
    :message="message"
    :link-to="ticketLink"
    level="info"
    height="2em"
    link-to-label="Ticket"
  />
</template>

<script>
import { SkBanner } from '@skelloapp/skello-ui';

export default {
  name: 'ReviewAppBanner',
  components: { SkBanner },
  props: {
    apiHost: {
      type: String,
      required: true,
    },
  },
  computed: {
    message() {
      return this.$t('super_admin_js.review_app_toolbar', { api_host: this.apiHost });
    },
    ticketNumber() {
      const matchData = this.apiHost.match(/skello-(dev-\d+)-/);
      return matchData && matchData[1] && matchData[1].toUpperCase();
    },
    ticketLink() {
      if (!this.ticketNumber) return '';

      return `https://skello.atlassian.net/browse/${this.ticketNumber}`;
    },
  },
};
</script>
