<template>
  <!-- eslint-disable  max-len -->
  <a
    :class="btnClasses"
    role="button"
    :disabled="disabled"
    @click="handleClickUnlessDisabled"
    v-html="text"
  />
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  props: {
    handleClick: {},
    text: {},
    classes: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClasses() {
      return {
        'default-btn': true,
        'default-btn--disabled': this.disabled,
        ...this.classes,
      };
    },
  },
  methods: {
    handleClickUnlessDisabled() {
      if (this.disabled) return;

      this.handleClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.default-btn {
  padding: .5em 1.5em;
  margin: .5em;
  font-weight: bold;
  border-radius: 2px;

  &.default-btn--disabled {
    cursor: not-allowed;
  }
}
</style>
