<template>
  <!-- eslint-disable  max-len -->
  <div class="sk-switch">
    <label class="sk-switch__label">
      <input
        :name="name"
        :checked="value"
        :disabled="disabled"
        :class="inputClasses"
        type="checkbox"
        @change="handleChange"
        @click="$emit('click', $event)"
      >
      <span class="sk-switch__slider round" />
    </label>
  </div>
</template>

<script>

export default {
  name: 'WhiteSkSwitch',
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    inputClasses() {
      return ['sk-switch__hidden-input', this.inputClass];
    },
  },
  methods: {
    handleChange(e) {
      if (this.disabled) return;

      this.$emit('input', e.target.checked);
      this.$emit('change', e);
    },
  },
};
</script>
