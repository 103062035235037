<template>
  <!-- eslint-disable  max-len -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 1280 380"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_532_6480)">
      <path
        d="M406.808 330.712C451.888 330.712 484.032 304.448 484.032 268.776C484.032 199.784 382.112 211.936 382.112 188.808C382.112 180.968 389.168 175.872 399.752 175.872C413.864 175.872 443.264 182.144 460.12 194.688L480.504 151.96C461.296 136.672 432.288 130.008 405.632 130.008C359.376 130.008 327.232 156.272 327.232 193.904C327.232 264.856 428.368 245.648 428.368 270.736C428.368 278.576 418.96 284.456 405.632 284.456C384.072 284.456 354.28 275.048 339.776 261.72L319 302.88C338.992 319.736 373.88 330.712 406.808 330.712Z"
        fill="black"
      />
      <path
        d="M630.122 133.928L563.482 213.504V52H508.21V326.4H563.482V240.16L633.258 326.4H704.21L617.97 226.832L697.938 133.928H630.122Z"
        fill="black"
      />
      <path
        d="M795.445 330.32C835.429 330.32 867.573 315.424 881.685 289.944L838.173 263.288C831.509 274.656 813.869 282.888 796.621 282.888C770.749 282.888 753.109 269.56 748.013 244.864H885.605C886.781 237.416 887.173 228.4 887.173 221.736C887.173 170.384 846.405 130.008 794.269 130.008C738.213 130.008 694.309 173.128 694.309 229.184C694.309 286.416 738.997 330.32 795.445 330.32ZM748.405 207.232C754.285 187.24 768.789 175.872 791.525 175.872C811.909 175.872 828.765 188.808 831.509 207.232H748.405Z"
        fill="black"
      />
      <path d="M967.457 52H912.185V326.4H967.457V52Z" fill="black" />
      <path d="M1056.13 52H1000.86V326.4H1056.13V52Z" fill="black" />
      <path
        d="M1179.35 130.008C1122.51 130.008 1079.39 173.128 1079.39 230.36C1079.39 287.592 1122.51 330.32 1179.35 330.32C1235.8 330.32 1279.31 287.592 1279.31 230.36C1279.31 173.128 1235.8 130.008 1179.35 130.008ZM1133.09 230.36C1133.09 203.312 1153.08 182.928 1179.35 182.928C1205.22 182.928 1225.6 203.312 1225.6 230.36C1225.6 257.016 1205.22 277.4 1179.35 277.4C1153.08 277.4 1133.09 257.016 1133.09 230.36Z"
        fill="black"
      />
      <g clip-path="url(#clip1_532_6480)">
        <path
          d="M15.8468 184.486C-5.31106 162.691 -4.79447 127.87 17.0006 106.713L65.0744 60.0444L150.052 147.582L62.515 232.559L15.8468 184.486Z"
          fill="#50A5FF"
        />
        <path
          d="M65.0742 60.0444L108.843 17.5555C133.016 -5.91045 171.634 -5.3375 195.1 18.8352V18.8352C218.566 43.008 217.993 81.6268 193.821 105.093L150.052 147.582L65.0742 60.0444Z"
          fill="#2B66FE"
        />
        <path
          d="M17.4665 361.306C-5.99952 337.133 -5.42658 298.514 18.7462 275.048L150.052 147.582L196.72 195.655C217.878 217.451 217.361 252.271 195.566 273.429L103.724 362.586C79.5513 386.052 40.9325 385.479 17.4665 361.306V361.306Z"
          fill="#2E26F2"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_532_6480">
        <rect width="1280" height="380" fill="white" />
      </clipPath>
      <clipPath id="clip1_532_6480">
        <rect width="213" height="380" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'SkelloIcon',
  props: {
    width: {
      type: String,
      default: '99',
    },
    height: {
      type: String,
      default: '32',
    },
  },
};
</script>
