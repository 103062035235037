<template>
  <!-- eslint-disable  max-len -->
  <div class="form-field-wrapper">
    <div
      :is="inputType(field.type)"
      v-for="field in filteredFields"
      :key="field.key"
      :object.sync="object"
      :field="field"
      :value="getValueFor(field)"
      :disabled="field.disabled"
      :tooltip="field.tooltip"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import SaFormCheckbox from './Fields/SaFormCheckbox';
import SaFormDate from './Fields/SaFormDate';
import SaFormDateInput from './Fields/SaFormDateInput';
import SaFormEmail from './Fields/SaFormEmail';
import SaFormPhone from './Fields/SaFormPhone';
import SaFormGroup from './Fields/SaFormGroup';
import SaFormHoursRange from './Fields/SaFormHoursRange';
import SaFormInputNumber from './Fields/SaFormInputNumber';
import SaFormLink from './Fields/SaFormLink';
import SaFormInput from './Fields/SaFormInput';
import SaFormLinksList from './Fields/SaFormLinksList';
import SaFormLinksTextList from './Fields/SaFormLinksTextList';
import SaFormLinkText from './Fields/SaFormLinkText';
import SaFormSelect from './Fields/SaFormSelect';
import SaFormSwitch from './Fields/SaFormSwitch';
import SaFormTag from './Fields/SaFormTag';
import SaFormText from './Fields/SaFormText';
import SaFormTextWithEditAndDeleteSvg from './Fields/SaFormTextWithEditAndDeleteSvg';
import SaFormSmsProgressBar from './Fields/SaFormSmsProgressBar';

export default {
  name: 'SaForm',
  components: {
    SaFormDate,
    SaFormDateInput,
    SaFormEmail,
    SaFormPhone,
    SaFormLink,
    SaFormInput,
    SaFormLinksList,
    SaFormLinksTextList,
    SaFormLinkText,
    SaFormTag,
    SaFormText,
    SaFormCheckbox,
    SaFormHoursRange,
    SaFormInputNumber,
    SaFormSelect,
    SaFormSwitch,
    SaFormGroup,
    SaFormTextWithEditAndDeleteSvg,
    SaFormSmsProgressBar,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filteredFields() {
      return this.fields.filter((field) => this.shouldShow(this.getValueFor(field), field));
    },
  },
  methods: {
    getValueFor(field) {
      return this.object[field.key];
    },
    shouldShow(obj, field) {
      const showTypes = ['hoursRange', 'group', 'dateInput', 'textWithEditAndDeleteSvg'];

      if (typeof field.shouldShow === 'function') return field.shouldShow();

      if (Array.isArray(obj)) return obj.length > 0;

      if (typeof obj === 'boolean') return true;

      if (showTypes.includes(field.type)) return true;

      return obj !== undefined && obj !== null;
    },
    inputType(type) {
      return `SaForm${this.ucFirst(type)}`;
    },
    ucFirst(str) {
      return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

div {
  .field-body {
    flex-grow: 2;

    > span {
      margin-top: .375em;
    }

    > span.tag {
      margin-top: .575em;
    }

    > strong {
      margin-top: .475em;
    }

    > ul {
      margin-top: .375em;
    }
  }

  .label {
    text-align: left;
  }
}
</style>

<style lang="scss">
.form-field-wrapper {
  .field {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .is-horizontal {
    display: flex;
    align-items: center;
  }

  .is-normal {
    padding-top: 0;
  }

  span {
    display: flex;
    align-items: center;
    margin-top: .2em;
  }
}
</style>
