import Vue from 'vue';
import { SkToast } from '@skelloapp/skello-ui';

const Component = Vue.extend(SkToast);

const DIMENSIONS = ['short', 'long'];
const VARIANTS = ['information', 'success', 'error'];

const validateOptions = ({
 message, variant, dimension, noTimeout, bottomOffset, containerId,
}) => {
  const props = {};

  if (DIMENSIONS.includes(dimension)) props.dimension = dimension;
  if (VARIANTS.includes(variant)) props.variant = variant;
  if (noTimeout) props.noTimeout = true;
  if (message) props.message = message;
  if (bottomOffset) props.bottomOffset = bottomOffset;
  if (containerId) props.containerId = containerId;

  return props;
};

const skToast = (options) => {
  const propsData = validateOptions(options);

  const toast = new (Vue.extend(Component))({
    el: document.createElement('div'),
    propsData,
  });

  if (document.getElementById('sk-toast')) {
    document.getElementById('toast-portal').replaceChild(toast.$el, document.getElementById('sk-toast'));
  } else {
    document.getElementById('toast-portal').appendChild(toast.$el);
  }
};

export { skToast };
