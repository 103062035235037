import {
  BlacklistedPhoneRepository,
  SvcCommunicationV2Client,
} from '@skelloapp/svc-communications-v2-sdk';

import { sendRequestConfig, successReceivedRequestConfig, errorReceivedRequestConfig } from '@config/http_client';

export class SvcCommunicationsClient {
  constructor() {
    const baseURL = process.env.VUE_APP_SVC_COMMUNICATIONS_V2_URL;

    const client = new SvcCommunicationV2Client(baseURL, {
      retries: 0,
      clientSource: 'superAdmin:web',
    });

    client.clientWithRetry.interceptors.request.use(sendRequestConfig);
    client.clientWithoutRetry.interceptors.request.use(sendRequestConfig);
    client.clientWithRetry.interceptors.response.use(
      successReceivedRequestConfig,
      errorReceivedRequestConfig,
    );
    client.clientWithoutRetry.interceptors.response.use(
      successReceivedRequestConfig,
      errorReceivedRequestConfig,
    );

    this.blacklistedPhoneRepository = new BlacklistedPhoneRepository(client);
  }

  async findOne(phone) {
    const { data } = await this.blacklistedPhoneRepository.findOne({ phone });

    return data;
  }

  async delete(phone) {
    return this.blacklistedPhoneRepository.delete({ phone });
  }
}

export const svcCommunicationsClient = new SvcCommunicationsClient();
