import { security } from '@config/security';
import { FactoryBillingAutomationWeb } from '@skelloapp/svc-billing-automation-sdk';

class BillingAutomationClient {
  constructor() {
    const baseURL = process.env.VUE_APP_SVC_BILLING_AUTOMATION_URL;

    this.factory = FactoryBillingAutomationWeb.create(baseURL, {
      clientSource: 'superAdmin:web',
      // Default timeout is 5000, some calls take more than that
      timeout: 10000,
    });
  }

  async requestShopSalesforceDelete(id) {
    const { token } = await security.getAuthToken();

    return this.factory.shop.requestSalesforceDelete(id, token);
  }

  async requestOrganisationSalesforceDelete(id) {
    const { token } = await security.getAuthToken();

    return this.factory.organisation.requestSalesforceDelete(id, token);
  }
}

export const billingAutomationClient = new BillingAutomationClient();

export default billingAutomationClient;
