<template>
  <!-- eslint-disable  max-len -->
  <b-tooltip
    v-if="tooltipLabel != ''"
    :label="tooltipLabel"
    multilined
    type="is-dark"
    size="is-small"
    position="is-left"
  >
    <a
      :href="realLink"
      role="button"
      :class="classes"
      @click="$emit('click', $event)"
    >
      <b-icon
        v-if="icon"
        :pack="iconPack"
        :icon="icon"
        :style="style"
      />
      <CircledIIcon
        v-if="warning"
        class="action-button__icon--errored"
        fill="#E12F57"
        width="16"
        height="16"
      />
    </a>
  </b-tooltip>
  <a
    v-else
    :href="realLink"
    role="button"
    class="action-button"
    @click="$emit('click', $event)"
  >
    <b-icon
      v-if="icon"
      :pack="iconPack"
      :icon="icon"
      :style="style"
    />
  </a>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
import { CircledIIcon } from '@skelloapp/skello-ui';

export default {
  components: {
    CircledIIcon,
  },
  props: {
    activeState: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    iconPack: String,
    icon: String,
    iconColor: {
      String,
      default: '#1a6efc',
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    realLink: {
      type: String,
      default: null,
    },
    tooltipLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      return { color: this.iconColor };
    },
    classes() {
      return {
        'action-button': true,
        'action-button--active': this.activeState,
        'action-button--inactive': !this.activeState,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$button-size: 40px;

.action-button {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: $button-size;
  height: $button-size;
  margin: 0 calc($button-size / 8);
  font-size: 12px;
  border-radius: 35px;
  border: 1px solid #ccc;

  &:hover {
    background: #E2E5E8;
  }
}

.action-button--active {
  background: #DDE9FF;
}

.action-button--inactive {
  background: #FFF;
}

.action-button__icon--errored {
  position: absolute;
  right: -2px;
  bottom: -2px;
}
</style>
