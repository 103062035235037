function updateProp(context, name, value, realName) {
  const propName = realName || name;
  const valueDidNotChange = context.initialShopProps[propName] === value;

  if (context.fetchingData) return;

  if (valueDidNotChange) {
    delete context.shopUpdatedProps[propName];
  } else {
    context.shopUpdatedProps[propName] = value;
  }
}

function shopComputed() {
  return {
    popularShiftsCount() {
      return this.shop.popularShiftsCount;
    },
    opti() {
      return this.shop.opti;
    },
    late_arrival_takes_planned_date() {
      return this.shopProps.late_arrival_takes_planned_date;
    },
    early_arrival_takes_planned_date() {
      return this.shopProps.early_arrival_takes_planned_date;
    },
    late_departure_counted_as_late() {
      return this.shopProps.late_departure_counted_as_late;
    },
    early_departure_counted_as_early() {
      return this.shopProps.early_departure_counted_as_early;
    },
    badging_lateness_notification_enabled() {
      return this.shopProps.badging_lateness_notification_enabled;
    },
    allow_badging_pause() {
      return this.shopProps.allow_badging_pause;
    },
    badging_takes_photo() {
      return this.shopProps.badging_takes_photo;
    },
    signature() {
      return this.shopProps.signature;
    },
    punch_clock_token() {
      return this.shopProps.punch_clock_token;
    },
    opening_time() {
      return this.shopProps.opening_time;
    },
    closing_time() {
      return this.shopProps.closing_time;
    },
    modulation() {
      return this.shopProps.modulation;
    },
    paid_vacation_calculation_type() {
      return this.shopProps.paid_vacation_calculation_type;
    },
  };
}

function shopWatchers() {
  return {
    popularShiftsCount(value, oldValue) {
      updateProp(this, 'popularShiftsCount', value, 'popular_shifts_count');
    },
    opti(value, oldValue) {
      updateProp(this, 'opti', value);
    },
    late_arrival_takes_planned_date(value, oldValue) {
      updateProp(this, 'late_arrival_takes_planned_date', value);
    },
    early_arrival_takes_planned_date(value, oldValue) {
      updateProp(this, 'early_arrival_takes_planned_date', value);
    },
    late_departure_counted_as_late(value, oldValue) {
      updateProp(this, 'late_departure_counted_as_late', value);
    },
    early_departure_counted_as_early(value, oldValue) {
      updateProp(this, 'early_departure_counted_as_early', value);
    },
    badging_lateness_notification_enabled(value, oldValue) {
      updateProp(this, 'badging_lateness_notification_enabled', value);
    },
    allow_badging_pause(value, oldValue) {
      updateProp(this, 'allow_badging_pause', value);
    },
    badging_takes_photo(value, oldValue) {
      const newValue = value.toString();

      if (newValue === 'true') {
        this.shopProps.signature = 'false';
        updateProp(this, 'signature', 'false');
      }

      updateProp(this, 'badging_takes_photo', newValue);
    },
    signature(value, oldValue) {
      const newValue = value.toString();

      if (newValue === 'true') {
        this.shopProps.badging_takes_photo = 'false';
        updateProp(this, 'badging_takes_photo', 'false');
      }

      updateProp(this, 'signature', newValue);
    },
    punch_clock_token(value, oldValue) {
      updateProp(this, 'punch_clock_token', value);
    },
    opening_time(value, oldValue) {
      updateProp(this, 'opening_time', value);
    },
    closing_time(value, oldValue) {
      updateProp(this, 'closing_time', value);
    },
    modulation(value, oldValue) {
      updateProp(this, 'modulation', value);
    },
    modulation_calculation_type(value, oldValue) {
      updateProp(this, 'modulation_calculation_type', value);
    },
    paid_vacation_calculation_type(value, oldValue) {
      updateProp(this, 'paid_vacation_calculation_type', value);
    },
  };
}

export default { shopComputed, shopWatchers };
