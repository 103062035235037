<template>
  <!-- eslint-disable  max-len -->
  <div
    class="users-list-wrapper"
    data-comp="user-list"
  >
    <div>
      <tab-body-first-line
        :title="title"
        :buttons="buttons"
      />
    </div>
    <div class="flex-grow">
      <user-list-table
        :users="users"
        :loading="isLoading"
        :handle-sort-change="handleSortChange"
      />
      <br>
      <b-pagination
        v-if="total > 0"
        :total="total"
        :current="page"
        order="is-right"
        per-page="100"
        @change="value => handlePageChange(value)"
      />
    </div>
    <send-pin-modal
      :title="sendPinModalTitle"
      :visible.sync="sendPinModalVisible"
      :base-params="actionParams"
    />
  </div>
</template>

<script>
import httpClient from '@config/http_client';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import UserListTable from '../../components/UserListTable';
import sortableMixin from '../../mixins/sortableMixin';
import sendInviteMixin from '../../mixins/sendInviteMixin';
import SendPinModal from '../../components/SendPinModal';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    TabBodyFirstLine,
    UserListTable,
    SendPinModal,
  },
  mixins: [sortableMixin, sendInviteMixin('plural')],
  props: ['organisation'],
  data() {
    return {
      buttons: [
        {
          iconPack: 'fa',
          icon: 'envelope-o',
          onClick: this.handleSendInviteClick,
          tooltipLabel: this.$t('super_admin_js.users.send_invit'),
        },
        {
          iconPack: 'fa',
          icon: 'paper-plane',
          onClick: this.handleSendPinClick,
          tooltipLabel: this.$t('super_admin_js.users.send_pin'),
        },
      ],
      users: [],
      isLoading: true,
      sort: 'id',
      direction: 'asc',
      page: 1,
      total: 0,
      sendPinModalVisible: false,
    };
  },
  computed: {
    title() {
      return `${this.$t('super_admin_js.users.list')} ${this.organisation.name}`;
    },
    actionParams() {
      return {
        type: 'organisation',
        organisation_id: this.organisation.id,
      };
    },
    sendPinModalTitle() {
      return this.$t('super_admin_js.organisations.send_pins', { name: this.organisation.name });
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      httpClient
        .get('/super_admin/api/users', {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
            type: 'organisation',
            organisation_id: this.organisation.id,
          },
        })
        .then((response) => {
          this.users = response.data.users;
          this.total = response.data.total;
          this.isLoading = false;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.errors.loading'),
          );
        });
    },
    handleSendPinClick() {
      this.sendPinModalVisible = true;
    },
  },
};
</script>
