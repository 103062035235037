<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <span
        :class="value.className"
        class="tag"
      >
        {{ value.text }}
      </span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormTag',
  mixins: [saFormTypeMixin],
};
</script>

<style lang="scss" scoped>
  .field-body > span {
    font-weight: bold;
  }
</style>
