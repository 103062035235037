<template>
  <!-- eslint-disable  max-len -->
  <div>
    <tab-body-first-line
      :buttons="buttons"
      :title="$t('super_admin_js.organisation.tabs.details')"
    />
    <sa-form
      :fields="organisationDetailsFormFields"
      :object="organisation"
    />

    <!-- FIXME: DEV-9112 Delete this when all clients will be migrated to new process -->
    <div v-if="canMigrateToNewProcess && !isMigratedToNewProcess" class="cash-recovery-wrapper">
      <div class="field-label is-normal">
        <label class="label sk-label">
          {{ $t('super_admin_js.organisations.details.new_process') }}
        </label>
      </div>
      <div class="field-body">
        <a
          class="button is-primary"
          @click="migrateToBillingNewProcess()"
        >
          {{ $t('super_admin_js.organisations.details.migrate') }}
        </a>
      </div>
    </div>

    <div v-if="canExcludeFromCashRecovery && !excludedFromCashRecovery" class="cash-recovery-wrapper">
      <div class="field-label is-normal">
        <label class="label sk-label">
          {{ $t('super_admin_js.organisations.details.remove_from_recovery') }}
        </label>
      </div>
      <div class="field-body">
        <a
          class="button is-danger"
          @click="excludeFromCashRecovery()"
        >
          {{ $t('super_admin_js.organisations.details.remove_from_recovery_btn') }}
        </a>
      </div>
    </div>

    <br>

    <div class="field">
      <div class="field-label is-normal" />
      <div class="field-body">
        <a
          class="button is-primary"
          @click="handleSubmitClick()"
        >
          {{ $t('super_admin_js.organisations.details.submit') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@config/http_client';
import i18n from '@/i18n/index.js';
import { billingAutomationClient } from '~/shared/utils/billing-automation-client';

import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import Form from '../../components/Form';
import deleteMixin from '../../mixins/deleteMixin';
import organisationDetailsFormFields from '../../form_fields/organisationDetailsFormFields';
import impersonateUser from '../../utils/impersonate_user';

/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    TabBodyFirstLine,
    saForm: Form,
  },
  mixins: [deleteMixin(i18n.t('super_admin_js.organisations.the_organisation'))],
  props: {
    organisation: {
      type: Object,
      default: () => {},
    },
    setClustersTrue: {
      type: Function,
      required: true,
    },
    canExcludeFromCashRecovery: {
      type: Boolean,
      required: true,
    },
    canMigrateToNewProcess: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      organisationDetailsFormFields: organisationDetailsFormFields(this),
      excludedFromCashRecovery: false,
      isMigratedToNewProcess: false,
    };
  },
  computed: {
    ...mapGetters(['isDevFeatureFlagActive']),
    buttons() {
      return [
        {
          iconPack: 'fa',
          icon: 'user',
          tooltipLabel: this.$t('super_admin_js.organisations.details.see_as'),
          onClick: this.impersonateFirstAdmin,
        },
        {
          iconPack: 'fa',
          icon: 'trash',
          iconColor: '#FF5E5F',
          tooltipLabel:
            this.$t(
              'super_admin_js.modals.delete.button_tooltip',
              { entity: this.$t('super_admin_js.organisations.the_organisation') },
            ),
          onClick: this.handleDeleteOrg,
        },
      ];
    },
    deleteModalTitle() {
      return this.$t('super_admin_js.modals.delete.title', { name: this.organisation.name });
    },
    deleteMessageWarning() {
      return this.$t('super_admin_js.modals.delete.message', { name: this.organisation.name });
    },
    deletePath() {
      return `/super_admin/api/organisations/${this.$route.params.id}`;
    },
    isNewStandAloneBundleFeatureDisabled() {
      return !this.isDevFeatureFlagActive('FEATUREDEV_NEW_STANDALONE_BUNDLES');
    },
  },
  mounted() {
    if (this.isNewStandAloneBundleFeatureDisabled) {
      this.organisation.packOfferNames = organisation.packOfferNames.filter(
          (packOffer) => packOffer.id !== 'Timeclock Standalone Success',
      );
    }
    this.organisation.licenses.forEach((originalType) => {
      const licenseName = this.$t(`super_admin_js.licenses.${originalType}`);
      this.organisationDetailsFormFields.push({
        type: 'linksList',
        label: licenseName,
        key: originalType,
      });
    });
  },
  methods: {
    handleDeleteOrg() {
      if (this.organisation.createdFromSvcBillingAutomation) {
        this.handleDeleteClick(this.salesforceDeleteCallback);
      } else {
        this.handleDeleteClick();
      }
    },
    salesforceDeleteCallback() {
      return billingAutomationClient.requestOrganisationSalesforceDelete(this.organisation.id);
    },
    impersonateFirstAdmin() {
      const user = this.organisation.availableReferents[0];
      if (!user) return;

      impersonateUser(user.id)
        .catch((error) => {
          console.error(error);
          this.makeAlertToast(this.$t('super_admin_js.errors.delete.button_tooltip'));
        });
    },
    handleSubmitClick() {
      if (this.organisation.originalValueClusters !== this.organisation.clusters) {
        this.$dialog.confirm({
          title: this.$t('super_admin_js.organisations.org_to_network'),
          message: this.$t('super_admin_js.modals.org_to_network', { organisation: this.organisation.name }),
          confirmText: this.$t('super_admin_js.organisations.details.confirm_text'),
          cancelText: this.$t('super_admin_js.organisations.details.cancel_text'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.handleConfirmClustersModal();
          },
        });
      } else {
        this.submitForm();
      }
    },
    handleConfirmClustersModal() {
      this.setClustersTrue();
      this.submitForm();
    },
    submitForm() {
      httpClient
        .patch(`/super_admin/api/organisations/${this.organisation.id}`, {
          organisation: {
            owner_id: this.organisation.referentId,
            status: this.organisation.status,
            clusters: this.organisation.clusters,
            pack_offer_name: this.organisation.packOfferName,
          },
        })
        .then((response) => {
          this.$emit('update:organisation', response.data.organisation);
          this.makeSuccessToast(
            this.$t('super_admin_js.organisations.messages.edit'),
          );
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.organisations.errors.edit'),
          );
        });
    },

    // FIXME: DEV-9112 Delete this when all clients will be migrated to new process
    migrateToBillingNewProcess() {
      this.$dialog.confirm({
        title: this.$t('super_admin_js.organisations.details.migrate_confirm_title'),
        message: this.$t('super_admin_js.organisations.messages.migrate'),
        confirmText: this.$t('super_admin_js.organisations.details.confirm_text'),
        cancelText: this.$t('super_admin_js.organisations.details.cancel_text'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.migrateShopsToBillingNewProcess();
        },
      });
    },
    migrateShopsToBillingNewProcess() {
      httpClient
        .post('/super_admin/api/organisations/migrate_to_billing_new_process',
          {
            organisation_id: this.organisation.id,
          })
        .then((response) => {
          this.isMigratedToNewProcess = true;
          this.makeSuccessToast(this.$t('super_admin_js.organisations.messages.migrate_ok'));
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('super_admin_js.organisations.errors.migrate'));
        });
    },
    excludeFromCashRecovery() {
      this.$dialog.confirm({
        title: this.$t('super_admin_js.organisations.shops.recovery'),
        message: this.$t('super_admin_js.organisations.shops.recovery_confirmation'),
        confirmText: this.$t('super_admin_js.organisations.details.confirm_text'),
        cancelText: this.$t('super_admin_js.organisations.details.cancel_text'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.unDisputeLastStripeCharge();
        },
      });
    },
    unDisputeLastStripeCharge() {
      httpClient
        .post('/super_admin/api/stripe_charges/undispute_last', {
          undispute: {
            organisation_id: this.organisation.id,
          },
        })
        .then((response) => {
          this.excludedFromCashRecovery = true;
          this.makeSuccessToast(this.$t('super_admin_js.organisations.messages.recovery_out'));
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('errors.error_happened'));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .cash-recovery-wrapper {
    display: flex;
  }
</style>
