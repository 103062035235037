<template>
  <b-modal :active.sync="modalVisible">
    <div class="modal-wrapper">
      <div class="modal-header">
        <SkMedallion
          content-component="DownloadArrowIcon"
          background-color="#F0F2F5"
          content-color="#6D7D8C"
        />
        <span class="modal-header-title">
          {{ $t('super_admin_js.integration_tasks.manual_launch.modal_title') }}
        </span>
        <div
          class="modal-header-close"
          @click="onCancel"
        >
          <i
            class="fa fa-times"
            aria-hidden="true"
          />
        </div>
        <hr />
      </div>
      <div class="modal-body">
        <div class="manual-launch__infobox">
          <div class="manual-launch__infobox__icon">
            <CircledIIcon
              fill="#1a6efc"
              width="24"
              height="24"
            />
          </div>
          <div class="manual-launch__infobox__text">
            <div class="manual-launch__infobox__title">
              {{ $t('super_admin_js.integration_tasks.manual_launch.infobox_title') }}
            </div>
            <div class="manual-launch__infobox__subtitle">
              {{ $t('super_admin_js.integration_tasks.manual_launch.infobox_subtitle') }}
            </div>
          </div>
        </div>
        <SkTextarea
          v-model="givenJson"
          type="textarea"
          label=".json"
          :min-height="194"
        />
      </div>
      <div class="modal-footer manual-relaunch-modal__footer-btns">
        <cancel-btn
          :handle-click="onCancel"
          class="cancel-btn"
          v-text="$t('actions.cancel')"
        />
        <validate-btn
          :handle-click="onSubmit"
          class="validate-btn"
          :disabled="submitDisabled"
          v-text="$t('actions.submit')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import httpClient from '@config/http_client';

import { SkMedallion, SkTextarea, CircledIIcon } from '@skelloapp/skello-ui';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: {
    ValidateBtn,
    CancelBtn,
    SkMedallion,
    CircledIIcon,
    SkTextarea,
  },
  mixins: [modalVisibleMixin],
  props: {
    organisationId: {
      type: Number,
      required: true,
    },
    job: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      givenJson: '',
      loading: false,
    };
  },
  computed: {
    submitDisabled() {
      return this.givenJson === '' || this.loading;
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.givenJson = this.job.example;
      }
    },
  },
  methods: {
    onSubmit() {
      if (this.loading) return;

      this.loading = true;

      const params = {
        organisation_id: this.organisationId,
        job_name: this.job.name,
        args: JSON.parse(this.givenJson),
      };

      httpClient
        .post('/super_admin/api/integrations/automatic_jobs/manual_launch', params)
        .then(() => {
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.integration_tasks.manual_launch.success'),
          );
        })
        .catch((error) => {
          this.makeAlertToast(error.response?.data?.error || error.message);
        })
        .finally(() => {
          this.loading = false;
          this.onCancel();
        });
    },
    onCancel() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  border-radius: 6px;
  background-color: white;
  padding: 2em;
  font-size: 16px;
  width: 620px;
  height: 505px;
}

.modal-header {
  margin: -12px;
}

.sk-medallion {
  float: left;
  margin-top: -5px;
}

.modal-header-title {
  font-size: 19px;
  font-weight: 700;
  margin: 22px;
  color: $sk-black;
}

.modal-header-close {
  color: $sk-black;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  float: right;
  background: transparent;
}

hr {
  display: block;
  height: 1px;
  border-top: 1px solid $sk-gray-light-3;
  width: 620px;
  margin: 1em 0 1em -20px;
}

.modal-body {
  padding: 1em 0 0 0;
  margin: 2px -7px 0 -7px;
  height: 365px;
}

.manual-relaunch-modal__footer-btns {
  margin: -15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.cancel-btn {
  color: $sk-blue-med-1;
  background: white;
  border: 1px solid $sk-gray-light-3;
  border-radius: 3px;
  font-size: 14px;
}

.validate-btn {
  color: white;
  border: 1px solid $sk-gray-light-3;
  border-radius: 3px;
  font-size: 14px;
}

.manual-launch__explanations {
  display: flex;
  align-items: center;
}

.manual-launch__explanations__job-name {
  width: 20%;
}

.manual-launch__explanations__arguments-expected {
  width: 80%;
}

.manual-launch__infobox {
  border: 1px solid $sk-gray-light-3;
  display: flex;
  padding: 22px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.manual-launch__infobox__text {
  margin-left: 22px;
  color: $sk-gray-med-2;
  font-size: 14px;
  line-height: 22px;
}

.manual-launch__infobox__title {
  font-weight: bold;
  margin-top: -4px;
}
</style>
