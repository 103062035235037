<template>
  <div>
    <header-content :title="$t('super_admin_js.links.feature_flags')" class="title" />
    <spinner v-if="!loaded" />
    <main-content v-else>
      <CreateFeatureFlagModal
        :creating-feature-flag-dev="creatingFeatureFlagDev"
        :feature-flags="featureFlags"
        @add-feature-flag="addFeatureFlag"
      />
      <Tabs>
        <Tab :name="$t('super_admin_js.feature_flags.tab_titles.product_flags')">
          <div class="feature-flags__infobox">
            <div class="feature-flags__infobox__icon">
              <IconInfo />
            </div>
            <div class="feature-flags__infobox__content">
              {{ $t('super_admin_js.feature_flags.infobox.product') }}
            </div>
          </div>
          <div v-if="currentUser.can_add_feature_flags" class="add-button">
            <SkButton icon="PlusSignIcon" @click="handleAddFeatureFlagClick">
              {{ $t('super_admin_js.feature_flags.add') }}
            </SkButton>
          </div>
          <div
            class="feature-flags__table"
          >
            <SkTable :columns="header">
              <ProductFlagRow
                v-for="featureFlag in featureFlags"
                :key="featureFlag.name"
                :feature-flag="featureFlag"
              />
            </SkTable>
          </div>
        </Tab>
        <Tab
          :name="$t('super_admin_js.feature_flags.tab_titles.dev_flags')"
        >
          <div class="feature-flags__infobox">
            <div class="feature-flags__infobox__icon">
              <IconInfo />
            </div>
            <div class="feature-flags__infobox__content">
              {{ $t('super_admin_js.feature_flags.infobox.dev') }}
            </div>
          </div>
          <div class="feature-flags-dev__subtabs">
            <a
              role="button"
              :class="subtabClasses('standard')"
              @click="subtab = 'standard'"
            >
              {{ $t('super_admin_js.feature_flags.table.name') }}
            </a>
            <a
              role="button"
              :class="subtabClasses('canary')"
              @click="subtab = 'canary'"
            >
              {{ $t('super_admin_js.feature_flags.canary') }}
            </a>
          </div>
          <div v-if="currentUser.can_add_feature_flags" class="add-button">
            <SkButton icon="PlusSignIcon" @click="handleAddFeatureFlagDevClick">
              {{ $t('super_admin_js.feature_flags.add_dev') }}
            </SkButton>
          </div>
          <SkTable
            v-if="subtab === 'standard'"
            :columns="headerDevFlags"
          >
            <DevFlagRow
              v-for="featureFlagDev in featureFlagsDev"
              :key="featureFlagDev.name"
              :feature-flag="featureFlagDev"
            />
          </SkTable>
          <SkTable
            v-else
            :columns="headerCanaryFlags"
          >
            <CanaryFlagRow
              v-for="featureFlagDev in featureFlagsCanary"
              :key="featureFlagDev.name"
              :feature-flag="featureFlagDev"
            />
          </SkTable>
        </Tab>
      </Tabs>
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';
import { mapState } from 'vuex';

import {
  SkTable,
  SkButton,
  skListenersMixin,
  MODAL_SHOW_EVENT,
  IconInfo,
} from '@skelloapp/skello-ui';
import HeaderContent from '../../components/HeaderContent';
import MainContent from '../../components/MainContent';
import Spinner from '../../components/Spinner';
import ProductFlagRow from '../../components/FeatureFlags/ProductFlagRow';
import DevFlagRow from '../../components/FeatureFlags/DevFlagRow';
import CanaryFlagRow from '../../components/FeatureFlags/CanaryFlagRow';
import CreateFeatureFlagModal from '../../components/FeatureFlags/modals/CreateFeatureFlagModal';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';

export default {
  components: {
    HeaderContent,
    MainContent,
    Spinner,
    SkTable,
    Tabs,
    Tab,
    ProductFlagRow,
    SkButton,
    CreateFeatureFlagModal,
    DevFlagRow,
    CanaryFlagRow,
    IconInfo,
  },
  mixins: [
    skListenersMixin,
  ],
  data() {
    return {
      creatingFeatureFlagDev: false,
      featureFlags: [],
      featureFlagsDev: [],
      featureFlagsCanary: [],
      loaded: false,
      subtab: 'standard',
    };
  },
  computed: {
    ...mapState(['currentUser']),
    header() {
      return [
        { title: this.$t('super_admin_js.feature_flags.table.name') },
        { title: this.$t('super_admin_js.feature_flags.table.countries') },
        { title: this.$t('super_admin_js.feature_flags.table.sectors') },
        { title: '' },
      ];
    },
    headerDevFlags() {
      return [
        { title: this.$t('super_admin_js.feature_flags.table.name') },
        { title: this.$t('super_admin_js.feature_flags.table.active') },
      ];
    },
    headerCanaryFlags() {
      return [
        { title: this.$t('super_admin_js.feature_flags.table.name') },
        { title: this.$t('super_admin_js.feature_flags.table.canaryReleaseType') },
        { title: '' },
        { title: this.$t('super_admin_js.feature_flags.table.preview') },
        { title: this.$t('super_admin_js.feature_flags.table.active') },
      ];
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    subtabClasses(activeTab) {
      return {
        'feature-flags-dev__subtab': true,
        'feature-flags-dev__subtab--active': this.subtab === activeTab,
      };
    },
    handleAddFeatureFlagClick(event) {
      this.creatingFeatureFlagDev = false;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-feature-flag-modal');
    },
    handleAddFeatureFlagDevClick(event) {
      this.creatingFeatureFlagDev = true;
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'create-feature-flag-modal');
    },
    addFeatureFlag(featureFlag) {
      if (this.creatingFeatureFlagDev) {
        this.featureFlagsDev.push(featureFlag);
      } else {
        this.featureFlags.push(featureFlag);
      }
    },
    fetchData() {
      httpClient
        .get('/super_admin/api/feature_flags')
        .then((r) => {
          // Check if user is authorized
          if (!this.currentUser.can_manage_feature_flags) {
            this.makeAlertToast(
              this.$t('errors.unauthorized'),
            );

            this.$router.push({ name: 'home' });
          }

          this.featureFlags = r.data.feature_flags;
          this.featureFlagsDev = r.data.feature_flags_dev
            .filter((flag) => !flag.canaryReleaseType);
          this.featureFlagsCanary = r.data.feature_flags_dev
            .filter((flag) => flag.canaryReleaseType);
          this.loaded = true;
        })
        .catch((e) => {
          if (e.response.status === 401) {
            this.makeAlertToast(
              this.$t('errors.unauthorized'),
            );

            this.$router.push({ name: 'home' });
          } else {
            this.makeAlertToast(
              this.$t('errors.loading'),
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// the tabs have a bigger margin-bottom than on the mockup
::v-deep .tabs-nav {
  margin-bottom: 24px;
}

.add-button {
  position: absolute;
  right: 0;
  top: -57px;
}

.feature-flags__infobox {
  display: flex;
  color: $sk-gray-med-2;
  font-size: 14px;
  margin-bottom: 24px;
  flex-direction: row;
  align-items: center;
  border: 1px solid $sk-gray-light-3;
  border-radius: 4px;
}

.feature-flags__infobox__icon {
  align-self: flex-start;
  padding-left: 26px;
  padding-top: 20px;
}

.feature-flags__infobox__content {
  padding: 20px;
  padding-left: 16px;
}

.feature-flags-dev__subtabs {
  margin-bottom: 24px;
}

.feature-flags-dev__subtab {
  font-size: 14px;
  color: $sk-gray-med-2;
  padding: 8px 13px;
  border-radius: 100px;
  margin-right: 10px;

  &:hover {
    background-color: $sk-gray-light-5;
  }
}

.feature-flags-dev__subtab--active {
  background-color: $sk-blue-med-1;
  color: #fff;

  &:hover {
    background-color: $sk-blue-med-1;
    color: #fff;
  }
}
</style>
