<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body align-field-body">
      <span>{{ computeValue }}</span>
      <div class="svg-btn grey" @click="editClickHandler">
        <IntegrationEditSvg />
      </div>
      <div class="svg-btn red" @click="deleteClickHandler">
        <IntegrationDeleteSvg />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';
import IntegrationEditSvg from './IntegrationEditSvg';
import IntegrationDeleteSvg from './IntegrationDeleteSvg';
import { INTEGRATION_EDIT_MODAL_SHOW, INTEGRATION_DELETE_MODAL_SHOW } from '../../../constants/integration_auto';

export default {
  name: 'SaFormSelect',
  components: { IntegrationEditSvg, IntegrationDeleteSvg },
  mixins: [saFormTypeMixin],
  computed: {
    availableOptions() {
      if (this.field.options) {
        return this.field.options;
      }
      return this.object[this.field.optionsKey];
    },
    computeValue() {
      return this.value || this.$t('super_admin_js.exports.warning_no_export');
    },
  },
  methods: {
    onChange(id) {
      const { update } = this.field;
      const newVal = this.object[this.field.optionsKey].find((e) => e.id === id);

      if (!update) { return true; }

      update.forEach((obj) => {
        this.object[obj.name] = newVal[obj.with];
      });

      return true;
    },
    editClickHandler() {
      this.$root.$emit(INTEGRATION_EDIT_MODAL_SHOW);
    },
    deleteClickHandler() {
      this.$root.$emit(INTEGRATION_DELETE_MODAL_SHOW);
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-btn {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  padding: 12px;
  margin-left: 6px;
  border-radius: 4px;
}

.red {
  background-color: rgba(255, 83, 85, .15);
}

.grey {
  background-color: #edf2f6;
}

.align-field-body {
  margin-top: -10px;
}
</style>
