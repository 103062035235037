<template>
  <modal
    :title="title"
    :visible.sync="modalVisible"
  >
    <div>
      {{ $t(`super_admin_js.modals.payroll.warning.${activateVerb}`) }} <slot />.
      {{ $t('super_admin_js.modals.payroll.continue_prompt') }}
    </div>
    <div
      slot="footer"
      class="payroll-modal-footer-btns"
    >
      <validate-btn :handle-click="handleValidateClick" />
      <cancel-btn :handle-click="closeModal" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    activation: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    activateVerb() {
      return this.activation ? 'activate' : 'deactivate';
    },
    validateToastText() {
      return this.$t(`super_admin_js.modals.payroll.validate.${this.activateVerb}`);
    },
    errorToastText() {
      return this.$t(`super_admin_js.modals.payroll.error.${this.activateVerb}`);
    },
  },
  methods: {
    handleValidateClick() {
      httpClient
        .post(`/super_admin/api/organisations/${this.params.organisation_id}/feature_payroll_legacy`, {
          features_names: ['payroll', 'multicontract', 'multidocument'],
          feature_active: this.params.payroll_active,
          organisation_id: this.params.organisation_id,
        })
        .then(() => {
          this.makeToast('is-success', this.validateToastText);
          if (this.callback) this.callback();
          this.closeModal();
        })
        .catch(() => {
          this.makeToast('is-danger', this.errorToastText);
        });
    },
    closeModal() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.payroll-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
