<template>
  <div class="filter-section">
    <button
      :ref="`dropdownButton_${buttonLabel}`"
      :class="resolveButtonClass"
      class="filter-button"
      @click="dropdownVisible = !dropdownVisible"
    >
      {{ buttonLabel }} {{ displayActiveFilterCount }}
    </button>
    <div
      v-show="dropdownVisible"
      v-closeDropdown="{
        exclude: [`dropdownButton_${buttonLabel}`, `dropdownList_${buttonLabel}`],
        handler: 'hideDropdown'
      }"
      :style="widerForConvention"
      class="dropdown-container"
    >
      <div
        :ref="`dropdownList_${buttonLabel}`"
        class="dropdown-list"
      >
        <div v-for="item in dataList" :key="item[0]" class="dropdown-item">
          <label class="pure-material-checkbox">
            <input
              :id="item[0]"
              v-model="collection"
              :value="item[0]"
              type="checkbox"
            >
            <span :for="item[0]">{{ item[1] }}</span>
          </label>
        </div>
      </div>
      <div class="dropdown-actions">
        <button class="clear-button" @click="clearCollection">
          Effacer
        </button>
        <button class="close-button" @click="submitCollection">
          Appliquer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

let handleOutsideClick;

Vue.directive('closeDropdown', {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;
      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          if (excludedEl) {
            clickedOnExcludedEl = excludedEl.contains(e.target);
          }
        }
      });
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }
    };
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
  },
  unbind() {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('touchstart', handleOutsideClick);
  },
});

export default {
  props: {
    buttonLabel: {
      type: String,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    setFilters: {
      type: Function,
      required: true,
    },
    orgaStatus: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      dropdownVisible: false,
      isActive: false,
      collection: this.orgaStatus ? [this.orgaStatus] : [],
    };
  },
  computed: {
    widerForConvention() {
      return this.buttonLabel === 'Convention' ? 'min-width: 350px;' : '';
    },
    resolveButtonClass() {
      if (this.isActive || this.orgaStatus === 'active') return 'active-filter-button';
      return '';
    },
    displayActiveFilterCount() {
      if (this.collection.length > 0) {
        return `+${this.collection.length}`;
      }
      return '';
    },
  },
  mounted() {
    if (this.buttonLabel === "Statut de l'orga") {
      this.submitCollection();
      this.dropdownVisible = false;
    }
  },
  methods: {
    hideDropdown() {
      this.dropdownVisible = false;
    },
    clearCollection() {
      this.collection = [];
      this.isActive = false;
    },
    submitCollection() {
      this.setFilters(this.buttonLabel, this.collection);
      if (this.collection.length > 0) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
      this.dropdownVisible = !this.dropdownVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-section {
  position: relative;
}

.filter-button {
  border: 1px solid #dbe0e0;
  border-radius: 2px;
  height: 30px;
  background: #fff;
  color: #4a4a4a;
  margin: 0 5px;
  cursor: pointer;
}

.active-filter-button {
  background: #1a6efc;
  color: white;
  border: none;
  font-weight: bold;
}

.dropdown-container {
  position: absolute;
  top: 35px;
  left: 5px;
  z-index: 3;
  box-shadow: 0 8px 16px rgba(0, 0, 0, .2);
  border-radius: 2px;
  max-height: 400px;
  min-width: 250px;
  background: #fff;
}

.dropdown-list {
  overflow: scroll;
  max-height: 320px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  line-height: 32px;
  margin-left: 10px;

  &:active {
    background: #f8f9fa;
    color: #16181b;
  }
}

.dropdown-actions {
  height: 50px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-button,
.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  background: none;
  border: none;
}

.close-button {
  background: #1a6efc;
  border-radius: 3px;
  padding: 5px 10px;
  color: #fff;
}

// Reco checkbox custo de Jubéo: https://codepen.io/finnhvman/pen/zpygBB
.pure-material-checkbox {
  width: 100%;
}

.pure-material-checkbox > input {
  z-index: -1;
  position: absolute;
  left: -2px;
  top: 2px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #1a6efc;
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity .3s, transform .2s;
}

.pure-material-checkbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
}

.pure-material-checkbox > span::before {
  content: "";
  display: inline-block;
  margin: 5px 10px 5px 1px;
  border: solid 2px #1a6efc;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color .2s, background-color .2s;
}

.pure-material-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color: #1a6efc;
  background-color: #1a6efc;
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: white;
}

.pure-material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}
</style>
