<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="title"
    :visible.sync="modalVisible"
  >
    <div class="export-modal-wrapper">
      <div class="export-modal__explanation">
        {{ $t('super_admin_js.modals.pay_export.warning') }}
        <slot />
      </div>

      <div class="export-modal__select-wrapper">
        <label
          for="export-modal-key"
          class="export-modal__label"
        >
          {{ $t('super_admin_js.modals.pay_export.choose_type') }}
        </label>
        <div class="export-modal__select">
          <select v-model="exporterKey">
            <option
              v-for="key in Object.keys(customAndAutoIntegrations)"
              :key="key"
              :value="key"
              :type="customAndAutoIntegrations[key]"
            >
              {{ key }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="badging-modal-footer-btns"
    >
      <validate-btn :handle-click="handleValidateClick" />
      <cancel-btn :handle-click="handleCancelClick" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';
import { RELOAD_SHOP_DATA } from '../../constants/integration_auto';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    baseParams: {
      type: Object,
      required: true,
    },
    defaultKey: {
      type: String,
      required: false,
    },
    exporters: {
      type: Array,
      required: true,
    },
    integrations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      exporterKeyChosen: null,
      chosenExporterType: null,
    };
  },
  computed: {
    customAndAutoIntegrations() {
      const payExportersObj = this.setPayExportersObject();
      const integrationsObj = this.setIntegrationsObject();

      return _.merge(payExportersObj, integrationsObj);
    },

    params() {
      return { ...this.baseParams, pay_exporters: [this.exporterKeyChosen] };
    },
    exporterKey: {
      get() {
        return this.defaultKey;
      },
      set(key) {
        this.exporterKeyChosen = key;
        this.chosenExporterType = this.customAndAutoIntegrations[key];
      },
    },
  },
  methods: {
    setPayExportersObject() {
      const exportersObj = {};

      this.exporters.map(
          (value, index) => {
              exportersObj[value] = '';
              return exportersObj;
          },
      );

      return _.mapValues(exportersObj, () => 'manMade');
    },
    setIntegrationsObject() {
      const integrationsObj = {};

      this.integrations.map(
          (value, index) => {
              integrationsObj[value] = '';
              return integrationsObj;
          },
      );

      return _.mapValues(integrationsObj, () => 'autoMade');
    },
    handleValidateClick() {
      if (this.chosenExporterType === 'manMade') {
        httpClient
          .patch('/super_admin/api/pay_exporters', this.params)
          .then((r) => {
            this.makeToast(
              'is-success',
              this.$t('super_admin_js.modals.pay_export.success_toast'),
            );
            this.handleCancelClick();
          })
          .catch((e) => {
            this.makeToast(
              'is-danger',
              this.$t('super_admin_js.modals.pay_export.error_toast'),
            );
          });
      } else {
        httpClient
          .patch('/super_admin/api/integrations/integration_replication', this.params)
          .then((response) => {
            this.makeToast(
              'is-success',
              this.$t('super_admin_js.modals.pay_export.automatic_export_success_toast'),
            );
            this.$root.$emit(RELOAD_SHOP_DATA);
            this.handleCancelClick();
          })
          .catch((e) => {
            this.makeToast(
              'is-danger',
              this.$t('super_admin_js.modals.pay_export.automatic_export_failure_toast'),
            );
          });
      }
    },
    handleCancelClick() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.badging-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.export-modal__select-wrapper {
  display: flex;
  margin: 3em 0;
}

.export-modal__label {
  flex: 1;
  text-align: center;
}

.export-modal__select {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  select {
    width: 75%;
    height: 3em;
  }
}
</style>
