<template>
  <SkModal
    :id="modalId"
    :modal-title="$t('super_admin_js.feature_flags.canary_target_modal.title')"
    modal-title-classes="canary-target-modal-title"
  >
    <template #body>
      <div class="canary-target-modal__content">
        <div class="canary-target-modal__body">
          {{ $t('super_admin_js.feature_flags.canary_target_modal.body') }}
        </div>
        <SkTextarea
          v-model="canaryReleaseIds"
          :label="$t('super_admin_js.feature_flags.canary_target_modal.label')"
          :max-height="300"
          :min-height="120"
          :errored="!idsValid"
          :error-message="invalidIdsMessage"
          rows="5"
          auto-grow
        />
      </div>
    </template>
    <template #submit-btn>
      <SkButton
        :disabled="!idsValid"
        @click="handleSubmit"
      >
        {{ $t('super_admin_js.feature_flags.submit') }}
      </SkButton>
    </template>
  </SkModal>
</template>

<script>
import {
  SkModal,
  SkButton,
  skListenersMixin,
  MODAL_HIDE_EVENT,
  SkTextarea,
} from '@skelloapp/skello-ui';

export default {
  components: {
    SkModal,
    SkButton,
    SkTextarea,
  },
  mixins: [skListenersMixin],
  props: {
    featureName: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      canaryReleaseIds: '',
    };
  },
  computed: {
    idsValid() {
      const ids = this.canaryReleaseIds;

      return ids === '' // Allows no ids (turns on ff for everyone)
        || /^\s*\d+\s*$/.test(ids) // allow one id with an optional space around
        || /^\s*\d[\d,\s]*[\d$]\s*$/.test(ids); // Allows comma-separated ids, with optional space
    },
    invalidIdsMessage() {
      if (this.idsValid) return null;

      return this.$t('super_admin_js.feature_flags.canary_target_modal.invalid_ids');
    },
    modalId() {
      return `canary-target-modal-${this.featureName}`;
    },
    newIds() {
      const idsWithoutSpaces = this.canaryReleaseIds.replace(/\s/g, '');
      const newIds = idsWithoutSpaces.split(',');

      const idsWithoutEmpty = newIds.filter((id) => id !== '');

      return JSON.stringify(idsWithoutEmpty) === JSON.stringify(['']) ? [] : idsWithoutEmpty;
    },
  },
  methods: {
    // To be called by parent when opening the modal
    setCanaryReleaseIds(newIds) {
      this.canaryReleaseIds = newIds;
    },
    handleSubmit() {
      if (!this.idsValid) return;

      this.emitOnRoot(MODAL_HIDE_EVENT, event, this.modalId);
      this.$emit('update', { newIds: this.newIds });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header .canary-target-modal-title {
  font-weight: bold;
  font-family: $poppins-font;
  font-size: 16px;
}

.canary-target-modal__body {
  margin-bottom: 24px;
}

.canary-target-modal__content {
  padding: 24px;
  font-family: $product-sans-font;
  font-size: 14px;

  ::v-deep .sk-textarea__input { // When text is put in, padding is off from design system
    padding: 1px 8px;
  }
}
</style>
