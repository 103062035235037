export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Function,
      default: () => '',
    },
  },
  methods: {
    getValueFor(field) {
      return this.object[field.key];
    },
    getOwnValue() {
      return this.object[this.field.key];
    },
  },
};
