<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="title"
    :visible.sync="modalVisible"
  >
    <div>
      {{ $t(`super_admin_js.modals.badging.warning.${activateVerb}`) }} <slot />.
      {{ $t('super_admin_js.modals.badging.continue_prompt') }}
    </div>
    <div
      slot="footer"
      class="badging-modal-footer-btns"
    >
      <validate-btn :handle-click="handleValidateClick" />
      <cancel-btn :handle-click="handleCancelClick" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    settingToTrue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    activateVerb() {
      return this.settingToTrue ? 'activate' : 'deactivate';
    },
    validateToastText() {
      if (this.settingToTrue) return this.$t('super_admin_js.modals.badging.validate_activated');
      return this.$t('super_admin_js.modals.badging.validate_deactivated');
    },
    errorToastText() {
      if (this.settingToTrue) return this.$t('super_admin_js.modals.badging.error_activated');
      return this.$t('super_admin_js.modals.badging.error_deactivated');
    },
  },
  methods: {
    handleValidateClick() {
      httpClient
        .patch('/super_admin/api/badgings', this.params)
        .then((r) => {
          this.makeToast('is-success', this.validateToastText);
          if (this.callback) this.callback();
          this.handleCancelClick();
        })
        .catch((e) => {
          this.makeToast('is-danger', this.errorToastText);
        });
    },
    handleCancelClick() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.badging-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
