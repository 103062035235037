<template>
  <div class="simulator__wrapper">
    <h1 class="simulator__title sk-header--1" v-html="$t('super_admin_js.simulator.title')" />
    <h2 class="simulator__subtitle sk-subtitle--medium">
      {{ $t('super_admin_js.simulator.subtitle') }}
    </h2>

    <div class="simulator__body-wrapper">
      <div class="simulator__form-wrapper">
        <h3 class="simulator__form-title sk-header--2">
          {{ $t('super_admin_js.simulator.info') }}
        </h3>

        <div class="simulator__form__inputs">
          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.pack') }}
            </div>

            <div class="simulator__form-line__input">
              <SkSelectV2
                v-model="pack"
                :options="packs"
              >
                <template #selected-option="packProps">
                  <div
                    class="simulator__form__select"
                    :class="`simulator__select--${packProps.value}`"
                  >
                    {{ packProps.value }}
                  </div>
                </template>
                <template #option="packProps">
                  <div :class="`simulator__select-option--${packProps.option.id}`">
                    {{ packProps.option.id }}
                  </div>
                </template>
              </SkSelectV2>
            </div>
          </div>

          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.commitment') }}
            </div>

            <div class="simulator__form-line__input">
              <SkSelectV2
                v-model="commitment"
                :options="commitments"
              >
                <template #selected-option="commitmentProps">
                  <div class="simulator__form__select">
                    {{ commitmentProps.value }}
                  </div>
                </template>
                <template #option="commitmentProps">
                  {{ commitmentProps.option.id }}
                </template>
              </SkSelectV2>
            </div>
          </div>

          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.planning_count') }}
            </div>

            <div class="simulator__form-line__input">
              <SkInput
                v-model="posCount"
                :label="$t('super_admin_js.simulator.plannings')"
                type="number"
              />
            </div>
          </div>

          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.clock_count') }}
            </div>

            <div class="simulator__form-line__input">
              <SkInput
                v-model="badgingsCount"
                :label="$t('super_admin_js.simulator.clock')"
                type="number"
              />
            </div>
          </div>

          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.automatic_planning_count') }}
            </div>

            <div class="simulator__form-line__input">
              <SkInput
                v-model="automaticPlanningsCount"
                :label="$t('super_admin_js.simulator.automatic_planning')"
                type="number"
              />
            </div>
          </div>

          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.esignature_count') }}
            </div>

            <div class="simulator__form-line__input">
              <SkInput
                v-model="esignaturesCount"
                :label="$t('super_admin_js.simulator.esignature')"
                type="number"
              />
            </div>
          </div>

          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.analytics_count') }}
            </div>

            <div class="simulator__form-line__input">
              <SkInput
                v-model="analyticsCount"
                :label="$t('super_admin_js.simulator.analytics')"
                type="number"
              />
            </div>
          </div>

          <div class="simulator__form-line">
            <div class="simulator__form-line__label">
              {{ $t('super_admin_js.simulator.integration_count') }}
            </div>

            <div
              class="simulator__form-line__input"
            >
              <SkInput
                v-model="integrationsCount"
                :label="integrationLabel"
                :disabled="isMonthlyEngagement"
                type="number"
              />
            </div>
          </div>
        </div>

        <div class="simulator__form-button">
          <SkButton fill="blank" @click="resetData">
            {{ $t('super_admin_js.simulator.reset') }}
          </SkButton>
        </div>
      </div>

      <div class="simulator__results-wrapper simulator__results-wrapper--desktop">
        <h3 class="simulator__form-title sk-header--2">
          {{ $t('super_admin_js.simulator.results') }}
        </h3>

        <div class="simulator__results__lines">
          <div class="simulator__results__line">
            <div class="simulator__results__line-title">
              {{ $t('super_admin_js.simulator.mmr_th') }}
            </div>

            <div class="simulator__results__line-value">
              {{ theoreticalMrr }} €
            </div>
          </div>

          <div class="simulator__results__line">
            <div class="simulator__results__line-title">
              {{ $t('super_admin_js.simulator.remise') }}
            </div>

            <div class="simulator__results__line-value simulator__results__line-value--big">
              {{ allowedDiscount }} %
            </div>
          </div>

          <div class="simulator__results__line">
            <div class="simulator__results__line-title">
              {{ $t('super_admin_js.simulator.mmr_fin') }}
            </div>

            <div class="simulator__results__line-value simulator__results__line-value--big">
              {{ finalMrr }} €
            </div>
          </div>

          <div class="simulator__results__line">
            <div class="simulator__results__line-title">
              {{ $t('super_admin_js.simulator.final_price') }}
              <span class="simulator__results__subtitle">
                ({{ $t('super_admin_js.simulator.first_levy') }})
              </span>
            </div>

            <div class="simulator__results__line-value">
              {{ finalPrice }} €
              <span class="simulator__results__line-legend">
                {{ $t('super_admin_js.simulator.levy_unit') }}
              </span>
            </div>
          </div>

          <div class="simulator__results__line">
            <div class="simulator__results__line-title">
              {{ $t('super_admin_js.simulator.arpu') }}
            </div>

            <div class="simulator__results__line-value">
              {{ monthlyArpu }} €
            </div>
          </div>
        </div>
      </div>

      <div class="simulator__results-wrapper simulator__results-wrapper--mobile">
        <h3 class="simulator__form-title sk-header--2">
          {{ $t('super_admin_js.simulator.results') }}
        </h3>

        <div class="simulator__results__lines">
          <div class="simulator__results__line">
            <div class="simulator__results__result simulator__results__result--bordered">
              <div class="simulator__results__line-title">
                {{ $t('super_admin_js.simulator.mmr_th') }}
              </div>

              <div class="simulator__results__line-value">
                {{ theoreticalMrr }} €
              </div>
            </div>

            <div class="simulator__results__result simulator__results__result--bold">
              <div class="simulator__results__line-title">
                {{ $t('super_admin_js.simulator.mmr_fin') }}
              </div>

              <div class="simulator__results__line-value">
                {{ finalMrr }} €
              </div>
            </div>
          </div>

          <div class="simulator__results__line">
            <div class="simulator__results__result simulator__results__result--big">
              <div class="simulator__results__line-title">
                {{ $t('super_admin_js.simulator.remise') }}
              </div>

              <div class="simulator__results__line-value">
                {{ allowedDiscount }} %
              </div>
            </div>

            <div class="simulator__results__result simulator__results__result--big">
              <div class="simulator__results__line-title">
                {{ $t('super_admin_js.simulator.final_price') }}
                <span class="simulator__results__subtitle">
                  ({{ $t('super_admin_js.simulator.first_levy') }})
                </span>
              </div>

              <div class="simulator__results__line-value">
                {{ finalPrice }} € <span class="simulator__results__line-legend">
                  {{ $t('super_admin_js.simulator.levy_unit') }}</span>
              </div>
            </div>
          </div>

          <div class="simulator__results__line">
            <div
              class="
                simulator__results__result
                simulator__results__result--fullsize
                simulator__results__result--bordered
              "
            >
              <div class="simulator__results__line-title">
                {{ $t('super_admin_js.simulator.arpu') }}
              </div>

              <div class="simulator__results__line-value">
                {{ monthlyArpu }} €
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SkInput, SkSelectV2, SkButton } from '@skelloapp/skello-ui';

export default {
  name: 'Simulator',
  components: { SkInput, SkSelectV2, SkButton },
  data() {
    return {
      pack: this.$t('super_admin_js.simulator.packs.success'),
      posCount: 1,
      badgingsCount: null,
      automaticPlanningsCount: null,
      esignaturesCount: null,
      integrationsCount: null,
      analyticsCount: null,
      badgingsPrices: {
        [this.$t('super_admin_js.simulator.commitments.monthly')]: 40,
        [this.$t('super_admin_js.simulator.commitments.annualy')]: 36,
        [this.$t('super_admin_js.simulator.commitments.seasonal')]: 32,
      },
      packs: [
        { id: this.$t('super_admin_js.simulator.packs.basic') },
        { id: this.$t('super_admin_js.simulator.packs.success') },
        { id: this.$t('super_admin_js.simulator.packs.premium') },
      ],
      commitment: this.$t('super_admin_js.simulator.commitments.monthly'),
      commitments: [
        { id: this.$t('super_admin_js.simulator.commitments.monthly') },
        { id: this.$t('super_admin_js.simulator.commitments.annualy') },
        { id: this.$t('super_admin_js.simulator.commitments.seasonal') },
      ],
      commitmentDiscounts: {
        [this.$t('super_admin_js.simulator.commitments.monthly')]: 0,
        [this.$t('super_admin_js.simulator.commitments.annualy')]: 0.1,
        [this.$t('super_admin_js.simulator.commitments.seasonal')]: 0.2,
      },
      automaticPlanningsPrices: {
        [this.$t('super_admin_js.simulator.commitments.monthly')]: 30,
        [this.$t('super_admin_js.simulator.commitments.annualy')]: 27,
        [this.$t('super_admin_js.simulator.commitments.seasonal')]: 24,
      },
      esignaturesPrices: {
        [this.$t('super_admin_js.simulator.commitments.monthly')]: 40,
        [this.$t('super_admin_js.simulator.commitments.annualy')]: 36,
        [this.$t('super_admin_js.simulator.commitments.seasonal')]: 32,
      },
      analyticsPrices: {
        [this.$t('super_admin_js.simulator.commitments.monthly')]: 30,
        [this.$t('super_admin_js.simulator.commitments.annualy')]: 27,
        [this.$t('super_admin_js.simulator.commitments.seasonal')]: 24,
      },
      integrationsPrices: {
        [this.$t('super_admin_js.simulator.commitments.monthly')]: 0,
        [this.$t('super_admin_js.simulator.commitments.annualy')]: 20,
        [this.$t('super_admin_js.simulator.commitments.seasonal')]: 18,
      },
      prices: {
        [this.$t('super_admin_js.simulator.packs.basic')]: 89,
        [this.$t('super_admin_js.simulator.packs.success')]: 109,
        [this.$t('super_admin_js.simulator.packs.premium')]: 162,
      },
    };
  },
  computed: {

    theoreticalMrr() {
      const discount = this.commitmentDiscounts[this.commitment];
      const pricePack = this.prices[this.pack] * this.posCount * (1 - discount);
      const priceBadging = this.badgingsCount * this.badgingsPrices[this.commitment];
      const priceSmartplanner = this.automaticPlanningsCount
        * this.automaticPlanningsPrices[this.commitment];
      const priceEsignature = this.esignaturesCount * this.esignaturesPrices[this.commitment];
      const priceAnalytics = this.analyticsCount * this.analyticsPrices[this.commitment];
      const priceIntegration = this.integrationsCount * this.integrationsPrices[this.commitment];
      const priceWithOptions = pricePack
        + priceBadging + priceSmartplanner + priceEsignature + priceAnalytics + priceIntegration;

      return priceWithOptions.toFixed(2);
    },
    allowedDiscount() {
      if (this.theoreticalMrr < 1000) return 0;
      if (this.theoreticalMrr < 1750) return 10;
      if (this.theoreticalMrr < 2500) return 15;
      if (this.theoreticalMrr < 4000) return 20;
      if (this.theoreticalMrr < 7500) return 25;
      return 25;
    },
    finalMrr() {
      const mrrStepDiscount = this.allowedDiscount / 100;
      return (this.theoreticalMrr * (1 - mrrStepDiscount)).toFixed(2);
    },
    finalPrice() {
      if (this.commitment === this.$t('super_admin_js.simulator.commitments.monthly')) {
        return this.finalMrr;
      }
        return (this.finalMrr * 12).toFixed(2);
    },
    monthlyArpu() {
      if (this.posCount === 0 || !this.posCount) return this.finalMrr;

      return (this.finalMrr / this.posCount).toFixed(2);
    },
    isMonthlyEngagement() {
      return this.commitment === this.$t('super_admin_js.simulator.commitments.monthly');
    },
    integrationLabel() {
      if (this.commitment === this.$t('super_admin_js.simulator.commitments.monthly')) {
        return this.$t('super_admin_js.simulator.integration_disabled');
      }
      return this.$t('super_admin_js.simulator.integration');
    },
  },
  methods: {
    resetData() {
      this.posCount = 1;
      this.badgingsCount = null;
      this.pack = this.$t('super_admin_js.simulator.packs.success');
      this.commitment = this.$t('super_admin_js.simulator.commitments.monthly');
      this.automaticPlanningsCount = null;
      this.esignaturesCount = null;
      this.integrationsCount = null;
      this.analyticsCount = null;
    },
  },
};
</script>

<style lang="scss" scoped>
$max-mobile-size: 750px;

.simulator__wrapper {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.simulator__title {
  margin-bottom: 6px;
}

.simulator__body-wrapper {
  margin-top: 40px;
  display: flex;
  flex: 1 1 auto;
}

.simulator__form-wrapper,
.simulator__results-wrapper {
  flex: 1 1 auto;
  width: 50%;
  border-radius: 4px;
  padding: 20px;
  max-height: 700px;
  max-width: 800px;
}

.simulator__form-wrapper {
  margin-right: 24px;
  border: 1px solid $sk-gray-light-3;
  display: flex;
  flex-direction: column;
}

.simulator__results-wrapper {
  margin-left: 24px;
  background-color: $sk-blue-med-1;
  color: white;
  display: flex;
  flex-direction: column;

  .simulator__form-title {
    color: white;
  }
}

.simulator__form-title {
  margin-bottom: 25px;
}

.simulator__form__inputs {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.simulator__form-line__input {
  min-width: 215px;
}

.simulator__form-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $sk-gray-light-3;
  padding: 12px 8px;
}

.simulator__form-line__label {
  font-weight: bold;
}

.simulator__form-button {
  display: flex;
  justify-content: flex-end;
}

.simulator__form__select {
  width: 181px;
}

.simulator__results__lines {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.simulator__results__line {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid white;
  font-weight: bold;
}

.simulator__results__line-value {
  height: 44px;
  flex: 1 1 auto;
  max-width: 260px;
  border-radius: 4px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  padding: 10px;
}

.simulator__results__line-title {
  min-width: 115px;
}

.simulator__results__line-legend {
  font-weight: normal;
  margin-left: .5em;
}

.simulator__results__line-value--big {
  border: none;
  font-size: 36px;
  padding: 0;
}

.simulator__select,
.simulator__select-option {
  &--Basique {
    color: #28B6A4;
  }

  &--Success {
    color: #484DC2;
  }

  &--Premium {
    color: #FDBF3F;
  }
}

.simulator__results__subtitle {
  font-size: 9px;
  font-weight: normal;
}

.simulator__results-wrapper--mobile {
  display: none;

  .simulator__results__result {
    margin: 8px 0;
    padding: 5px 10px;
  }

  .simulator__results__line-value {
    padding: 0;
    height: auto;
    border: 0;
  }

  .simulator__results__result--bordered {
    border: 1px solid white;
    border-radius: 4px;
    font-weight: normal;

    .simulator__results__line-title {
      font-size: 10px;
      line-height: 12px;
    }

    .simulator__results__line-value {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .simulator__results__result--bold {
    .simulator__results__line-title {
      font-size: 10px;
      line-height: 12px;
    }

    .simulator__results__line-value {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .simulator__results__result--big {
    .simulator__results__line-title {
      font-size: 10px;
      line-height: 12px;
    }

    .simulator__results__line-value {
      font-size: 30px;
      line-height: 36px;
      align-items: flex-end;
      justify-content: space-between;
    }

    .simulator__results__line-legend {
      font-weight: normal;
      font-size: 8px;
      line-height: 10px;
      margin-bottom: 1em;
    }
  }

  .simulator__results__result--fullsize {
    flex: 1 1 auto;
  }
}

@media screen and (max-width: $max-mobile-size) {
  .hidden-mobile {
    display: none;
  }

  .simulator__body-wrapper {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .simulator__form-wrapper,
  .simulator__results-wrapper {
    width: 100%;
    max-height: 250px;
    margin-right: 0;
    margin-left: 0;
  }

  .simulator__form__inputs {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .simulator__form-line__label {
    display: none;
  }

  .simulator__form-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .simulator__form-line {
    width: 50%;
    border-top: none;
  }

  .simulator__form-line__input {
    min-width: 0;
    width: 100%;
  }

  .sk-button--medium.sk-button--blank {
    padding: 7px 4px;
  }

  .simulator__results-wrapper--desktop {
    display: none;
  }

  .simulator__results-wrapper--mobile {
    display: flex;
  }
}
</style>
