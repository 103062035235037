import moment from 'moment';

const formatDate = (lang) => (date, format = 'LLL', useUTC0 = true) => {
  if (useUTC0) return moment(date).utcOffset('+0000').locale(lang).format(format);

  return moment(date).locale(lang).format(format);
};

const formatShopHours = (hours) => (hours === undefined ? undefined : hours.join(' - '));

const formatUserName = (userId, userName) => (userName === undefined ? `${userId}` : `${userName} (${userId})`);

const formatShopName = (shopId, shopName) => `${shopName} (${shopId})`;

const totalTarget = (target) => (target ? target.length : 0);

const formatSourceTemplate = (templateType, sourceTemplateApplied) => ({
    label: `super_admin_js.events_history.notification.planning_apply_template.template_type.${templateType}`,
    value: {
      template_name: sourceTemplateApplied,
      week_number: templateType === 'previous-week-day' ? moment(sourceTemplateApplied).isoWeek() : '',
    },
  });

export const formatSvcEventsResponse = (lang) => (events) => events
    .map((event) => ({
      ...event,
      type: `super_admin_js.events_history.notification.${event.subtype}.type`,
      subtype_text: `super_admin_js.events_history.notification.${event.subtype}.text`,
      visualDate: formatDate(lang)(event.createdAt, 'LLL', false),
      shop: formatShopName(event.shopId, event.context.shop_name),
      context: {
        ...event.context,
        previous_hours: formatShopHours(event.context.previous_hours),
        new_hours: formatShopHours(event.context.new_hours),
        date: formatDate(lang)(event.context.date ? event.context.date : event.context.start_date, 'LL'),
        weekly_option_date: formatDate(lang)(event.context.weekly_option_date, 'LL'),
        shift_total: totalTarget(event.context.shift_ids),
        user_target_total: totalTarget([...new Set(event.context.user_target_ids)]),
        user_target_names: [...new Set(event.context.user_target_names)].join(', '),
        start_date_time: formatDate(lang)(event.context.start_date, 'LT'),
        end_date_time: formatDate(lang)(event.context.end_date, 'LT'),
        conflict_resolution_choice: event.context.conflict_resolution_choice
          ? `super_admin_js.events_history.notification.planning_apply_template.conflict_choice.${event.context.conflict_resolution_choice}`
          : undefined,
        source_template_applied: event.context.template_type
        && event.context.source_template_applied
        ? formatSourceTemplate(
          event.context.template_type, event.context.source_template_applied,
        ) : undefined,
        user: formatUserName(
          event.userId,
          event.context.user_source_name ?? event.context.user_name,
        ),
        nb_employees: event.context.user_target_ids?.length,
        week_number: event.context.date ? moment(event.context.date).isoWeek() : '',
        team_name: Array.isArray(event.context.team_name)
          ? event.context.team_name[0]
          : event.context.team_name,
        new_team_name: Array.isArray(event.context.team_name)
          ? event.context.team_name[1]
          : undefined,
        poste_name: Array.isArray(event.context.poste_name)
          ? event.context.poste_name[0]
          : event.context.poste_name,
        new_poste_name: Array.isArray(event.context.poste_name)
          ? event.context.poste_name[1]
          : undefined,
        poste_color: Array.isArray(event.context.poste_color)
          ? event.context.poste_color[0]
          : event.context.poste_color,
        new_poste_color: Array.isArray(event.context.poste_color)
          ? event.context.poste_color[1]
          : undefined,
        poste_pause_time: Array.isArray(event.context.poste_pause_time)
          ? (event.context.poste_pause_time[0] / 60)
          : event.context.poste_pause_time,
        new_poste_pause_time: Array.isArray(event.context.poste_pause_time)
          ? (event.context.poste_pause_time[1] / 60)
          : undefined,
        document_id: event.context.document_id,
        document_name: event.context.document_name,
      },
    }));
