<template>
  <!-- eslint-disable  max-len -->
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.888889 14.2222C0.888889 15.2 1.68889 16 2.66667 16H9.77778C10.7556 16 11.5556 15.2 11.5556 14.2222V3.55556H0.888889V14.2222ZM12.4444 0.888889H9.33333L8.44444 0H4L3.11111 0.888889H0V2.66667H12.4444V0.888889Z" fill="#FF5355" />
  </svg>
</template>

<script>

export default {
  name: 'IntegrationEditSvg',
};
</script>
