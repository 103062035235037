<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="$t('super_admin_js.modals.mail_preview.title')"
    :visible.sync="modalVisible"
  >
    <spinner
      v-if="isNotReady"
      no-margin="true"
    />
    <div
      v-else
      class="invoice-new__wrapper"
    >
      <div class="invoice-new__form">
        <div class="invoice-new__section-header">
          <h2 class="invoice-new__section-title">
            {{ $t('super_admin_js.modals.mail_preview.summary') }}
            <a
              :href="fullUrlFor(invoice.url)"
              target="_blank"
            >
              invoice.pdf
            </a>
          </h2>
        </div>
        <hr>
        <div class="override-text-enabled">
          <label class="override-text-enabled__label">
            <b-switch v-model="overrideTextEnabled" />

            <span>
              {{ $t('super_admin_js.modals.mail_preview.send_mail') }}
            </span>
          </label>
        </div>
        <div class="background">
          <tr>
            <td max-width="600" align="center">
              <a
                href="https://www.skello.io"
                style="text-decoration: none; color: #1a6efc; outline: none;"
                target="blank"
              >
                <img
                  src="https://github.com/skelloapp/photos-skello/blob/master/skello-logo-v3.png?raw=true"
                  style="display: block; font-family: arial"
                  width="200"
                  height="80"
                  alt="Skello"
                >
              </a>
            </td>
          </tr>
          <table
            cellpadding="0"
            cellspacing="0"
            style="margin-left: auto; margin-right: auto; max-width: 600px; background-color: #ffffff; border-radius: 6px 6px 0px 0px; padding-top: 20px; padding-bottom: 20px; border-bottom: 3px solid #1a6efc;"
          >
            <tr style="width: 100%;">
              <td
                max-width="600"
                style="padding-left: 20px; padding-right: 20px; padding-bottom: 10px; padding-top: 10px; font-family: arial; font-size: 21px; color: #1a6efc; font-weight: bold;"
              >
                {{ $t('super_admin_js.modals.mail_preview.hello') }}
              </td>
            </tr>
            <tr style="width: 100%;">
              <td
                max-width="600"
                align="justify"
                style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px; font-family: arial; font-size: 15px; color: #333333;"
              >
                {{ $t(
                  'super_admin_js.modals.mail_preview.invoice_details',
                  { invoice_date: moment(invoice.created_at).format('DD/MM/YYYY'), social_denomination: invoice.customer_social_denomination }
                )
                }}
              </td>
            </tr>
            <tr v-if="overrideTextEnabled" style="width: 100%;">
              <td
                max-width="600"
                align="justify"
                style="padding-left: 20px; padding-right: 20px; padding-bottom: 20px; font-family: arial; font-size: 15px; color: #333333;"
              >
                {{ $t('super_admin_js.modals.mail_preview.replaced_invoice', { invoice_number: invoice.invoice_number }) }}
              </td>
            </tr>
            <tr>
              <td
                width="600"
                style="padding-left: 20px; padding-right: 20px; font-family: arial; font-size: 15px; color: #333333;"
              >
                {{ $t('super_admin_js.modals.mail_preview.final_greetings') }}
              </td>
            </tr>
            <tr>
              <td height="margin: 20px" />
            </tr>
            <tr>
              <td
                width="600"
                style="padding-left: 20px; padding-right: 20px; font-family: arial; font-size: 15px; color: #333333;"
              >
                {{ $t('super_admin_js.modals.mail_preview.team') }} <b><span style="color: #02133c;">S</span><span style="color: #1a6efc;">k</span><span style="color: #02133c;">ello</span></b>
              </td>
            </tr>
          </table>
          <table
            width="100%"
            cellpadding="0"
            cellspacing="0"
          >
            <tr max-width="600">
              <td
                align="left"
                style="vertical-align: top"
              >
                <table
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                >
                  <tr>
                    <td
                      align="left"
                      style="vertical-align: top; font-size: 12px; padding-left: 5px; padding-right: 5px; padding-bottom: 3px;"
                    >
                      <a
                        href="mailto:contact@skello.io"
                        style="color: #1a6efc; text-decoration: none; font-family: arial; font-weight: bold;"
                      >
                        {{ $t('super_admin_js.modals.mail_preview.contact_us') }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      align="left"
                      max-width="300"
                      style="padding-left: 5px; padding-right: 5px;"
                    >
                      <a
                        href="https://www.facebook.com/skello.io/"
                        style="color: #1a6efc; text-decoration: none; display: inline-block; margin-right: 5px; outline: none;"
                        target="blank"
                      ><img
                        src="https://github.com/skelloapp/photos-skello/blob/master/fb.png?raw=true"
                        width="20"
                        height="20"
                        alt="Facebook"
                        style="color: #333333; font-size: 10px; font-family: arial;"
                      ></a>
                      <a
                        href="https://twitter.com/skelloapp"
                        style="color: #1a6efc; text-decoration: none; display: inline-block; margin-right: 5px; outline: none;"
                        target="blank"
                      ><img
                        src="https://github.com/skelloapp/photos-skello/blob/master/twitter.png?raw=true"
                        width="20"
                        height="20"
                        alt="Twitter"
                        style="color: #333333; font-size: 10px; font-family: arial;"
                      ></a>
                      <a
                        href="https://www.linkedin.com/company/10613048"
                        style="color: #1a6efc; text-decoration: none; display: inline-block; outline: none;"
                        target="blank"
                      ><img
                        src="https://github.com/skelloapp/photos-skello/blob/master/linkedin.png?raw=true"
                        width="20"
                        height="20"
                        alt="LinkedIn"
                        style="color: #333333; font-size: 10px; font-family: arial;"
                      ></a>
                      <a
                        href="https://www.instagram.com/skelloapp/"
                        style="color: #1a6efc; text-decoration: none; display: inline-block; outline: none;"
                        target="blank"
                      ><img
                        src="https://github.com/skelloapp/photos-skello/blob/master/instagram.png?raw=true"
                        width="20"
                        height="20"
                        alt="Instagram"
                        style="color: #333333; font-size: 10px; font-family: arial;"
                      ></a>
                    </td>
                  </tr>
                </table>
              </td>
              <td
                align="right"
                style="vertical-align: top"
              >
                <table
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                >
                  <tr>
                    <td
                      align="right"
                      max-width="300"
                      style="font-size: 12px; color: #aaaaaa; font-family: arial; padding-left: 5px; padding-right: 5px;"
                    >
                      Skello - <a
                        href="https://www.skello.io"
                        style="color: #1a6efc; font-weight: bold; text-decoration: none;"
                        target="blank"
                      >skello.io</a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      align="right"
                      max-width="300"
                      style="font-size: 12px; color: #aaaaaa; font-family: arial; padding-left: 5px; padding-right: 5px;"
                    >
                      {{ $t('address.first_line') }}<br>{{ $t('address.second_line') }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <hr>
      </div>
    </div>
    <div
      v-if="!loading"
      slot="footer"
      class="badging-modal-footer-btns"
    >
      <validate-btn
        :handle-click="handleValidateClick"
        :text="$t('super_admin_js.modals.mail_preview.validate')"
      />
      <cancel-btn :handle-click="handleCancelClick" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';
import { buildLinkFor } from '@config';

import moment from 'moment';
import Modal from '../Modal';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';
import Spinner from '../Spinner';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';

export default {
  components: {
 Modal, Spinner, ValidateBtn, CancelBtn,
},
  mixins: [modalVisibleMixin],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    reload: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      overrideTextEnabled: false,
    };
  },
  computed: {
    /*
    * Not ready if loading is at true OR invoice is not yet set
    * Because before invoice is set, modal is still rendered by Vue
    */
    isNotReady() {
      return this.loading || !this.invoiceLoaded;
    },
    invoiceLoaded() {
      return Object.keys(this.invoice).length > 0;
    },
  },
  methods: {
    fullUrlFor(path) {
      return buildLinkFor(path);
    },
    moment(date) {
      return moment(date);
    },
    handleValidateClick() {
      this.sendInvoiceAgain(this.invoice);
      this.closeModal();
    },
    handleCancelClick() {
      this.closeModal();
    },
    closeModal() {
      this.modalVisible = false;
    },
    sendInvoiceAgain(charge) {
      this.loading = true;
      httpClient
        .post('/super_admin/api/send_invoice_again', {
          stripe_invoice_id: charge.invoice.id,
          stripe_charge_id: charge.id,
          override_text_enabled: this.overrideTextEnabled,
        })
        .then((r) => {
          this.reload();
          this.loading = false;
          this.closeModal();
          this.makeSuccessToast(this.$t('super_admin_js.stripe_charge.toasts.sent'));
        })
        .catch((e) => {
          this.loading = false;
          this.makeAlertToast(this.$t('super_admin_js.stripe_charge.errors.loaded'));
          this.closeModal();
        });
    },
  },
};

</script>

<style lang="scss" scoped>
.badging-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.invoice-new__section-title {
  font-weight: bold;
}

.background {
  background-color: #f9f9f9;
}

.override-text-enabled {
  margin-bottom: 1em;
}
</style>
