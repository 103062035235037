<template>
  <div v-if="smsLimit" class="sms-progress__wrapper">
    <h3 class="sms-progress__title">
      {{ $t('super_admin_js.organisation.tabs.sms_progress') }}
    </h3>
    <div class="sms-progress__bar-wrapper">
      <div class="sms-progress__bar">
        <div :style="barProgressionStyle" class="sms-progress__progress" />
      </div>
      <div class="sms-progress__legend">
        {{ smsUsed }} / {{ smsLimit }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormSmsProgressBar',
  mixins: [saFormTypeMixin],
  computed: {
    smsLimit() {
      // If sms unlimited, smsLimit returns undefined
      return this.object.smsLimit;
    },
    smsUsed() {
      return this.object.smsCount;
    },
    barProgressionStyle() {
      const smsProgressPercent = (this.smsUsed / this.smsLimit) * 100;
      return { width: `${smsProgressPercent}%` };
    },
  },
};
</script>

<style lang="scss" scoped>
.sms-progress__wrapper {
  display: flex;
  align-items: center;
}

.sms-progress__title {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: flex-end;
  margin-right: 1.5rem;
  color: #363636;
  font-weight: 600;
}

.sms-progress__bar-wrapper {
  flex: 3 1 0;
  display: flex;
  align-items: center;
}

.sms-progress__bar {
  width: 200px;
  height: 5px;
  background: #ddd;
  border-radius: 2px;
  position: relative;
}

.sms-progress__legend {
  margin-left: .5em;
  font-weight: 600;
  font-size: .8em;
}

.sms-progress__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 2px;
  background: #1a6efc;
}
</style>
