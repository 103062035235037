<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <a :href="`mailto:${value}`">
        {{ value }}
      </a>

      <b-tooltip
        :label="field.deleteTooltip"
        multilined
        type="is-dark"
        size="is-small"
        position="is-right"
      >
        <a role="button" class="delete-button" @click="handleDeleteClick">
          <i class="fa fa-trash" />
        </a>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormEmail',
  mixins: [saFormTypeMixin],
  methods: {
    handleDeleteClick() {
      this.field.handleDeleteClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-button {
  height: 20px;
  width: 20px;
  font-size: .8em;
  color: white;
  background-color: #e12f57;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;

  &:hover,
  &:active,
  &:focus {
    opacity: .8;
  }
}
</style>
