<template>
  <!-- eslint-disable  max-len -->
  <div>
    <b-table
      ref="table"
      :data="users"
      :loading="loading"
      striped
      mobile-cards
      backend-sorting
      default-sort="id"
      @sort="(field, order) => sort(field, order)"
    >
      <template slot-scope="props">
        <b-table-column
          field="id"
          :label="$t('super_admin_js.users.table.id')"
          class="user_column user_column--id"
          sortable
        >
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          field="first_name"
          :label="$t('super_admin_js.users.table.name')"
          class="user_column user_column--name nowrap"
          sortable
        >
          <router-link :to="linkForUser(props.row)">
            {{ props.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.email')"
          class="user_column user_column--email"
        >
          <a :href="'mailto:' + props.row.email">
            {{ props.row.email }}
          </a>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.phone')"
          class="user_column user_column--phone nowrap"
        >
          {{ props.row.phoneNumber }}
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.organisation')"
          class="user_column user_column--organisation nowrap"
        >
          <router-link
            v-if="props.row.organisation.name"
            :to="props.row.organisation.url"
          >
            {{ props.row.organisation.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.shop')"
          class="user_column user_column--shop nowrap"
        >
          <router-link
            v-if="props.row.shop.name"
            :to="props.row.shop.url"
          >
            {{ props.row.shop.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.other_shops')"
          class="user_column user_column--shops"
        >
          <router-link
            v-for="shop in props.row.shops"
            :key="shop.id"
            :to="shop.url"
          >
            {{ shop.name }}
          </router-link>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.license')"
          field="license"
          class="user_column user_column--role nowrap"
          sortable
        >
          {{ props.row.user_license_name }}
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.status')"
          field="archived_at"
          class="user_column user_column--status"
          sortable
        >
          <span
            :class="props.row.status.className"
            class="tag"
          >
            {{ props.row.status.text }}
          </span>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.invite')"
          class="user_column user_column--invite"
        >
          <span
            class="pointer"
            @click="handleInviteClick(props.row)"
          >
            <b-icon
              pack="fa"
              icon="envelope-o"
              size="is-small"
              type="is-primary"
            />
          </span>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.send_pin')"
          class="user_column user_column--send-pin"
        >
          <span
            class="pointer"
            @click="handleSendPinClick(props.row)"
          >
            <b-icon
              pack="fa"
              icon="paper-plane"
              size="is-small"
              type="is-primary"
            />
          </span>
        </b-table-column>
        <b-table-column
          :label="$t('super_admin_js.users.table.see_as')"
          class="user_column user_column--see-as"
        >
          <a role="button" @click="impersonate(props.row.id)">
            <b-icon
              pack="fa"
              icon="user"
              size="is-small"
              type="is-primary"
            />
          </a>
        </b-table-column>
      </template>
    </b-table>
    <send-pin-modal
      :title="sendPinModalTitle"
      :visible.sync="sendPinModalVisible"
      :base-params="actionParams"
    />
  </div>
</template>

<script>
import SendPinModal from '../SendPinModal';
import sendInviteMixin from '../../mixins/sendInviteMixin';
import impersonateUser from '../../utils/impersonate_user';

export default {
  components: { SendPinModal },
  mixins: [sendInviteMixin('singular')],
  props: {
    users: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    handleSortChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      sendPinModalVisible: false,
      inviteModalVisible: false,
      sendPinModalTitle: this.$t('super_admin_js.modals.titles.send_pin'),
      currentModalUser: null,
    };
  },
  computed: {
    actionParams() {
      if (!this.currentModalUser) return {};
      return {
        type: 'user',
        user_id: this.currentModalUser.id,
      };
    },
  },
  methods: {
    handleInviteClick(user) {
      this.currentModalUser = user;
      this.handleSendInviteClick();
    },
    handleSendPinClick(user) {
      this.currentModalUser = user;
      this.sendPinModalVisible = true;
    },
    impersonate(id) {
      impersonateUser(id)
        .catch((error) => {
          console.error(error);
          const errorType = error.response.status === 403 ? 'user' : 'server';

          this.makeAlertToast(this.$t(`super_admin_js.users.errors.impersonate.${errorType}`));
        });
    },
    linkForShopId(shopId) {
      return {
        name: 'shop',
        params: {
          id: shopId,
        },
      };
    },
    linkForUser(user) {
      return {
        name: 'user',
        params: {
          id: user.id,
        },
      };
    },
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;

      this.handleSortChange(field, order);
    },
  },
};
</script>

<style lang="scss">
  .nowrap {
    white-space: nowrap;
  }

  .pointer {
    cursor: pointer;
  }
</style>
