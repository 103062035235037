function formFields(context) {
  const fields = [
    {
      type: 'text',
      label: 'ID',
      key: 'id',
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.name'),
      key: 'name',
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.corporate_name'),
      key: 'corporateName',
    },
    {
      type: 'select',
      label: context.$t('super_admin_js.organisation.details_form.pack'),
      key: 'packOfferName',
      optionsKey: 'packOfferNames',
    },
    {
      type: 'smsProgressBar',
      key: 'smsLimit',
    },
    {
      type: 'select',
      label: context.$t('super_admin_js.organisation.details_form.referent'),
      key: 'referentId',
      update: [
        { name: 'referentEmail', with: 'email' },
        { name: 'referentPhone', with: 'phone' },
      ],
      optionsKey: 'availableReferents',
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.referent_email'),
      key: 'referentEmail',
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.referent_phone'),
      key: 'referentPhone',
    },
    {
      type: 'select',
      label: context.$t('super_admin_js.organisation.details_form.status'),
      key: 'status',
      optionsKey: 'organisationStatuses',
      disabled: false,
      // TODO uncomment when we're ready Raul side
      // disabled: context.organisation.createdFromSvcBillingAutomation,
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.created_at'),
      key: 'createdAt',
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.total_active_employees'),
      key: 'nActiveEmployees',
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.total_shops'),
      key: 'nShops',
    },
    {
      type: 'text',
      label: context.$t('super_admin_js.organisation.details_form.siren'),
      key: 'siren',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.organisation.details_form.networks'),
      key: 'clusters',
      tooltip() {
        return (context.organisation.originalValueClusters ? context.$t('super_admin_js.organisation.details_form.tooltip') : '');
      },
      disabled: context.organisation.originalValueClusters,
      shouldShow() {
        return context.organisation.showClusterSwitch;
      },
    },
  ];

  return fields;
}

export default formFields;
