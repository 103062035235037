<template>
  <!-- eslint-disable  max-len -->
  <div>
    <b-table
      v-if="shouldShowTable"
      ref="table"
      :data="histories"
      :loading="loading"
      striped
      mobile-cards
      backend-sorting
      default-sort="id"
      @sort="(field, order) => sort(field, order)"
    >
      <template slot-scope="props">
        <b-table-column
          field="id"
          label="ID"
          sortable
        >
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          field="created_at"
          :label="$t('super_admin_js.users.history_table.created_at')"
          sortable
        >
          {{ props.row.action_date }}
        </b-table-column>
        <b-table-column
          field="action_type_category"
          :label="$t('super_admin_js.users.history_table.place')"
          sortable
        >
          {{ props.row.action_type_category }}
        </b-table-column>
        <b-table-column
          field="description"
          :label="$t('super_admin_js.users.history_table.action_type')"
        >
          {{ props.row.description }}
        </b-table-column>
      </template>
    </b-table>
    <div v-else>
      {{ $t('super_admin_js.users.history_table.no_history') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    histories: {
      type: Array,
      required: true,
    },
    handleSortChange: {
      type: Function,
      required: true,
    },
  },
  computed: {
    shouldShowTable() {
      return this.loading || this.histories.length > 0;
    },
  },
  methods: {
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;

      this.handleSortChange(field, order);
    },
  },
};
</script>
