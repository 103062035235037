<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="$t('super_admin_js.modals.perms_replication.title')"
    :visible.sync="modalVisible"
  >
    <div class="permissions-modal-wrapper">
      <div class="permissions-modal__explanation">
        <p>{{ $t('super_admin_js.modals.perms_replication.warning_title') }}</p>
      </div>
      <!-- source shop selection -->
      <div class="permissions-modal__form-wrapper">
        <div class="permissions-modal__select-wrapper">
          <label
            for="permissions-modal-key"
            class="permissions-modal__label"
          >
            {{ $t('super_admin_js.modals.perms_replication.form.source_shop.label') }}
          </label>
          <div class="permissions-modal__select">
            <b-select
              v-model="source"
              :placeholder="$t('super_admin_js.modals.perms_replication.form.source_shop.placeholder')"
              :loading="sourceLoading"
              :disabled="sourceLoading"
              class="js-disable-form-alert"
              icon="institution"
              @focus="fetchDataIfNeeded"
            >
              <option
                v-for="shop in configuredShops"
                :key="shop.id"
                :value="shop"
              >
                {{ shop.name }}
              </option>
            </b-select>
          </div>
        </div>
        <!-- target shop selection -->
        <div class="permissions-modal__select-wrapper">
          <label
            for="permissions-modal-key"
            class="permissions-modal__label"
          >
            {{ $t('super_admin_js.modals.perms_replication.form.target_shop.label') }}
          </label>
          <div class="permissions-modal__select">
            <b-dropdown hoverable>
              <button
                slot="trigger"
                :disabled="fetchingData"
                class="button permissions-modal__dropdown-btn"
              >
                <span>
                  {{ $t('super_admin_js.modals.perms_replication.form.target_shop.placeholder') }}
                </span>
                <i
                  v-if="fetchingData"
                  class="fa fa-clock-o"
                  style="margin-left: .5em;"
                />
                <i
                  v-else
                  class="fa fa-chevron-down"
                  style="font-size: .7em;"
                />
              </button>
              <b-dropdown-item>
                <b-checkbox @input="toggleCheckAll">
                  {{ $t('super_admin_js.modals.perms_replication.all_shops') }}
                </b-checkbox>
              </b-dropdown-item>
              <b-dropdown-item v-for="shop in targetShops" :key="shop.id">
                <b-checkbox
                  v-model="target"
                  :native-value="shop.id"
                  class="js-disable-form-alert"
                >
                  {{ shop.name }}
                </b-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="permissions-modal__options-wrapper">
          <div class="permissions-modal__options-explanation">
            {{ $t('super_admin_js.modals.perms_replication.choose_export_models') }}
          </div>

          <!-- baseReplicationOptions always present -->
          <div
            v-for="(replicationOption, index) in baseReplicationOptions"
            :key="`alwaysPresentReplications-${index}`"
            class="permissions-modal__option"
          >
            <label class="permission-modal__label">
              <div class="permission-modal__name">
                {{ replicationOption.name }}
                <b-tooltip
                  v-if="replicationOption.legend"
                  :label="replicationOption.legend"
                  multilined
                >
                  <b-icon icon="question-circle" />
                </b-tooltip>
              </div>
              <b-checkbox
                v-model="selectedReplicationOptions"
                :native-value="replicationOption.value"
                class="js-disable-form-alert"
              />
            </label>
          </div>

          <!-- Optional : optimisation_settings -->
          <div
            v-if="source.optimisation"
            class="permissions-modal__option"
          >
            <label class="permission-modal__label">
              <div class="permission-modal__name">
                <span>{{ optimisationReplicationOption.name }}</span>
              </div>
              <b-checkbox
                v-model="selectedOptimisationReplicationOptions"
                :native-value="optimisationReplicationOption.value"
                class="js-disable-form-alert"
              />
            </label>
          </div>

          <!-- Convention & Alerts -->
          <div class="permissions-modal__option">
            <label
              class="permission-modal__label"
            >
              <div class="permission-modal__name">
                <span>{{ conventionOption.name }}</span>
              </div>

              <b-select
                v-model="selectedConventionReplicationOptions"
                :placeholder="$t('super_admin_js.modals.perms_replication.form.replication_options.convention.placeholder')"
                class="js-disable-form-alert"
              >
                <option :value="null" selected>
                  {{ $t('super_admin_js.modals.perms_replication.dont_copy') }}
                </option>
                <!-- options present depending on whether source shop has flexible or default convention -->
                <option
                  v-for="option in conventionReplicationOptions"
                  :key="option.name"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </b-select>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="badging-modal-footer-btns"
    >
      <validate-btn :handle-click="handleValidateClick" />
      <cancel-btn :handle-click="handleCancelClick" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';
import replicationOptionsDescriptor from './replicationOptions';

const BASE_OPTIONS = [
  'opening_hours',
  'base_settings',
  'meal_rules',
  'badging_settings',
  'postes',
  'teams',
  'absences',
  'automatic_planning_rules',
  'paid_breaks',
  'tax_rate',
  'popular_shifts',
];

export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    organisationId: {
      type: Number,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedReplicationOptions: [],
      selectedConventionReplicationOptions: null,
      selectedOptimisationReplicationOptions: false,
      shops: [],
      source: [],
      target: [],
      fetchingData: false,
    };
  },
  computed: {
    configuredShops() {
      return this.shops.filter((s) => s.configured);
    },
    targetShops() {
      let shops = this.shops.filter((s) => s.id !== this.source.id);
      shops = shops.filter((s) => s.configured);
      return shops;
    },
    replicationOptions() {
      return replicationOptionsDescriptor(this);
    },
    sourceLoading() {
      return this.fetchingData;
    },
    baseReplicationOptions() {
      return this.replicationOptions
                 .filter((replicationOption) => BASE_OPTIONS.includes(replicationOption.value));
    },
    optimisationReplicationOption() {
      return this.replicationOptions
                 .find((replicationOption) => replicationOption.value === 'optimisation_settings');
    },
    conventionOption() {
      return this.replicationOptions
                 .find((replicationOption) => replicationOption.value === 'convention_and_alerts_select');
    },
    conventionReplicationOptions() {
      const shownOptions = ['default_convention_and_alerts'];
      const isSourceUsingLabourLaw = this.source.labour_law_config
        && Object.keys(this.source.labour_law_config).length > 0;
      // We're not handling flexible convention for LLL shops yet  (see SuperAdmin::ConventionReplicator)
      if (this.source.convention_flexible && !isSourceUsingLabourLaw) {
        shownOptions.push('flexible_convention_and_alerts');
      }

      return this.conventionOption
                 .options
                 .filter((opt) => shownOptions.includes(opt.value));
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.fetchDataIfNeeded();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleValidateClick() {
      const targeted = this.target.filter((e) => Number(e) === e);
      const params = {
        replication_options: this.selectedReplicationOptions,
        convention_replication_options: this.selectedConventionReplicationOptions,
        optimisation_replication_options: this.selectedOptimisationReplicationOptions,
        source_shop_id: this.source.id,
        targeted_shop_ids: targeted,
      };

      httpClient
        .patch('/super_admin/api/bulk_shops', params)
        .then((r) => {
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.modals.perms_replication.success_message'),
          );
          this.handleCancelClick();
          if (this.callback) this.callback();
        })
        .catch((e) => {
          const message = e.response.data && e.response.data.errors;
          let messageString = this.$t('super_admin_js.modals.perms_replication.error_message');

          if (message && message.length > 0) {
            messageString = message.join('<br>');
          }

          this.makeToast(
            'is-danger',
            messageString,
          );
        });
},

    toggleCheckAll(newValue) {
      if (newValue) {
        this.target = this.targetShops.map((s) => s.id);
      } else {
        this.target = [];
      }
    },

    handleCancelClick() {
      this.$emit('update:visible', false);
    },

    fetchDataIfNeeded() {
      if (this.shops.length === 0) {
        this.fetchData();
      }
    },

    fetchData() {
      if (this.fetchingData) { return; }

      this.fetchingData = true;

      httpClient
        .get(`/super_admin/api/organisations/${this.organisationId}/shops?list_only=true`)
        .then((r) => {
          this.shops = r.data;
          this.source = this.shops.find((s) => s.configured);
          this.fetchingData = false;
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.modals.perms_replication.loading_error_message'),
          );
        });
},
  },
};
</script>

<style lang="scss" scoped>
.permissions-modal__select-wrapper,
.permissions-modal__checkbox-wrapper {
  display: flex;
  margin: 2em 0;
}

.permissions-modal__label {
  flex: 1;
  text-align: center;
  font-size: .9em;
}

.permissions-modal__select {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  select {
    width: 70%;
    height: 3em;
  }
}

.permissions-modal__option {
  display: flex;
  margin: .5em 0;
}

.permission-modal__label {
  flex: 1;
  display: flex;
}

.permission-modal__name {
  width: 70%;
}

.dropdown-content {
  max-height: 250px;
  overflow-y: scroll;
}

.permissions-modal__dropdown-btn {
  color: #777;
}
</style>
