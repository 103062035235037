<template>
  <SkModal
    :id="`dev-flag-update-modal-${devFlagName}`"
    :modal-title="title"
    modal-title-classes="dev-flag-update-modal-title"
    :submit-button-label="submitButtonLabel"
  >
    <template #body>
      <div class="dev-flag-update-modal__content">
        {{ body }}
      </div>
    </template>
    <template #submit-btn>
      <SkButton
        :variant="submitVariant"
        @click="handleSubmit"
      >
        {{ submitButtonLabel }}
      </SkButton>
    </template>
  </SkModal>
</template>

<script>
import {
  SkModal, SkButton, skListenersMixin, MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

export default {
  name: 'DevFlagUpdateModal',
  components: {
    SkModal,
    SkButton,
  },
  mixins: [skListenersMixin],
  props: {
    devFlagStatus: {
      required: true,
      type: Boolean,
    },
    devFlagName: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    body: {
      required: true,
      type: String,
    },
    submitButtonLabel: {
      required: true,
      type: String,
    },
  },
  computed: {
    submitVariant() {
      return this.devFlagStatus ? 'danger' : 'primary';
    },
  },
  methods: {
    handleSubmit() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, `dev-flag-update-modal-${this.devFlagName}`);
      this.$emit('devFlagUpdateValidation', { newStatus: !this.devFlagStatus });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header .dev-flag-update-modal-title {
  font-weight: bold;
  font-family: $poppins-font;
  font-size: 16px;
}

.dev-flag-update-modal__content {
  padding: 24px;
  font-family: $product-sans-font;
  font-size: 14px;
}
</style>
