<template>
  <div
    :style="resolveBorderTop"
    class="shop-card"
  >
    <a :href="resolveShopUrl" class="card-link">
      <div class="top-section">
        <div class="convention-section">{{ shop.convention }}</div>
        <div>{{ shop.nb_employees }} <i class="fa fa-users" /></div>
      </div>
      <div v-if="showReferents" class="middle-section">
        <p>Bizdev : {{ shop.bizdev ? shop.bizdev : '-' }}</p>
        <p>Coach : {{ shop.coach ? shop.coach : '-' }}</p>
      </div>
      <div class="bottom-section">
        <p class="shop-name">{{ shop.name }}</p>
        <p><b>Organisation:</b> {{ shop.organisation_name }}</p>
        <p v-if="shop.referent_name">
          {{ shop.referent_name }} |
          <i class="fa fa-phone small-icon" />
          {{ shop.referent_phone ? shop.referent_phone : '-' }} |
          <i class="fa fa-envelope-o small-icon" />
          {{ shop.referent_mail ? shop.referent_mail : '-' }}
        </p>
        <p v-if="shop.address">{{ shop.address }}</p>
      </div>
    </a>
  </div>
</template>

<script>
const BORDER_COLORS = {
  'IDCC 1979 - Hôtellerie Café Restaurant': '#ff6f59',
  'IDCC 1517 - Commerce de détail non alimentaire': '#7dcfb6',
  'IDCC 1501 - Restauration Rapide': '#a0dde6',
  'IDCC 843 - Boulangerie Pâtisserie': '#ef3054',
  'IDCC 1996 - Pharmacie': '#f79256',
  'IDCC 1747 - Boulangerie Pâtisserie Industrielle': '#c11a66',
  'IDCC 1370 - Hôtellerie 3 & 4 *': '#2de1fc',
  'IDCC 1505 - Fruits et Légumes': '#50e3c2',
  'IDCC 1702 - Travaux Publics': '#f3a712',
  'IDCC 953 - Traiteur Charcuterie de détail': '#eca1c3',
  'IDCC 1267 - Pâtisserie Confiserie': '#8eaeff',
  'IDCC 2216 - Commerce alimentaire': '#a06b9a',
  'ICC 2060 - Cafétéria': '#ad9cff',
};

export default {
  BORDER_COLORS,
  props: {
    shop: {
      type: Object,
      required: true,
    },
    isActiveCard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    resolveBorderTop() {
      const borderColor = BORDER_COLORS[this.shop.convention];
      return `border-top: 5px solid ${borderColor}`;
    },
    showReferents() {
      return this.isActiveCard;
    },
    resolveShopUrl() {
      return `/shops/${this.shop.id}`;
    },
  },
};
</script>

<style lang='scss' scoped>
.shop-card {
  margin-bottom: 25px;
  box-shadow: 2px 2px 5px 2px rgba(123, 142, 189, .35);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background: #f9f9f9;
  }
}

.card-link {
  color: #4a4a4a;
}

.top-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.convention-section {
  font-weight: bold;
  font-size: 16px;
}

.middle-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.shop-name {
  font-weight: bold;
  font-size: 18px;
}
</style>
