<template>
  <!-- eslint-disable  max-len -->
  <b-table
    ref="table"
    :data="accountCreations"
    :loading="loading"
    striped
    mobile-cards
    backend-sorting
    default-sort="id"
    @sort="(field, order) => sort(field, order)"
  >
    <template slot-scope="props">
      <b-table-column
        field="id"
        label="ID"
        sortable
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        field="organisations.name"
        :label="$t('super_admin_js.account_creation.list.organisation_name')"
        sortable
      >
        {{ props.row.organisation_name }}
      </b-table-column>
      <b-table-column
        field="step"
        :label="$t('super_admin_js.account_creation.list.step')"
        sortable
      >
        {{ props.row.step }}
      </b-table-column>
      <b-table-column
        field="name"
        :label="$t('super_admin_js.account_creation.list.name')"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        field="email"
        :label="$t('super_admin_js.account_creation.list.email')"
      >
        <a :href="mailTo(props.row.email)">
          {{ props.row.email }}
        </a>
      </b-table-column>
      <b-table-column
        field="phone"
        :label="$t('super_admin_js.account_creation.list.phone')"
        sortable
      >
        {{ props.row.phone }}
      </b-table-column>
      <b-table-column
        field="bizdev"
        :label="$t('super_admin_js.account_creation.list.sales_name')"
      >
        {{ props.row.bizdev }}
      </b-table-column>
      <b-table-column :label="$t('super_admin_js.account_creation.list.resend_invite')">
        <span
          class="pointer"
          @click="handleReSendInvitation(props.row)"
        >
          <b-icon
            pack="fa"
            icon="paper-plane"
            size="is-small"
            type="is-primary"
          />
        </span>
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
import httpClient from '@config/http_client';

export default {
  props: {
    accountCreations: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    handleSortChange: {
      type: Function,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },
  methods: {
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;

      this.handleSortChange(field, order);
    },
    mailTo(email) {
      return `mailto:${email}`;
    },
    modifyStatus(prospect) {
      const params = { prospect };
      httpClient
        .patch(`/super_admin/api/prospects/${prospect.id}`, params)
        .then(() => {
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.account_creation.list.updated'),
          );
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.account_creation.list.not_updated'),
          );
        });
    },
    handleReSendInvitation(prospect) {
      httpClient
        .post(`/super_admin/api/prospects/${prospect.id}/reinvite`)
        .then(() => {
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.account_creation.list.sent'),
          );
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.account_creation.list.not_sent'),
          );
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
</style>
