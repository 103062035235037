import { security } from '@config/security';
import { FactoryEvent, SOURCE } from '@skelloapp/svc-events-sdk';
import moment from 'moment';
import { mapState } from 'vuex';
import httpClient from '@config/http_client';
import SmallPaginationButton from '../SmallPaginationButton';
import TabBodyFirstLine from '../TabBodyFirstLine';
import MainContent from '../MainContent';
import Spinner from '../Spinner';
import EventsListTable from './EventsListTable.vue';
import sortableMixin from '../../mixins/sortableMixin';
import sendInviteMixin from '../../mixins/sendInviteMixin';
import Filters from './Filters/Filters.vue';
import { formatSvcEventsResponse } from './events-formatting';
import { splitPerChunk } from './events-utils';

const MAX_EVENTS_PER_PAGE = 20;
const FETCH_LIMIT = 40;

export default {
  name: 'EventsHistory',
  components: {
    Filters,
    EventsListTable,
    TabBodyFirstLine,
    MainContent,
    SmallPaginationButton,
    Spinner,
  },
  mixins: [sortableMixin, sendInviteMixin('plural')],
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    clusterNodeId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      events: [],
      next: null,
      page: 1,
      loading: true,
      initialLoading: true,
      svcEvents: undefined,
      entityId: this.clusterNodeId ?? this.id,
      title: this.$t('super_admin_js.events_history.title'),

      fetchLimit: FETCH_LIMIT,
      orderDirection: 'desc',
      employees: {},
      managers: {},

      filters: {
        selectedSubtypes: [],
        selectedUserIds: [],
        selectedDates: [moment().format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')],
      },
    };
  },
  computed: {
    ...mapState(['currentUser']),
    users() {
      return { ...this.employees, ...this.managers };
    },
    hasNext() {
      return this.filteredEvents.length > MAX_EVENTS_PER_PAGE * this.page;
    },
    hasPrev() {
      return this.page > 1;
    },
    isOrganisationLevel() {
      return this.type === 'organisation';
    },
    paginatedEvents() {
      return splitPerChunk(this.filteredEvents, MAX_EVENTS_PER_PAGE)[this.page - 1] ?? [];
    },
    filteredUsers() {
      return this.filters.selectedUserIds.map((id) => id.toString());
    },
    filteredEvents() {
      if (!this.filters.selectedUserIds.length) return this.events;

      return this.events.filter((event) => this.filteredUsers.includes(event.userId.toString())
        || this.filteredUsers.includes(event.impersonatedBy?.toString())
        || this.filteredUsers.includes(event.context.user_target_id?.toString())
        || this.filteredUsers.filter(
            (id) => event.context.user_target_ids?.map(
              (userTargetId) => userTargetId?.toString(),
            )
              .includes(id),
        ).length > 0);
    },
  },
  async mounted() {
    this.svcEvents = FactoryEvent.create(
      `${process.env.VUE_APP_SVC_EVENTS_URL}`,
      SOURCE.SUPER_ADMIN,
      {},
    );

    const [managers, employees] = await Promise.all([this.getManagers(), this.getEmployees()]);

    this.managers = managers;
    this.employees = employees;

    await this.getEvents();

    this.initialLoading = false;
  },
  methods: {
    getFilters() {
      const filters = {};

      if (this.filters.selectedSubtypes.length) {
        filters.subtypeFilters = this.filters.selectedSubtypes;
      }

      if (this.filters.selectedDates.length === 2) {
        const start = moment(this.filters.selectedDates[0]).set('hour', 0).set('minute', 1).valueOf();
        const end = moment(this.filters.selectedDates[1]).set('hour', 23).set('minute', 59).valueOf();
        filters.range = { start, end };
      }

      return filters;
    },
    async getEvents() {
      while (this.filteredEvents.length <= this.fetchLimit && this.next !== undefined) {
        // eslint-disable-next-line no-await-in-loop
        await this.fetchEventsHistory(this.next);
      }
    },
    async fetchEventsHistory(next = undefined, sort = false) {
      this.loading = true;

      try {
        const response = await this.fetch(next);

        if (response.data.length) {
          const formattedEvents = formatSvcEventsResponse(this.currentUser.lang)(response.data)
            .map((event) => ({
              ...event,
              context: {
                ...event.context,
                conflict_resolution_choice: event.context.conflict_resolution_choice
                  ? this.$t(event.context.conflict_resolution_choice)
                  : undefined,
                  source_template_applied: event.source_template_applied
                  ? this.$t(source_template_applied.label, source_template_applied.value)
                  : undefined,
                  user: event.impersonatedBy && event.impersonatedBy !== event.userId
                  ? `${event.context.user} - ${this.$t('super_admin_js.events_history.impersonate')} (${event.impersonatedBy})`
                  : event.context.user
                  ,
              },
            }));
          if (sort) {
            this.events = [...formattedEvents];
          } else {
            this.events.push(...formattedEvents);
          }
        }

        this.next = response.next;
      } catch (error) {
        console.error(error);
        this.next = undefined;
        this.makeAlertToast(this.$t('super_admin_js.events_history.events_error'));
      } finally {
        this.loading = false;
      }
    },
    async fetch(next = undefined) {
      const authToken = await security.getAuthToken();
      const findAllById = this.type === 'organisation' ? 'findAllByClusterNodeId' : 'findAllByShopId';

      return this.svcEvents.event[findAllById](this.entityId, {
        authorization: {
          jwt: authToken.token,
        },
        limit: FETCH_LIMIT,
        orderBy: this.orderDirection,
        next,
        ...this.getFilters(),
      });
    },
    async nextPage() {
      if (this.fetchLimit < this.filteredEvents.length + FETCH_LIMIT) {
        this.fetchLimit += FETCH_LIMIT;
      }

      await this.getEvents();
      this.page += 1;
    },
    prevPage() {
      if (this.hasPrev) this.page -= 1;
    },
    async initialize(filters = {}) {
      this.filters = filters;
      this.events = [];
      this.next = null;
      this.page = 1;
      await this.getEvents();
    },
    async getEmployees() {
      let page = 1;
      let next = true;
      const employees = [];

      while (next) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const response = await this.fetchEmployees(page);

          employees.push(...response.data.users);
          page += 1;

          if (employees.length >= response.data.total) next = false;
        } catch {
          console.error(error);
          next = false;
        }
      }

      return employees.reduce((acc, employee) => ({ ...acc, [employee.id]: employee }), {});
    },
    async fetchEmployees(page) {
      let params = {
        sort: 'id',
        direction: 'asc',
        page,
        type: this.type,
      };

      params = this.type === 'shop' ? { ...params, shop_id: this.id } : { ...params, organisation_id: this.id };

      return httpClient.get('/super_admin/api/users', { params });
    },
    async getManagers() {
      const response = (await httpClient.get(`/super_admin/api/${this.type}s/${this.id}`)).data[this.type];

      const managers = [];

      response.licenses.forEach((license) => { managers.push(...(response[license] ?? [])); });

      return managers.reduce((acc, manager) => ({ ...acc, [manager.id]: manager }), {});
    },
    async handleSort(direction) {
      if (this.loading) return;

      this.orderDirection = direction.direction;
      this.page = 1;
      this.next = null;
      await this.fetchEventsHistory(this.next, true);
    },
  },
};
