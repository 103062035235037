<template>
  <div class="error-page">
    Error
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style lang="scss" scoped>
.error-page {
  background: red;
}
</style>
