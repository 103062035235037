<template>
  <!-- eslint-disable  max-len -->
  <b-table
    ref="table"
    :data="shops"
    :loading="loading"
    striped
    mobile-cards
    backend-sorting
    default-sort="id"
    @sort="(field, order) => sort(field, order)"
  >
    <template slot-scope="props">
      <b-table-column
        field="id"
        :label="$t('super_admin_js.shops.table.id')"
        class="shop_column shop_column--id"
        sortable
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        field="name"
        :label="$t('super_admin_js.shops.table.name')"
        class="shop_column shop_column--name"
        sortable
      >
        <router-link :to="linkForShop(props.row)">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column
        field="managers_count"
        :label="$t('super_admin_js.shops.table.total_managers')"
        class="shop_column shop_column--managers-count"
        sortable
      >
        {{ props.row.managers_count }}
      </b-table-column>
      <b-table-column
        field="employees_count"
        :label="$t('super_admin_js.shops.table.total_active_employees')"
        class="shop_column shop_column--active-employees-count"
        sortable
      >
        {{ props.row.employees_count }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.shops.table.clocking_terminal')"
        class="shop_column shop_column--badging"
      >
        {{ props.row.badging ? 'Oui' : 'Non' }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.shops.table.paid_software')"
        class="shop_column shop_column--integrations"
      >
        {{ props.row.integrations }}
      </b-table-column>
      <b-table-column
        field="convention_id"
        :label="$t('super_admin_js.shops.table.collective_agreement')"
        class="shop_column shop_column--convention"
        sortable
      >
        {{ props.row.convention_name }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.shops.table.billing_address')"
        class="shop_column shop_column--billing-address"
      >
        {{ props.row.address }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.shops.table.clocking_terminal_token')"
        class="shop_column shop_column--billing-address"
      >
        {{ props.row.authentication_token }}
      </b-table-column>
    </template>
  </b-table>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    shops: {
      type: Array,
      required: true,
    },
    handleSortChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    linkForShop(shop) {
      return {
        name: 'shop',
        params: {
          id: shop.id,
        },
      };
    },
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;

      this.handleSortChange(field, order);
    },
  },
};
</script>

<style lang="scss">
  .shop_column--name,
  .shop_column--convention {
    white-space: nowrap;
  }
</style>
