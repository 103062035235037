<template>
  <!-- eslint-disable  max-len -->
  <sa-form-item-wrapper>
    <label
      slot="label"
      class="label"
    >{{ field.label }}</label>
    <b-field slot="input">
      <p class="control">
        <span class="button is-static">{{ field.from_label }}</span>
      </p>
      <b-select v-model="object[field.keys[0]]">
        <option
          v-for="(option, index) in field.start_values"
          :key="index"
          :value="option"
        >
          {{ option }}
        </option>
      </b-select>
      <p class="control">
        <span class="button is-static">{{ field.to_label }}</span>
      </p>
      <b-select v-model="object[field.keys[1]]">
        <option
          v-for="(option, index) in field.end_values"
          :key="index"
          :value="option"
        >
          {{ option }}
        </option>
      </b-select>
    </b-field>
  </sa-form-item-wrapper>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';
import SaFormItemWrapper from './Layout/SaFormItemWrapper';

export default {
  name: 'SaFormHoursRange',
  components: { SaFormItemWrapper },
  mixins: [saFormTypeMixin],
  computed: {
    wrapped() {
      return !this.field.withoutWrapper;
    },
  },
};
</script>
