<template>
  <main-content>
    <tab-body-first-line
      :title="$t('super_admin_js.licenses.title')"
    />
    <div class="tab-body-subtitle">
      {{ $t('super_admin_js.licenses.subtitle') }}
    </div>
    <LicenseTable
      :titles="titles"
      :items="licenses"
    >
      <LicenseRow
        slot-scope="{ item }"
        :license="item"
        @updateLicenseVisibility="handleLicenseVisibleToggle"
      />
    </LicenseTable>
  </main-content>
</template>

<script>
import httpClient from '@config/http_client';

import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import LicenseTable from '../../components/LicenseTable';
import LicenseRow from '../../components/LicenseRow';
import MainContent from '../../components/MainContent';

export default {
  name: 'LicensesList',
  components: {
    TabBodyFirstLine,
    LicenseTable,
    LicenseRow,
    MainContent,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      licenses: [],
    };
  },
  computed: {
    titles() {
        return [
          this.$t('super_admin_js.licenses.tabs.name'),
          this.$t('super_admin_js.licenses.tabs.visible'),
        ];
    },
  },
  mounted() {
    this.fetchLicenses();
  },
  methods: {
    fetchLicenses() {
       httpClient
        .get(`/super_admin/api/organisations/${this.organisation.id}/licenses`)
        .then((response) => {
          this.licenses = response.data.licenses;
        })
        .catch(() => { this.makeAlertToast(this.$t('super_admin_js.licenses.errors.organisation')); });
    },
    handleLicenseVisibleToggle(event) {
      this.updateLicense(event.licenseId, event.value);
    },
    updateLicense(licenseId, newVisibleValue) {
      httpClient
        .patch(`/super_admin/api/organisations/${this.organisation.id}/licenses/${licenseId}`, {
            visible: newVisibleValue,
            active: newVisibleValue,
        })
        .then((response) => {
          this.licenseById(response.data.id).visible = response.data.visible;
          const visible = response.data.visible ? 'visible' : 'not_visible';
          this.makeSuccessToast(
            this.$t(`super_admin_js.licenses.success.${visible}`, { name: response.data.name }),
          );
        })
        .catch((error) => {
          this.makeAlertToast(this.$t('super_admin_js.licenses.errors.back_update'));
        });
    },
    licenseById(id) {
      return this.licenses.find((license) => license.id === id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-body-subtitle {
  padding-bottom: 25px;
}
</style>
