<template>
  <div class="searchbar-container">
    <h1 class="sk-map-title">
      {{ $t('super_admin_js.skello_map.title') }} :
    </h1>
    <form
      @submit.prevent="fetchData"
      @keyup.enter="fetchData"
    >
      <label class="search-label">{{ $t('super_admin_js.skello_map.address') }} :</label>
      <input
        v-model="newQuery"
        class="search-input"
        @change="$emit('changeQuery', newQuery)"
      >
      <label class="search-label">{{ $t('super_admin_js.skello_map.show_shops') }} :</label>
      <input
        v-model="newRadius"
        class="search-input search-input--small"
        type="number"
        min="0"
        @change="$emit('changeRadius', newRadius)"
      >
      Km
    </form>
  </div>
</template>

<script>

export default {
  props: {
    fetchData: {
      type: Function,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    searchRadius: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newQuery: this.searchQuery,
      newRadius: this.searchRadius,
    };
  },
};
</script>

<style lang='scss' scoped>
  .sk-map-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .search-input {
    border-radius: 3px;
    border: 1px solid #1a6efc;
    padding: .5em;
    box-shadow: none;
    font-weight: lighter;
    font-size: 14px;
    margin: 0 10px;
    width: 270px;
  }

  .search-input--small {
    width: 67px;
  }
</style>
