<template>
  <div class="filters-container">
    <div class="left-section">
      <ShopsMapFilterSection
        :data-list="conventions"
        :set-filters="setFilters"
        :button-label="$t('super_admin_js.skello_map.convention')"
      />
      <ShopsMapFilterSection
        :data-list="sectors"
        :set-filters="setFilters"
        :button-label="$t('super_admin_js.skello_map.sector')"
      />
      <ShopsMapFilterSection
        :data-list="activeEmployees"
        :set-filters="setFilters"
        :button-label="$t('super_admin_js.skello_map.active_employees')"
      />
      <ShopsMapFilterSection
        :data-list="features"
        :set-filters="setFilters"
        :button-label="$t('super_admin_js.skello_map.features')"
      />
    </div>
    <div class="right-section">
      <button
        class="filter-submit-button"
        @click="submitSearch"
      >
        {{ $t('super_admin_js.skello_map.go') }} !
      </button>
    </div>
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import ShopsMapFilterSection from '../ShopsMapFilterSection';

export default {
  components: { ShopsMapFilterSection },
  props: {
    setFilters: {
      type: Function,
      required: true,
    },
    submitSearch: {
      type: Function,
      required: true,
    },
    firstFetch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      conventions: [],
      sectors: [],
      activeEmployees: [
        ['one_ten', this.$t('super_admin_js.skello_map.filters.one_ten')],
        ['ten_twenty', this.$t('super_admin_js.skello_map.filters.ten_twenty')],
        ['twenty_forty', this.$t('super_admin_js.skello_map.filters.twenty_forty')],
        ['forty_sixty', this.$t('super_admin_js.skello_map.filters.forty_sixty')],
        ['sixty_plus', this.$t('super_admin_js.skello_map.filters.sixty_plus')],
      ],
      features: [],
    };
  },
  mounted() {
    this.fetchFiltersData();
  },
  methods: {
    fetchFiltersData() {
      httpClient
        .get('/super_admin/api/fetch_filters', {})
        .then((response) => {
          this.conventions = response.data.conventions;
          this.sectors = response.data.sectors;
          this.features = response.data.features;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.try_later'),
          );
        });
    },
  },
};
</script>

<style>
.filters-container {
  border: 1px solid #dbe0e0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-section {
  margin-left: 10px;
}

.right-section {
  font-size: 13px;
}

.filter-submit-button {
  margin: 0 15px;
  background: #1a6efc;
  border-radius: 3px;
  padding: 5px 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  border: none;
  cursor: pointer;
}

.filter-switch {
  margin: 0 10px;
}
</style>
