<template>
  <!-- eslint-disable  max-len -->
  <b-table
    ref="table"
    :data="organisations"
    :loading="loading"
    striped
    mobile-cards
    backend-sorting
    default-sort="id"
    @sort="(field, order) => sort(field, order)"
  >
    <template slot-scope="props">
      <b-table-column
        field="id"
        :label="$t('super_admin_js.organisations.table.id')"
        class="organisation_column organisation_column--id"
        sortable
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        field="name"
        :label="$t('super_admin_js.organisations.table.name')"
        class="organisation_column organisation_column--name"
        sortable
      >
        <router-link :to="linkForOrganisation(props.row)">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column
        field="status"
        :label="$t('super_admin_js.organisations.table.status')"
        class="organisation_column organisation_column--status"
        sortable
      >
        {{ props.row.status }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.organisations.table.referent')"
        class="organisation_column organisation_column--referent"
      >
        <router-link
          v-if="props.row.owner_id"
          :to="linkForUserId(props.row.owner_id)"
        >
          {{ props.row.owner_name }}
        </router-link>
        <template v-else>
          {{ props.row.owner_name }}
        </template>
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.organisations.table.referent_number')"
        class="organisation_column organisation_column--referent-phone"
      >
        {{ props.row.owner_phone }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.organisations.table.referent_email')"
        class="organisation_column organisation_column--referent-email"
      >
        {{ props.row.owner_email }}
      </b-table-column>
      <b-table-column
        field="shops_count"
        :label="$t('super_admin_js.organisations.table.total_shops')"
        class="organisation_column organisation_column--shops-count"
        sortable
      >
        {{ props.row.shops_count }}
      </b-table-column>
      <b-table-column
        field="employees_count"
        :label="$t('super_admin_js.organisations.table.total_active_employees')"
        class="organisation_column organisation_column--active-employees-count"
        sortable
      >
        {{ props.row.users_count }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.organisations.table.siren')"
        class="organisation_column organisation_column--siren"
      >
        {{ props.row.siren }}
      </b-table-column>
    </template>
  </b-table>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
    handleSortChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    linkForOrganisation(organisation) {
      return {
        name: 'organisation',
        params: {
          id: organisation.id,
        },
      };
    },
    linkForUserId(userId) {
      return {
        name: 'user',
        params: {
          id: userId,
        },
      };
    },
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;

      this.handleSortChange(field, order);
    },
  },
};
</script>

<style lang="scss">
  .organisation_column--name,
  .organisation_column--referent {
    white-space: nowrap;
  }
</style>
