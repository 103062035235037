<template>
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <b-select
        v-model="object[field.key]"
        :disabled="field.disabled"
        @input="onChange"
      >
        <option
          v-for="option in availableOptions"
          :key="option.id"
          :value="option.id"
          :disabled="option.disabled"
        >
          {{ option.name }}
        </option>
      </b-select>
    </div>
  </div>
</template>

<script>
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormSelect',
  mixins: [saFormTypeMixin],
  computed: {
    availableOptions() {
      if (this.field.options) {
        return this.field.options;
      }
      return this.object[this.field.optionsKey];
    },
  },
  methods: {
    onChange(id) {
      const { update } = this.field;
      const newVal = this.object[this.field.optionsKey].find((e) => e.id === id);

      if (!update) { return true; }

      update.forEach((obj) => {
        this.object[obj.name] = newVal[obj.with];
      });

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
div.field-body {
  option[disabled] {
    color: #999;
  }
}
</style>
