export const arrayToSelectOptions = (array, callback) => {
  if (!array || array.length === 0) return [];
  return array.map((value) => ({
    id: value,
    text: typeof callback === 'function' ? callback(value) : value,
  }));
};

export const sortSelectOptions = (selectOptions) => selectOptions.sort(
    (optionA, optionB) => {
      if (optionA.text === optionB.text) {
        return 0;
      }
      if (optionA.text > optionB.text) {
        return 1;
      }
      return -1;
    },
  );

// eslint-disable-next-line arrow-body-style
export const filterObjectFields = (objectFields, fieldsToExclude) => {
  return _.pick(
    objectFields,
    _.differenceWith(Object.keys(objectFields), fieldsToExclude, _.isEqual),
  );
};
