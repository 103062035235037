import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translations from './lang';

Vue.use(VueI18n);

const messages = translations;

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  silentTranslationWarn: true,
  messages,
});

export default i18n;

const loadedLanguages = ['fr']; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);

  return lang;
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  return import(/* webpackChunkName: "lang-[request]" */ `./${lang}.js`)
    .then((newMessages) => {
      i18n.setLocaleMessage(lang, newMessages.default);

      loadedLanguages.push(lang);
      return setI18nLanguage(lang);
    });
}
