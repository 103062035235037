<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="$t('super_admin_js.stripe_charge.edit.modal_title')"
    :visible.sync="modalVisible"
  >
    <ProgressionBar
      :step="currentStep"
      :change-step="changeStep"
      first-text="Éditer la facture"
      second-text="Mettre à jour la facture"
    />
    <template v-if="currentStep === 0">
      <spinner
        v-if="isNotReady"
        no-margin="true"
      />
      <div
        v-else
        class="invoice-new__wrapper"
      >
        <div class="invoice-new__explanation">
          {{ $t('super_admin_js.stripe_charge.edit.update_invoice') }}
        </div>

        <div class="invoice-new__form">
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span invoice-new__label-span--datepicker">
              {{ $t('super_admin_js.stripe_charge.edit.labels.invoice_period') }}
            </label>
            <div class="invoice-edit__datepicker-wrapper">
              <Datepicker
                v-model="invoicePeriodStart"
                class="invoice-edit__datepicker"
                monday-first
                format="dd/MM/yyyy"
              />
              <Datepicker
                v-model="invoicePeriodEnd"
                class="invoice-edit__datepicker"
                monday-first
                format="dd/MM/yyyy"
              />
            </div>
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.social_denomination') }}
            </label>
            <input v-model="invoice.customer_social_denomination">
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.address') }}
            </label>
            <input v-model="invoice.customer_address">
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.postal_code') }}
            </label>
            <input v-model="invoice.customer_postal_code">
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.city') }}
            </label>
            <input v-model="invoice.customer_city">
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.country') }}
            </label>
            <input v-model="invoice.customer_country">
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        slot="footer"
        class="badging-modal-footer-btns"
      >
        <validate-btn :handle-click="moveToAccept" />
        <cancel-btn :handle-click="handleCancelClick" />
      </div>
    </template>
    <template v-if="currentStep === 1">
      <spinner
        v-if="isNotReady"
        no-margin="true"
      />
      <div
        v-else
        class="invoice-new__wrapper"
      >
        <div class="invoice-new__explanation">
          <p>{{ $t('super_admin_js.stripe_charge.edit.modal_explanation') }}</p>
          <p>{{ $t('super_admin_js.stripe_charge.edit.modal_warning') }}</p>
        </div>

        <div class="invoice-new__form">
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span invoice-new__label-span--datepicker">
              {{ $t('super_admin_js.stripe_charge.edit.labels.invoice_period') }}
            </label>
            {{ invoice.invoice_period_start }} - {{ invoice.invoice_period_end }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.social_denomination') }}
            </label>
            {{ invoice.customer_social_denomination }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.address') }}
            </label>
            {{ invoice.customer_address }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.postal_code') }}
            </label>
            {{ invoice.customer_postal_code }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.city') }}
            </label>
            {{ invoice.customer_city }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.edit.labels.country') }}
            </label>
            {{ invoice.customer_country }}
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        slot="footer"
        class="badging-modal-footer-btns"
      >
        <validate-btn
          :handle-click="handleValidateClick"
          :text="$t('super_admin_js.stripe_charge.edit.update')"
        />
        <cancel-btn :handle-click="moveToEdit" />
      </div>
    </template>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';

import Modal from '../Modal';
import Spinner from '../Spinner';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';
import ProgressionBar from '../ProgressionBar';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    Modal,
    Spinner,
    ValidateBtn,
    CancelBtn,
    ProgressionBar,
    Datepicker,
  },
  mixins: [modalVisibleMixin],
  props: {
    reload: {
      type: Function,
      required: true,
    },
    invoice: {
      type: Object,
      required: false,
    },
    clearModalInvoice: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      currentStep: 0,
    };
  },
  computed: {
    /*
    * Not ready if loading is at true OR invoice is not yet set
    * Because before invoice is set, modal is still rendered by Vue
    */
    isNotReady() {
      return this.loading || !this.invoice;
    },
    invoicePeriodStart: {
      get() {
        return this.invoice.invoice_period_start;
      },
      set(newDate) {
        this.invoice.invoice_period_start = moment(newDate).format('YYYY-MM-DD');
      },
    },
    invoicePeriodEnd: {
      get() {
        return this.invoice.invoice_period_end;
      },
      set(newDate) {
        this.invoice.invoice_period_end = moment(newDate).format('YYYY-MM-DD');
      },
    },
  },
  methods: {
    handleValidateClick() {
      this.loading = true;
      const url = `/super_admin/api/stripe_charges/${this.invoice.id}`;
      const params = { stripe_charge: this.invoice };
      httpClient
        .patch(url, params)
        .then((r) => {
          this.reload();
          this.makeSuccessToast(this.$t('super_admin_js.stripe_charge.edit.update_success'));
          this.loading = false;
          this.resetForm();
          this.closeModal();
        })
        .catch((e) => {
          this.loading = false;
          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.try_later'),
          );
          this.closeModal();
        });
    },
    handleCancelClick() {
      this.closeModal();
    },
    closeModal() {
      this.modalVisible = false;
      this.clearModalInvoice();
    },
    changeStep(newStep) {
      this.currentStep = newStep;
    },
    moveToAccept() {
      this.currentStep = 1;
    },
    moveToEdit() {
      this.currentStep = 0;
    },
    resetForm() {
      this.currentStep = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.badging-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.invoice-new__form {
  margin: 1em 0;
}

.invoice-new__label-wrapper {
  display: flex;
  margin: 1em 0;
}

.invoice-new__label-span {
  flex: .5;
}

.invoice-new__section-title {
  font-weight: bold;
}

.invoice-edit__datepicker-wrapper {
  display: flex;
}

.invoice-new__label-span--datepicker {
  flex: auto;
  max-width: 180px;
}

.invoice-edit__datepicker {
  margin-right: 1em;

  // Override weird vuejs-datepicker rules
  &::v-deep input {
    padding-left: 1em;
  }
}
</style>
