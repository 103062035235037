<template>
  <div class="labour-laws__table-container">
    <div class="labour-laws__toolbar">
      <SkSelectV2
        v-model="country"
        :options="availableCountries"
        :label="$t('super_admin_js.labour_laws.form.labels.country')"
        data-test="lll-list__country"
        :disabled="isLoading"
      />
      <SkButton
        icon="PlusSignIcon"
        data-test="lll-list__display-form"
        :disabled="isLoading"
        @click="displayForm"
      >
        {{ $t('super_admin_js.labour_laws.buttons.add') }}
      </SkButton>
    </div>

    <spinner v-if="isLoading" />
    <div
      v-else
      class="labour-laws__table"
    >
      <SkTable :columns="header">
        <LabourLawListItem
          v-for="labourLaw in labourLaws"
          :key="labourLaw.name"
          :labour-law="labourLaw"
          :edit-action="(labourLaw) => handleRowEdit(labourLaw)"
          :delete-action="(labourLaw) => handleRowDelete(labourLaw)"
          :is-loading="isLoading"
        />
      </SkTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  SkButton,
  SkSelectV2,
  SkTable,
  SkTooltipDirective,
  skListenersMixin,
} from '@skelloapp/skello-ui';
import Spinner from '~/components/Spinner';
import LabourLawListItem from './LabourLawListItem';
import { AVAILABLE_COUNTRIES } from '../lll-constants.js';

export default {
  name: 'LabourLawList',
  components: {
    LabourLawListItem,
    SkButton,
    SkSelectV2,
    SkTable,
    Spinner,
  },
  directives: { tooltip: SkTooltipDirective },
  mixins: [
    skListenersMixin,
  ],
  data() {
    return {
      currentCountry: 'FR',
      isLoading: true,
    };
  },
  computed: {
    ...mapState(['labourLaws']),
    header() {
      return [
        { title: this.$t('super_admin_js.labour_laws.form.labels.name') },
        { title: this.$t('super_admin_js.labour_laws.form.labels.scope') },
        { title: this.$t('super_admin_js.labour_laws.form.labels.key') },
        { title: this.$t('super_admin_js.labour_laws.form.labels.status') },
        { title: '' },
      ];
    },
    availableCountries() {
      return AVAILABLE_COUNTRIES
        .map((countryKey) => ({ id: countryKey, text: this.$t(`countries.${countryKey}`) }))
        .sort((countryA, countryB) => countryA.text.localeCompare(countryB.text));
    },
    country: {
      set(newCountry) {
        if (newCountry === this.currentCountry) return;

        this.currentCountry = newCountry;
        this.fetchData();
      },
      get() {
        return this.currentCountry;
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['fetchLabourLaws', 'deleteLabourLaw']),
    fetchData() {
      this.isLoading = true;

      this.fetchLabourLaws(this.country.toLowerCase())
        .catch(() => {
          this.displayErrorToaster();
        })
        .finally(() => { this.isLoading = false; });
    },
    displayForm() {
      this.$emit('display-form', null);
    },
    displayErrorToaster(message) {
      this.makeToast(
        'is-danger',
        message || this.$t('super_admin_js.labour_laws.errors.submit_failure'),
      );
    },
    handleRowEdit(labourLaw) {
      this.$emit('display-form', labourLaw);
    },
    handleRowDelete(labourLaw) {
      this.$dialog.confirm({
        title: this.$t('super_admin_js.labour_laws.confirm.delete_title'),
        message: this.$t('super_admin_js.labour_laws.confirm.delete_message'),
        confirmText: this.$t('super_admin_js.modals.delete.actions.confirm'),
        cancelText: this.$t('super_admin_js.modals.delete.actions.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.submitRowDelete(labourLaw);
        },
      });
    },
    submitRowDelete(labourLaw) {
      this.isLoading = true;

      this.deleteLabourLaw({
        key: labourLaw.key,
        scope: labourLaw.scope,
        country: labourLaw.country,
      })
        .then(() => {
          this.fetchData();
        })
        .catch((error) => {
          const nbShops = error.response?.data?.message?.match(/\(\d\)/)?.pop();
          if (error.response.status === 401 && nbShops) {
            this.displayErrorToaster(this.$t('super_admin_js.labour_laws.errors.delete_forbidden', { nb_shops: nbShops }));
          } else {
            this.displayErrorToaster();
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .labour-laws__table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .labour-laws__toolbar {
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-end;
    flex-flow: 0;

    .sk-select {
      width: 200px !important;
      margin-right: 16px;
    }

    .sk-button {
      display: block;
    }
  }

  .labour-laws__table {
    overflow-y: auto;
    flex-grow: 1;
  }
</style>
