<template>
  <!-- eslint-disable  max-len -->
  <b-table
    ref="table"
    :data="sentEmails"
    :loading="loading"
    class="list-table list-table--invoices"
    striped
    mobile-cards
    backend-sorting
    default-sort="id"
    @sort="(field, order) => sort(field, order)"
  >
    <template slot-scope="props">
      <b-table-column
        :label="$t('super_admin_js.sent_emails.table.fields.event')"
        field="event"
        class="charge_column charge_column--type"
      >
        <b-tooltip
          :label="props.row.event.tooltip"
          multilined
          type="is-dark"
          size="is-small"
          position="is-right"
        >
          <span
            :class="eventLabelClass(props.row.event.status)"
            class="email-list__label"
          >
            {{ props.row.event.status }}
          </span>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.sent_emails.table.fields.date')"
        field="date"
        class="charge_column charge_column--invoice_number column-date"
        sortable
      >
        {{ moment(props.row.date).format('DD/MM/YYYY HH:mm') }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.sent_emails.table.fields.subject')"
        field="subject"
        class="charge_column charge_column--kind"
      >
        {{ props.row.subject }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.sent_emails.table.fields.from')"
        field="from"
        class="charge_column charge_column--id"
      >
        {{ props.row.from }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.sent_emails.table.fields.to')"
        field="to"
        class="charge_column charge_column--id"
        sortable
      >
        {{ props.row.to }}
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    sentEmails: {
      type: Array,
      required: true,
    },
    handleChangeSort: {
      type: Function,
      required: true,
    },
  },
  computed: {
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;
      this.handleChangeSort(field, order);
    },
    eventLabelClass(eventStatus) {
      const eventLabelClasses = {
        Ouvert: 'email-list__label-opened',
        Envoyé: 'email-list__label-requests',
        Remis: 'email-list__label-delivered',
        Cliqué: 'email-list__label-clicks',
        Bloqué: 'email-list__label-blocked',
        'Email invalide': 'email-list__label-invalid',
        Différé: 'email-list__label-deferred',
        Rejeté: 'email-list__label-softBounces',
        'Rejeté (soft)': 'email-list__label-softBounces',
        'Rejeté (hard)': 'email-list__label-hardBounces',
      };
      return eventLabelClasses[eventStatus] || 'email-list__label-default';
    },
  },
};
</script>

<style lang="scss">
  .charge_column--customer_email,
  .charge_column--address {
    white-space: nowrap;
  }

  .invoice_pending {
    border: 2px solid #ff9600;
    padding: 3px;
    border-radius: 3px;
    background-color: rgba(255, 150, 0, .2);
    color: #ff9600;
    font-weight: 600;
  }

  .email-list__label {
    padding: 1px .5rem 3px;
    border-radius: 3px;
    color: #fff;
  }

  .column-date {
    min-width: 120px;
  }

  .email-list__label-requests {
    background-color: #66b2dc;
  }

  .email-list__label-clicks {
    background-color: #9cc980;
  }

  .email-list__label-delivered {
    background-color: #4388ae;
  }

  .email-list__label-hardBounces {
    background-color: #e6735c;
  }

  .email-list__label-softBounces {
    background-color: #ea8b6f;
  }

  .email-list__label-invalid {
    background-color: #cd7867;
  }

  .email-list__label-deferred {
    background-color: #efa381;
  }

  .email-list__label-opened {
    background-color: #66d2dc;
  }

  .email-list__label-default {
    background-color: #1a6efc;
  }

  .email-list__label-blocked {
    background-color: #f7d3a6;
  }
</style>
