function actionButtons(context) {
  return [
    {
      iconPack: 'fa',
      icon: 'files-o',
      tooltipLabel: context.$t('super_admin_js.buttons.tooltips.shop.replicate_settings'),
      onClick: context.handleReplicateParamsClick,
    },
    {
      iconPack: 'fa',
      icon: 'plus',
      tooltipLabel: context.$t('super_admin_js.buttons.tooltips.shop.add_automatic_plannings'),
      onClick: context.handleAddAutomaticPlanningsClick,
      hidden: context.shop.maxAutomaticPlanningUses === 0
        || !context.canAddAutomaticPlanningCredits,
    },
  ];
}

export default actionButtons;
