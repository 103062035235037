import Vue from 'vue';
import Buefy from 'buefy';
import Sortable from 'sortablejs';
import VueSortable from 'vue-sortable';
import wysiwyg from 'vue-wysiwyg';
import Datepicker from 'vuejs-datepicker';
import VueGoogleMaps from 'vue-googlemaps';
import 'vue-googlemaps/dist/vue-googlemaps.css';

import '@skelloapp/skello-ui/dist/skello-ui.css';
import {
  SkTrackDirective,
  SkModalDirective,
  SkTooltipDirective,
} from '@skelloapp/skello-ui';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import { skToast } from './config/sk-toast';

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_URL,
  environment: process.env.VUE_APP_APP_ENV,
  logErrors: true,
  integrations: [new Integrations.BrowserTracing()],
});

Vue.use(Buefy, { defaultIconPack: 'fa' });
Vue.use(Datepicker);
Vue.use(VueSortable);
Vue.use(wysiwyg, {
  hideModules: {
    hyperlink: true,
    headings: true,
    image: true,
    table: true,
  },
});

Vue.use(VueGoogleMaps, {
  load: {
    apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  },
});

Vue.directive('sortable', {
  inserted(el, binding) {
    const sortable = new Sortable(el, binding.value || {});
  },
});

Vue.directive('track', SkTrackDirective);
Vue.directive('modal', SkModalDirective);
Vue.directive('tooltip', SkTooltipDirective);
Vue.mixin({ methods: { $skToast: skToast } });

Vue.mixin({
  methods: {
    makeToast(type, message) {
      const variant = type === 'is-danger' ? 'error' : 'success';
      this.$skToast({
        variant,
        message,
        duration: 5000,
        position: 'is-bottom',
      });
    },
    makeAlertToast(message) {
      this.makeToast('is-danger', message);
    },
    makeSuccessToast(message) {
      this.makeToast('is-success', message);
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
