<template>
  <tr class="canary-table__row">
    <td class="canary-table__cell canary-table__cell--grow">
      <CanaryTargetModal
        ref="targetModal"
        :feature-name="featureFlag.name"
        @update="handleTargetUpdate"
      />
      <CanaryConfirmationModal
        :feature-flag="featureFlag"
        @validate="handleActiveUpdate"
      />
      <SkInput
        v-model="featureFlag.name"
        v-tooltip="tooltipText"
        :disabled="true"
        :label="$t('super_admin_js.feature_flags.fields.name')"
      />
    </td>
    <td class="canary-table__cell canary-table__cell--select">
      <div class="canary-table__select">
        <SkSelectV2
          v-model="featureFlag.canaryReleaseType"
          :options="canaryReleaseTypes"
          @input="updateFlag"
        >
          <template #selected-option="{ value }">
            {{ capitalize(value) }}
          </template>
          <template #option="{ option }">
            <div class="step-form__select-text text-truncate">
              {{ capitalize(option.id) }}
            </div>
          </template>
        </SkSelectV2>
      </div>
    </td>
    <td class="canary-table__cell canary-table__cell--link canary-table__cell--centered">
      <a
        role="button"
        class="sk-link--medium canary-table__link"
        @click="showTargetModal"
      >
        {{ $t('super_admin_js.feature_flags.table.target') }}
      </a>
    </td>
    <td class="canary-table__cell canary-table__cell--preview">
      <div class="canary-table__ids-preview">
        {{ previewCanaryIds }}
      </div>
    </td>
    <td>
      <SkSwitch
        :value="featureFlag.active"
        @input="showConfirmationModal"
      />
    </td>
  </tr>
</template>

<script>
import {
  SkInput,
  SkSwitch,
  SkSelectV2,
  SkTooltipDirective,
  MODAL_SHOW_EVENT,
  skListenersMixin,
} from '@skelloapp/skello-ui';
import httpClient from '@config/http_client';
import CanaryTargetModal from '../modals/CanaryTargetModal';
import CanaryConfirmationModal from '../modals/CanaryConfirmationModal';

export default {
  components: {
    SkInput,
    SkSwitch,
    CanaryTargetModal,
    CanaryConfirmationModal,
    SkSelectV2,
  },
  directives: { tooltip: SkTooltipDirective },
  mixins: [skListenersMixin],
  props: {
    featureFlag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      canaryReleaseTypes: [{ id: 'shop' }, { id: 'user' }, { id: 'organisation' }],
    };
  },
  computed: {
    previewCanaryIds() {
      const ids = this.featureFlag.canaryReleaseIds?.join();
      if (!ids) return this.$t('super_admin_js.feature_flags.table.apply_to_all');

      const cutoffSize = 100; // Max length of preview string

      if (ids.length <= cutoffSize) {
        return ids;
      }

      const cutIds = ids
        .slice(0, cutoffSize) // Get a smaller string back, where the final id might be cut
        .split(',') // split into an array of ids
        .slice(0, -1) // remove the last id, that might be cut
        .join(); // turn back into comma-separated string

      return `${cutIds},...`;
    },
    tooltipText() {
      return `<b>${this.featureFlag.name}</b><br/>${this.featureFlag.description}`;
    },
  },
  methods: {
    capitalize(string) {
      return `${string[0].toUpperCase()}${string.slice(1)}`;
    },
    showTargetModal() {
      this.$refs.targetModal.setCanaryReleaseIds(this.featureFlag.canaryReleaseIds?.join() || '');
      setTimeout(() => {
        this.emitOnRoot(MODAL_SHOW_EVENT, event, `canary-target-modal-${this.featureFlag.name}`);
      }, 0); // Open modal after ids are set
    },
    showConfirmationModal() {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, `canary-confirmation-modal-${this.featureFlag.name}`);
    },
    handleTargetUpdate({ newIds }) {
      // If nothing was changed, we don't call the update:
      // Updating a flag to the same flag causes issues in local.
      if (JSON.stringify(this.featureFlag.canaryReleaseIds) === JSON.stringify(newIds)) return;

      this.$set(this.featureFlag, 'canaryReleaseIds', newIds);
      this.updateFlag();
    },
    handleActiveUpdate({ newStatus }) {
      this.featureFlag.active = newStatus;
      this.updateFlag();
    },
    updateFlag() {
      const params = { feature_flag: this.featureFlag };

      httpClient
        .put(`/super_admin/api/feature_flags_dev/${this.featureFlag.name}`, params)
        .then(() => {
          this.makeSuccessToast(this.$t('super_admin_js.feature_flags.update_success'));
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.makeAlertToast(
              this.$t('errors.unauthorized'),
            );

            this.$router.push({ name: 'home' });
          } else {
            this.makeAlertToast(
              this.$t('errors.loading'),
            );
          }
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.canary-table__row {
  height: 68px;
}

.canary-table__select {
  width: 158px;
}

.canary-table__cell {
  padding-right: 14px;
}

.canary-table__link {
  font-weight: $fw-bold;
}

.canary-table__cell--grow {
  width: 30%;
  min-width: 280px;
}

.canary-table__cell--link {
  padding-left: 18px;
  padding-right: 32px;
}

.canary-table__cell--centered {
  vertical-align: middle;
}

.canary-table__cell--preview {
  vertical-align: middle;
  width: 280px;
  max-width: 280px;
}

.canary-table__cell--select {
  width: 172px;
}

.canary-table__ids-preview {
  font-size: 12px;
  overflow-wrap: break-word;
}
</style>
