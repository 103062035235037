function replicationOptions(context) {
  const baseTranslationKey = 'super_admin_js.modals.perms_replication.form.replication_options';
  const options = [
    {
      name: context.$t(`${baseTranslationKey}.opening_hours.title`),
      value: 'opening_hours',
    },
    {
      name: context.$t(`${baseTranslationKey}.base_settings.title`),
      value: 'base_settings',
      legend: context.$t(`${baseTranslationKey}.base_settings.legend`),
    },
    {
      name: context.$t(`${baseTranslationKey}.meal_rules.title`),
      value: 'meal_rules',
    },
    {
      name: context.$t(`${baseTranslationKey}.badging_settings.title`),
      value: 'badging_settings',
    },
    {
      name: context.$t(`${baseTranslationKey}.postes.title`),
      value: 'postes',
    },
    {
      name: context.$t(`${baseTranslationKey}.teams.title`),
      value: 'teams',
    },
    {
      name: context.$t(`${baseTranslationKey}.absences.title`),
      value: 'absences',
    },
    {
      name: context.$t(`${baseTranslationKey}.optimisation_settings.title`),
      value: 'optimisation_settings',
    },
    {
      name: context.$t(`${baseTranslationKey}.convention.title`),
      value: 'convention_and_alerts_select',
      options: [
        {
          name: `${context.source.convention_legible} (Standard)`,
          value: 'default_convention_and_alerts',

        },
        {
          name: `${context.source.convention_legible} (Flexible)`,
          value: 'flexible_convention_and_alerts',

        },
      ],
    },
    {
      name: context.$t(`${baseTranslationKey}.automatic_planning_rules.title`),
      value: 'automatic_planning_rules',
    },
    {
      name: context.$t(`${baseTranslationKey}.paid_breaks.title`),
      value: 'paid_breaks',
    },
    {
      name: context.$t(`${baseTranslationKey}.tax_rate.title`),
      value: 'tax_rate',
    },
    {
      name: context.$t(`${baseTranslationKey}.popular_shifts.title`),
      value: 'popular_shifts',
    },
  ];

  return options;
}

export default replicationOptions;
