<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <ul>
        <li v-for="item in value" :key="item.url">
          <router-link :to="item.url">
            {{ item.name }}
          </router-link>
          &nbsp;{{ item.details }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormLinksTextList',
  mixins: [saFormTypeMixin],
};
</script>
