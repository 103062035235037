function base(context) {
  return [
    {
      type: 'inputNumber',
      label: context.$t('super_admin_js.labels.shop.settings.popular_shifts_count'),
      key: 'popular_shifts_count',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.activate_opti'),
      key: 'optimisation',
    },
  ];
}

function badging(context) {
  return [
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.late_arrival_takes_planned_date'),
      key: 'late_arrival_takes_planned_date',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.early_arrival_takes_planned_date'),
      key: 'early_arrival_takes_planned_date',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.late_departure_counted_as_late'),
      key: 'late_departure_counted_as_late',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.early_departure_counted_as_early'),
      key: 'early_departure_counted_as_early',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.badging_lateness_notification_enabled'),
      key: 'badging_lateness_notification_enabled',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.allow_badging_pause'),
      key: 'allow_badging_pause',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.badging_takes_photo'),
      key: 'badging_takes_photo',
    },
    {
      type: 'switch',
      label: context.$t('super_admin_js.labels.shop.settings.signature'),
      key: 'signature',
    },
    {
      type: 'inputNumber',
      label: context.$t('super_admin_js.labels.shop.settings.lateness_notification'),
      key: 'lateness_notification',
    },
    {
      type: 'input',
      label: context.$t('super_admin_js.labels.shop.settings.punch_clock_token'),
      key: 'punch_clock_token',
    },
  ];
}

export default { base, badging };
