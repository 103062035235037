<template>
  <!-- eslint-disable  max-len -->
  <div>
    <header-content :title="$t('super_admin_js.links.users')" />
    <main-content>
      <h4 class="title is-4">
        {{ title }}
      </h4>
      <user-list-table
        :users="users"
        :loading="isLoading"
        :handle-sort-change="handleSortChange"
      />
      <br>
      <b-pagination
        v-if="total > 0"
        :total="total"
        :current="page"
        order="is-right"
        per-page="100"
        @change="value => handlePageChange(value)"
      />
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import HeaderContent from '../../components/HeaderContent';
import MainContent from '../../components/MainContent';
import UserListTable from '../../components/UserListTable';
import sortableMixin from '../../mixins/sortableMixin';

export default {
  components: {
    HeaderContent,
    MainContent,
    UserListTable,
  },
  mixins: [sortableMixin],
  data() {
    return {
      isLoading: true,
      users: [],
      sort: 'id',
      direction: 'asc',
      page: 1,
      total: 0,
    };
  },
  computed: {
    status() {
      return this.$route.query.status;
    },
    title() {
      switch (this.status) {
        case undefined:
          return this.$t('super_admin_js.users.user_list');
        case 'active':
          return this.$t('super_admin_js.users.active_list');
        case 'archived':
          return this.$t('super_admin_js.users.archived_list');
        default:
          return this.$t('super_admin_js.users.user_list');
      }
    },
  },
  watch: {
    status() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      httpClient
        .get('/super_admin/api/users', {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
            type: 'all',
            status: this.status,
          },
        })
        .then((response) => {
          this.users = response.data.users;
          this.total = response.data.total;
          this.isLoading = false;
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('super_admin_js.users.errors.generic'));
        });
    },
    linkForShopId(shopId) {
      return {
        name: 'shop',
        params: {
          id: shopId,
        },
      };
    },
    linkForUser(user) {
      return {
        name: 'user',
        params: {
          id: user.id,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 1em;
}
</style>
