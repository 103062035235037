<template>
  <modal
    :title="title"
    :visible.sync="modalVisible"
  >
    <div>
      {{ $t('super_admin_js.modals.disable_pay_exports.warning') }}
      <slot />
    </div>
    <div
      slot="footer"
      class="disable-pay-exports-modal-footer-btns"
    >
      <validate-btn :handle-click="handleValidateClick" />
      <cancel-btn :handle-click="handleCancelClick" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  name: 'DisableAllIntegrationsModal',
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    baseParams: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleValidateClick() {
      httpClient
        .patch('/super_admin/api/pay_exporters', { ...this.baseParams, pay_exporters: [] })
        .then(() => {
          this.makeToast('is-success', this.$t('super_admin_js.modals.disable_pay_exports.disable_automatic_export_success_toast'));
          if (this.callback) this.callback();
          this.handleCancelClick();
        })
        .catch(() => {
          this.makeToast('is-danger', this.$t('super_admin_js.modals.disable_pay_exports.disable_automatic_export_failure_toast'));
        });
    },
    handleCancelClick() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-pay-exports-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
