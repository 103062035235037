<template>
  <div class="navbar__wrapper">
    <div class="navbar__logo">
      <SkelloIcon @click.native="goHome" />
    </div>
    <div v-if="monogram" class="navbar__search__wrapper">
      <SearchBar />
      <div class="navbar__search__separator" />
    </div>
    <div
      v-if="monogram"
      class="navbar__right"
      @click="dropdownVisible = !dropdownVisible"
    >
      <div class="navbar__monogram">
        {{ monogram }}
      </div>

      <div class="navbar__caret" :class="caretClass">
        <CaretIcon />
      </div>
    </div>

    <div v-if="dropdownVisible" class="navbar__dropdown">
      <a class="navbar__dropdown__link" role="button" :href="currentUser.profileUrl">
        <div class="navbar__dropdown__icon">
          <PersonIcon fill="#1a6efc" height="15" width="15" />
        </div>

        {{ $t('layout.navbar.profile') }}
      </a>
      <a class="navbar__dropdown__link" :href="currentUser.editPasswordUrl">
        <div class="navbar__dropdown__icon">
          <LockIcon fill="#1a6efc" height="15" width="15" />
        </div>

        {{ $t('layout.navbar.change_password') }}
      </a>
      <a class="navbar__dropdown__link" role="button" @click="logout">
        <div class="navbar__dropdown__icon">
          <PowerOffIcon fill="#1a6efc" height="15" width="15" />
        </div>

        {{ $t('layout.navbar.logout') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  PowerOffIcon,
  PersonIcon,
  LockIcon,
  CaretIcon,
} from '@skelloapp/skello-ui';
import { security } from '@config/security';

import SkelloIcon from '../../assets/SkelloIcon';
import SearchBar from '../../components/SearchBar';

export default {
  name: 'Navbar',
  components: {
    SkelloIcon,
    PowerOffIcon,
    LockIcon,
    PersonIcon,
    CaretIcon,
    SearchBar,
  },
  data() {
    return {
      dropdownVisible: false,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    monogram() {
      const { firstName, lastName } = this.currentUser;

      if (!firstName || !lastName) {
        return null;
      }

      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    },
    caretClass() {
      return this.dropdownVisible ? 'navbar__caret--up' : 'navbar__caret--down';
    },
  },
  methods: {
    ...mapActions(['removeCurrentUser']),
    logout() {
      this.dropdownVisible = false;
      this.removeCurrentUser();
      security.logOut();
    },
    goHome() {
      this.$router.push({ name: 'organisations' });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar__wrapper {
  display: flex;
  justify-content: space-between;
  height: 75px;
  padding: 0 35px;
  align-items: center;
  border-bottom: 1px solid $sk-gray-light-3;
  position: relative;
}

.navbar__logo {
  cursor: pointer;
}

.navbar__search__wrapper {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.navbar__search__separator {
  height: 20px;
  border-right: 1px solid #EBEBEB;
  margin: 0 26px;
}

.navbar__caret {
  margin-left: 10px;
}

.navbar__caret--up {
  transform: rotate(180deg);
}

.navbar__monogram {
  width: 30px;
  height: 30px;
  background: $sk-blue-dark-2;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
}

.navbar__dropdown {
  position: absolute;
  right: 10px;
  top: 74px;
  width: 240px;
  background: white;
  border: 1px solid $sk-gray-light-3;
  padding: 13px 10px;
  z-index: 1;
}

.navbar__dropdown__link {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;

  &:hover,
  &:active {
    color: $sk-blue-med-1;
    text-decoration: none;
    background-color: $sk-gray-light-1;
  }
}

.navbar__dropdown__icon {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.navbar__right {
  display: flex;
  cursor: pointer;
}
</style>
