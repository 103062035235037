<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <b-tooltip
        v-if="tooltip() != ''"
        :label="tooltip()"
        multilined
        type="is-dark"
        size="is-small"
        position="is-left"
      >
        <b-switch
          v-model="object[field.key]"
          :disabled="disabled"
        />
      </b-tooltip>
      <b-switch
        v-else
        v-model="object[field.key]"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormSwitch',
  mixins: [saFormTypeMixin],
};
</script>
