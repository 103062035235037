<template>
  <!-- eslint-disable  max-len -->
  <div>
    <header-content :title="title" />
    <main-content>
      <tab-body-first-line
        v-if="canCreateOrUpdateInvoices"
        :title="$t('super_admin_js.stripe_charge.labels.list_page_title')"
        :buttons="buttons"
      >
        <div class="sc-export__wrapper">
          <span class="sc-export-label sc-export-label--from">
            {{ $t('super_admin_js.stripe_charge.export.from') }}
          </span>
          <div class="sc-datepicker__wrap">
            <Datepicker
              v-model="exportStart"
              monday-first
              format="dd/MM/yyyy"
            />
          </div>
          <span class="sc-export-label sc-export-label--to">
            {{ $t('super_admin_js.stripe_charge.export.to') }}
          </span>
          <div class="sc-datepicker__wrap">
            <Datepicker
              v-model="exportEnd"
              monday-first
              format="dd/MM/yyyy"
            />
          </div>
        </div>
      </tab-body-first-line>
      <invoice-list-table
        :charges="charges"
        :can-create-or-update-invoices="canCreateOrUpdateInvoices"
        :loading="isLoading"
        :handle-sort-change="handleSortChange"
        :handle-edit-click="handleEditClick"
        :handle-preview-click="handlePreviewClick"
      />
      <br>
      <b-pagination
        v-if="total > 0"
        :total="total"
        :current="page"
        order="is-right"
        per-page="100"
        @change="value => handlePageChange(value)"
      />
      <invoiceNewModal
        :visible.sync="invoiceNewModalVisible"
        :reload="fetchData"
      />
      <InvoiceEditModal
        :visible.sync="invoiceEditModalVisible"
        :invoice="editedInvoice"
        :reload="fetchData"
        :clear-modal-invoice="clearEditModalInvoice"
      />
      <MailPreviewModal
        :visible.sync="mailPreviewModalVisible"
        :invoice="previewInvoice"
        :reload="fetchData"
      />
    </main-content>
  </div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import httpClient from '@config/http_client';
import { buildLinkFor } from '@config';

import sortableMixin from '../../mixins/sortableMixin';
import HeaderContent from '../../components/HeaderContent';
import MainContent from '../../components/MainContent';
import InvoiceListTable from '../../components/InvoiceListTable';
import InvoiceNewModal from '../../components/InvoiceNewModal';
import InvoiceEditModal from '../../components/InvoiceEditModal';
import MailPreviewModal from '../../components/MailPreviewModal';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';

export default {
  components: {
    Datepicker,
    HeaderContent,
    InvoiceListTable,
    InvoiceNewModal,
    InvoiceEditModal,
    MailPreviewModal,
    MainContent,
    TabBodyFirstLine,
  },
  mixins: [sortableMixin],
  data() {
    return {
      isLoading: false,
      invoiceNewModalVisible: false,
      invoiceEditModalVisible: false,
      mailPreviewModalVisible: false,
      editedInvoice: null,
      previewInvoice: {},
      charges: [],
      sort: 'id',
      direction: 'desc',
      page: 1,
      total: 0,
      exportStart: moment().startOf('month').format('L'),
      exportEnd: moment().endOf('month').format(),
      canCreateOrUpdateInvoices: false,
    };
  },
  computed: {
    formattedExportStart() {
      return this.formatDate(this.exportStart);
    },
    formattedExportEnd() {
      return this.formatDate(this.exportEnd);
    },
    title() {
      return this.$t('super_admin_js.stripe_charge.labels.general_title');
    },
    buttons() {
      return [
        {
          fontPack: 'fa',
          icon: 'file-excel-o',
          onClick: this.handleExportClick,
          tooltipLabel: this.$t('super_admin_js.stripe_charge.export.export_invoices'),
        },
        {
          fontPack: 'fa',
          icon: 'plus',
          onClick: this.handleAddClick,
          tooltipLabel: this.$t('super_admin_js.stripe_charge.new.button_tooltip'),
        },
      ];
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleExportClick() {
      const baseUrl = buildLinkFor('/super_admin/api/invoices/exports.xlsx');
      const params = `start_date=${this.formattedExportStart}&end_date=${this.formattedExportEnd}`;
      window.open(`${baseUrl}?${params}`);
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    fetchData() {
      httpClient
        .get('/super_admin/api/stripe_charges', {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
            type: 'billing_info',
            billing_info_scope: 'all',
          },
        })
        .then((r) => {
          this.charges = r.data.stripe_charges;
          this.total = r.data.total;
          this.canCreateOrUpdateInvoices = r.data.can_create_or_update_invoices;
          this.isLoading = false;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.errors.loaded'),
          );
        });
},
    handleAddClick() {
      this.invoiceNewModalVisible = true;
    },
    handleEditClick(charge) {
      this.editedInvoice = charge;
      this.invoiceEditModalVisible = true;
    },
    clearEditModalInvoice() {
      this.editedInvoice = null;
    },
    handlePreviewClick(charge) {
      this.previewInvoice = charge;
      this.mailPreviewModalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sc-export__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1em 0;
}

.sc-export-label {
  font-size: .8em;
  font-weight: bold;
}

.sc-export-label,
.sc-datepicker__wrap {
  margin-right: 1em;
}

.sc-datepicker__wrap {
  max-width: 110px;

  ::v-deep input {
    font-size: .7em;
  }

  ::v-deep .vdp-datepicker__calendar {
    left: auto;
    right: 0;
  }
}
</style>
