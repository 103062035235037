<template>
  <div>
    <table class="license-table">
      <thead>
        <tr>
          <th v-for="title in titles" :key="title">
            {{ title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <slot v-for="item in items" :item="item" />
      </tbody>
    </table>
    <div v-if="noItems" class="license-table__empty-row">
      {{ emptyRowText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicenseTable',
  props: {
    titles: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    emptyRowText: {
      type: String,
      default() {
        return this.$t('super_admin_js.licenses.nothing_to_show');
      },
    },
  },
  computed: {
    noItems() {
      return this.items.length === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.license-table {
  width: 100%;
  min-width: 440px;

  thead {
    height: 53px;
    font-family: "ProximaNova", "Helvetica", sans-serif;
    font-size: 16px;
    line-height: 28px;
    background-color: #1e2b44;

    tr {
      th {
        color: #fff;
        vertical-align: middle;
        padding-left: 23px;
      }
    }
  }

  tbody {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .1);
    box-sizing: border-box;
  }
}

.license-table__empty-row {
  height: 60px;
  text-align: center;
  line-height: 60px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  box-sizing: border-box;
}
</style>
