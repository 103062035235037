<template>
  <div class="page__container">
    <header-content :title="titleText" class="title" />
    <LabourLawForm
      v-if="isFormDisplayed"
      :labour-law-params="formLabourLaw"
      @close-form="toggleForm(null)"
    />
    <LabourLawsList
      v-else
      @display-form="(labourLaw) => toggleForm(labourLaw)"
    />
  </div>
</template>
<script>

import { mapState } from 'vuex';
import LabourLawForm from '~/components/LabourLaws/LabourLawForm';
import LabourLawsList from '~/components/LabourLaws/LabourLawsList';
import HeaderContent from '~/components/HeaderContent';

export default {
  components: {
    LabourLawForm,
    LabourLawsList,
    HeaderContent,
  },
  data() {
    return {
      isFormDisplayed: false,
      formLabourLaw: null,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    titleText() {
      return this.isFormDisplayed
        ? this.$t('super_admin_js.labour_laws.form.title')
        : this.$t('super_admin_js.links.labour_laws');
    },
  },
  mounted() {
    if (!this.currentUser.can_manage_labour_laws) {
      this.makeAlertToast(
        this.$t('errors.unauthorized'),
      );

      this.$router.push({ name: 'home' });
    }
  },
  methods: {
    toggleForm(labourLaw) {
      this.formLabourLaw = labourLaw;
      this.isFormDisplayed = !this.isFormDisplayed;
    },
  },
};
</script>
<style scoped type="scss">
  .page__container {
    overflow-y: hidden;
    padding-bottom: 60px;
  }
</style>
