<template>
  <!-- eslint-disable  max-len -->
  <div>
    <HeaderContent :title="$t('super_admin_js.links.organisations')" />
    <MainContent>
      <h4 class="title is-4">
        {{ $t('super_admin_js.organisations.organisation_list') }}
      </h4>
      <OrganisationListTable
        :organisations="organisations"
        :loading="isLoading"
        :handle-sort-change="handleSortChange"
      />
      <br>
      <b-pagination
        v-if="total > 0"
        :total="total"
        :current="page"
        order="is-right"
        per-page="100"
        @change="value => handlePageChange(value)"
      />
    </MainContent>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import httpClient from '@config/http_client';
import HeaderContent from '../../components/HeaderContent';
import MainContent from '../../components/MainContent';
import OrganisationListTable from '../../components/OrganisationListTable';
import sortableMixin from '../../mixins/sortableMixin';

export default {
  components: {
    HeaderContent,
    MainContent,
    OrganisationListTable,
  },
  mixins: [sortableMixin],
  data() {
    return {
      isLoading: true,
      organisations: [],
      sort: 'id',
      direction: 'asc',
      page: 1,
      total: 0,
    };
  },
    computed: {
      ...mapState(['currentUser']),
    },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      if (!this.currentUser.id) return;

      httpClient
        .get('/super_admin/api/organisations', {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
          },
        })
        .then((response) => {
          this.organisations = response.data.organisations;
          this.total = response.data.total;
          this.isLoading = false;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.errors.loading'),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 1em;
}
</style>
