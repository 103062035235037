<template>
  <!-- eslint-disable  max-len -->
  <modal
    :visible.sync="modalVisible"
    :title="$t('super_admin_js.modals.import_users.modal_title')"
  >
    <UserImportErrorToast
      :visible="showErrorToast"
      @close-toast="showErrorToast = false"
    >
      <template slot="content">
        <div>
          {{ $t('super_admin_js.modals.import_users.error') }}
        </div>
        <div
          v-for="(error, index) in toastErrorMessages"
          :key="error + index"
        >
          {{ error }}
        </div>
      </template>
    </UserImportErrorToast>
    <div>
      <div class="has-text-centered">
        {{ $t('super_admin_js.modals.import_users.warning') }}
      </div>

      <spinner
        v-if="loading"
        no-margin="true"
      />
      <div v-else>
        <div class="has-text-centered margined-section--double template-download-section">
          <a
            href="https://www.notion.so/skelloapp/Import-Employees-on-the-SuperAdmin-71a0d94a6e3f4b84926671c3d63f9c21"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ $t('super_admin_js.modals.import_users.how_to') }}
          </a>
        </div>

        <div class="has-text-centered margined-section--double template-download-section">
          <h2 class="users-import__titles">
            <b>{{ $t('super_admin_js.modals.import_users.step_1') }}</b> {{ $t('super_admin_js.modals.import_users.download_files') }}
          </h2>

          <div class="columns">
            <div class="column">
              <SkSelectV2
                v-model="lang"
                :options="languages"
                :label="$t('language')"
                :search-placeholder="$t('language')"
                :searchable="true"
              />
            </div>
            <div class="column">
              <SkSelectV2
                v-model="country"
                :options="countries"
                :label="$t('country')"
                :search-placeholder="$t('country')"
                :searchable="true"
              />
            </div>
          </div>
          <div>
            <a
              class="template-download__link"
              :href="importTemplateLink"
            >
              {{ $t('super_admin_js.modals.import_users.download_template') }}
            </a>
          </div>
        </div>

        <div class="has-text-centered margined-section">
          <h2 class="users-import__titles">
            <b>{{ $t('super_admin_js.modals.import_users.step_2') }}</b> {{ $t('super_admin_js.modals.import_users.upload_file') }}
          </h2>
          <b-field v-if="!file">
            <b-upload
              v-model="file"
              drag-drop
            >
              <section class="section">
                <div class="content has-text-centered">
                  <p>
                    <b-icon
                      icon="cloud-upload"
                      size="is-large"
                    />
                  </p>
                  <p>{{ $t('super_admin_js.modals.import_users.drag_or_click') }}</p>
                </div>
              </section>
            </b-upload>
          </b-field>
          <section
            v-else
            class="section"
          >
            <div :class="filenameClasses">
              <p>{{ file.name }}</p>
              <button
                class="delete is-small"
                type="button"
                @click="deleteDropFile()"
              />
            </div>
          </section>
          <div
            v-if="errors.length > 0"
            class="errored__panel"
          >
            <div class="errored__icon">
              <CircledExclamationMarkIcon
                fill="#e12f57"
                width="23"
                height="23"
              />
            </div>
            <div class="errored__info-section">
              <p>{{ $t('super_admin_js.modals.import_users.errors.first_line', {number_of_errors: errors.length}) }}</p>
              <ul>
                <li v-for="error in errors" :key="error.i18n">
                  {{ getErrorMessage(error) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      slot="footer"
      class="badging-modal-footer-btns"
    >
      <validate-btn
        :handle-click="handleValidateClick"
        text="Importer"
      />
      <cancel-btn :handle-click="handleCancelClick" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SkSelectV2, CircledExclamationMarkIcon } from '@skelloapp/skello-ui';

import { countryKeys } from '~/shared/utils/country-keys.js';
import { arrayToSelectOptions, sortSelectOptions } from '~/shared/utils/form';
import { AVAILABLE_LANGUAGES } from '../../shared/constants';
import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';
import Spinner from '../Spinner';
import UserImportErrorToast from '../UserImportErrorToast';
import enumerate from '../../utils/enumerate.js';

/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */

export default {
  components: {
    Modal,
    ValidateBtn,
    CancelBtn,
    Spinner,
    UserImportErrorToast,
    SkSelectV2,
    CircledExclamationMarkIcon,
  },
  mixins: [modalVisibleMixin],
  props: {
    visible: Boolean,
    shop: Object,
  },
  data() {
    return {
      file: null,
      loading: false,
      errors: [],
      showErrorToast: false,
      toastErrorMessages: null,
      selectedCountry: null,
      selectedLang: null,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapGetters(['isDevFeatureFlagActive']),
    lang: {
      get() {
        return this.selectedLang || this.defaultUserLang;
      },
      set(lang) {
        this.selectedLang = lang;
      },
    },
    country: {
      get() {
        return this.selectedCountry || this.shop.country;
      },
      set(country) {
        this.selectedCountry = country;
      },
    },
    importTemplateLink() {
      return `${process.env.VUE_APP_API_ROOT}/super_admin/api/user_import_template?language=${this.lang}&country=${this.country}`;
    },
    defaultUserLang() {
      return this.currentUser.lang.toUpperCase();
    },
    countries() {
      const countryList = countryKeys(this.country);
      return sortSelectOptions(
        arrayToSelectOptions(countryList, (value) => this.$t(`countries.${value}`)),
      );
    },
    languages() {
     return arrayToSelectOptions(AVAILABLE_LANGUAGES, (value) => this.$t(`languages.${value}`));
    },
    filenameClasses() {
      return {
        content: true,
        'has-text-centered': true,
        errored__filename: this.errors.length > 0,
      };
    },
  },
  methods: {
    ...mapActions(['enumerate']),
    deleteDropFile() {
      this.file = null;
      this.errors = [];
    },
    handleValidateClick() {
      if (this.loading) return;
      const url = `/super_admin/api/shops/${this.shop.id}/imports/users/upsert`;
      const data = new FormData();
      data.append('file', this.file, this.file.name);
      this.loading = true;
      httpClient.patch(url, data)
        .then((r) => {
          this.makeToast('is-success', this.$t('super_admin_js.modals.import_users.success'));
          this.loading = false;
          this.modalVisible = false;

          // needs to be reset if there was an error beforehand
          this.showErrorToast = false;
          this.errors = [];
        })
        .catch((e) => {
          this.loading = false;
          if (e.response.data.errors) {
            this.errors = e.response.data.errors;
            return;
          }
          if (e.response.data.error) {
            this.renderErrorToast(e.response.data.error);
          }
        });
    },
    handleCancelClick() {
      this.modalVisible = false;
    },
    getErrorMessage(error) {
      const maxItemsToDisplay = 5;
      const message = this.$t(
        `super_admin_js.modals.import_users.errors.${error.key}`,
        { field: error.field },
      );
      if (error.users.length === 0) return `${message}.`;

      const enumeratedUsers = enumerate({ items: error.users, maxItemsToDisplay });
      if (!enumeratedUsers) return `${message}.`;
      if (error.users.length <= maxItemsToDisplay) return `${message}: ${enumeratedUsers}.`;
      return `${message}: ${enumeratedUsers} ${this.$t('super_admin_js.modals.import_users.more')}.`;
    },
    renderErrorToast(message) {
      // Errors are formatted in the back with a ';;' as a separator
      this.toastErrorMessages = message.split(';;');

      this.$nextTick(() => {
        this.showErrorToast = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.badging-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.margined-section {
  margin: 1em 0;
}

.margined-section--double {
  margin: 2em 0;
}

.user-import__toast {
  padding: 20px;
  flex-direction: column;
  cursor: pointer;
}

.errored__filename {
  color: $sk-red-dark-1;
  font-weight: $fw-bold;
}

.errored__panel {
  color: $sk-red-dark-1;
  background-color: $sk-red-light-1;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
}

.errored__icon {
  margin-right: 1rem;
}

.errored__info-section {
  text-align: left;
  word-break: break-word;
}

.errored__info-section > ul {
  list-style: initial;
  padding: 1rem;
}
</style>
