<template>
  <tr class="feature-flags__table__row">
    <td class="feature-flags__table__cell">
      <SkInput
        v-model="featureFlag.name"
        v-tooltip="tooltipText"
        :disabled="true"
        :label="$t('super_admin_js.feature_flags.fields.name')"
      />
    </td>
    <td class="feature-flags__table__cell">
      <SkSelectV2
        v-model="featureFlag.languages"
        :options="sortedCountryOptions"
        :label="$t('super_admin_js.feature_flags.fields.countries')"
        force-search-bar-behavior="force-hide"
        multi
        no-confirm
        :group-options="optionHeaders"
        object-value
      >
        <template #selected-option="{ value }">
          {{ displayCountries(value) }}
        </template>
        <template #option="{ option }">
          <div class="step-form__select-text text-truncate">
            {{ option.text }}
          </div>
        </template>
      </SkSelectV2>
    </td>
    <td class="feature-flags__table__cell">
      <SkSelectV2
        v-model="featureFlag.sectors"
        :options="sortedSectorOptions"
        :label="$t('super_admin_js.feature_flags.fields.sectors')"
        force-search-bar-behavior="force-hide"
        multi
        no-confirm
        :group-options="optionHeaders"
        object-value
      >
        <template #selected-option="{ value }">
          {{ displaySectors(value) }}
        </template>
        <template #option="{ option }">
          <div class="step-form__select-text text-truncate">
            {{ option.text }}
          </div>
        </template>
      </SkSelectV2>
    </td>
    <td class="feature-flags__table__cell">
      <SkButton
        :spinner="isLoading"
        :disabled="!isFeatureFlagEdited"
        @click="handleUpdate"
      >
        {{ $t('super_admin_js.feature_flags.submit') }}
      </SkButton>
    </td>
  </tr>
</template>

<script>
import _ from 'lodash';
import {
  SkInput,
  SkSelectV2,
  SkTooltipDirective,
  SkButton,
} from '@skelloapp/skello-ui';
import httpClient from '@config/http_client';

export default {
  components: { SkInput, SkSelectV2, SkButton },
  directives: { tooltip: SkTooltipDirective },
  props: {
    featureFlag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      originalFeatureFlag: { ...this.featureFlag },
      sectorOptions: [
        {
          id: 'general',
          text: this.$t('super_admin_js.feature_flags.sectors.general'),
        },
        {
          id: 'hospitality',
          text: this.$t('super_admin_js.feature_flags.sectors.hospitality'),
        },
        {
          id: 'retail',
          text: this.$t('super_admin_js.feature_flags.sectors.retail'),
        },
        {
          id: 'health',
          text: this.$t('super_admin_js.feature_flags.sectors.health'),
        },
        {
          id: 'leisure',
          text: this.$t('super_admin_js.feature_flags.sectors.leisure'),
        },
        {
          id: 'industry_and_services',
          text: this.$t('super_admin_js.feature_flags.sectors.industry_and_services'),
        },
        {
          id: 'industry',
          text: this.$t('super_admin_js.feature_flags.sectors.industry'),
        },
        {
          id: 'services',
          text: this.$t('super_admin_js.feature_flags.sectors.services'),
        },
        {
          id: 'construction',
          text: this.$t('super_admin_js.feature_flags.sectors.construction'),
        },
      ],
      countryOptions: [
        {
          id: 'fr',
          text: this.$t('super_admin_js.feature_flags.countries.fr'),
        },
        {
          id: 'gb',
          text: this.$t('super_admin_js.feature_flags.countries.gb'),
        },
        {
          id: 'es',
          text: this.$t('super_admin_js.feature_flags.countries.es'),
        },
        {
          id: 'de',
          text: this.$t('super_admin_js.feature_flags.countries.de'),
        },
        {
          id: 'ch',
          text: this.$t('super_admin_js.feature_flags.countries.ch'),
        },
        {
          id: 'be',
          text: this.$t('super_admin_js.feature_flags.countries.be'),
        },
        {
          id: 'other',
          text: this.$t('super_admin_js.feature_flags.countries.other'),
        },
      ],
      isLoading: false,
      optionHeaders: [
        { id: 'all', text: this.$t('super_admin_js.feature_flags.all'), matchKey: '*' },
      ],
    };
  },
  computed: {
    tooltipText() {
      return `<b>${this.featureFlag.name}</b><br/>${this.featureFlag.description}`;
    },
    sortedCountryOptions() {
      return this.sortOptions(this.countryOptions);
    },
    sortedSectorOptions() {
      return this.sortOptions(this.sectorOptions);
    },
    isFeatureFlagEdited() {
      return !(_.isEqual(this.originalFeatureFlag, this.featureFlag));
    },
  },
  methods: {
    displaySectors(sectors) {
      return sectors.map((sector) => this.$t(`super_admin_js.feature_flags.sectors.${sector}`)).sort().join(', ');
    },
    displayCountries(countries) {
      return countries.map((country) => this.$t(`super_admin_js.feature_flags.countries.${country}`)).sort().join(', ');
    },
    sortOptions(options) {
      return options.sort((first, second) => {
        const firstKey = first.text.toLowerCase();
        const secondKey = second.text.toLowerCase();
        // compare strings to see which comes first alphabetically
        if (firstKey > secondKey) { return 1; }
        if (firstKey < secondKey) { return -1; }
        return 0;
      });
    },
    handleUpdate() {
      if (this.isLoading) return;

      const params = {
        feature_flag: {
          name: this.featureFlag.name,
          languages: this.featureFlag.languages,
          sectors: this.featureFlag.sectors,
        },
      };
      this.isLoading = true;
      httpClient
        .put(`/super_admin/api/feature_flags/${this.featureFlag.name}`, params)
        .then(() => {
          this.makeSuccessToast(this.$t('super_admin_js.feature_flags.update_success'));
          this.originalFeatureFlag = { ...this.featureFlag };
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.makeAlertToast(
              this.$t('errors.unauthorized'),
            );

            this.$router.push({ name: 'home' });
          } else {
            this.makeAlertToast(
              this.$t('errors.loading'),
            );
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.feature-flags__table__row {
  height: 68px;

  td {
    padding-right: 16px;

    &:last-child {
      vertical-align: middle;
      padding-right: 0;
    }
  }
}

.feature-flags__table__cell {
  max-width: 289px;

  // trick to get the last cell to fit button size
  &:last-child {
    width: 1%;
    white-space: nowrap;
  }
}
</style>
