<!-- eslint-disable max-len -->
<template>
  <SkModal
    id="delete-super-admin-user-modal"
    ref="deleteSuperAdminUserModal"
    :modal-title="$t('super_admin_js.manage_super_admin_users.modals.delete_super_admin_user.title')"
    :submit-button-label="$t('super_admin_js.manage_super_admin_users.modals.delete_super_admin_user.submit_label')"
    :submit-disabled="isLoading"
    @cancel="handleHide"
    @close="handleHide"
  >
    <template #body>
      <div class="delete-super-admin-user__content">
        <span>
          {{ $t('super_admin_js.manage_super_admin_users.modals.delete_super_admin_user.description', { email }) }}
        </span>
      </div>
    </template>
    <template #submit-btn>
      <SkButton
        variant="danger"
        @click="handleSubmit"
      >
        {{ submitButtonLabel }}
      </SkButton>
    </template>
  </SkModal>
</template>

<script>
import {
  MODAL_HIDE_EVENT,
  MODAL_SHOW_EVENT,
  SkButton,
  skListenersMixin,
  SkModal,
} from '@skelloapp/skello-ui';

import httpClient from '@config/http_client';

export default {
  components: {
    SkButton,
    SkModal,
  },
  mixins: [skListenersMixin],
  data() {
    return {
      email: '',
      isLoading: false,
    };
  },
  computed: {
    submitButtonLabel() {
      return this.$t(
        'super_admin_js.manage_super_admin_users.modals.delete_super_admin_user.submit_label',
      );
    },
  },
  mounted() {
    this.listenOnRoot(MODAL_SHOW_EVENT, this.show);
  },
  methods: {
    handleHide() {
      // Timeout to avoid flickering when closing modal
      setTimeout(() => {
        this.email = '';
      }, 200);
    },
    handleSubmit() {
      this.isLoading = true;

      httpClient
        .patch('/super_admin/api/users/bulk', {
          users: [{
            email: this.email,
            super_admin: false,
          }],
        })
        .then(() => {
          this.emitOnRoot(MODAL_HIDE_EVENT, event, 'delete-super-admin-user-modal');
          this.$emit('super-admin-user-deleted', this.email);
          this.makeSuccessToast(this.$t('super_admin_js.manage_super_admin_users.modals.delete_super_admin_user.success'));
          this.handleHide();
        }).catch(() => {
          this.makeAlertToast(
            this.$t('super_admin_js.manage_super_admin_users.modals.generic_error'),
          );
        }).finally(() => {
          this.isLoading = false;
        });
    },
    show(_event, modalId, email) {
      if (modalId !== 'delete-super-admin-user-modal') {
        return;
      }

      this.email = email;
      this.$refs.deleteSuperAdminUserModal.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-super-admin-user__content {
  padding: 24px 24px 16px 24px;
}
</style>
