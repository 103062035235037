<template>
  <!-- eslint-disable  max-len -->
  <div class="third-stage-wrapper">
    <div class="category-title">
      {{ $t('super_admin_js.modals.new_integration.third_stage.title') }}
    </div>
    <div
      v-for="workedTimeInfo in integration.evps.worked_time_infos"
      :key="workedTimeInfo.name"
      class="third-stage-wrapper--abs-line"
    >
      <integration-text
        :class="activeEvpClass(workedTimeInfo)"
        :text="workedTimeInfo.name"
        :width="250"
      />
      <integration-select
        v-model="workedTimeInfo.counter_type"
        :class="activeEvpClass(workedTimeInfo)"
        :options="contractTypeOptions"
        :width="150"
        :label="$t('super_admin_js.modals.new_integration.third_stage.concerned_contracts')"
      />
      <integration-input
        v-model="workedTimeInfo.code"
        :class="activeEvpClass(workedTimeInfo)"
        :width="80"
        label="Code"
      />
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="workedTimeInfo.active"
          :value="workedTimeInfo.active"
          :field="`evp_${workedTimeInfo.name}`"
          :model="workedTimeInfo"
          :modelAttribute="workedTimeInfo.active"
          :changeAction="activateEvp"
          name="active"
        />
      </div>
      <div
        v-if="workedTimeInfo.key == 'effective_hours'"
        class="third-stage-wrapper__tooltip"
      >
        <b-icon
          v-tooltip="$t('super_admin_js.modals.new_integration.third_stage.only_for_pause_compensation_shops')"
          icon="question-circle"
        />
      </div>
    </div>
    <div class="category-title">
      {{ $t('super_admin_js.modals.new_integration.third_stage.hours_with_majoration') }}
    </div>
    <div
      v-for="hoursIncreasedInfo in integration.evps.hours_increased_infos"
      :key="hoursIncreasedInfo.name"
      class="third-stage-wrapper--abs-line"
    >
      <integration-text
        :class="activeEvpClass(hoursIncreasedInfo)"
        :text="hoursIncreasedInfo.name"
        :width="250"
      />
      <integration-select
        v-model="hoursIncreasedInfo.counter_type"
        :class="activeEvpClass(hoursIncreasedInfo)"
        :options="contractTypeOptions"
        :width="150"
        :label="$t('super_admin_js.modals.new_integration.third_stage.concerned_contracts')"
        :on-change="spreadChangeInHoursIncreasedEvpGroup"
        :on-change-param="hoursIncreasedInfo.key"
      />
      <integration-input
        v-model="hoursIncreasedInfo.code"
        :class="activeEvpClass(hoursIncreasedInfo)"
        :width="80"
        label="Code"
      />
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="hoursIncreasedInfo.active"
          :value="hoursIncreasedInfo.active"
          :field="`evp_${hoursIncreasedInfo.name}`"
          :model="hoursIncreasedInfo"
          :modelAttribute="hoursIncreasedInfo.active"
          :changeAction="activateEvp"
          name="active"
        />
      </div>
    </div>
    <div class="category-title">
      {{ $t('super_admin_js.modals.new_integration.third_stage.meals') }}
    </div>
    <div
      v-for="mealsInfo in integration.evps.meals_infos"
      :key="mealsInfo.name"
      class="third-stage-wrapper--abs-line"
    >
      <integration-text
        :class="activeEvpClass(mealsInfo)"
        :text="mealsInfo.name"
        :width="250"
      />
      <integration-select
        v-model="mealsInfo.counter_type"
        :class="activeEvpClass(mealsInfo)"
        :options="contractTypeOptions"
        :width="150"
        label="Contrats concernés"
      />
      <integration-input
        v-model="mealsInfo.code"
        :class="activeEvpClass(mealsInfo)"
        :width="80"
        label="Code"
      />
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="mealsInfo.active"
          :value="mealsInfo.active"
          :field="`evp_${mealsInfo.name}`"
          :model="mealsInfo"
          :modelAttribute="mealsInfo.active"
          :changeAction="activateEvp"
          name="active"
        />
      </div>
    </div>
    <div class="category-title">
      {{ $t('super_admin_js.modals.new_integration.third_stage.pay_variables') }}
    </div>
    <div
      v-for="salaryInfo in integration.evps.salary_infos"
      :key="salaryInfo.name"
      class="third-stage-wrapper--abs-line"
    >
      <integration-text
        :class="activeEvpClass(salaryInfo)"
        :text="salaryInfo.name"
        :width="250"
      />
      <integration-select
        v-model="salaryInfo.counter_type"
        :class="activeEvpClass(salaryInfo)"
        :options="contractTypeOptions"
        :width="150"
        :label="$t('super_admin_js.modals.new_integration.third_stage.concerned_contracts')"
      />
      <integration-input
        v-model="salaryInfo.code"
        :class="activeEvpClass(salaryInfo)"
        :width="80"
        label="Code"
      />
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="salaryInfo.active"
          :value="salaryInfo.active"
          :field="`evp_${salaryInfo.name}`"
          :model="salaryInfo"
          :modelAttribute="salaryInfo.active"
          :changeAction="activateEvp"
          name="active"
        />
      </div>
    </div>
    <div class="category-title">
      {{ $t('super_admin_js.modals.new_integration.third_stage.comment') }}
    </div>
    <div
      v-for="commentInfo in integration.evps.comment_infos"
      :key="commentInfo.name"
      class="third-stage-wrapper--abs-line"
    >
      <integration-text
        :class="activeEvpClass(commentInfo)"
        :text="commentInfo.name"
        :width="250"
      />
      <integration-select
        v-model="commentInfo.counter_type"
        :class="activeEvpClass(commentInfo)"
        :options="contractTypeOptions"
        :width="150"
        :label="$t('super_admin_js.modals.new_integration.third_stage.concerned_contracts')"
      />
      <integration-input
        v-model="commentInfo.code"
        :class="activeEvpClass(commentInfo)"
        :width="80"
        label="Code"
      />
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="commentInfo.active"
          :value="commentInfo.active"
          :field="`evp_${commentInfo.name}`"
          :model="commentInfo"
          :modelAttribute="commentInfo.active"
          :changeAction="activateEvp"
          name="active"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SkTooltipDirective,
 } from '@skelloapp/skello-ui';
import IntegrationSelect from '../IntegrationSelect';
import IntegrationInput from '../IntegrationInput';
import IntegrationText from '../IntegrationText';
import WhiteSkSwitch from '../../../WhiteSkSwitch';

export default {
  name: 'NewIntegrationModalThirdStage',
  components: {
    IntegrationSelect,
    IntegrationInput,
    IntegrationText,
    WhiteSkSwitch,
  },
  directives: { tooltip: SkTooltipDirective },
  props: {
    shopId: {
      type: Number,
      required: true,
    },
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    contractTypeOptions() {
      return [
        { key: '', value: this.$t('super_admin_js.modals.new_integration.third_stage.all') },
        { key: 'hourly_rate', value: this.$t('super_admin_js.modals.new_integration.third_stage.hourly_rate') },
        { key: 'daily_rate', value: this.$t('super_admin_js.modals.new_integration.third_stage.daily_rate') },
      ];
    },
  },
  watch: {},
  mounted() {},
  methods: {
    activeEvpClass(evp) {
      if (evp.active) return '';

      return this.$t('super_admin_js.modals.new_integration.third_stage.not_active');
    },
    activateEvp(evp) {
      evp.active = !evp.active;
    },
    spreadChangeInHoursIncreasedEvpGroup(hourIncreasedKey, contractType) {
      ['over_hours', 'complementary_hours'].map((key) => {
        if (hourIncreasedKey.startsWith(key)) {
          this.integration.evps.hours_increased_infos
            .filter((hoursIncreasedInfo) => hoursIncreasedInfo.key.startsWith(key))
            .forEach((hoursIncreaseInfo) => { hoursIncreaseInfo.counter_type = contractType; });
        }
        return null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.third-stage-wrapper {
  padding: 30px;
  max-height: 330px;
}

.third-stage-wrapper--abs-line {
  display: flex;
}

.third-stage-wrapper__tooltip {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.inactive {
  opacity: .4;
}

.sk-switch-inline {
  height: 30px;
  margin: auto 10px;
  padding-top: 2px;
}

.category-title {
  margin: 7px 0 7px 7px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
}
</style>
