export const SCOPES = ['agreement', 'region', 'country'];

export const AVAILABLE_COUNTRIES = [
  // 'BE',
  // 'CH',
  'DE',
  'ES',
  'FR',
  // 'GB',
];

// There are 3 versions of this list that needs to be kept up to date:
// - This one
// - On skello-app: etc/absences/fr.yml
// - On svc-labour-laws: src/Types/Common/AbsenceTypes.ts
// TODO DEV-15938: make this list dynamic with a single source of truth
export const ABSENCES_WITH_OWED_MEALS_FR = [
  'all_accounts_due',
  'authorized_absence',
  'break',
  'child_adoption_leave',
  'commuting_accident',
  'compensatory_rest',
  'contract_suspension',
  'disciplinary_dismissal',
  'dress_up_time_leave',
  'early_departure',
  'family_event_leave',
  'fixed_price_contract_rest',
  'in_out_absence',
  'leave_for_child_care',
  'leave_for_sick_child',
  'maternity_leave',
  'medical_exam_absence',
  'meeting',
  'mourn_leave',
  'night_compensatory_rest',
  'notice_compensatory_leave',
  'occupational_disease',
  'on_call_duty',
  'paid_authorized_absence',
  'paid_leave',
  'parental_leave',
  'partial_activity',
  'paternity_leave',
  'permanent_disability',
  'precautionary_dismissal',
  'public_holiday_recovery',
  'public_holiday',
  'quarterly_leave',
  'recovery',
  'seniority_leave',
  'sick_leave',
  'staff_delegation',
  'strike',
  'temporary_layoff',
  'therapeutic_part_time',
  'training_leave',
  'training',
  'unjustified_absence',
  'unpaid_leave',
  'weekly_rest',
  'work_study_absence',
  'work_time_recovery',
  'workplace_accident',
  'home_office',
  'birth_leave',
  'split_leave',
  'parental_presence_leave',
  'defence_and_citizenship_day',
];
