<template>
  <!-- eslint-disable  max-len -->
  <div>
    <header-content :title="organisation.name" />
    <spinner v-if="loading" />
    <main-content v-else>
      <tabs :key="tabsHolderKey">
        <tab :name="$t('super_admin_js.organisation.tabs.details')">
          <organisation-details
            :organisation.sync="organisation"
            :setClustersTrue="setClustersTrue"
            :can-exclude-from-cash-recovery="canExcludeFromCashRecovery"
            :can-migrate-to-new-process="canMigrateToNewProcess"
          />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.etabs')">
          <shops-list
            :organisation="organisation"
            :can-activate-esignature="canActivateEsignature"
          />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.employees')">
          <users-list :organisation="organisation" />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.invoices')">
          <invoices-list :organisation="organisation" />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.smsEmail')">
          <SentEmailsWrapper :organisation="organisation" />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.licenses')">
          <licensesList :organisation="organisation" />
        </tab>
        <tab
          v-if="currentUser.can_run_integration_jobs"
          :name="$t('super_admin_js.organisation.tabs.integration_tasks')"
        >
          <IntegrationTaskList :organisation="organisation" />
        </tab>
        <tab
          v-if="isEventTabEnabled"
          :name="$t('super_admin_js.events_history.title')"
        >
          <events-history
            :id="organisation.id"
            type="organisation"
            :cluster-node-id="clusterNodeId"
          />
        </tab>
      </tabs>
    </main-content>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import httpClient from '@config/http_client';

import HeaderContent from '../../components/HeaderContent';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import MainContent from '../../components/MainContent';
import Spinner from '../../components/Spinner';
import OrganisationDetails from './OrganisationDetails';
import ShopsList from './ShopsList';
import UsersList from './UsersList';
import InvoicesList from './InvoicesList';
import SentEmailsWrapper from './SentEmailsWrapper';
import licensesList from './LicensesList';
import IntegrationTaskList from './IntegrationTaskList';
import EventsHistory from '../../components/EventsHistory/index.vue';

export default {
  components: {
    HeaderContent,
    MainContent,
    Tabs,
    Tab,
    OrganisationDetails,
    ShopsList,
    UsersList,
    InvoicesList,
    SentEmailsWrapper,
    licensesList,
    Spinner,
    IntegrationTaskList,
    EventsHistory,
  },
  beforeRouteUpdate(to, from, next) {
    this.organisationId = to.params.id;
    this.fetchData();
    next();
  },
  data() {
    return {
      loading: true,
      organisation: {},
      organisationId: this.$route.params.id,
      canExcludeFromCashRecovery: false,
      canMigrateToNewProcess: false,
      canActivateEsignature: false,
      tabsHolderKey: 0,
      clusterNodeId: undefined,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapGetters(['isDevFeatureFlagActive']),
    clusters() {
      return this.organisation.clusters;
    },
    isEventTabEnabled() {
      return this.isDevFeatureFlagActive('FEATUREDEV_SVC_EVENTS_ENABLED');
    },
    isNewStandAloneBundleFeatureDisabled() {
      return !this.isDevFeatureFlagActive('FEATUREDEV_NEW_STANDALONE_BUNDLES');
    },
  },
  watch: {
    organisation(newOrg, oldOrg) {
      if (newOrg.hrisIntegration !== oldOrg.hrisIntegration) {
        this.refreshIntegrationTabDisplay();
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      httpClient
        .get(`/super_admin/api/organisations/${this.organisationId}`)
        .then((response) => {
          this.organisation = response.data.organisation;
          if (this.isNewStandAloneBundleFeatureDisabled) {
            this.organisation.packOfferNames = this.organisation.packOfferNames.filter(
              (packOffer) => packOffer.id !== 'Timeclock Standalone Success',
            );
          }
          this.loaded = true;
          this.canExcludeFromCashRecovery = response.data.can_exclude_from_cash_recovery;
          this.canMigrateToNewProcess = response.data.can_migrate_to_new_process;
          this.canActivateEsignature = response.data.can_activate_esignature;
          this.clusterNodeId = parseInt(this.organisation.clusterNodeId);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.$router.push('/404');
            return;
          }
          this.makeAlertToast(
            this.$t('super_admin_js.organisations.errors.loading'),
          );
        }).finally(() => {
          this.loading = false;
        });
    },
    setClustersTrue() {
      this.organisation.originalValueClusters = true;
    },
    refreshIntegrationTabDisplay() {
      // force Tabs component to re-render
      this.tabsHolderKey += 1;
    },
  },
};
</script>
