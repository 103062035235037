<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="$t('super_admin_js.stripe_charge.new.modal_title')"
    :visible.sync="modalVisible"
  >
    <ProgressionBar
      :step="currentStep"
      :change-step="changeStep"
      :first-text="$t('super_admin_js.stripe_charge.new.labels.fill')"
      :second-text="$t('super_admin_js.stripe_charge.new.labels.fill')"
    />
    <template v-if="currentStep === 0">
      <spinner
        v-if="loading"
        no-margin="true"
      />
      <div
        v-else
        class="invoice-new__wrapper"
      >
        <div class="invoice-new__form">
          <div class="invoice-new__section-header">
            <h2 class="invoice-new__section-title">
              {{ $t('super_admin_js.stripe_charge.new.user_attributes') }}
            </h2>
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__email"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_email') }}
            </label>
            <input v-model="stripeCharge.customer_email">
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__social-denomination"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_social_denomination') }}
            </label>
            <input v-model="stripeCharge.customer_social_denomination">
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__address"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_address') }}
            </label>
            <input v-model="stripeCharge.customer_address">
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__postal-code"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_postal_code') }}
            </label>
            <input v-model="stripeCharge.customer_postal_code">
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__city"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_city') }}
            </label>
            <input v-model="stripeCharge.customer_city">
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__country"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_country') }}
            </label>
            <input v-model="stripeCharge.customer_country">
          </div>
          <div class="invoice-new__section-header">
            <h2 class="invoice-new__section-title">
              {{ $t('super_admin_js.stripe_charge.new.price_attributes') }}
            </h2>
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__type"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.payment_or_refund') }}
            </label>
            <select v-model="stripeInvoice.payment_or_refund">
              <option
                v-for="type in payment_or_refund"
                :key="type"
                :value="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__kind"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.kind') }}
            </label>
            <select v-model="stripeInvoice.kind">
              <option
                v-for="(kindName, index) in kinds"
                :key="index"
                :value="kindName"
              >
                {{ kindName }}
              </option>
            </select>
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__zone"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.zone') }}
            </label>
            <select v-model="stripeInvoice.zone">
              <option
                v-for="(zoneName, index) in zones"
                :key="index"
                :value="zoneName"
              >
                {{ zoneName }}
              </option>
            </select>
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__total"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.total') }}
            </label>
            <input v-model="stripeCharge.total">
          </div>
          <div
            class="invoice-new__label-wrapper"
            data-test="new-invoice__shop-id"
          >
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.linked_shop') }}
            </label>
            <select v-model="stripeCharge.shop_id">
              <option
                v-for="shop in shops"
                :key="shop.id"
                :value="shop.id"
              >
                {{ shop.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        slot="footer"
        class="badging-modal-footer-btns"
      >
        <validate-btn :handle-click="moveToAccept" />
        <cancel-btn :handle-click="handleCancelClick" />
      </div>
    </template>
    <template v-if="currentStep === 1">
      <spinner
        v-if="loading"
        no-margin="true"
      />
      <div
        v-else
        class="invoice-new__wrapper"
      >
        <div class="invoice-new__explanation">
          <p>{{ $t('super_admin_js.stripe_charge.new.modal_explanation') }}</p>
          <p>{{ $t('super_admin_js.stripe_charge.edit.modal_warning') }}</p>
        </div>

        <div class="invoice-new__form">
          <div class="invoice-new__section-header">
            <h2 class="invoice-new__section-title">
              {{ $t('super_admin_js.stripe_charge.new.user_attributes') }}
            </h2>
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_email') }}
            </label>
            {{ stripeCharge.customer_email }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_social_denomination') }}
            </label>
            {{ stripeCharge.customer_address }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_address') }}
            </label>
            {{ stripeCharge.customer_address }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_postal_code') }}
            </label>
            {{ stripeCharge.customer_postal_code }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_city') }}
            </label>
            {{ stripeCharge.customer_city }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.customer_country') }}
            </label>
            {{ stripeCharge.customer_country }}
          </div>
          <div class="invoice-new__section-header">
            <h2 class="invoice-new__section-title">
              {{ $t('super_admin_js.stripe_charge.new.price_attributes') }}
            </h2>
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.payment_or_refund') }}
            </label>
            {{ stripeInvoice.payment_or_refund }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.kind') }}
            </label>
            {{ stripeInvoice.kind }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.zone') }}
            </label>
            {{ stripeInvoice.zone }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.total') }}
            </label>
            {{ stripeCharge.total }}
          </div>
          <div class="invoice-new__label-wrapper">
            <label class="invoice-new__label-span">
              {{ $t('super_admin_js.stripe_charge.new.labels.linked_shop') }}
            </label>
            {{ selectedShop.name }}
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        slot="footer"
        class="badging-modal-footer-btns"
      >
        <validate-btn
          :handle-click="handleCreateAndSendClick"
          :text="$t('super_admin_js.stripe_charge.new.create_and_send')"
        />
        <validate-btn
          :handle-click="handleCreateClick"
          :text="$t('super_admin_js.stripe_charge.new.create')"
        />
        <cancel-btn
          :handle-click="moveToEdit"
          :text="$t('super_admin_js.stripe_charge.new.edit')"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import Spinner from '../Spinner';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';
import ProgressionBar from '../ProgressionBar';

const emptyStripeCharge = {
  total: 0,
  customer_email: '',
  customer_social_denomination: '',
  customer_address: '',
  customer_postal_code: '',
  customer_city: '',
  customer_country: '',
  shop_id: null,
};

const emptyStripeInvoice = {
  kind: null,
  zone: null,
};
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
 Modal, Spinner, ValidateBtn, CancelBtn, ProgressionBar,
},
  mixins: [modalVisibleMixin],
  props: {
    visible: Boolean,
    reload: Function,
    organisation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      stripeCharge: { ...emptyStripeCharge },
      stripeInvoice: { ...emptyStripeInvoice },
      payment_or_refund: ['payment', 'refund'],
      kinds: [],
      zones: [],
      shops: [],
      currentStep: 0,
      from_scratch: '',
    };
  },
  computed: {
    selectedShop() {
      return this.shops.filter((shop) => shop.id === this.stripeCharge.shop_id)[0];
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      setTimeout(() => {
        this.fetchKinds();
        this.fetchZones();
        this.fetchShops();
      }, 0);
    },
    fetchKinds() {
      const url = '/super_admin/api/stripe_charges/kinds';

      httpClient
        .get(url)
        .then((r) => {
          this.kinds = r.data.kinds;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;

          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.new.toasts.type'),
          );
        });
    },
    fetchZones() {
      const url = '/super_admin/api/stripe_charges/zones';

      httpClient
        .get(url)
        .then((r) => {
          this.zones = r.data.zones;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;

          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.new.toasts.zone'),
          );
        });
    },
    fetchShops() {
      const url = `/super_admin/api/organisations/${this.organisation.id}/shops?list_only=true`;

      httpClient
        .get(url)
        .then((r) => {
          this.shops = r.data;
          this.loading = false;
          this.stripeCharge.shop_id = this.shops[0].id;
        })
        .catch((e) => {
          this.loading = false;

          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.new.toasts.no_shops'),
          );
        });
    },
    createInvoice(options) {
      const url = '/super_admin/api/stripe_charges';
      if (typeof this.shop !== 'undefined') {
        this.stripeCharge.shop_id = this.shop.id;
      }
      const params = {
        stripe_charge: this.stripeCharge,
          stripe_invoice: this.stripeInvoice,
          from_scratch: '',
        ...options,
      };

      this.loading = true;

      httpClient
        .post(url, params)
        .then((r) => {
          this.loading = false;

          this.makeSuccessToast(this.$t('super_admin_js.stripe_charge.new.toasts.bill'));
          this.reload();
          this.resetForm();
          this.handleCancelClick();
        })
        .catch((e) => {
          this.loading = false;
          this.resetForm();

          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.new.toasts.no_bill'),
          );
        });
    },
    handleCreateClick() {
      this.createInvoice({});
    },
    handleCreateAndSendClick() {
      this.createInvoice({ resend: true });
    },
    handleCancelClick() {
      this.resetForm();
      this.modalVisible = false;
    },
    changeStep(newStep) {
      this.currentStep = newStep;
    },
    moveToAccept() {
      this.currentStep = 1;
    },
    moveToEdit() {
      this.currentStep = 0;
    },
    resetForm() {
      this.currentStep = 0;
      this.stripeCharge = emptyStripeCharge;
      this.stripeInvoice = emptyStripeInvoice;
      this.stripeCharge.shop_id = this.shops[0].id;
    },
  },
};
</script>

<style lang="scss" scoped>
.badging-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.invoice-new__form {
  margin: 1em 0;
}

.invoice-new__label-wrapper {
  display: flex;
  margin: 1em 0;
}

.invoice-new__label-span {
  flex: .5;
}

.invoice-new__section-title {
  font-weight: bold;
}
</style>
