<template>
  <!-- eslint-disable  max-len -->
  <div
    v-if="active"
    class="tab"
  >
    <slot />
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  props: ['name'],
  data() {
    return {
      active: false,
    };
  },
  computed: {
    classes() {
      return {
        'tab--active': this.active,
      };
    },
  },
  methods: {
    activate() {
      this.active = true;
    },
    deactivate() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  width: 100%;
}
</style>
