<template>
  <!-- eslint-disable  max-len -->
  <b-modal
    :active.sync="modalVisible"
    has-modal-card
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t('super_admin_js.modals.new_account_creation_mail.title') }}
        </p>
      </header>
      <section class="modal-card-body">
        <spinner
          v-if="sending"
          no-margin="true"
        />
        <div v-else>
          <div class="columns">
            <b-field
              :label="$t('super_admin_js.modals.new_account_creation_mail.labels.customer_name')"
              class="column is-half"
            >
              <input
                v-model="customerName"
                type="text"
                class="input"
              >
            </b-field>
          </div>
          <div class="columns">
            <b-field
              :label="$t('super_admin_js.modals.new_account_creation_mail.labels.to')"
              class="column is-half"
            >
              <input
                v-model="email"
                type="text"
                class="input"
              >
            </b-field>
          </div>
          <div
            v-if="packOffers"
            class="columns"
          >
            <b-field
              :label="$t('super_admin_js.modals.new_account_creation_mail.pack_offers.title')"
              class="column is-half"
            >
              <div class="pack-offers-select">
                <b-select v-model="selectedPackOffer">
                  <option
                    v-for="packOffer in packOffers"
                    :key="packOffer.id"
                    :value="packOffer.id"
                    :disabled="packOffer.deprecated"
                  >
                    {{ packOffer.name }}
                  </option>
                </b-select>
              </div>
            </b-field>
          </div>
          <div
            v-if="withPayment"
            class="columns"
          >
            <b-field
              :label="$t('super_admin_js.modals.new_account_creation_mail.labels.payment_type.label')"
              class="column is-half"
            >
              <div class="payment-select">
                <b-select v-model="paymentType">
                  <option
                    v-for="option in paymentTypes"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.text }}
                  </option>
                </b-select>
              </div>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column checkbox-row">
            <b-checkbox
              id="join-excel-checkbox"
              v-model="includeEmployeesImportFile"
            >
              <input
                type="checkbox"
                class="input"
              >
            </b-checkbox>
            {{ $t('super_admin_js.modals.new_account_creation_mail.labels.join_excel') }}
          </div>
        </div>
        <div v-if="includeEmployeesImportFile" class="columns">
          <div class="column">
            <SkSelectV2
              v-model="lang"
              :options="languages"
              :value="defaultUserLang"
              :label="$t('language')"
              :search-placeholder="$t('language')"
              :searchable="true"
            />
          </div>
          <div class="column">
            <SkSelectV2
              v-model="selectedCountry"
              :options="countries"
              :label="$t('country')"
              :search-placeholder="$t('country')"
              :searchable="true"
            />
          </div>
        </div>
        <div style="height: 25px;" />
        <div class="mail-preview">
          <hr>
          <table
            max-width="600"
            style="margin-left: auto; margin-right: auto;"
          >
            <tr>
              <td
                max-width="600"
                align="center"
              >
                <img
                  src="https://github.com/skelloapp/photos-skello/blob/master/skello-logo-v3.png?raw=true"
                  style="display: block; "
                  width="200"
                  height="80"
                  alt="Skello"
                >
              </td>
            </tr>
          </table>
          <span style="font-size:1.5em; font-weight: 700;">{{ $t('super_admin_js.modals.new_account_creation_mail.messages.welcome') }}</span>
          <span style="font-size:1.5em; color:#1a6efc; font-weight: 700;">{{ capitalizedCustomerName }}</span>
          <span style="font-size:1.5em; font-weight: 700;">,</span>
          <div style="height: 25px;" />
          <p>{{ currentUser.name }} {{ $t('super_admin_js.modals.new_account_creation_mail.messages.invitation') }}</p>
          <p>{{ $t('super_admin_js.modals.new_account_creation_mail.messages.please_click_on_button') }}</p>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
            "
          >
            <td
              max-width="600"
              align="center"
              style="
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 30px;
                padding-top: 30px;
                text-align: center;
              "
            >
              <p
                style="
                  display: inline-block;
                  width: 500px;
                  background-color: #1a6efc;
                  border-radius: 6px;
                  padding-left: 15px;
                  padding-right: 15px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  text-decoration: none;
                  color: #ffffff;
                "
              >
                {{ $t('super_admin_js.modals.new_account_creation_mail.create_account') }}
              </p>
              <p
                style="
                  width: 500px;
                  padding: 10px 20px 15px 20px;
                  font-family: arial;
                  font-size: 12px;
                  font-style: italic;
                  line-height: 20px;
                  color: #6D7D8C;"
              >
                {{ $t('super_admin_js.modals.new_account_creation_mail.messages.cgu_before') }}<b>{{ $t('super_admin_js.modals.new_account_creation_mail.messages.cgu_bold') }}</b>{{ $t('super_admin_js.modals.new_account_creation_mail.messages.cgu_after') }}
              </p>
            </td>
          </div>
          <p v-if="includeEmployeesImportFile" style="margin-bottom: 20px;">
            {{ $t('super_admin_js.modals.new_account_creation_mail.messages.include_employees_import_file', { email: selectedCountry === 'ES' ? currentUser.email : 'onboarder@skello.io' }) }}
          </p>
          <tr>
            <td>
              <p>{{ $t('super_admin_js.modals.new_account_creation_mail.messages.signature_l1') }}</p>
              <p>{{ $t('super_admin_js.modals.new_account_creation_mail.messages.signature_l2') }}</p>
            </td>
          </tr>
          <hr
            style="height:2px; border:none; color:#1a6efc; background-color:#1a6efc;"
          >
          <table
            style="
              margin-left: auto;
              margin-right: auto;
              margin-top: 5px; width: 600px;
              max-width: 600px;
              background-color:#fff;
              border-bottom-left-radius:5px;
              border-bottom-right-radius:5px;
            "
          >
            <tbody>
              <tr>
                <td max-width="600">
                  <table
                    width="100%"
                    cellpadding="0"
                    cellspacing="0"
                  >
                    <tr max-width="600">
                      <td align="left" style="vertical-align: top">
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tr>
                            <td
                              align="left"
                              style="vertical-align: top; font-size: 12px; padding-left: 5px; padding-right: 5px; padding-bottom: 3px;"
                            >
                              <p
                                style="
                                  color: #1a6efc;
                                  text-decoration: none;
                                  font-family: arial;
                                  font-weight: bold;
                                "
                              >
                                {{ $t('super_admin_js.modals.new_account_creation_mail.messages.sav') }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="left"
                              max-width="300"
                              style="padding-left: 5px; padding-right: 5px;"
                            >
                              <img
                                src="https://github.com/skelloapp/photos-skello/blob/master/v3_facebook.jpg?raw=true"
                                width="25"
                                height="25"
                                alt="Facebook"
                                style="color: #333333; font-size: 10px; font-family: arial;"
                              >
                              <img
                                src="https://github.com/skelloapp/photos-skello/blob/master/v3_twitter.jpg?raw=true"
                                width="25"
                                height="25"
                                alt="Twitter"
                                style="color: #333333; font-size: 10px; font-family: arial;"
                              >
                              <img
                                src="https://github.com/skelloapp/photos-skello/blob/master/v3_linkedin.jpg?raw=true"
                                width="25"
                                height="25"
                                alt="LinkedIn"
                                style="color: #333333; font-size: 10px; font-family: arial;"
                              >
                              <img
                                src="https://github.com/skelloapp/photos-skello/blob/master/v3_instagram.jpg?raw=true"
                                width="25"
                                height="25"
                                alt="Instagram"
                                style="color: #333333; font-size: 10px; font-family: arial;"
                              >
                            </td>
                          </tr>
                        </table>
                      </td>
                      <td
                        align="right"
                        style="vertical-align: top"
                      >
                        <table
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tr>
                            <td
                              align="right"
                              max-width="300"
                              style="
                                font-size: 12px;
                                color: #aaaaaa;
                                font-family: arial;
                                padding-left: 5px;
                                padding-right: 5px;
                              "
                            >
                              Skello -
                              <span
                                style="color: #1a6efc; font-weight: bold; text-decoration: none;"
                              >skello.io
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              align="right"
                              max-width="300"
                              style="
                                font-size: 12px;
                                color: #aaaaaa;
                                font-family: arial;
                                padding-left: 5px;
                                padding-right: 5px;
                              "
                            >
                              {{ $t('address.first_line') }}
                              <br>
                              {{ $t('address.second_line') }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style="height:5px;" />
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <footer class="modal-card-foot columns">
        <div class="column is-third">
          <div class="field">
            <p class="control">
              <a
                :disabled="sending"
                class="button is-danger"
                @click="handleCancel"
              >
                {{ $t('super_admin_js.modals.new_account_creation_mail.labels.cancel') }}
              </a>
            </p>
          </div>
        </div>
        <div class="column is-third" />
        <div class="column is-third">
          <div class="field is-pulled-right">
            <p class="control">
              <a
                :disabled="sending || !email"
                class="button"
                @click="submitData"
              >
                {{ $t('super_admin_js.modals.new_account_creation_mail.labels.save') }}
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import httpClient from '@config/http_client';
import { SkSelectV2 } from '@skelloapp/skello-ui';

import { countryKeys, languageToCountryCode } from '~/shared/utils/country-keys.js';
import { arrayToSelectOptions, sortSelectOptions } from '~/shared/utils/form';
import { DEFAULT_COUNTRY, AVAILABLE_LANGUAGES } from '../../shared/constants';
import Spinner from '../Spinner';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: { Spinner, SkSelectV2 },
  mixins: [modalVisibleMixin],
  /* eslint-disable vue/require-prop-types */
  /* eslint-disable vue/require-default-prop */
  props: {
    title: String,
    visible: Boolean,
    currentUser: Object,
    withPayment: {
      type: Boolean,
      default: false,
    },
    packOffers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sending: false,
      email: '',
      customerName: '',
      includeEmployeesImportFile: false,
      selectedPackOffer: 'success',
      paymentType: 'sepa',
      selectedCountry: DEFAULT_COUNTRY,
      selectedLang: null,
    };
  },
  computed: {
    lang: {
      get() {
        return this.selectedLang || this.defaultUserLang;
      },
      set(lang) {
        this.selectedLang = lang;
      },
    },
    defaultUserLang() {
      return this.currentUser.lang.toUpperCase();
    },
    defaultUserCountryCode() {
      return languageToCountryCode(this.currentUser.lang);
    },
    countries() {
      const countryList = countryKeys(this.defaultUserCountryCode);
      return sortSelectOptions(
        arrayToSelectOptions(countryList, (value) => this.$t(`countries.${value}`)),
        );
    },
    languages() {
     return arrayToSelectOptions(AVAILABLE_LANGUAGES, (value) => this.$t(`languages.${value}`));
    },
    paymentTypes() {
      return [
        {
          id: 'sepa',
          text: this.$t(
            'super_admin_js.modals.new_account_creation_mail.labels.payment_type.options.sepa',
          ),
        },
        {
          id: 'transfer',
          text: this.$t(
            'super_admin_js.modals.new_account_creation_mail.labels.payment_type.options.transfer',
          ),
        },
      ];
    },
    capitalizedCustomerName() {
      const name = this.customerName;
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
  },
  methods: {
    submitData() {
      if (this.sending) return;

      const params = {
        mail: {
          to: this.email,
          include_employees_import_file: this.includeEmployeesImportFile,
          customer_name: this.capitalizedCustomerName,
          payment_type: this.paymentType ? this.paymentType : 'no_type',
          pack_offer_name: this.selectedPackOffer,
          language: this.lang,
          country: this.selectedCountry,
        },
      };

      this.sending = true;

      // FIXME: This API should be POST /super_admin/api/prospects
      httpClient
        .post('/super_admin/api/mails/deliver', params)
        .then((response) => {
          this.resetModalData();

          this.modalVisible = false;
          this.sending = false;
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.modals.new_account_creation_mail.messages.succesfully_sent'),
          );
        })
        .catch((e) => {
          this.sending = false;
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.modals.new_account_creation_mail.messages.error_sending', {
              error: e.response.data.error,
            }),
          );
        });
    },
    handleCancel() {
      if (this.sending) return;
      this.resetModalData();
      this.modalVisible = false;
    },
    resetModalData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-body {
  padding-top: 2em;
  padding-bottom: 5em;
}

::v-deep.select {
  width: 100%;

  select {
    width: 100%;
  }
}

.checkbox-row {
  display: flex;
}

.payment-select,
.pack-offers-select {
  border-radius: 3px;
  width: 100%;

  option[disabled] {
    color: #aaa;
  }

  // make select full size for outline
  ::v-deep select,
  ::v-deep .select {
    width: 100%;
  }
}

p.button_here {
  font-style: italic;
}

.mail-preview {
  padding-bottom: 20px;
}

@media (min-width: 769px) {
  #do-not-pay-checkbox {
    position: relative;
    top: 12px;
  }
}
</style>
