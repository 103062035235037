// There are 3 versions of this list that needs to be kept up to date:
// - This one
// - On skello-app: etc/conventions/alerts.yml
// - On svc-labour-laws: src/Types/Common/AlertTypes.ts
export const ALERT_KEYS = [
  'absence_hours_in_counter_exceed_contract_hours',
  'coupure',
  'daily_hours_worked',
  'daily_rest',
  'disallowed_cuts',
  'extra_contract',
  'holidays',
  'holidays_increase',
  'late_modification',
  'max_weekly',
  'maximum_cuts',
  'minimum_part_time_work',
  'minimum_part_time_work_weekly',
  'minimum_weekly_work_time',
  'night_shift',
  'overtime_limit',
  'overtime_limit_2',
  'part_time_max_day_length',
  'pause',
  'shift_delay',
  'six_days_straight',
  'sunday_increase',
  'weekly_rest',
];
