<template>
  <SkModal
    id="extract-clock-in-outs-modal-id"
    :modal-title="$t('super_admin_js.modals.titles.extract_clock_in_outs')"
    modal-title-classes="extract-clock-in-outs-modal-title"
    size="inherited"
    @show="onShow"
  >
    <template #body>
      <div v-if="loadingUsers" class="extract-clockinout--loading">
        <SkSpinner size="large" />
      </div>
      <SkModalSection v-else border-bottom="none">
        {{ $t('super_admin_js.modals.extract_clock_in_outs.desc_text') }}
        <div class="extract-clockinout__form-line">
          <div class="extract-clockinout__form-line-label">
            {{ $t('super_admin_js.modals.extract_clock_in_outs.dates') }}
          </div>
          <SkDatePicker
            v-model="currentDateValue"
            class="date-range__input"
            input-moment-format="YYYY-MM-DD"
            :lang="$i18n.locale"
            :clearable="false"
            range
          />
        </div>
        <div class="extract-clockinout__form-line">
          <div class="extract-clockinout__form-line-label">
            {{ $t('super_admin_js.modals.extract_clock_in_outs.users') }}
          </div>
          <SkSelectV2
            v-model="selectedUsers"
            :options="userOptions"
            force-search-bar-behavior="force-show"
            :label="$t('super_admin_js.modals.extract_clock_in_outs.select_employees.label')"
            :search-placeholder="$t('actions.search')"
            :no-results-label="
              $t('super_admin_js.modals.extract_clock_in_outs.select_employees.no_search_results')
            "
            width="250px"
            multi
            append-to-body
            select-all
          >
            <template #select-all-label>
              {{ $t('super_admin_js.modals.extract_clock_in_outs.select_employees.all') }}
            </template>
            <template #selected-option="{ value }">
              {{
                $tc(
                  'super_admin_js.modals.extract_clock_in_outs.select_employees.employee_count'
                  , value.length
                )
              }}
            </template>
          </SkSelectV2>
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkButton
        :spinner="loadingMatching"
        :disabled="disableSubmit"
        @click="handleSubmit"
      >
        {{ $t('super_admin_js.modals.extract_clock_in_outs.submit') }}
      </SkButton>
    </template>
  </SkModal>
</template>

<script>
import {
  SkModal,
  SkButton,
  SkModalSection,
  SkSelectV2,
  SkDatePicker,
  SkSpinner,
  skListenersMixin,
  MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';
import moment from 'moment';
import httpClient from '@config/http_client';
import { ClockInOutWithShiftModel } from '@skelloapp/svc-punch-js';

import _ from 'lodash';
import { punchClient } from '~/shared/utils/punch-client';

import skDate from '../../utils/skDate';

const MAX_SELECTABLE_USERS = 50;

export default {
  name: 'ExtractClockInOutsModal',
  components: {
    SkModal,
    SkModalSection,
    SkDatePicker,
    SkButton,
    SkSelectV2,
    SkSpinner,
  },
  mixins: [skListenersMixin],
  props: {
    shop: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loadingMatching: false,
      loadingUsers: false,
      userOptions: [],
      selectedUserIds: [],
      dates: [
        moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
      csvTitles: [
        'SHOP_ID',
        'USER_ID',
        'FIRST_NAME',
        'LAST_NAME',
        'SHIFT_START',
        'SHIFT_END',
        'SHIFT_PREVISIONAL_START',
        'SHIFT_PREVISIONAL_END',
        'SHIFT_ABSENCE_TYPE',
        'BADGING_IN',
        'BADGING_OUT',
      ],
    };
  },
  computed: {
    currentDateValue: {
      get() {
        return this.dates;
      },
      set(newDate) {
        if (moment(newDate[1]).diff(moment(newDate[0]), 'years') >= 1) {
          this.makeAlertToast(
            this.$t('super_admin_js.modals.extract_clock_in_outs.dates_warning'),
          );
        }

        this.dates = newDate;
      },
    },
    selectedUsers: {
      get() {
        return this.selectedUserIds;
      },
      set(newValue) {
        if (newValue.length > MAX_SELECTABLE_USERS) {
          this.makeAlertToast(
            this.$t('super_admin_js.modals.extract_clock_in_outs.users_warning'),
          );
        }

        this.selectedUserIds = newValue;
      },
    },
    isMoreThanOneYear() {
      return moment(this.dates[1]).diff(moment(this.dates[0]), 'years') >= 1;
    },
    disableSubmit() {
      return this.selectedUserIds.length === 0
        || this.selectedUserIds.length > MAX_SELECTABLE_USERS
        || this.isMoreThanOneYear;
    },
  },
  methods: {
    fetchUsers() {
      this.loadingUsers = true;
      httpClient
        .get('/super_admin/api/users', {
          params: {
            skip_pagination: true,
            fields: ['first_name', 'last_name', 'shop_id', 'id'],
            type: 'shop',
            shop_id: this.shop.id,
          },
        })
        .then((response) => {
          const { users } = response.data;

          this.userOptions = users.map((user) => ({
            id: String(user.id),
            text: `${user.firstName} ${user.lastName}`,
            firstName: user.firstName,
            lastName: user.lastName,
          }));

          if (this.userOptions.length < MAX_SELECTABLE_USERS) {
            this.selectedUserIds = this.userOptions.map((user) => user.id);
          }
        })
        .catch(() => {
          this.makeAlertToast(
            this.$t('errors.loading'),
          );
        })
        .finally(() => {
          this.loadingUsers = false;
        });
    },
    onShow() {
      this.fetchUsers();
    },
    async handleSubmit(event) {
      this.loadingMatching = true;
      const clockInOutsPromises = this.selectedUserIds.map(
        (userId) => punchClient.findClockInOutsByUserId(
          userId,
          this.currentDateValue[0],
          this.currentDateValue[1],
        ),
      );

      const clockInOutsResponses = await Promise.all(clockInOutsPromises);
      const clockInOuts = clockInOutsResponses.flat().map((clockInOut) => ({
          ...clockInOut,
          in: skDate(skDate(clockInOut.in).tz(this.shop.timezone).format('YYYY-MM-DDTHH:mm:ss')).toDate(),
          out: clockInOut.out ? skDate(skDate(clockInOut.out).tz(this.shop.timezone).format('YYYY-MM-DDTHH:mm:ss')).toDate() : null,
        }));

      httpClient.get('/v3/api/v1/shifts', {
        params: {
          user_ids: this.selectedUserIds,
          start_date: this.currentDateValue[0],
          end_date: this.currentDateValue[1],
          skip_pagination: 'true',
          shop_id: this.shop.id,
        },
      })
      .then((response) => {
        const shifts = response.data.data;

        if (clockInOuts.length === 0 && shifts.length === 0) {
          this.makeAlertToast(
            this.$t('super_admin_js.modals.extract_clock_in_outs.no_clock_in_outs'),
          );

          return;
        }

        this.generateDataAndCsv(
          ClockInOutWithShiftModel.addShiftsToClockInOuts(clockInOuts, shifts, false),
          shifts,
        );

        this.emitOnRoot(MODAL_HIDE_EVENT, event, 'extract-clock-in-outs-modal-id');
      })
      .catch(() => {
        this.makeAlertToast(
          this.$t('errors.loading'),
        );
      })
      .finally(() => {
        this.loadingMatching = false;
      });
    },
    generateDataAndCsv(clockInOutsWithShift, shifts) {
      const shiftsWithBadgingsIds = [];
      const rows = [];

      clockInOutsWithShift.forEach((clockInOut) => {
        if (clockInOut.shift?.id) {
          shiftsWithBadgingsIds.push(clockInOut.shift.id);
        }

        const clockInOutUser = this.userOptions.find((user) => user.id === clockInOut.userId);

        rows.push(
          [
            clockInOut.shopId,
            clockInOut.userId,
            clockInOutUser.firstName,
            clockInOutUser.lastName,
            this.csvUtcDate(clockInOut.shift?.attributes?.startsAt),
            this.csvUtcDate(clockInOut.shift?.attributes?.endsAt),
            this.csvUtcDate(clockInOut.shift?.attributes?.previsionalStart),
            this.csvUtcDate(clockInOut.shift?.attributes?.previsionalEnd),
            clockInOut.shift?.attributes?.absenceCalculation,
            this.csvDate(clockInOut.in),
            this.csvDate(clockInOut.out),
          ],
        );
      });

      const shiftsWithoutBadgings = shifts.filter(
        (shift) => !shiftsWithBadgingsIds.includes(String(shift.id)),
      );

      shiftsWithoutBadgings.forEach((shift) => {
        const clockInOutUser = this.userOptions.find(
          (user) => user.id === String(shift.attributes.userId),
        );

        rows.push(
          [
            shift.attributes.shopId,
            shift.attributes.userId,
            clockInOutUser.firstName,
            clockInOutUser.lastName,
            this.csvUtcDate(shift.attributes.startsAt),
            this.csvUtcDate(shift.attributes.endsAt),
            this.csvUtcDate(shift.attributes.previsionalStart),
            this.csvUtcDate(shift.attributes.previsionalEnd),
            shift.attributes.absenceCalculation,
            '',
            '',
          ],
        );
      });

      const sortedRows = _.sortBy(rows, (row) => row[2] || row[7]);

      sortedRows.unshift(this.csvTitles);

      this.generateAndOpenCsvFile(sortedRows);
    },
    generateAndOpenCsvFile(rows) {
      const csvContent = `data:text/csv;charset=utf-8,${ rows.map((row) => row.join(',')).join('\n')}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute(
        'download',
        `${this.shop.name}_${this.formatDate(this.dates[0])}_${this.formatDate(this.dates[1])}.csv`,
      );
      document.body.appendChild(link);

      link.click();
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    csvDate(date) {
      if (!date) return '';

      return skDate(date).format('YYYY-MM-DDTHH:mm:ss');
    },
    csvUtcDate(date) {
      if (!date) return '';

      return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header .extract-clock-in-outs-modal-title {
  font-weight: bold;
  font-family: $poppins-font;
  font-size: 16px;
  color: $sk-black;
}

::v-deep .sk-modal__content {
  width: 620px;
}

.extract-clockinout--loading {
  height: 200px;
  width: '100%';
  display: flex;
  align-items: center;
  justify-content: center;
}

.extract-clockinout__form-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  &:first-child {
    padding-top: 24px;
  }

  &:last-child {
    padding-bottom: 22px;
  }
}

.date-range__input {
  width: 250px;
}

.extract-clockinout__form-line-label {
  font-family: $product-sans-font;
  font-size: 14px;
  font-weight: bold;
}
</style>
