<template>
  <div class="shop-list">
    <ShopsMapCard
      v-for="shop in shops"
      :key="shop.id"
      :shop="shop"
      :isActiveCard="shop.id === selectedShop"
    />
  </div>
</template>

<script>
import ShopsMapCard from '../ShopsMapCard';

export default {
  components: { ShopsMapCard },
  props: {
    shops: {
      type: Array,
      required: true,
    },
    selectedShop: {
      type: Number,
      default: () => null,
    },
  },
};
</script>

<style>
.shop-list {
  margin-left: 15px;
  width: 100%;
  height: 75vh;
  overflow-y: scroll;
  padding-right: 5px;
  padding-left: 10px;
}
</style>
