<template>
  <!-- eslint-disable  max-len -->
  <div>
    <header-content :title="$t('super_admin_js.account_creation.list.title')" />
    <spinner v-if="!loaded" />
    <main-content v-else>
      <div
        class="account-creations-list-wrapper"
        data-comp="account-creation-list"
      >
        <div>
          <tab-body-first-line
            :title="$t('super_admin_js.account_creation.list.title')"
          >
            <b-dropdown position="is-bottom-left">
              <action-button
                slot="trigger"
                :tooltip-label="$t('super_admin_js.account_creation.list.new_account.create')"
                icon="plus"
                icon-pack="fa"
              />

              <b-dropdown-item
                v-if="canLinkWithoutPay"
                @click="handleNewAccountWithoutPaiementCreationMailClick"
              >
                {{ $t('super_admin_js.account_creation.list.new_account.without_link') }}
              </b-dropdown-item>
              <b-dropdown-item @click="handleNewAccountCreationMailClick">
                {{ $t('super_admin_js.account_creation.list.new_account.with_link') }}
              </b-dropdown-item>
            </b-dropdown>
          </tab-body-first-line>
        </div>
        <div class="flex-grow">
          <account-creation-list-table
            :account-creations="accountCreations"
            :statuses="statuses"
            :loading="!loaded"
            :handle-sort-change="handleSortChange"
          />
          <br>
          <b-pagination
            v-if="total > 0"
            :total="total"
            :current="page"
            order="is-right"
            per-page="100"
            @change="value => handlePageChange(value)"
          />
        </div>
      </div>
      <new-account-creation-mail-modal
        :current-user="currentUser"
        :pack-offers="packOffers"
        :visible.sync="newAccountCreationMailModalVisible"
        with-payment
      />
      <new-account-creation-mail-modal
        :current-user="currentUser"
        :pack-offers="packOffers"
        :visible.sync="newAccountCreationWithoutPaiementMailModalVisible"
      />
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';
import { mapState, mapGetters } from 'vuex';

import HeaderContent from '../../components/HeaderContent';
import MainContent from '../../components/MainContent';
import Spinner from '../../components/Spinner';
import AccountCreationListTable from '../../components/AccountCreationListTable';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import NewAccountCreationMailModal from '../../components/NewAccountCreationMailModal';
import sortableMixin from '../../mixins/sortableMixin';
import ActionButton from '../../components/ActionButton';

export default {
  name: 'AccountCreation',
  components: {
    HeaderContent,
    MainContent,
    Spinner,
    AccountCreationListTable,
    TabBodyFirstLine,
    NewAccountCreationMailModal,
    ActionButton,
  },
  mixins: [sortableMixin],
  data() {
    return {
      loaded: false,
      accountCreations: [],
      statuses: [],
      sort: 'id',
      direction: 'desc',
      page: 1,
      total: 0,
      newAccountCreationMailModalVisible: false,
      newAccountCreationWithoutPaiementMailModalVisible: false,
      packOffers: [],
      canLinkWithoutPay: false,
    };
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapGetters(['isDevFeatureFlagActive']),
    isNewStandAloneBundleFeatureDisabled() {
      return !this.isDevFeatureFlagActive('FEATUREDEV_NEW_STANDALONE_BUNDLES');
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleNewAccountCreationMailClick() {
      this.newAccountCreationMailModalVisible = true;
    },
    handleNewAccountWithoutPaiementCreationMailClick() {
      this.newAccountCreationWithoutPaiementMailModalVisible = true;
    },
    fetchData() {
      const params = {
        sort: this.sort,
        direction: this.direction,
        page: this.page,
      };
      httpClient
        .get('/super_admin/api/prospects', { params })
        .then((response) => {
          this.accountCreations = response.data.account_creations;
          this.total = response.data.total;
          this.statuses = response.data.statuses;
          this.packOffers = this.isNewStandAloneBundleFeatureDisabled
                ? response.data.pack_offers.filter((offer) => offer.id !== 'Timeclock Standalone Success')
                : response.data.pack_offers;
          this.canLinkWithoutPay = response.data.can_link_without_pay;
          this.loaded = true;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.account_creation.list.error_loading_list'),
          );
        });
    },
  },
};
</script>
