import httpClient from '@config/http_client';

export default (entityType) => ({
  computed: {
    afterDeletedPath() {
      if (this.afterDeletePath) { return this.afterDeletePath; }
      const regexp = /(\/.*)(\/.*)/;
      const match = this.$route.path.match(regexp);

      return match[1];
    },
  },
  methods: {
    handleDeleteClick(deleteAction) {
      const deleteActionWithDefault = deleteAction
        || (() => httpClient.delete(this.deletePath, this.actionParams));
      this.$dialog.confirm({
        title: this.deleteModalTitle,
        message: this.deleteMessageWarning,
        confirmText: this.$t('super_admin_js.modals.delete.actions.confirm'),
        cancelText: this.$t('super_admin_js.modals.delete.actions.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          deleteActionWithDefault()
            .then((r) => {
              this.makeToast('is-success', this.$t('super_admin_js.modals.delete.success', { name: entityType }));
              this.$router.replace({ path: this.afterDeletedPath });
            })
            .catch((e) => {
              this.makeToast('is-danger', this.$t('super_admin_js.modals.delete.error', { name: entityType }));
              throw e;
            });
        },
      });
    },
  },
});
