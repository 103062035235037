<template>
  <!-- eslint-disable  max-len -->
  <div :style="{ 'width': width + 'px' }" :class="selectWrapClasses">
    <label> {{ label }}</label>
    <select
      v-if="areOptionsInHashFormat"
      :disabled="disabled"
      :value="value"
      class="select-design"
      @change="handleChange"
    >
      <option
        v-for="option in options"
        :key="option.key"
        :value="option.key"
        selected="option.key == value"
      >
        {{ option.value }}
      </option>
    </select>
    <select
      v-else
      :disabled="disabled"
      :value="value"
      class="select-design"
      @change="$emit('input', $event.target.value)"
    >
      <option
        v-for="option in options"
        :key="option"
        :value="option"
        selected="option == value"
      >
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'IntegrationSelect',
  components: { },
  mixins: [],
  props: {
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
      default: 200,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    onChange: {
      type: Function,
      required: false,
      default: null,
    },
    onChangeParam: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    areOptionsInHashFormat() {
      if (!this.options || !this.options.length) return false;

      return this.options.every((option) => option.key || option.value);
    },
    selectWrapClasses() {
      return {
        'select-wrap': true,
        'select-wrap--disabled': this.disabled,
      };
    },
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value);
      if (!this.onChange) return;

      if (this.onChangeParam) {
        this.onChange(this.onChangeParam, event.target.value);
      } else {
        this.onChange(event.target.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-wrap {
  margin: 10px 5px 10px 0;
  border-radius: 4px;
  padding: 0 5px;
  width: 200px;
  background-color: #edf2f6;
  position: relative;

  &--disabled {
    background: $sk-off-white-1;
    opacity: .6;
    cursor: not-allowed;
  }
}

.select-wrap label {
  font-size: 8px;
  text-transform: uppercase;
  color: #616161;
  padding: 0 8px;
  position: absolute;
  top: 6px;
}

.select-design {
  background-color: #edf2f6;
  color: rgba(0, 0, 0, .87);
  border: 0;
  outline: 0;
  height: 30px;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  font-family: "Product Sans", sans-serif;
  opacity: 1; // override user agent stylesheet for disabled
}
</style>
