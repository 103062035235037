export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    modalVisible: {
      get() {
        return this.visible;
      },
      set(x) {
        this.$emit('update:visible', x);
      },
    },
  },
};
