<template>
  <!-- eslint-disable  max-len -->
  <div>
    <main-content>
      <SentEmailList
        :organisation="organisation"
      />
    </main-content>
  </div>
</template>

<script>
  import MainContent from '../../components/MainContent';
  import SentEmailList from './SentEmailList';

  export default {
    components: {
      MainContent,
      SentEmailList,
    },
    props: {
      organisation: {
        type: Object,
        required: true,
      },
    },
  };
</script>
