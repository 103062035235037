<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      {{ value }}
      <b-tooltip
        :label="field.unBlacklistPhoneTooltip"
        multilined
        type="is-dark"
        size="is-small"
        position="is-right"
      >
        <a v-if="canDestroyRecords && value && blackListedPhone" role="button" class="unblacklist-button" @click="handleUnblacklistPhone">
          <i class="fa fa-minus-circle" />
        </a>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { svcCommunicationsClient } from '~/shared/utils/svc-communication-v2-client';
import { security } from '@config/security';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormEmail',
  mixins: [saFormTypeMixin],
  data() {
    return {
      blackListedPhone: '',
      canDestroyRecords: false,
    };
  },
  async mounted() {
    const authToken = await security.getAuthToken();

    this.canDestroyRecords = authToken.user.superAdminPermissions.canDestroyRecords;

    if (!this.canDestroyRecords) {
      return;
    }

    this.blackListedPhone = await svcCommunicationsClient.findOne(this.value);
  },
  methods: {
    handleUnblacklistPhone() {
      svcCommunicationsClient.delete(this.value).then(() => {
        this.blackListedPhone = '';

        this.makeToast('is-success', this.$t('super_admin_js.modals.blacklist_phone.success'));
      })
      .catch((error) => {
        this.makeToast('is-danger', this.$t('super_admin_js.modals.blacklist_phone.error'));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.unblacklist-button {
  height: 20px;
  width: 20px;
  font-size: .8em;
  color: white;
  background-color: #e12f57;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;

  &:hover,
  &:active,
  &:focus {
    opacity: .8;
  }
}
</style>
