<template>
  <!-- eslint-disable  max-len -->
  <integration-modal
    :title="title"
    :visible.sync="modalVisible"
    :close-modal="closeModal"
  >
    <div name="modal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div v-if="currentStage !== 0" class="progression-bar-container">
            <div class="progression-bar-wrapper">
              <integration-progression-bar
                :step="currentStage - 1"
                :changeStep="changeStep"
                :secondTextPadding="10"
                :thirdTextPadding="thirdTextPadding"
              />
            </div>
          </div>

          <span v-if="currentStage === 0" class="current-step-wrapper">
            <NewIntegrationModalZeroStage
              :action="action"
              :integration="integration"
              :availableIntegrations="availableIntegrations"
            />
          </span>

          <span v-if="currentStage === 1" class="current-step-wrapper">
            <NewIntegrationModalFirstStage
              :integration="integration"
            />
          </span>

          <span v-if="currentStage === 2" class="current-step-wrapper">
            <NewIntegrationModalSecondStage
              :integration="integration"
              :shopId="shopId"
            />
          </span>

          <span v-if="currentStage === 3" class="current-step-wrapper">
            <NewIntegrationModalThirdStage
              :integration="integration"
              :shopId="shopId"
            />
          </span>

          <span v-if="isFourthStageDisplayed" class="current-step-wrapper">
            <NewIntegrationModalFourthStage
              :integration="integration"
              :shopId="shopId"
              @on-file-read-loading="handleReadingFile"
            />
          </span>
        </div>
      </div>
    </div>

    <div slot="footer">
      <div v-if="currentStage === 0 && anyIntegration">
        <validate-btn
          :handle-click="handleNextClickFromScratchOrIntegration"
          :text="$t('super_admin_js.modals.new_integration.next')"
          class="nextButton sk-btn-submit"
        />
      </div>
      <div v-if="isIntermediateStage">
        <p
          v-if="anyIntegration"
          class="previous-click"
          @click="handlePreviousClick"
        >
          {{ $t('super_admin_js.modals.new_integration.back') }}
        </p>
        <validate-btn
          :title="nextButtonTitle"
          :disabled="isNextDisabled"
          :handle-click="handleNextClick"
          :text="$t('super_admin_js.modals.new_integration.next')"
          class="nextButton sk-btn-submit"
        />
      </div>
      <div v-if="isFinalStage">
        <p class="previous-click" @click="handlePreviousClick">
          {{ $t('super_admin_js.modals.new_integration.back') }}
        </p>
        <validate-btn
          :disabled="isFinalStepDisabled"
          :handle-click="handleSubmitClick"
          :text="validateText"
          class="nextButton sk-btn-submit"
        />
      </div>
    </div>
  </integration-modal>
</template>

<script>
import httpClient from '@config/http_client';
import { mapState } from 'vuex';

import IntegrationModal from '../ui/IntegrationModal';
import IntegrationProgressionBar from '../ui/IntegrationProgressionBar';
import NewIntegrationModalZeroStage from '../ui/NewIntegrationModalZeroStage';
import NewIntegrationModalFirstStage from '../ui/NewIntegrationModalFirstStage';
import NewIntegrationModalSecondStage from '../ui/NewIntegrationModalSecondStage';
import NewIntegrationModalThirdStage from '../ui/NewIntegrationModalThirdStage';
import NewIntegrationModalFourthStage from '../ui/NewIntegrationModalFourthStage';
import ValidateBtn from '../../ValidateBtn';
import modalVisibleMixin from '../../../mixins/modalVisibleMixin';
import { RELOAD_SHOP_DATA } from '../../../constants/integration_auto';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    IntegrationModal,
    IntegrationProgressionBar,
    NewIntegrationModalZeroStage,
    NewIntegrationModalFirstStage,
    NewIntegrationModalSecondStage,
    NewIntegrationModalThirdStage,
    NewIntegrationModalFourthStage,
    ValidateBtn,
  },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStage: 0,
      shopId: null,
      firstShopId: null,
      source: '',
      availableIntegrations: [],
      anyIntegration: true,
      bulkUpdate: false,
      numberOfShopsAffected: 0,
      integration: {
        createMethod: this.action === 'update' ? 'fromIntegration' : 'fromScratch',
        selectedIntegrationId: null,
        payroll_software: '',
        show_absence_unit: false,
        count_day_rate_absences_in_days: false,
        format: '',
        separator: '',
        field_wrapper: null,
        absences: [],
        evps: {
          worked_time_infos: [],
          hours_increased_infos: [],
          meals_infos: [],
          salary_infos: [],
          comment_infos: [],
        },
        evp_daily_aggregation: false,
        absence_daily_aggregation: false,
        group_absences_in_oneline: false,
        handle_evps_with_multicontracts: false,
        config_json: null,
        version: null,
        show_days_without_shifts_or_abs: false,
        show_days_without_shifts_or_abs_code: '',
        excluded_teams: '',
        exclude_users_without_pay_identification_number: false,
      },
      isFileReadLoading: false,
    };
  },
  computed: {
    ...mapState(['currentUser']),

    validateText() {
      const buttonStatus = this.isFileReadLoading ? 'loading' : 'finish';
      return this.$t(`super_admin_js.modals.new_integration.${buttonStatus}`);
    },
    thirdTextPadding() {
      return this.currentUser.can_add_integration_config ? 43 : 19;
    },
    isFourthStageDisplayed() {
      return this.currentStage === 4 && this.currentUser.can_add_integration_config;
    },
    isIntermediateStage() {
      return this.currentStage > 0
        && (this.currentStage < 3
        || (this.currentUser.can_add_integration_config && this.currentStage === 3));
    },
    isFinalStage() {
      return this.currentStage === 4
        || (!this.currentUser.can_add_integration_config && this.currentStage === 3);
    },
    isCustomPayrollSoftware() {
      return this.integration.payroll_software === this.$t('super_admin_js.modals.new_integration.first_stage.software_other');
    },
    isNextDisabled() {
      return (!this.integration.custom_name || !this.integration.version)
        && this.isCustomPayrollSoftware;
    },
    isFinalStepDisabled() {
      return !this.integration.config_json
        && this.isCustomPayrollSoftware
        && this.integration.createMethod === 'fromScratch';
    },
    nextButtonTitle() {
      return this.isFinalStepDisabled ? this.$t('super_admin_js.modals.new_integration.empty_name') : '';
    },
  },
  mounted() {
    this.setSource();
    this.setShopId();
    this.fetchIntegrations();
  },
  methods: {
    setSource() {
      this.source = this.params.type;
    },
    setShopId() {
      if (this.source === 'shop') {
        this.shopId = this.params.shop_id;
      } else {
        this.shopId = this.params.first_shop_id;
      }
    },
    changeStep(step) {
      this.currentStage = step;
    },
    handleNextClick() {
      if (this.isNextDisabled) return;

      if (this.currentStage === 1) {
        this.fetchAbsences();
      }
      if (this.currentStage === 2) {
        this.fetchEvps();
      }
      this.currentStage += 1;
    },
    handleNextClickFromScratchOrIntegration() {
      if (this.integration.createMethod === 'fromIntegration') {
        this.fetchIntegrationPayrollAndFormat();
      }
      this.fetchAbsences();
      this.fetchEvps();
      this.currentStage += 1;
    },
    handlePreviousClick() {
      this.currentStage -= 1;
    },
    handleCancelClick() {
      this.modalVisible = false;
    },
    closeModal() {
      this.modalVisible = false;
    },
    defaultPayrollUnitOptions() {
      if (this.integration.payroll_software === 'Quadratus') {
        return this.$t('super_admin_js.modals.new_integration.hours_and_days');
      }
      return this.$t('super_admin_js.modals.new_integration.hours');
    },
    handleReadingFile(value) {
      this.isFileReadLoading = value;
    },
    fetchIntegrationPayrollAndFormat() {
      httpClient
        .get(`/super_admin/api/integrations/basic_data/${this.shopId}`, {
          params: {
            action_type: this.action,
            selected_integration_id: this.integration.selectedIntegrationId,
          },
        })
        .then((response) => {
          if (!['Silae', 'Quadratus'].includes(response.data.payroll_software)) {
            this.integration.payroll_software = this.$t('super_admin_js.modals.new_integration.first_stage.software_other');
            this.integration.custom_name = response.data.payroll_software;
            this.integration.version = response.data.version;
          } else {
            this.integration.payroll_software = response.data.payroll_software;
          }
          // eslint-disable-next-line max-len
          this.integration.show_days_without_shifts_or_abs = response.data.show_days_without_shifts_or_abs;
          // eslint-disable-next-line max-len
          this.integration.show_days_without_shifts_or_abs_code = response.data.show_days_without_shifts_or_abs_code;
          this.integration.excluded_teams = response.data.excluded_teams.toString();
          this.integration.exclude_users_without_pay_identification_number = response
          .data.exclude_users_without_pay_identification_number;
          this.integration.evp_daily_aggregation = response.data.evp_daily_aggregation;
          this.integration.absence_daily_aggregation = response.data.absence_daily_aggregation;
          this.integration.group_absences_in_oneline = response
            .data.group_absences_in_oneline;
          this.integration.handle_evps_with_multicontracts = response
            .data.handle_evps_with_multicontracts;
          this.integration.format = `.${ response.data.format}`;
          this.integration.separator = response.data.separator;
          this.integration.field_wrapper = response.data.field_wrapper;
          this.integration.show_absence_unit = response.data.show_absence_unit;
          this.integration.count_day_rate_absences_in_days = response
            .data.count_day_rate_absences_in_days;
          this.integrationId = response.data.integration_id;
          this.bulkUpdate = response.data.bulk_update;
          this.numberOfShopsAffected = response.data.shops_with_same_int_key;
          if (this.action === 'update' && this.bulkUpdate) {
            const confirmationMessage = this.$t(
                'super_admin_js.modals.new_integration.confirm',
                { nb_shops: this.numberOfShopsAffected },
              );
            // eslint-disable-next-line no-alert
            if (!confirm(confirmationMessage)) {
              // this.closeModal();
              this.currentStage = 0;
            }
          }
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.modals.new_integration.error_toast'),
          );
        });
    },
    fetchIntegrations() {
      httpClient
        .get('/super_admin/api/integrations/integrations/', {
          params: {
            shop_id: this.shopId,
            source: this.source,
          },
        })
        .then((response) => {
          this.availableIntegrations = response.data.integrations;
          if (this.availableIntegrations.length === 0) {
            this.anyIntegration = false;
          }
          if (this.availableIntegrations.length === 0 && this.action === 'create') {
            this.currentStage = 1;
            this.anyIntegration = false;
            this.fetchAbsences();
            this.fetchEvps();
          }
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.modals.new_integration.error_toast'),
          );
        });
    },
    fetchAbsences() {
      httpClient
        .get(`/super_admin/api/integrations/absences/${this.shopId}`, {
          params: {
            action_type: this.action,
            create_method: this.integration.createMethod,
            selected_integration_id: this.integration.selectedIntegrationId,
          },
        })
        .then((response) => {
          this.integration.absences = response.data.absences.map((absence) => {
            absence.unit = absence.unit || this.defaultPayrollUnitOptions();
            absence.as_evp = absence.as_evp || false;
            return absence;
          });
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.modals.new_integration.error_toast'),
          );
        });
    },
    fetchEvps() {
      if (!this.anyIntegration && this.action === 'update') return;
      httpClient
        .get(`/super_admin/api/integrations/evps/${this.shopId}`, {
          params: {
            action_type: this.action,
            create_method: this.integration.createMethod,
            selected_integration_id: this.integration.selectedIntegrationId,
          },
        })
        .then((response) => {
          const { evps } = response.data;
          this.integration.evps.worked_time_infos = this.splitEvpsByCategory(evps, 1);
          this.integration.evps.hours_increased_infos = this.splitEvpsByCategory(evps, 2);
          this.integration.evps.meals_infos = this.splitEvpsByCategory(evps, 3);
          this.integration.evps.salary_infos = this.splitEvpsByCategory(evps, 4);
          this.integration.evps.comment_infos = this.splitEvpsByCategory(evps, 5);
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.modals.new_integration.error_toast'),
          );
        });
    },
    splitEvpsByCategory(evps, groupKey) {
      return _.filter(evps, (evp) => groupKey === evp.group);
    },
    handleSubmitClick() {
      // Don't do anything while file is being read
      // This should be instantanious for most file size
      if (this.isFileReadLoading || this.isFinalStepDisabled) return;

      if (this.integration.custom_name) {
        this.integration.payroll_software = this.integration.custom_name;
        delete this.integration.custom_name;
      }

      // Transform the excluded teams string into an array
      this.integration.excluded_teams = this.integration.excluded_teams
        .split(',')
        .map((team) => team.trim());

      if (this.action === 'create') {
        httpClient
            .post('/super_admin/api/integrations/integrations', {
              integration: this.integration,
              shop_id: this.shopId,
              source: this.source,
              organisation_id: this.params.organisation_id,
            })
            .then((response) => {
              this.closeModal();
              this.$root.$emit(RELOAD_SHOP_DATA);
              this.makeSuccessToast(
                this.$t('super_admin_js.modals.new_integration.create_success'),
              );
            })
            .catch((e) => {
              this.makeToast(
                'is-danger',
                e.response?.data?.error || this.$t('super_admin_js.modals.new_integration.error_toast'),
              );
            });
      } else {
        httpClient
            .patch(`/super_admin/api/integrations/integrations/${this.integrationId}`, {
              integration: this.integration,
              shop_id: this.shopId,
              source: this.source,
              organisation_id: this.params.organisation_id,
            })
            .then((response) => {
              this.closeModal();
              this.$root.$emit(RELOAD_SHOP_DATA);
              this.makeSuccessToast(
                this.$t('super_admin_js.modals.new_integration.update_success'),
              );
            })
            .catch((e) => {
              this.makeToast(
                'is-danger',
                e.response?.data?.error || this.$t('super_admin_js.modals.new_integration.error_toast'),
              );
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
}

.modal-container {
  width: 100%;
  min-height: 400px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto !important;
  margin: auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  border: none;
  position: relative;
}

.nextButton {
  float: right;
}

.progression-bar-container {
  height: 70px;
  background-color: #edf2f6;
}

.progression-bar-wrapper {
  height: 100%;
  padding: .5em 2em;
}

.previous-click {
  display: inline;
  float: left;
  margin-left: 30px;
  margin-top: 10px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}
</style>
