import { SkSelectV2, SkIconButton, SkDatePicker } from '@skelloapp/skello-ui';
import { EVENT_SUBTYPE_ACTION, EVENT_SUBTYPE_LOG } from '@skelloapp/svc-events-sdk';

export default {
  props: {
    filters: {
      required: true,
      type: Object,
    },
    users: {
      required: true,
      type: Object, // {user_id: user}
    },
  },
  components: {
    SkSelectV2,
    SkIconButton,
    SkDatePicker,
  },
  data() {
    return {
      subtypesList: [],
    };
  },
  computed: {
    selected() {
      return this.filters.selectedSubtypes?.length > 0 ? this.filters.selectedSubtypes : undefined;
    },
    usersArray() {
      return Object.values(this.users)
        .map((user) => ({ id: user.id, text: user.name, type: user.type }));
    },
    selectedUsers() {
      return this.filters.selectedUserIds.length > 0 ? this.filters.selectedUserIds : undefined;
    },
    isSelectedSubtypesEmtpy() {
      return this.filters.selectedSubtypes.length === 0;
    },
    isSelectedUsersEmtpy() {
      return this.filters.selectedUserIds.length === 0;
    },
  },
  mounted() {
    const actionsEvent = this.fomatEvents(EVENT_SUBTYPE_ACTION);
    const logsEvent = this.fomatEvents(EVENT_SUBTYPE_LOG);

    this.subtypesList = actionsEvent.concat(logsEvent);
    this.updateSubtype([], false);
  },
  methods: {
    displayFilters(filters) {
      if (filters.length === 1) return this.$t(`super_admin_js.events_history.notification.${filters[0]}.type`);

      return `${this.$t(`super_admin_js.events_history.notification.${filters[0]}.type`)}, +${filters.length - 1} more`;
    },
    displayUsers(users) {
      if (users.length === 1) return this.users[users[0]].name ?? userId;

      return `${this.users[users[0]].name ?? userId}, +${users.length - 1} more`;
    },
    fomatEvents(events) {
      return Object.values(events)
        .map((event) => ({ id: event, text: this.$t(`super_admin_js.events_history.notification.${event}.type`) }))
        .sort((a, b) => (a.text > b.text ? 1 : -1));
    },
    updateSubtype(newSubtypes = [], emit = true) {
      this.filters.selectedSubtypes = newSubtypes;

      if (emit) this.emitfilters();
    },
    updateUsers(newSelectedUserIds = [], emit = true) {
      this.filters.selectedUserIds = newSelectedUserIds;

      if (emit) this.emitfilters();
    },
    updateSelectedDates(value) {
      this.filters.selectedDates = value;
      this.emitfilters();
    },
    emitfilters() {
      this.$emit('filters-changed', this.filters);
    },
  },
};
