<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <b-datepicker v-model="object[field.key]" :disabled="disabled" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormDateInput',
  mixins: [saFormTypeMixin],
  mounted() {
    this.preventBackspace();
  },
  methods: {
    preventBackspace() {
      const datepickers = document.querySelectorAll('.field-body .datepicker input');
      datepickers.forEach((datepicker) => {
        datepicker.addEventListener('keydown', (evt) => { evt.preventDefault(); });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// change the b-datepicker style directly,
// without modifying buefy code
.field::v-deep .datepicker {
  width: 18em;

  .field.has-addons {
    margin-top: 0;
  }

  .pagination.field {
    margin: 0;
  }

  .pagination-list {
    margin: 0;
  }
}
</style>
