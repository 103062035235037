<template>
  <div>
    <SkNav class="tabs-nav">
      <SkNavItem
        v-for="(tab, index) in tabs"
        :key="index"
        :active="tab.name === activeTab"
        @click="trySetTab(tab)"
      >
        {{ tab.name }}
      </SkNavItem>
    </SkNav>
    <div class="tab-slot-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import { SkNav, SkNavItem } from '@skelloapp/skello-ui';

export default {
  components: { SkNav, SkNavItem },
  data() {
    return {
      tabs: [],
      activeTab: '',
    };
  },
  computed: {
    ...mapState(['hasModifiedInput']),
  },
  mounted() {
    // Probe tabs
    if (this.$slots.default) {
      this.tabs = this.$slots.default
        .filter((tab) => tab.componentInstance || false)
        .map((tab) => tab.componentInstance);
    } else {
      this.tabs = [];
    }
    this.setFirstTabActive();
  },
  methods: {
    setFirstTabActive() {
      if (this.tabs.length === 0) return;
      this.setTab(this.tabs[0]);
    },
    trySetTab(tab) {
      if (this.hasModifiedInput) {
        this.askBeforeChangingTab(tab);
      } else {
        this.setTab(tab);
      }
    },
    askBeforeChangingTab(tab) {
      this.$dialog.confirm({
        title: this.$t('super_admin_js.modals.change_tab.title'),
        message: this.$t('super_admin_js.modals.change_tab.message'),
        confirmText: this.$t('super_admin_js.modals.change_tab.actions.confirm'),
        cancelText: this.$t('super_admin_js.modals.change_tab.actions.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.setTab(tab);
        },
      });
    },
    setTab(tab) {
      this.tabs.forEach((tabEl) => tabEl.deactivate());
      tab.activate();
      this.activeTab = tab.name;
    },
    tabNameClass(tab) {
      return {
        'tab--active': tab.name === this.activeTab,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs-nav {
  margin-bottom: 38px;
}

.tab-slot-wrapper {
  right: 0;
  left: 210px;
  bottom: 0;
  top: 150px;
}
</style>
