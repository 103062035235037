<template>
  <SkModal
    id="create-feature-flag-modal"
    :modal-title="$t('super_admin_js.modals.titles.new_feature_flag')"
    modal-title-classes="create-modal-title"
    size="inherited"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template #body>
      <SkModalSection border-bottom="none">
        {{ $t('super_admin_js.modals.feature_flags.desc_text') }}
        <div class="new-form-line">
          <div class="new-form-line-label">
            {{ $t('super_admin_js.modals.feature_flags.feature_flag') }}*
          </div>
          <SkInput
            v-model="featureFlag.name"
            :errored="featureFlagsName.includes(featureFlag.name)"
            :error-message="$t('super_admin_js.modals.feature_flags.name_exist')"
            :label="$t('super_admin_js.modals.feature_flags.name')"
          />
        </div>
        <div class="new-form-line">
          <div class="new-form-line-label">
            {{ $t('super_admin_js.modals.feature_flags.feature_flag_desc') }}*
          </div>
          <SkInput
            v-model="featureFlag.description"
            :label="$t('super_admin_js.modals.feature_flags.description')"
          />
        </div>
      </SkModalSection>
    </template>
    <template #submit-btn>
      <SkButton
        :spinner="loadingSubmit"
        :disabled="!canAddFeatureFlag"
        @click="handleSubmit"
      >
        {{ $t('actions.submit') }}
      </SkButton>
    </template>
  </SkModal>
</template>

<script>
import httpClient from '@config/http_client';
import {
  SkModal,
  SkModalSection,
  SkInput,
  SkButton,
  MODAL_HIDE_EVENT,
  skListenersMixin,
} from '@skelloapp/skello-ui';

export default {
  components: {
    SkModal,
    SkModalSection,
    SkInput,
    SkButton,
  },
  mixins: [
    skListenersMixin,
  ],
  props: {
    featureFlags: {
      type: Array,
      default: () => [],
    },
    creatingFeatureFlagDev: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingSubmit: false,
      featureFlag: {
        name: '',
        description: '',
      },
    };
  },
  computed: {
    canAddFeatureFlag() {
      return this.featureFlag.name
      && this.featureFlag.description
      && !this.featureFlag.name.includes(' ');
    },
    featureFlagsName() {
      return this.featureFlags.map((featureFlag) => featureFlag.name);
    },
  },
  methods: {
    clearData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    handleCancel(event) {
      if (this.featureFlag.name || this.featureFlag.description) {
        event.preventDefault();

        this.$root.$emit('confirm', event, {
          title: this.$t('super_admin_js.modals.feature_flags.continue'),
          description: this.$t('super_admin_js.modals.feature_flags.not_saved'),
          onConfirm: (_event) => {
            this.emitOnRoot(MODAL_HIDE_EVENT, _event, 'create-feature-flag-modal');

            this.clearData();
          },
        });
      }
    },
    handleSubmit(event) {
      this.loadingSubmit = true;

      const params = {
        feature_flag: this.featureFlag,
      };

      const url = this.creatingFeatureFlagDev ? '/super_admin/api/feature_flags_dev' : '/super_admin/api/feature_flags';

      if (this.creatingFeatureFlagDev) {
        params.feature_flag.active = false;
      }

      httpClient
        .post(url, params)
        .then((r) => {
          this.$emit('add-feature-flag', r.data);
          this.clearData();
          this.loadingSubmit = false;
          this.emitOnRoot(MODAL_HIDE_EVENT, event, 'create-feature-flag-modal');

          this.makeSuccessToast(
            this.$t('super_admin_js.feature_flags.add_success', { feature_flag: r.data.name }),
          );
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('errors.loading'),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header .create-modal-title {
  font-weight: bold;
  font-family: $poppins-font;
  font-size: 16px;
  color: $sk-black;
}
</style>

<style lang="scss" scoped>
::v-deep .sk-modal__content {
  width: 620px;
}

.new-form-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  &:first-child {
    padding-top: 24px;
  }

  &:last-child {
    padding-bottom: 22px;
  }
}

.new-form-line-label {
  font-family: $product-sans-font;
  font-size: 14px;
  font-weight: bold;
}

.sk-input {
  width: 60%;
}
</style>
