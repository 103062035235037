<template>
  <div>
    <header-content :title="$t('super_admin_js.links.manage_super_admin_users')" />
    <spinner v-if="isLoading" />
    <main-content v-else>
      <div class="manage-super-admin__button-wrapper">
        <SkButton
          icon="PlusSignIcon"
          :spinner="isButtonLoading"
          :disabled="isButtonLoading"
          @click="displayAddSuperAdminModal"
        >
          {{ $t('super_admin_js.manage_super_admin_users.add_button') }}
        </SkButton>
      </div>
      <SuperAdminListTable :users="sortedUsers" />
    </main-content>
    <AddSuperAdminUsersModal @super-admin-users-added="handleSuperAdminUsersAdded" />
    <DeleteSuperAdminUserModal @super-admin-user-deleted="handleSuperAdminUserDeleted" />
  </div>
</template>

<script>
import {
  SkButton,
  skListenersMixin,
  MODAL_SHOW_EVENT,
} from '@skelloapp/skello-ui';

import httpClient from '@config/http_client';

import AddSuperAdminUsersModal from '~/components/ManageSuperAdminUsers/AddSuperAdminUsersModal';
import DeleteSuperAdminUserModal from '~/components/ManageSuperAdminUsers/DeleteSuperAdminUserModal';
import HeaderContent from '~/components/HeaderContent';
import MainContent from '~/components/MainContent';
import Spinner from '~/components/Spinner';
import SuperAdminListTable from '~/components/ManageSuperAdminUsers/SuperAdminListTable';

export default {
  components: {
    AddSuperAdminUsersModal,
    DeleteSuperAdminUserModal,
    HeaderContent,
    MainContent,
    SkButton,
    Spinner,
    SuperAdminListTable,
  },
  mixins: [skListenersMixin],
  data() {
    return {
      isButtonLoading: false,
      isLoading: true,
      users: [],
    };
  },
  computed: {
    sortedUsers: {
      get() {
        return this.users;
      },
      set(value) {
        this.users = value.sort((a, b) => {
            if (a.email < b.email) {
              return -1;
            }
            if (a.email > b.email) {
              return 1;
            }
            return 0;
          });
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    displayAddSuperAdminModal() {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'add-super-admin-users-modal');
    },
    fetchData() {
      httpClient
        .get('/super_admin/api/users/super_admin')
        .then((response) => {
          this.sortedUsers = response.data.users;
        })
        .catch(() => {
          this.makeAlertToast(this.$t('super_admin_js.users.errors.generic'));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleSuperAdminUserDeleted(email) {
      this.sortedUsers = this.sortedUsers.filter((user) => user.email !== email);
    },
    handleSuperAdminUsersAdded(updatedUsers) {
      // enforce uniqueness
      const setObj = new Set([...this.sortedUsers, ...updatedUsers].map(JSON.stringify));
      this.sortedUsers = Array.from(setObj).map(JSON.parse);
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-super-admin__button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
}
</style>
