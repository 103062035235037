<template>
  <div
    class="pagination is-right with-padding"
  >
    <a
      :class="!hasPrev ? 'is-disabled' : ''"
      class="pagination pagination-previous"
      @click="prevPage"
    >
      <span class="icon"><i class="fa fa-angle-left" /></span>
    </a>
    <a
      :class="!hasNext ? 'is-disabled' : ''"
      class="pagination pagination-next"
      @click="nextPage"
    >
      <span class="icon"><i class="fa fa-angle-right" /></span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    hasPrev: {
      type: Boolean,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    prevPage: {
      type: Function,
      required: true,
    },
    nextPage: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style>
  .with-padding {
    margin-top: 10px;
  }
</style>
