<!-- eslint-disable max-len -->
<template>
  <b-modal :active.sync="modalVisible">
    <div class="modal__wrapper">
      <div class="modal__header">
        <SkMedallion
          content-component="PersonIcon"
          background-color="#F0F2F5"
          content-color="#6D7D8C"
        />
        <span class="modal__header__title">
          {{ $t('super_admin_js.modals.integration.update_user_annualization_data.title') }}
        </span>
        <div
          class="modal__header__close"
          @click="onCancel"
        >
          <i
            class="fa fa-times"
            aria-hidden="true"
          />
        </div>
        <hr />
      </div>
      <div class="modal__body">
        <h2 class="modal__body__integration-steps">
          <b>{{ $t('super_admin_js.modals.integration.update_user_annualization_data.step_1_title') }}</b>
        </h2>
        <div class="modal__body__template-download-section">
          <a
            class="modal__body__template-download__link"
            :href="templateLink"
          >
            {{ $t('super_admin_js.modals.integration.update_user_annualization_data.step_1_message') }}
          </a>
        </div>
        <h2 class="modal__body__integration-steps">
          <b>{{ $t('super_admin_js.modals.integration.update_user_annualization_data.step_2_title') }}</b>
        </h2>
        <b-field v-if="!file">
          <b-upload
            v-model="file"
            drag-drop
          >
            <section class="modal__body__upload-section">
              <div class="content has-text-centered">
                <p class="modal__body__upload-section__title">
                  {{ $t('super_admin_js.modals.integration.update_user_annualization_data.upload.title') }}
                </p>
                <span class="modal__body__upload-section__separator">
                  {{ $t('super_admin_js.modals.integration.update_user_annualization_data.upload.join_word') }}
                </span>
                <div class="modal__body__upload-section__button">
                  {{ $t('super_admin_js.modals.integration.update_user_annualization_data.upload.button') }}
                </div>
              </div>
            </section>
          </b-upload>
        </b-field>
        <section
          v-else
          class="section"
        >
          <div class="content has-text-centered">
            <p>{{ file.name }}</p>
            <button
              class="delete is-small"
              @click="clearFile()"
            />
          </div>
        </section>
      </div>
      <div class="modal__footer">
        <cancel-btn
          :handle-click="onCancel"
          class="cancel-btn"
          v-text="$t('super_admin_js.modals.integration.update_user_annualization_data.cancel')"
        />
        <validate-btn
          :disabled="isParsing"
          :handle-click="onSubmit"
          class="validate-btn"
          v-text="$t('super_admin_js.modals.integration.update_user_annualization_data.import')"
        />
      </div>
    </div>
  </b-modal>
</template>
<!-- eslint-enable max-len -->

<script>
import { parse } from 'csv-parse';
import { SkMedallion } from '@skelloapp/skello-ui';
import { EmployeesFactory } from '@skelloapp/svc-employees-client';
import { security } from '@config/security';
import httpClient from '@config/http_client';
import skDate from '~/utils/skDate';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: {
    ValidateBtn,
    CancelBtn,
    SkMedallion,
  },
  mixins: [modalVisibleMixin],
  props: {
    organisationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      localFile: null,
      isParsing: false,
      parsedCsvLines: [],
      parsingError: null,
      svcEmployeesClient: null,
    };
  },
  computed: {
    file: {
      get() {
        return this.localFile;
      },
      set(value) {
        this.localFile = value;

        if (value) {
          // Parsing the new CSV file
          value.text()
            .then((text) => {
              const parser = parse(text, {
                delimiter: ';',
                skip_empty_lines: true,
                trim: true,
              })
              .on('readable', () => {
                if (this.parsingError) {
                  return;
                }

                this.isParsing = true;
                let line = parser.read();

                while (line !== null) {
                  // Filters out lines with empty user_id or theoretical_balances
                  if (line[0] && line[1]) {
                    this.parsedCsvLines.push(line);
                  }

                  line = parser.read();
                }
              })
              .on('end', () => {
                this.isParsing = false;
                this.parsedCsvLines.shift();
              })
              .on('error', () => { this.parsingError = true; });
            });
        } else {
          this.parsedCsvLines = [];
          this.parsingError = false;
        }
      },
    },
    templateLink() {
      return `${process.env.VUE_APP_API_ROOT}/csv/user_annualization_data_update_template.csv`;
    },
    userIdsFromCsv() {
      return this.parsedCsvLines.map((line) => line[0]);
    },
  },
  mounted() {
    this.svcEmployeesClient = EmployeesFactory.create(
      `${process.env.VUE_APP_SVC_EMPLOYEES_URL}`,
    );
  },
  methods: {
    clearFile() {
      this.file = null;
    },
    async bulkUpdateUserAnnualizationData(configPerUser) {
      const bulkUpdateParamsPerShop = {};

      configPerUser.forEach((userConfig) => {
        const userId = String(userConfig.user_id);
        const newTheoretialBalances = this.parsedCsvLines.find(
          ([user, _]) => user === userId,
        )[1];

        if (isNaN(+newTheoretialBalances)) {
          return;
        }

        const config = userConfig.config.data.attributes;

        // +newTheoretialBalances is used to convert the string to a number
        // parseInt doesn't work with floats
        const theoreticalBalances = {
          [skDate.utc(config.currentPeriodStartDate).valueOf()]: +newTheoretialBalances,
        };

        bulkUpdateParamsPerShop[config.shopId] ??= [];
        bulkUpdateParamsPerShop[config.shopId].push({
          userId,
          theoreticalBalances,
        });
      });

      const authToken = await security.getAuthToken();

      return Promise.all(
        Object.entries(bulkUpdateParamsPerShop).map(([shopId, params]) => (
          this.svcEmployeesClient.annualizationConfig.bulkUpdate(
            shopId,
            params,
            authToken.token,
          )
        )),
      );
    },
    async onSubmit() {
      if (this.loading) return;
      if (this.parsingError || this.userIdsFromCsv.length === 0) {
        this.makeAlertToast(
          this.$t('super_admin_js.integration_tasks.error_file'),
        );
        return;
      }

      this.loading = true;

      const query = `?user_ids=${this.userIdsFromCsv.join(',')}`;

      try {
        const response = await httpClient.get(
          `/super_admin/api/organisations/${this.organisationId}/annualization_configs${query}`,
        );
        const configPerUser = response.data.config_per_user.filter(({ config }) => !!config);

        if (configPerUser.length === 0) {
          throw new Error('No annualization config found');
        }

        await this.bulkUpdateUserAnnualizationData(configPerUser);

        this.makeToast(
          'is-success',
          this.$t('super_admin_js.integration_tasks.launching'),
        );

        this.clearFile();
        this.onCancel();
      } catch (error) {
        this.makeAlertToast(
          this.$t('super_admin_js.integration_tasks.error_launching'),
        );
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__wrapper {
  border-radius: 6px;
  background-color: white;
  padding: 2em;
  font-size: 16px;
  width: 620px;
  height: 505px;
}

.modal__header {
  margin: -12px;
}

.sk-medallion {
  float: left;
  margin-top: -5px;
}

.modal__header__title {
  font-size: 19px;
  font-weight: 700;
  margin: 22px;
  color: $sk-black;
}

.modal__header__close {
  color: $sk-black;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  float: right;
  background: transparent;
}

hr {
  display: block;
  height: 1px;
  border-top: 1px solid $sk-gray-light-3;
  width: 620px;
  margin: 1em 0 1em -20px;
}

.modal__body {
  padding: 1em 0 0 0;
  margin: 2px -7px 0 -7px;
  height: 365px;
}

.modal__body__integration-steps {
  font-size: 17px;
  font-weight: 900;
}

.modal__body__template-download-section {
  font-size: 15px;
  text-decoration: underline;
  margin-bottom: 28px;
}

.modal__body__upload-section {
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  width: 572px;
  height: 194px;
  padding: 40px;

  &__title {
    color: $sk-gray-med-2;
    font-size: 15px;
  }

  &__separator {
    display: block;
    margin: -13px 0 9px 0;
    color: $sk-gray-med-2;
    font-size: 12px;
  }

  &__button {
    border: 1px solid $sk-gray-light-3;
    width: 194px;
    height: 40px;
    margin: auto;
    border-radius: 3px;
    padding: 10px 10px 0 10px;
    font-size: 14px;
    color: $sk-blue-med-1;
  }
}

.modal__footer {
  margin: -15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.cancel-btn {
  color: $sk-blue-med-1;
  background: white;
  border: 1px solid $sk-gray-light-3;
  border-radius: 3px;
  font-size: 14px;
}

.validate-btn {
  color: white;
  border: 1px solid $sk-gray-light-3;
  border-radius: 3px;
  font-size: 14px;
}
</style>
