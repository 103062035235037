<template>
  <!-- eslint-disable  max-len -->
  <integration-modal
    :title="title"
    :visible.sync="modalVisible"
    :close-modal="closeModal"
  >
    <div name="modal">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div v-if="availableIntegrations.length > 0">
            <div class="int-label">
              {{ $t('super_admin_js.modals.delete_integration.select_integration') }}
            </div>
            <b-select
              v-model="selectedIntegrationId"
              class="int-select"
              :placeholder="$t('super_admin_js.modals.delete_integration.choose_ref_integration')"
            >
              <option
                v-for="integration in availableIntegrations"
                :key="integration.id"
                :value="integration.id"
              >
                {{ integration.name }}
              </option>
            </b-select>
          </div>
          <div v-else>
            {{ $t('super_admin_js.modals.delete_integration.no_integration') }}
          </div>
        </div>
      </div>
    </div>

    <div slot="footer">
      <validate-btn
        v-if="availableIntegrations.length > 0"
        :handle-click="handleSubmitClick"
        :text="$t('super_admin_js.modals.delete_integration.validate')"
        class="nextButton sk-btn-submit"
      />
    </div>
  </integration-modal>
</template>

<script>
import httpClient from '@config/http_client';

import IntegrationModal from '../ui/IntegrationModal';
import ValidateBtn from '../../ValidateBtn';
import modalVisibleMixin from '../../../mixins/modalVisibleMixin';
import { RELOAD_SHOP_DATA } from '../../../constants/integration_auto';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    IntegrationModal,
    ValidateBtn,
  },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shopId: null,
      availableIntegrations: [],
      selectedIntegrationId: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.fetchIntegrations();
  },
  methods: {
    handleCancelClick() {
      this.modalVisible = false;
    },
    closeModal() {
      this.modalVisible = false;
    },
    fetchIntegrations() {
      httpClient
        .get('/super_admin/api/integrations/integrations/', {
          params: {
            shop_id: this.params.shop_id,
            source: this.params.type,
          },
        })
        .then((response) => {
          this.availableIntegrations = response.data.integrations;
        })
        .catch((e) => {
          this.makeToast(
            'is-danger',
            this.$t('super_admin_js.modals.delete_integration.error_toast'),
          );
        });
    },
    handleSubmitClick() {
      httpClient
          .patch(`/super_admin/api/integrations/integrations/${this.selectedIntegrationId}`, {
            shop_id: this.params.shop_id,
            source: this.params.type,
            fake_destroy: true,
          })
          .then((response) => {
            this.closeModal();
            this.$root.$emit(RELOAD_SHOP_DATA);
            this.makeSuccessToast(this.$t('super_admin_js.modals.delete_integration.success_toast'));
          })
          .catch((e) => {
            this.makeToast('is-danger', this.$t('super_admin_js.modals.delete_integration.success_toast'));
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  overflow: auto;
}

.modal-container {
  width: 100%;
  min-height: 400px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto !important;
  margin: auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  border: none;
  position: relative;
  padding: 1.5em;
}

.nextButton {
  float: right;
}

.int-label {
  margin-bottom: 1em;
}
</style>
