<template>
  <!-- eslint-disable  max-len -->
  <div>
    <header-content :title="$t('super_admin_js.links.shops')" />
    <main-content>
      <h4 class="title is-4">
        {{ $t('super_admin_js.organisation.tabs.etabs') }}
      </h4>
      <shops-list-table
        :shops="shops"
        :loading="isLoading"
        :handle-sort-change="handleSortChange"
      />
      <br>
      <b-pagination
        v-if="total > 0"
        :total="total"
        :current="page"
        order="is-right"
        per-page="100"
        @change="value => handlePageChange(value)"
      />
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import HeaderContent from '../../components/HeaderContent';
import MainContent from '../../components/MainContent';
import ShopsListTable from '../../components/ShopsListTable';
import sortableMixin from '../../mixins/sortableMixin';

export default {
  components: {
    HeaderContent,
    MainContent,
    ShopsListTable,
  },
  mixins: [sortableMixin],
  data() {
    return {
      isLoading: true,
      shops: [],
      sort: 'id',
      direction: 'desc',
      page: 1,
      total: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      httpClient
        .get('/super_admin/api/shops', {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
          },
        })
        .then((response) => {
          this.shops = response.data.shops;
          this.total = response.data.total;
          this.isLoading = false;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('errors.loading'),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 1em;
}
</style>
