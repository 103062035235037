<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="$t('super_admin_js.modals.add_automatic_plannings.title', {
      automatic_planning_uses_left: shop.automaticPlanningUsesLeft
    })"
    :visible.sync="modalVisible"
  >
    <div class="add_automatic_plannings_modal__input-wrapper">
      <label
        for="add_automatic_plannings_modal-key"
        class="add_automatic_plannings_modal__label"
      >
        {{ $t('super_admin_js.modals.add_automatic_plannings.form.automatic_planning_number') }}
      </label>
      <b-input
        v-model="addAutomaticPlannings"
        min="0"
        max="10"
        type="number"
        size="is-small"
      />
    </div>
    <validate-btn :handle-click="handleValidateClick" />
    <cancel-btn :handle-click="handleCancelClick" />
  </modal>
</template>

<script>
import httpClient from '@config/http_client';
import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    shop: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      addAutomaticPlannings: 0,
    };
  },
  methods: {
    handleValidateClick() {
      const params = {
        shop_id: this.shop.id,
        add_automatic_plannings: this.addAutomaticPlannings,
      };
      httpClient
        .post('/super_admin/api/shops/add_automatic_plannings', params)
        .then((r) => {
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.modals.add_automatic_plannings.success_message'),
          );
          this.handleCancelClick();
          if (this.callback) this.callback();
        })
        .catch((e) => {
          const message = e.response.data && e.response.data.errors;
          let messageString = this.$t('super_admin_js.modals.add_automatic_plannings.error_message');

          if (message && message.length > 0) {
            messageString = message.join('<br>');
          }

          this.makeToast(
            'is-danger',
            messageString,
          );
        });
    },
    handleCancelClick() {
      this.clearDatas();
      this.$emit('update:visible', false);
    },
    clearDatas() {
      this.addAutomaticPlannings = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.add_automatic_plannings_modal__label {
  width: 260px;
}

.add_automatic_plannings_modal__input-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 0 45px 0;
}
</style>
