import { FactoryPunchWeb } from '@skelloapp/svc-punch-js';
import { security } from '@config/security';

class PunchClient {
  constructor() {
    const baseURL = process.env.VUE_APP_SVC_PUNCH_URL;

    this.factory = FactoryPunchWeb.create(baseURL, {
      clientSource: 'superAdmin:web',
    });
  }

  async findClockInOutsByUserId(userId, inStart, inEnd) {
    const { token } = await security.getAuthToken();

    const params = {
      inStart: new Date(inStart),
      inEnd: new Date(inEnd),
    };

    return this.factory.clockInOut.findAllByUserId(userId, params, token);
  }
}

export const punchClient = new PunchClient();

export default punchClient;
