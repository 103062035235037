<!-- eslint-disable max-len -->
<template>
  <SkModal
    id="add-super-admin-users-modal"
    :modal-title="$t('super_admin_js.manage_super_admin_users.modals.add_super_admin_users.title')"
    :submit-disabled="isSubmitDisabled"
    @cancel="handleHide"
    @close="handleHide"
    @submit.prevent="handleSubmit"
  >
    <template #body>
      <div class="add-super-admin-users__content">
        <span>
          {{ $t('super_admin_js.manage_super_admin_users.modals.add_super_admin_users.description') }}
        </span>
        <br />
        <span class="add-super-admin-users__description-separator">
          {{ $t('super_admin_js.manage_super_admin_users.modals.add_super_admin_users.description_separator') }}
        </span>
        <SkTextarea
          v-model="emailInput"
          :class="textAreaClasses"
          :min-height="168"
          rows="5"
        />
        <span
          v-if="errored"
          class="add-super-admin-users__error-label"
        >
          {{ errorMessage }}
        </span>
      </div>
    </template>
  </SkModal>
</template>

<script>
import {
  MODAL_HIDE_EVENT,
  skListenersMixin,
  SkModal,
  SkTextarea,
} from '@skelloapp/skello-ui';

import httpClient from '@config/http_client';

export default {
  components: {
    SkModal,
    SkTextarea,
  },
  mixins: [skListenersMixin],
  data() {
    return {
      emailInput: '',
      errored: false,
      errorMessage: null,
      isLoading: false,
      missingUsers: [],
      updatedUsers: [],
    };
  },
  computed: {
    emailNotFromSkello() {
      return this.parsedEmails.filter((email) => !email.toLowerCase().endsWith('@skello.io'));
    },
    isSubmitDisabled() {
      return this.emailInput === '' || this.isLoading;
    },
    invalidEmailErrorMessage() {
      return this.$tc(
        'super_admin_js.manage_super_admin_users.modals.add_super_admin_users.errors.invalid_emails',
        this.missingUsers.length,
      );
    },
    notFromSkelloErrorMessage() {
      return this.$tc(
        'super_admin_js.manage_super_admin_users.modals.add_super_admin_users.errors.not_from_skello',
        this.emailNotFromSkello.length,
      );
    },
    parsedEmails() {
      return this.emailInput.split(/(?:[\s,;\r\n])+/);
    },
    textAreaClasses() {
      return {
        'add-super-admin-users__input': true,
        'add-super-admin-users__input--errored': this.errored,
      };
    },
  },
  methods: {
    handleHide() {
      // Timeout to avoid flickering when closing modal
      setTimeout(() => {
        this.emailInput = '';
        this.errored = false;
        this.missingUsers = [];
        this.updatedUsers = [];
      }, 200);
    },
    handleSubmit() {
      if (this.emailNotFromSkello.length > 0) {
        this.errored = true;
        this.errorMessage = this.notFromSkelloErrorMessage;
        return;
      }

      // Reset errored state if it was previously errored
      if (this.errored) {
        this.errored = false;
      }

      this.isLoading = true;

      httpClient
        .patch('/super_admin/api/users/bulk', {
          users: this.parsedEmails.map((email) => ({ email, super_admin: true })),
        })
        .then((response) => {
          const updatedUsers = response.data.updated_users;
          const missingUsers = response.data.missing_users;

          this.$emit('super-admin-users-added', updatedUsers);

          if (missingUsers.length === 0) {
            this.emitOnRoot(MODAL_HIDE_EVENT, event, 'add-super-admin-users-modal');
            this.handleHide();
          } else {
            this.emailInput = missingUsers.join(', ');
            this.errored = true;
            this.missingUsers = missingUsers;
            this.updatedUsers = updatedUsers.map((user) => user.email);
            this.errorMessage = this.invalidEmailErrorMessage;
          }

          if (updatedUsers.length > 0) {
            this.makeSuccessToast(
              this.$tc(
                'super_admin_js.manage_super_admin_users.modals.add_super_admin_users.success',
                updatedUsers.length,
              ),
            );
          }
        }).catch(() => {
          this.makeAlertToast(
            this.$t('super_admin_js.manage_super_admin_users.modals.generic_error'),
          );
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-super-admin-users {
  &__content {
    padding: 24px 24px 16px 24px;

    ::v-deep .sk-textarea__input { // When text is put in, padding is off from design system
      padding: 4px 8px;
    }
  }

  &__description-separator {
    font-size: 12px;
    color: '#727272';
  }

  &__input {
    margin-top: 16px;

    &--errored {
      margin-bottom: 16px;
    }
  }

  &__error-label {
    color: $sk-red-dark-1;
  }
}
</style>
