<template>
  <!-- eslint-disable  max-len -->
  <div>
    <tab-body-first-line title="Historique" />
    <user-history-table
      :loading="loading"
      :histories="histories"
      :handle-sort-change="handleSortChange"
    />
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import UserHistoryTable from '../../components/UserHistoryTable';
import sortableMixin from '../../mixins/sortableMixin';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: { TabBodyFirstLine, UserHistoryTable },
  mixins: [sortableMixin],
  props: ['user'],
  data() {
    return {
      histories: [],
      loading: true,
      sort: 'id',
      direction: 'asc',
      page: 1,
      total: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const url = `/super_admin/api/users/${this.user.id}/action_history_items`;
      const params = {
        sort: this.sort,
        direction: this.direction,
        page: this.page,
      };
      httpClient
        .get(url, { params })
        .then((response) => {
          const result = response.data;
          this.histories = result.action_history_items;
          this.total = result.total;
          this.loading = false;
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('super_admin_js.users.errors.history'));
        });
    },
  },
};
</script>
