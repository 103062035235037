<template>
  <!-- eslint-disable  max-len -->
  <div :style="{ 'width': width + 'px' }" class="input-wrap">
    <label> {{ label }}</label>
    <input
      :value="value"
      :readonly="disabled"
      :class="inputDesignClasses"
      :placeHolder="placeHolder"
      @change="$emit('input', $event.target.value)"
    >
  </div>
</template>

<script>

export default {
  name: 'IntegrationInput',
  components: { },
  mixins: [],
  props: {
    label: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
      default: 200,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeHolder: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    inputDesignClasses() {
      return {
        'input-design': true,
        'input-design--disabled': this.disabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrap {
  margin: 10px 5px 10px 0;
  border-radius: 4px;
  padding: 0 5px;
  width: 200px;
  background-color: #edf2f6;
  position: relative;
}

.input-wrap label {
  font-size: 8px;
  text-transform: uppercase;
  color: #616161;
  padding: 0 8px;
  position: absolute;
  top: 6px;
}

.input-design {
  background-color: #edf2f6;
  color: rgba(0, 0, 0, .87);
  border: 0;
  outline: 0;
  height: 30px;
  font-size: 14px;
  width: 100%;
  margin-top: 10px;

  &--disabled {
    background: $sk-gray-light-5;
    color: $sk-gray-med-2;
    cursor: not-allowed !important;
  }
}
</style>
