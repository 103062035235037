<template>
  <SkModal
    :id="`canary-confirmation-modal-${featureFlag.name}`"
    :modal-title="$t(`super_admin_js.modals.dev_flags_update.title_${updateModalVerb}`)"
    modal-title-classes="canary-confirmation-modal-title"
    :submit-button-label="$t(`super_admin_js.modals.dev_flags_update.${updateModalVerb}`)"
  >
    <template #body>
      <div class="canary-confirmation-modal__content">
        {{ modalBody }}
      </div>
    </template>
    <template #submit-btn>
      <SkButton
        :variant="submitVariant"
        @click="handleSubmit"
      >
        {{ $t(`super_admin_js.modals.dev_flags_update.${updateModalVerb}`) }}
      </SkButton>
    </template>
  </SkModal>
</template>

<script>
import {
  SkModal, SkButton, skListenersMixin, MODAL_HIDE_EVENT,
} from '@skelloapp/skello-ui';

export default {
  components: {
    SkModal,
    SkButton,
  },
  mixins: [skListenersMixin],
  props: {
    featureFlag: {
      required: true,
      type: Object,
    },
  },
  computed: {
    submitVariant() {
      return this.featureFlag.active ? 'danger' : 'primary';
    },
    updateModalVerb() {
      return this.featureFlag.active ? 'deactivate' : 'activate';
    },
    modalBody() {
      return this.$t(
        `super_admin_js.modals.dev_flags_update.content_${this.updateModalVerb}`,
        { ff_name: this.featureFlag.name },
      );
    },
  },
  methods: {
    handleSubmit() {
      this.emitOnRoot(MODAL_HIDE_EVENT, event, `canary-confirmation-modal-${this.featureFlag.name}`);
      this.$emit('validate', { newStatus: !this.featureFlag.active });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .sk-modal__header .canary-confirmation-modal-title {
  font-weight: bold;
  font-family: $poppins-font;
  font-size: 16px;
}

.canary-confirmation-modal__content {
  padding: 24px;
  font-family: $product-sans-font;
  font-size: 14px;
}
</style>
