<template>
  <modal
    :title="title"
    :visible.sync="modalVisible"
  >
    <div>
      {{ $t(`super_admin_js.modals.silae_hris_integration.warning.${activateVerb}`) }} <slot />.
      {{ $t('super_admin_js.modals.silae_hris_integration.continue_prompt') }}
    </div>
    <div
      slot="footer"
      class="modal-footer"
    >
      <validate-btn :handle-click="handleValidateClick" />
      <cancel-btn :handle-click="() => modalVisible = false" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    activatingModule: {
      type: Boolean,
      required: true,
    },
    organisation: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    activateVerb() {
      return this.activatingModule ? 'activate' : 'deactivate';
    },
  },
  methods: {
    handleValidateClick() {
      httpClient
        .patch(`/super_admin/api/organisations/${this.organisation.id}`, {
          organisation: {
            modules: {
              fortify_silae: this.activatingModule,
            },
          },
        })
        .then(() => {
          this.makeToast(
            'is-success',
            this.$t(`super_admin_js.modals.silae_hris_integration.validate.${this.activateVerb}`),
          );
          this.$emit('submit', this.activatingModule);
          this.modalVisible = false;
        })
        .catch(() => {
          this.makeToast(
            'is-danger',
            this.$t(`super_admin_js.modals.silae_hris_integration.error.${this.activateVerb}`),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
