<template>
  <!-- eslint-disable  max-len -->
  <div class="body">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  min-height: calc(100vh - 155px);
}
</style>
