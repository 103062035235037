<template>
  <div class="sidebar__wrapper">
    <router-link
      class="sidebar__link"
      :class="{'router-link-active': /^\/organisations/.test($route.fullPath)}"
      to="/organisations"
    >
      {{ $t('super_admin_js.links.organisations') }}
    </router-link>
    <router-link class="sidebar__link" to="/shops">
      {{ $t('super_admin_js.links.shops') }}
    </router-link>
    <router-link class="sidebar__link" to="/users">
      {{ $t('super_admin_js.links.users') }}
    </router-link>
    <router-link class="sidebar__link" to="/invoices">
      {{ $t('super_admin_js.links.invoices') }}
    </router-link>
    <router-link class="sidebar__link" to="/account_creation">
      {{ $t('super_admin_js.links.account_creation') }}
    </router-link>
    <router-link class="sidebar__link" to="/simulator">
      {{ $t('super_admin_js.links.simulator') }}
    </router-link>
    <router-link class="sidebar__link" to="/skello_map">
      {{ $t('super_admin_js.links.skello_map') }}
    </router-link>
    <router-link
      v-if="currentUser.can_manage_feature_flags"
      class="sidebar__link"
      to="/feature_flags"
    >
      {{ $t('super_admin_js.links.feature_flags') }}
    </router-link>
    <router-link
      v-if="labourLawsEnabled"
      class="sidebar__link"
      to="/labour_laws"
    >
      {{ $t('super_admin_js.links.labour_laws') }}
    </router-link>
    <router-link
      v-if="currentUser.can_manage_super_admin_users"
      class="sidebar__link"
      to="/manage_super_admin_users"
    >
      {{ $t('super_admin_js.links.manage_super_admin_users') }}
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Sidebar',
  computed: {
    ...mapState(['currentUser']),
    ...mapGetters(['isDevFeatureFlagActive']),

    labourLawsEnabled() {
      return this.currentUser.can_manage_labour_laws
        && this.isDevFeatureFlagActive('FEATUREDEV_LLL_SUPERADMIN_ENABLED');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar__wrapper {
  border-right: 1px solid $sk-gray-light-3;
  width: 232px;
  padding: 30px 24px;

  @media screen and (max-width: 1100px) {
    display: none;
  }
}

.sidebar__link {
  padding: 20px 14px;
  font-size: 14px;
  color: $sk-black;
  width: 183px;
  display: block;

  &:hover,
  &:active {
    text-decoration: none;
  }

  &.router-link-exact-active,
  &.router-link-active {
    background: $sk-blue-light-2;
    color: $sk-blue-med-1;
    font-weight: bold;
    border-radius: 4px;
  }
}
</style>
