<template>
  <!-- eslint-disable  max-len -->
  <div>
    <main-content>
      <tab-body-first-line
        v-if="canCreateOrUpdateInvoices"
        :title="$t('super_admin_js.stripe_charge.labels.list_page_title')"
        :buttons="buttons"
      />
      <invoice-list-table
        :charges="charges"
        :loading="isLoading"
        :can-create-or-update-invoices="canCreateOrUpdateInvoices"
        :handle-sort-change="handleSortChange"
        :handle-edit-click="handleEditClick"
        :handle-preview-click="handlePreviewClick"
      />
      <br>
      <b-pagination
        v-if="total > 0"
        :total="total"
        :current="page"
        order="is-right"
        per-page="100"
        @change="value => handlePageChange(value)"
      />
      <invoice-new-modal
        :visible.sync="invoiceNewModalVisible"
        :reload="fetchData"
        :shop="shop"
      />
      <InvoiceEditModal
        :visible.sync="invoiceEditModalVisible"
        :invoice="editedInvoice"
        :reload="fetchData"
        :clear-modal-invoice="clearEditModalInvoice"
      />
      <MailPreviewModal
        :visible.sync="mailPreviewModalVisible"
        :invoice="previewInvoice"
        :reload="fetchData"
      />
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import sortableMixin from '../../mixins/sortableMixin';
import MainContent from '../../components/MainContent';
import InvoiceListTable from '../../components/InvoiceListTable';
import InvoiceNewModal from '../../components/InvoiceNewModal';
import InvoiceEditModal from '../../components/InvoiceEditModal';
import MailPreviewModal from '../../components/MailPreviewModal';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';

export default {
  components: {
    TabBodyFirstLine,
    MainContent,
    InvoiceListTable,
    InvoiceNewModal,
    InvoiceEditModal,
    MailPreviewModal,
  },
  mixins: [sortableMixin],
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      invoiceNewModalVisible: false,
      invoiceEditModalVisible: false,
      mailPreviewModalVisible: false,
      editedInvoice: null,
      previewInvoice: {},
      charges: [],
      sort: 'id',
      direction: 'asc',
      page: 1,
      total: 0,
      canCreateOrUpdateInvoices: false,
    };
  },
  computed: {
    title() {
      return this.$t('super_admin_js.stripe_charge.labels.general_title');
    },
    buttons() {
      return [
        {
          fontPack: 'fa',
          icon: 'plus',
          onClick: this.handleAddClick,
          tooltipLabel: this.$t('super_admin_js.stripe_charge.new.button_tooltip'),
        },
      ];
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      httpClient
        .get('/super_admin/api/stripe_charges', {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
            type: 'billing_info',
            billing_info_scope: 'shop',
            shop_id: this.shop.id,
          },
        })
        .then((r) => {
          this.charges = r.data.stripe_charges;
          this.total = r.data.total;
          this.isLoading = false;
          this.canCreateOrUpdateInvoices = r.data.can_create_or_update_invoices;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.stripe_charge.errors.loaded'),
          );
        });
},
    handleAddClick() {
      this.invoiceNewModalVisible = true;
    },
    handleEditClick(charge) {
      this.editedInvoice = charge;
      this.invoiceEditModalVisible = true;
    },
    clearEditModalInvoice() {
      this.editedInvoice = null;
    },
    handlePreviewClick(charge) {
      this.previewInvoice = charge;
      this.mailPreviewModalVisible = true;
    },
  },
};
</script>
