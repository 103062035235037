<template>
  <div class="map-container">
    <div class="results-count">
      {{ results.length }} {{ $t('super_admin_js.skello_map.results_found') }}
    </div>
    <googlemaps-map
      ref="map"
      :center="center"
      :zoom="zoom"
      class="map"
    >
      <googlemaps-user-position
        @update:position="setUserPosition"
      />
      <googlemaps-marker
        v-for="result in results"
        :key="result.id"
        :title="result.name"
        :position="shopPosition(result)"
        @click="handleMarkerClick"
      />
    </googlemaps-map>
  </div>
</template>

<script>

export default {
  props: {
    center: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Number,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
    setUserPosition: {
      type: Function,
      required: true,
    },
    shopPosition: {
      type: Function,
      required: true,
    },
    handleMarkerClick: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang='scss' scoped>
.map-container::v-deep .map-view {
  position: relative;
  height: 70vh;
  width: 50vw;
}

.results-count {
  height: 25px;
}
</style>
