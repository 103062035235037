import httpClient from '@config/http_client'; import i18n from '@/i18n/index.js';

export default (number) => ({
  methods: {
    handleRefreshPinClick() {
      this.$dialog.confirm({
        title: this.$t(`super_admin_js.modals.refresh_pin.title.${number}`),
        message: this.$t(`super_admin_js.modals.refresh_pin.dialog.${number}`),
        confirmText: this.$t('actions.send'),
        cancelText: this.$t('actions.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          httpClient
            .patch('/super_admin/api/pins', this.actionParams)
            .then((r) => {
              this.makeToast(
                'is-success',
                this.$t(`super_admin_js.modals.refresh_pin.success.${number}`),
              );
              this.handlePinRefreshed(r.data);
            })
            .catch((e) => {
              this.makeToast(
                'is-danger',
                this.$t(`super_admin_js.modals.refresh_pin.error.${number}`),
              );
            });
        },
      });
    },
  },
});
