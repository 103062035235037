// Allow review apps to override the API superadmin calls from
// Only allowed if ENV variable turned on
const RELATIVE_ROOT_ENABLED = process.env.VUE_APP_RELATIVE_ROOT_ENABLED === 'true';

export const storedRelativeApiRoot = RELATIVE_ROOT_ENABLED && localStorage.getItem('relative-api-root')
  ? localStorage.getItem('relative-api-root')
  : process.env.VUE_APP_API_ROOT;

export const buildLinkFor = (relativePath) => `${storedRelativeApiRoot}${relativePath}`;

export const ApiConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Source-Client': 'skelloSuperAdmin:web',
  },
  baseURL: storedRelativeApiRoot,
};
