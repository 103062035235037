<template>
  <!-- eslint-disable  max-len -->
  <default-btn
    :handle-click="handleClick"
    :text="text"
    :classes="btnClasses"
    :disabled="disabled"
  />
</template>

<script>
import DefaultBtn from '../DefaultBtn';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: { DefaultBtn },
  props: {
    handleClick: {},
    text: {
      default() {
        return this.$t('actions.cancel');
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClasses() {
      return {
        'cancel-btn': true,
        'cancel-btn--disabled': this.disabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-btn {
  background-color: #ff5355;
  color: white;

  &.cancel-btn--disabled {
    background-color: $sk-gray-light-4;
    color: $sk-gray-med-3;
  }
}
</style>
