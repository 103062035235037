<template>
  <!-- eslint-disable  max-len -->
  <div class="first-stage-wrapper">
    <div>
      <b>{{ $t('super_admin_js.modals.new_integration.first_stage.software_title') }}</b>
    </div>
    <integration-select
      v-model="integration.payroll_software"
      :disabled="isUpdateModal"
      :options="payrollSoftwareOptions"
      :width="250"
      :label="$t('super_admin_js.modals.new_integration.first_stage.software_label')"
    />
    <div
      v-if="isCustomPayrollSoftware"
      class="first-stage__custom-payroll-name-wrapper"
    >
      <integration-input
        v-model="integration.custom_name"
        :disabled="!currentUser.can_add_integration_config"
        :width="250"
        :label="$t('super_admin_js.modals.new_integration.first_stage.custom_software_label')"
      />
      <integration-input
        v-model="integration.version"
        :disabled="!currentUser.can_add_integration_config"
        :width="250"
        :label="$t('super_admin_js.modals.new_integration.first_stage.custom_software_version_label')"
      />
    </div>
    <div class="first-stage__format-separator-wrapper">
      <integration-select
        v-model="integration.format"
        :options="formats"
        :width="250"
        :label="$t('super_admin_js.modals.new_integration.first_stage.export_format')"
      />
      <integration-select
        v-if="showSeparator"
        v-model="integration.separator"
        :options="separators"
        :width="250"
        :label="$t('super_admin_js.modals.new_integration.separator')"
      />
      <integration-select
        v-model="integration.field_wrapper"
        :options="fieldWrappers"
        :width="250"
        :label="$t('super_admin_js.modals.new_integration.field_wrapper')"
      />
    </div>
    <b class="first-stage__display-settings-title">{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.title') }}</b>
    <div class="is-flex">
      <div class="first-stage__display-settings-labels">
        <p><b>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.subtitle') }}</b></p>
        <p>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.hours_days_worked') }}</p>
        <p>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.absences') }}</p>
      </div>
      <div class="first-stage__display-settings-buttons">
        <div class="first-stage__display-settings-column">
          <p>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.bundled') }}</p>
          <b-radio
            v-model="integration.evp_daily_aggregation"
            :native-value="false"
          />
          <b-radio
            v-model="integration.absence_daily_aggregation"
            :native-value="false"
          />
        </div>
        <div class="first-stage__display-settings-column">
          <p>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.detail_days') }}</p>
          <b-radio
            v-model="integration.evp_daily_aggregation"
            :native-value="true"
          />
          <b-radio
            v-model="integration.absence_daily_aggregation"
            :native-value="true"
          />
        </div>
      </div>
    </div>
    <div class="is-flex">
      <div class="first-stage__display-settings-labels">
        <p><b>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.absences_subtitle') }}</b></p>
        <p v-if="showSwitch">
          {{ $t('super_admin_js.modals.new_integration.switch_label') }}
        </p>
        <p>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.count_day_rate_absences_as_days') }}</p>
        <p>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.group_absences_in_oneline') }}</p>
        <p>{{ $t('super_admin_js.modals.new_integration.first_stage.display_report_settings.handle_evps_with_multicontracts') }}</p>
      </div>
      <div class="first-stage__display-settings-buttons">
        <div class="first-stage__display-settings-column">
          <integration-switch
            v-if="showSwitch"
            v-model="integration.show_absence_unit"
          />
          <integration-switch
            v-model="integration.count_day_rate_absences_in_days"
          />
          <integration-switch
            v-model="integration.group_absences_in_oneline"
          />
          <integration-switch
            v-model="integration.handle_evps_with_multicontracts"
          />
        </div>
      </div>
    </div>
    <div class="is-flex">
      <div class="first-stage__display-settings-labels">
        <p><b>{{ $t('super_admin_js.modals.new_integration.first_stage.special_cases.subtitle') }}</b></p>
        <p>{{ $t('super_admin_js.modals.new_integration.first_stage.special_cases.show_days_without_shifts') }}</p>
      </div>
      <div class="first-stage__display-settings-buttons">
        <div class="first-stage__display-settings-column">
          <div class="first-stage__display-settings-buttons">
            <integration-input
              v-model="integration.show_days_without_shifts_or_abs_code"
              :width="80"
              :label="$t('super_admin_js.modals.new_integration.first_stage.special_cases.show_days_without_shifts_code')"
            />
            <integration-switch
              v-model="integration.show_days_without_shifts_or_abs"
              :width="115"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="is-flex">
      <div class="first-stage__display-settings-labels">
        <p><b>{{ $t('super_admin_js.modals.new_integration.first_stage.population_exclusion.subtitle') }}</b></p>
        <b-tooltip
          :label="$t('super_admin_js.modals.new_integration.first_stage.population_exclusion.tooltip')"
          multilined
          type="is-dark"
          size="is-small"
          position="is-right"
        >
          <p>{{ $t('super_admin_js.modals.new_integration.first_stage.population_exclusion.exclude_teams') }}</p>
        </b-tooltip>
        <br>
        <b-tooltip
          :label="$t('super_admin_js.modals.new_integration.first_stage.population_exclusion.tooltip_users_without_pay_identification_number')"
          multilined
          type="is-dark"
          size="is-small"
          position="is-right"
        >
          <p>{{ $t('super_admin_js.modals.new_integration.first_stage.population_exclusion.exclude_users_without_pay_identification_number') }}</p>
        </b-tooltip>
      </div>
      <div class="first-stage__display-settings-column">
        <div>
          <integration-input
            v-model="integration.excluded_teams"
            :placeHolder="$t('super_admin_js.modals.new_integration.first_stage.population_exclusion.placeholder')"
            :width="230"
            :label="$t('super_admin_js.modals.new_integration.first_stage.population_exclusion.teams')"
          />
        </div>
        <div class="switch-container">
          <integration-switch
            v-model="integration.exclude_users_without_pay_identification_number"
            :width="115"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import IntegrationInput from '../IntegrationInput';
import IntegrationSelect from '../IntegrationSelect';
import IntegrationSwitch from '../IntegrationSwitch';

export default {
  name: 'NewIntegrationModalFirstStage',
  components: {
    IntegrationInput,
    IntegrationSelect,
    IntegrationSwitch,
  },
  mixins: [],
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formats: ['.CSV', '.TXT', '.AXLSX', '.ZIP', '.240'],
      separators: [
        { key: ',', value: ',' },
        { key: ';', value: ';' },
        { key: 'pipe', value: '|' },
        { key: 'space', value: this.$t('super_admin_js.modals.new_integration.space') },
        { key: 'tab', value: this.$t('super_admin_js.modals.new_integration.tabulation') },
        { key: null, value: this.$t('super_admin_js.modals.new_integration.no_separator') },
      ],
      fieldWrappers: [
        { key: null, value: this.$t('super_admin_js.modals.new_integration.none') },
        { key: '"', value: '"' },
      ],
    };
  },
  computed: {
    ...mapState(['currentUser']),

    showSeparator() {
      const isSilae = this.integration.payroll_software === 'Silae';
      const isAxlsx = this.integration.format === '.AXLSX';
      const isOther = this.isCustomPayrollSoftware;
      return (isSilae && !isAxlsx) || isOther;
    },
    showSwitch() {
      return this.integration.payroll_software === 'Silae' || this.isCustomPayrollSoftware;
    },
    isCustomPayrollSoftware() {
      return this.integration.payroll_software === this.$t('super_admin_js.modals.new_integration.first_stage.software_other');
    },
    isUpdateModal() {
      return this.integration.createMethod === 'fromIntegration';
    },
    payrollSoftwareOptions() {
      const options = ['Silae', 'Quadratus'];
      if (this.currentUser.can_add_integration_config || this.isUpdateModal) {
        options.push(this.$t('super_admin_js.modals.new_integration.first_stage.software_other'));
      }
      return options;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'integration.payroll_software': function () {
      if (this.integration.payroll_software === 'Silae') {
        this.integration.format = '.CSV';
        this.integration.separator = ',';
      } else if (this.integration.payroll_software === 'Quadratus') {
        this.integration.format = '.TXT';
        this.integration.separator = ':';
      }
    },
  },
  mounted() {
    this.integration.payroll_software = 'Silae';
    this.integration.separator = ',';
  },
};
</script>

<style lang="scss" scoped>
.first-stage-wrapper {
  padding: 30px;
}

.first-stage__custom-payroll-name-wrapper {
  display: flex;
}

.first-stage__format-separator-wrapper {
  display: flex;
  padding-bottom: 16px;
}

.first-stage__display-settings-title {
  font-size: 16px;
}

.first-stage__display-settings-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding-top: 16px;

  .switch-container {
    ::v-deep .switch-design {
      padding-top: 0;
      margin-right: 15px;
    }
  }
}

.first-stage__display-settings-buttons {
  display: flex;
  justify-content: space-around;
  flex: 2;
}

.first-stage__display-settings-labels {
  flex: 4;
  padding-top: 16px;
  line-height: 2;
}

::v-deep .first-stage__display-settings-column .switch-wrap {
  margin: -5px -30px 0 0;
  text-align: right;
}
</style>
