<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div class="field-label is-normal">
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <span>
        <router-link :to="value.url">
          {{ value.name }}
        </router-link>
        &nbsp;{{ value.details }}
      </span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';

export default {
  name: 'SaFormLinkText',
  mixins: [saFormTypeMixin],
};
</script>
