<template>
  <div :style="{ 'width': width + 'px' }" class="switch-wrap">
    <label> {{ label }}</label>
    <div class="switch-design">
      <WhiteSkSwitch v-model="switchModel" />
    </div>
  </div>
</template>

<script>
import WhiteSkSwitch from '../../../WhiteSkSwitch';

export default {
  name: 'IntegrationSwitch',
  components: { WhiteSkSwitch },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 200,
    },
  },
  computed: {
    switchModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    areOptionsInHashFormat() {
      if (!this.options || !this.options.length) return false;

      return this.options.every((option) => option.key || option.value);
    },
  },
  methods: {
    handleChange(event) {
      this.$emit('input', event.target.value);
      if (!this.onChange) return;

      if (this.onChangeParam) {
        this.onChange(this.onChangeParam, event.target.value);
      } else {
        this.onChange(event.target.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-wrap {
  margin: 10px 5px 10px 0;
  border-radius: 4px;
  padding: 0 5px;
  width: 200px;
  position: relative;
}

.switch-wrap label {
  font-size: 8px;
  text-transform: uppercase;
  color: #616161;
  padding: 0 8px;
  position: absolute;
  top: 6px;
}

.switch-design {
  padding-top: 21px;
}
</style>
