<template>
  <section class="section">
    <ShopsMapSearchbar
      :fetchData="fetchData"
      :searchRadius="searchRadius"
      :searchQuery="searchQuery"
      @changeQuery="setQuery"
      @changeRadius="setRadius"
    />
    <ShopsMapFilters
      :setFilters="setFilters"
      :submitSearch="submitSearch"
      :firstFetch="firstFetch"
    />
    <div v-if="resultsLoaded" class="map-and-shops-container">
      <ShopsMapMap
        :center="center"
        :zoom="zoom"
        :results="shops"
        :setUserPosition="setUserPosition"
        :shopPosition="shopPosition"
        :handleMarkerClick="handleMarkerClick"
      />
      <ShopsMapList
        :shops="shops"
        :selectedShop="selectedShop"
      />
    </div>
    <div v-else class="superloader-container">
      <SuperLoader />
    </div>
  </section>
</template>

<script>
import httpClient from '@config/http_client';
import ShopsMapSearchbar from '../../components/ShopsMapSearchbar';
import ShopsMapFilters from '../../components/ShopsMapFilters';
import ShopsMapMap from '../../components/ShopsMapMap';
import ShopsMapList from '../../components/ShopsMapList';
import SuperLoader from '../../components/SuperLoader';

export default {
  components: {
    ShopsMapFilters,
    ShopsMapSearchbar,
    ShopsMapMap,
    ShopsMapList,
    SuperLoader,
  },
  data() {
    return {
      center: {
        lat: 48.853,
        lng: 2.298,
      },
      searchQuery: '30 rue de Gramont 75002 Paris',
      searchRadius: 1,
      resultsLoaded: false,
      selectedShop: null,
      shops: [],
      filters: {},
      firstFetch: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    setQuery(newQuery) {
      this.searchQuery = newQuery;
    },
    setRadius(newRadius) {
      this.searchRadius = Number(newRadius);
    },
    setUserPosition(position) {
      this.userPosition = position;
    },
    shopPosition(shop) {
      return { lat: shop.lat, lng: shop.lng };
    },
    getZoomFromRadius(radius) {
      radius = parseFloat(radius);
      return 15 - (radius / 2);
    },
    setFilters(filterName, filterCollection) {
      if (filterName === this.$t('super_admin_js.skello_map.convention')) {
        this.filters.conventions = filterCollection;
      } else if (filterName === this.$t('super_admin_js.skello_map.sector')) {
        this.filters.sectors = filterCollection;
      } else if (filterName === this.$t('super_admin_js.skello_map.active_employees')) {
        this.filters.active_employees = filterCollection;
      } else if (filterName === this.$t('super_admin_js.skello_map.features')) {
        this.filters.features = filterCollection;
      }
    },
    roundedCoordinate(coordinate) {
      return coordinate.toFixed(5);
    },
    findShopByCoordinates(coordinates) {
      const lat = this.roundedCoordinate(coordinates.lat());
      const lng = this.roundedCoordinate(coordinates.lng());

      return _.find(
        this.shops, (shop) => this.roundedCoordinate(shop.lat) === lat
        && this.roundedCoordinate(shop.lng) === lng,
      );
    },
    handleMarkerClick(marker) {
      const shop = this.findShopByCoordinates(marker.latLng);
      _.remove(this.shops, (shopArray) => shopArray === shop);
      this.shops.unshift(shop);
      this.selectedShop = shop.id;

      this.$forceUpdate();
    },
    submitSearch() {
      const { searchQuery, searchRadius, filters } = this;
      analytics.track(
        'Skello Map: Click on GO!',
        { searchQuery, searchRadius, filters },
      );
      this.fetchData();
    },
    fetchData() {
      this.resultsLoaded = false;

      httpClient
        .get('/super_admin/api/map_shops', {
          params: {
            search_address: this.searchQuery,
            search_radius: this.searchRadius,
            filters: this.filters,
          },
        })
        .then((response) => {
          this.resultsLoaded = true;
          this.shops = response.data.shops;
          this.center.lat = response.data.searched_lat;
          this.center.lng = response.data.searched_lng;
          this.zoom = this.getZoomFromRadius(this.searchRadius);
          this.firstFetch = false;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.try_later'),
          );
        });
    },
  },
};
</script>

<style lang='scss' scoped>
  .map-and-shops-container {
    display: flex;

    ::v-deep .resize-observer {
      display: none;
    }
  }

  .superloader-container {
    position: absolute;
    left: 210px;
    right: 0;
    bottom: 0;
    top: 270px;
  }
</style>
