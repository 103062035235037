<template>
  <!-- eslint-disable  max-len -->
  <b-autocomplete
    id="sa-global-search-input"
    ref="globalSearchInput"
    v-model="searchQuery"
    class="search-bar__input"
    :data="searchData"
    :loading="isFetchingData"
    :placeholder="$t('layout.navbar.search')"
    icon="search"
    field="content"
    max-results="10"
    has-custom-template
    @input="getAsyncData"
    @select="navigateToShow"
  >
    <template slot-scope="props">
      <div class="media">
        <div class="media-left">
          <b-icon
            :icon="getIconName(props.option)"
            pack="fa"
          />
        </div>
        <div class="media-content">
          <span class="result">{{ props.option.content }}</span>
          <span class="additional-information">{{ props.option.additional_information }}</span>
        </div>
      </div>
    </template>
  </b-autocomplete>
</template>

<script>
import httpClient from '@config/http_client';
import _ from 'lodash';

export default {
  data() {
    return {
      searchQuery: null,
      searchData: [],
      isFetchingData: false,
      firstLoad: true,
    };
  },
  watch: {
    $route() {
      this.focusOnGlobalInput();
    },
  },
  mounted() {
    this.focusOnGlobalInput();
  },
  methods: {
    getAsyncData: _.debounce(function getData() {
      this.isFetchingData = true;
      const currentSearchValue = this.searchQuery;
      httpClient
        .get(`/super_admin/api/search?query=${this.searchQuery}`)
        .then((response) => {
          if (currentSearchValue === this.searchQuery) {
            this.searchData = response.data;
          }

          this.isFetchingData = false;
        })
        .catch((e) => {
          this.$skToast({
            duration: 5000,
            message: this.$t('super_admin_js.ui.search_bar.error_toast'),
            position: 'is-bottom',
            type: 'is-danger',
          });
        });
    }, 400),
    navigateToShow(event) {
      if (!event) return;
      this.$router.push(`/${event.searchable_type.toLowerCase()}s/${event.searchable_id}`);
    },
    focusOnGlobalInput() {
      this.$refs.globalSearchInput.focus();
    },
    getIconName(option) {
      switch (option.searchable_type) {
        case 'User':
          return 'user';
        case 'Organisation':
          return 'sitemap';
        default:
          return 'home';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-bar__input {
  min-width: 400px;

  ::v-deep a.button.is-link {
    justify-content: flex-start;
    text-decoration: none;
    width: 100%;
    padding: 0 !important;
  }

  ::v-deep #sa-global-search-input {
    border: 0;
    background: #F0F2F5;
    border-radius: 6px;
    box-shadow: none;
    padding-left: 55px;
    font-size: 14px;
    height: 40px;
  }

  ::v-deep .control.has-icons-left .icon {
    color: #6D7D8C;
    font-size: .85em;
    top: 3px;
    left: 10px;
  }
}

.media {
  font-size: 16px;
  padding: 5px 0 5px 10px;
}

.media-left {
  margin-right: .5rem;
}

.additional-information {
  font-size: 10px;
  color: #424242;
  margin-left: 10px;
}
</style>
