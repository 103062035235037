import Cookies from 'js-cookie';
import {
  AuthWebClient,
  TokenWebModel,
} from '@skelloapp/skello-auth-client/web';

import { buildLinkFor, ApiConfig } from '.';

class Security {
  static KEY_AUTH_ACCESS_TOKEN = 'SkelloApp_AccessToken';

  static KEY_AUTH_REFRESH_TOKEN = 'SkelloApp_RefreshToken';

  static KEY_AUTH_ADMIN_REFRESH_TOKEN = 'SkelloApp_SkelloUser_RefreshToken';

  static KEY_AUTH_IMPERSONATE_USER_ID = 'SkelloApp_ImpersonateUserId';

  constructor() {
    this.svcUsersUrl = process.env.VUE_APP_SVC_USERS_BASE_URL;
    this.svcUsersMiddleware = process.env.VUE_APP_SVC_USERS_MIDDLEWARE === 'true';
    // TODO
    this.useSvcUserForQa = false;

    if (this.svcUsersUrl && this.svcUsersMiddleware) {
      this.svcUserAuthClient = new AuthWebClient({
        headers: ApiConfig.headers,
        baseURL: this.svcUsersUrl,
      });
    }

    this.authClient = new AuthWebClient(ApiConfig);

    this.cookieDomain = process.env.VUE_APP_COOKIE_DOMAIN;

    this.authToken = null;
  }

  async verifyTokenFromCookies(refreshToken) {
    if (!this.cookieDomain) {
      throw new Error('No cookie domain env');
    }

    if (!refreshToken) {
      // only use this refreskToken when reviewapps are using sandbox url
      refreshToken = Cookies.get(
        Security.KEY_AUTH_ADMIN_REFRESH_TOKEN,
        {
          domain: this.cookieDomain,
        },
      );
    }

    if (!refreshToken) {
      throw new Error('No refresh token');
    }

    const decryptedRefreshToken = new TokenWebModel({ token: null, refreshToken }).refreshTokenData;

    const authClient = this.useSvcUserAuthClient(decryptedRefreshToken.subMail)
      ? this.svcUserAuthClient
      : this.authClient;

    const token = await authClient.refreshToken({ refreshToken });
    if (!token.refreshTokenData.superAdmin) {
      throw new Error('No super admin token');
    }

    return this.setAuthToken(token);
  }

  async getAuthToken() {
    this.getAuthTokenFromStorage();

    if (!this.authToken) {
      return null;
    }

    await this.refreshToken();

    if (!this.authToken) {
      throw new Error('Token is empty.');
    }

    return this.authToken;
  }

  useSvcUserAuthClient(email) {
    // TODO
    // if (this.forceAuthMiddlewareForAllUsers) {
    //   return true;
    // }

    if (!this.svcUsersMiddleware || this.svcUserAuthClient === undefined) return false;

    let subEmail = email;

    if (!subEmail) {
      this.getAuthTokenFromStorage();
      subEmail = this.authToken?.tokenData?.data?.email;
    }
    // match whatever+jwt-alias10k@skello.io
    const msUsersEmails = /^.+\+jwt(-[a-z\d\-_]*)*@skello\.io$/gi;
    // match qa-team+whatever@skello.io
    const qaTeamEmails = /^qa-team(\+.*)*@skello\.io$/gi;

    return msUsersEmails.test(subEmail) || (qaTeamEmails.test(subEmail) && this.useSvcUserForQa);
  }

  async refreshToken() {
    if (this.authToken.refreshTokenIsExpired()) {
      await this.logOut();
    }

    if (this.authToken.tokenIsExpired()) {
      const authClient = this.useSvcUserAuthClient(this.authToken?.tokenData?.data?.email)
        ? this.svcUserAuthClient
        : this.authClient;

      const data = await authClient.refreshToken({
        refreshToken: this.authToken.refreshToken,
      });

      this.setAuthToken(data);
    }

    return this.authToken;
  }

  setImpersonateCookie(userId) {
    Cookies.set(
      Security.KEY_AUTH_IMPERSONATE_USER_ID,
      userId,
      {
        domain: this.cookieDomain,
      },
    );
  }

  setAuthToken(data) {
    this.authToken = data;

    const storage = data.tokenData.rememberMe ? localStorage : sessionStorage;

    storage.setItem(Security.KEY_AUTH_ACCESS_TOKEN, this.authToken.token);
    storage.setItem(Security.KEY_AUTH_REFRESH_TOKEN, this.authToken.refreshToken);

    Cookies.set(
      Security.KEY_AUTH_ADMIN_REFRESH_TOKEN,
      this.authToken.refreshToken,
      {
        expires: new Date(data.refreshExp * 1000),
        domain: this.cookieDomain,
      },
    );

    return this.authToken;
  }

  deleteAuthToken() {
    localStorage.removeItem(Security.KEY_AUTH_ACCESS_TOKEN);
    localStorage.removeItem(Security.KEY_AUTH_REFRESH_TOKEN);

    sessionStorage.removeItem(Security.KEY_AUTH_ACCESS_TOKEN);
    sessionStorage.removeItem(Security.KEY_AUTH_REFRESH_TOKEN);
  }

  deleteCookie() {
    Cookies.remove(Security.KEY_AUTH_ADMIN_REFRESH_TOKEN, { domain: this.cookieDomain });
    Cookies.remove(Security.KEY_AUTH_IMPERSONATE_USER_ID, { domain: this.cookieDomain });
    Cookies.remove(Security.KEY_AUTH_REFRESH_TOKEN, { domain: this.cookieDomain });
  }

  logOut() {
    this.deleteAuthToken();
    this.deleteCookie();
    window.location = buildLinkFor('/super_admin/frontend_redirections/logout');
  }

  getAuthTokenFromStorage() {
    if (this.authToken) return;

    let token = sessionStorage.getItem(Security.KEY_AUTH_ACCESS_TOKEN);
    let refreshToken = sessionStorage.getItem(Security.KEY_AUTH_REFRESH_TOKEN);

    if (!token && !refreshToken) {
      token = localStorage.getItem(Security.KEY_AUTH_ACCESS_TOKEN);
      refreshToken = localStorage.getItem(Security.KEY_AUTH_REFRESH_TOKEN);
    }

    if (token && refreshToken) {
      this.authToken = new TokenWebModel({ token, refreshToken });
    }
  }
}

export const security = new Security();

export default security;
