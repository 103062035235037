<template>
  <!-- eslint-disable  max-len -->
  <div>
    <main-content>
      <tab-body-first-line
        :title="$t('super_admin_js.sent_emails.labels.list_page_title')"
      />
      <div class="sent_emails__search-wrapper">
        <div class="sent_emails__search-container">
          <input
            v-model="search"
            :class="isDeactivated"
            :disabled="isDisabled"
            class="sent-emails__input"
            type="text"
            :placeholder="$t('super_admin_js.users.email_search')"
            @keyup.enter="handleClick"
          >
          <i class="sent-emails__icon sent-emails__icon-search fa fa-search" />
        </div>
        <div class="right-section__search">
          <Datepicker
            v-model="month"
            :clear-button="true"
            class="js-disable-form-alert"
            clear-button-icon="fa fa-times"
            format="MM/yyyy"
            minimum-view="month"
            placeholder="Mois"
            language="fr"
          />
          <i class="sent-emails__icon sent-emails__icon-calendar fa fa-calendar" />
          <button
            class="sent-emails__submit-btn"
            @click="handleClick"
          >
            {{ $t('super_admin_js.users.email_search_prompt') }}
          </button>
        </div>
      </div>
      <SentEmailsListTable
        :sentEmails="filteredSentEmails"
        :loading="isLoading"
        :handle-change-sort="handleChangeSort"
      />
      <br>
      <b-pagination
        v-if="sentEmails.length > 99"
        :total="total"
        :current="page"
        :per-page="perPage"
        order="is-right"
        @change="value => handleChangePage(value)"
      />
    </main-content>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import httpClient from '@config/http_client';

import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import MainContent from '../../components/MainContent';
import SentEmailsListTable from '../../components/SentEmailsListTable';

export default {
  components: {
    TabBodyFirstLine,
    MainContent,
    SentEmailsListTable,
    Datepicker,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      total: 0,
      sentEmails: [],
      originalSentEmails: [],
      perPage: 100,
      month: new Date(),
      search: '',
    };
  },
  computed: {
    filteredSentEmails() {
      return this.sentEmails.filter((email) => (
          email.from.toLowerCase().includes(this.search.toLowerCase())
          || email.to.toLowerCase().includes(this.search.toLowerCase())
          || email.subject.toLowerCase().includes(this.search.toLowerCase())
        ));
    },
    isDisabled() {
      return this.sentEmails.length === 0;
    },
    isDeactivated() {
      return this.sentEmails.length !== 0 ? '' : 'input-deactivated';
    },
  },
  mounted() {
    this.fetchData(this.user, this.month);
  },
  methods: {
    fetchData(user, date) {
      if (this.user.email === '') {
        this.makeAlertToast(
          this.$t('super_admin_js.users.errors.no_email'),
        );
        return;
      }
      this.isLoading = true;
      httpClient
        .get(`/super_admin/api/sent_emails/${this.user.id}`, {
          params: {
            date,
            user_email: this.user.email,
            page: this.page,
          },
        })
        .then((r) => {
          this.originalSentEmails = r.data.sent_emails;
          // Paginate the results
          this.sentEmails = this.originalSentEmails.slice(
            (this.page - 1) * this.perPage,
            this.page * this.perPage,
          );
          this.total = r.data.total;
          this.isLoading = false;
          if (this.sentEmails.length === 0) {
            this.makeAlertToast(
              this.$t('super_admin_js.users.errors.no_email_this_month'),
            );
          }
        })
        .catch((e) => {
          const message = `${e}`;
          if (message.includes('Request failed')) {
            // Problem from Sendinblue API
            this.makeAlertToast(
              this.$t('super_admin_js.sent_emails.errors.sendinblue_error'),
            );
          } else {
            this.makeAlertToast(message);
          }
          this.isLoading = false;
        });
},
    handleChangePage(value) {
      this.sentEmails = this.originalSentEmails.slice(
        (value - 1) * this.perPage,
        value * this.perPage,
      );
      this.page = value;
    },
    handleChangeSort(field, direction) {
      window.scrollTo(0, 0);
      if (direction === 'asc') {
        const arr = _.sortBy(this.sentEmails, field);
        this.sentEmails = arr;
      } else {
        const arr = _.sortBy(this.sentEmails, field);
        arr.reverse();
        this.sentEmails = arr;
      }
    },
    handleClick() {
      this.fetchData(this.user, this.month);
    },
  },
};
</script>

<style lang="scss">
.right-section__search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -15px;
}

.vdp-datepicker__calendar {
  width: 250px !important;
}

.vdp-datepicker__clear-button {
  position: relative;
  right: 24px;
  top: 1px;
  color: #c0c1bf;
}

.vdp-datepicker input {
  width: 115px;
  margin-left: -1px;
  border-radius: 0 3px 3px 0;
  height: 44px;
  outline: none;
  box-shadow: none !important;
  border: solid 1px #f1f1f1;
  padding-left: 50px;
}

.sent_emails__search-wrapper {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: unset;
}

.sent_emails__search-container {
  display: flex;
  align-items: center;
}

.sent-emails__input {
  width: 499px;
  padding: 1px;
  border-width: 2px;
  position: relative;
  border-right: none;
  border-radius: 3px 0 0 3px;
  height: 44px;
  outline: none;
  box-shadow: none !important;
  border: solid 1px #f1f1f1;
  padding-left: 50px;
}

.input-deactivated {
  opacity: .5;
  cursor: not-allowed;
}

.sent-emails__submit-btn {
  height: 44px;
  width: 129px;
  font-size: 14px;
  margin: 0 15px;
  background: #1a6efc;
  border-radius: 3px;
  padding: 5px 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
}

.sent-emails__icon {
  position: absolute;
  font-size: 20px;
}

.sent-emails__icon-search {
  left: 17px;
}

.sent-emails__icon-calendar {
  left: 518px;
}
</style>
