<template>
  <!-- eslint-disable  max-len -->
  <b-modal :active.sync="modalVisible">
    <div class="modal-wrapper">
      <div class="modal-header">
        <div
          class="modal-title"
          v-html="title"
        />
        <div
          class="sk-modal-close"
          @click="closeModal"
        >
          <i
            class="fa fa-times"
            aria-hidden="true"
          />
        </div>
      </div>
      <div class="modal-body">
        <slot />
      </div>
      <div class="modal-footer">
        <slot name="footer" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import modalVisibleMixin from '../../../../mixins/modalVisibleMixin';

export default {
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  background-color: white;
  font-size: 14px;
  width: 100%;
  margin-top: 50px;
}

.modal-header {
  display: flex;
  padding: 1.5em;
}

.modal-footer {
  height: 55px !important;
}

.modal-title {
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

.modal-body {
  width: 100%;
}

.sk-modal-close {
  color: #2d2f30;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  float: right;
  background: transparent;
}
</style>
