export default {
  methods: {
    handleSortChange(sort, direction) {
      window.scrollTo(0, 0);
      this.isLoading = true;
      this.sort = sort;
      this.direction = direction;
      this.page = 1;
      this.fetchData();
    },
    handlePageChange(newPageNumber) {
      window.scrollTo(0, 0);
      this.isLoading = true;
      this.page = newPageNumber;
      this.fetchData();
    },
  },
};
