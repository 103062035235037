<template>
  <tr class="feature-flags__table__row">
    <td class="feature-flags__table__cell">
      <DevFlagUpdateModal
        :devFlagStatus="!!featureFlag.active"
        :devFlagName="featureFlag.name"
        :title="updateModalTitle"
        :body="updateModalBody"
        :submit-button-label="$t(`super_admin_js.modals.dev_flags_update.${updateModalVerb}`)"
        @devFlagUpdateValidation="handleSubmit"
      />
      <SkInput
        v-model="featureFlag.name"
        v-tooltip="tooltipText"
        :disabled="true"
        :label="$t('super_admin_js.feature_flags.fields.name')"
      />
    </td>
    <td class="feature-flags__table__cell">
      <SkSwitch
        :value="featureFlag.active"
        @input="showConfirmationModal"
      />
    </td>
  </tr>
</template>

<script>
import {
  SkInput,
  SkSwitch,
  SkTooltipDirective,
  MODAL_SHOW_EVENT,
  skListenersMixin,
} from '@skelloapp/skello-ui';
import httpClient from '@config/http_client';
import DevFlagUpdateModal from '../modals/DevFlagUpdateModal';

export default {
  components: { SkInput, SkSwitch, DevFlagUpdateModal },
  directives: { tooltip: SkTooltipDirective },
  mixins: [skListenersMixin],
  props: {
    featureFlag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tooltipText() {
      return `<b>${this.featureFlag.name}</b><br/>${this.featureFlag.description}`;
    },
   updateModalTitle() {
      return this.$t(`super_admin_js.modals.dev_flags_update.title_${this.updateModalVerb}`);
    },
    updateModalBody() {
      return this.$t(
        `super_admin_js.modals.dev_flags_update.content_${this.updateModalVerb}`,
        { ff_name: this.featureFlag.name },
      );
    },
    updateModalVerb() {
      return this.featureFlag.active ? 'deactivate' : 'activate';
    },
  },
  methods: {
    showConfirmationModal() {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, `dev-flag-update-modal-${this.featureFlag.name}`);
    },
    handleSubmit(event) {
      this.featureFlag.active = event.newStatus;
      this.updateFeatureFlagDev();
    },
    updateFeatureFlagDev() {
      const params = {
        feature_flag: {
          name: this.featureFlag.name,
          active: this.featureFlag.active,
        },
      };

      httpClient
        .put(`/super_admin/api/feature_flags_dev/${this.featureFlag.name}`, params)
        .then(() => {
          this.makeSuccessToast(this.$t('super_admin_js.feature_flags.update_success'));
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.makeAlertToast(
              this.$t('errors.unauthorized'),
            );

            this.$router.push({ name: 'home' });
          } else {
            this.makeAlertToast(
              this.$t('errors.loading'),
            );
          }
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.feature-flags__table__row {
  height: 68px;
}

.feature-flags__table__cell {
  width: 50%;
  padding-right: 16px;
}
</style>
