<template>
  <!-- eslint-disable  max-len -->
  <div class="wrapper">
    <div class="title-wrapper">
      <h4 class="title is-4">
        {{ title }}
      </h4>
    </div>

    <div class="buttons-wrapper">
      <slot />
      <action-button
        v-for="(button, index) in shownButtons"
        :key="index"
        :icon="button.icon"
        :icon-color="button.iconColor"
        :icon-pack="button.iconPack"
        :real-link="button.realLink"
        :tooltip-label="button.tooltipLabel"
        :active-state="button.activeState"
        :warning="button.warning"
        @click.stop="button.onClick()"
      />
    </div>
  </div>
</template>

<script>
import ActionButton from '../ActionButton';

/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: { ActionButton },
  props: {
    title: {},
    buttons: {
      default: () => [],
    },
  },
  computed: {
    shownButtons() {
      return this.buttons.filter((button) => !button.hidden);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 1em;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 60px;
  padding-bottom: 20px;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
}
</style>
