<template>
  <!-- eslint-disable  max-len -->
  <div>
    <main-content>
      <tab-body-first-line :title="$t('super_admin_js.shops.history')" />
      <ul>
        <li v-for="(shiftHistory, index) in shiftHistories" :key="index">
          {{ $t('super_admin_js.shops.history_line', { user_name: shiftHistory.user_name, user_id: shiftHistory.user_id, shifts_count: shiftHistory.shifts_count, date: shiftHistory.date, hour: shiftHistory.hour, weeks_numbers: shiftHistory.weeks_numbers}) }}
        </li>
      </ul>
      <br>
      <b-pagination
        v-if="total > 0"
        :total="total"
        :current="page"
        order="is-right"
        per-page="100"
        @change="value => handlePageChange(value)"
      />
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';

import sortableMixin from '../../mixins/sortableMixin';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import MainContent from '../../components/MainContent';

export default {
  components: { TabBodyFirstLine, MainContent },
  mixins: [sortableMixin],
  props: {
    shop: { type: Object, required: true },
  },
  data() {
    return {
      shiftHistories: [],
      page: 1,
      total: 0,
      direction: 'desc',
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      httpClient
        .get('/super_admin/api/shifts_histories', {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
            type: 'shop',
            shop_id: this.shop.id,
          },
        })
        .then((r) => {
          this.shiftHistories = r.data.shifts_histories;
          this.total = r.data.total;
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.errors.history_error'),
          );
        });
},
  },
};
</script>

<style lang="scss" scoped>
  .form-group {
    margin-bottom: 5em;

    & > * {
      margin-left: 4em;
      margin-bottom: 1.7em;
    }

    h6 {
      margin-left: 0;
      margin-top: .7em;
      // font-size: 1.3em;
      font-weight: normal;
      text-decoration: underline;
    }
  }
</style>
