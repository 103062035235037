<template>
  <b-modal :active.sync="modalVisible">
    <div class="modal-wrapper">
      <div class="modal-header">
        <SkMedallion
          content-component="AnalyticsIcon"
          background-color="#F0F2F5"
          content-color="#6D7D8C"
        />
        <span class="modal-header-title">
          {{ $t('super_admin_js.integration_tasks.update_kpis_label') }}
        </span>
        <div
          class="modal-header-close"
          @click="onCancel"
        >
          <i
            class="fa fa-times"
            aria-hidden="true"
          />
        </div>
        <hr />
      </div>
      <div class="modal-body">
        <h2 class="integration-steps">
          <b>{{ $t('super_admin_js.modals.integration.job_modal.step_1_title') }}</b>
        </h2>
        <div class="template-download-section">
          <a
            class="template-download__link"
            :href="templateLink"
          >
            {{ $t('super_admin_js.modals.integration.job_modal.step_1_message') }}
          </a>
        </div>
        <h2 class="integration-steps">
          <b>{{ $t('super_admin_js.modals.integration.job_modal.step_2_title') }}</b>
        </h2>
        <b-field v-if="!file">
          <b-upload
            v-model="file"
            drag-drop
          >
            <section class="upload-section">
              <div class="content has-text-centered">
                <p class="upload-section__title">
                  {{ $t('super_admin_js.modals.integration.job_modal.upload.title') }}
                </p>
                <span class="upload-section__separator">
                  {{ $t('super_admin_js.modals.integration.job_modal.upload.join_word') }}
                </span>
                <div class="upload-section__button">
                  {{ $t('super_admin_js.modals.integration.job_modal.upload.button') }}
                </div>
              </div>
            </section>
          </b-upload>
        </b-field>
        <section
          v-else
          class="section"
        >
          <div class="content has-text-centered">
            <p>{{ file.name }}</p>
            <button
              class="delete is-small"
              @click="clearFile()"
            />
          </div>
        </section>
      </div>
      <div class="modal-footer update-contract-number-modal__footer-btns">
        <cancel-btn
          :handle-click="onCancel"
          class="cancel-btn"
          v-text="$t('super_admin_js.modals.integration.job_modal.cancel')"
        />
        <validate-btn
          :handle-click="onSubmit"
          class="validate-btn"
          v-text="$t('super_admin_js.modals.integration.job_modal.import')"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import httpClient from '@config/http_client';

import { SkMedallion } from '@skelloapp/skello-ui';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: {
    ValidateBtn,
    CancelBtn,
    SkMedallion,
  },
  mixins: [modalVisibleMixin],
  props: {
    organisationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      loading: false,
    };
  },
  computed: {
    templateLink() {
      return `${process.env.VUE_APP_API_ROOT}/csv/update_kpis_template.csv`;
    },
  },
  methods: {
    clearFile() {
      this.file = null;
    },
    onSubmit() {
      if (this.loading) return;

      const data = new FormData();
      data.append('file', this.file, this.file.name);
      data.append('organisation_id', this.organisationId);
      data.append('key', 'kpis_update');
      this.loading = true;

      httpClient
        .post('/super_admin/api/integrations/integrations/run_integration_job', data)
        .then(() => {
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.integration_tasks.launching'),
          );
        })
        .catch(() => {
          this.makeAlertToast(
            this.$t('super_admin_js.integration_tasks.error_launching'),
          );
        })
        .finally(() => {
          this.clearFile();
          this.loading = false;
          this.onCancel();
        });
    },
    onCancel() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  border-radius: 6px;
  background-color: white;
  padding: 2em;
  font-size: 16px;
  width: 620px;
  height: 505px;
}

.modal-header {
  margin: -12px;
}

.sk-medallion {
  float: left;
  margin-top: -5px;
}

.modal-header-title {
  font-size: 19px;
  font-weight: 700;
  margin: 22px;
  color: $sk-black;
}

.modal-header-close {
  color: $sk-black;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  float: right;
  background: transparent;
}

hr {
  display: block;
  height: 1px;
  border-top: 1px solid $sk-gray-light-3;
  width: 620px;
  margin: 1em 0 1em -20px;
}

.modal-body {
  padding: 1em 0 0 0;
  margin: 2px -7px 0 -7px;
  height: 365px;
}

.integration-steps {
  font-size: 17px;
  font-weight: 900;
}

.template-download-section {
  font-size: 15px;
  text-decoration: underline;
  margin-bottom: 28px;
}

.upload-section {
  display: inline-block;
  cursor: pointer;
  border-radius: 6px;
  width: 572px;
  height: 194px;
  padding: 40px;

  &__title {
    color: $sk-gray-med-2;
    font-size: 15px;
  }

  &__separator {
    display: block;
    margin: -13px 0 9px 0;
    color: $sk-gray-med-2;
    font-size: 12px;
  }

  &__button {
    border: 1px solid $sk-gray-light-3;
    width: 194px;
    height: 40px;
    margin: auto;
    border-radius: 3px;
    padding: 10px 10px 0 10px;
    font-size: 14px;
    color: $sk-blue-med-1;
  }
}

.update-contract-number-modal__footer-btns {
  margin: -15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.cancel-btn {
  color: $sk-blue-med-1;
  background: white;
  border: 1px solid $sk-gray-light-3;
  border-radius: 3px;
  font-size: 14px;
}

.validate-btn {
  color: white;
  border: 1px solid $sk-gray-light-3;
  border-radius: 3px;
  font-size: 14px;
}
</style>
