import { security } from '@config/security';

import httpClient from '../config/http_client';

export default function impersonateUser(userId) {
  return httpClient
    .get(`/super_admin/api/users/${userId}/impersonate_callback_url`)
    .then((response) => {
      const callbackUrl = response.data.callback_url;

      // use this cookie when reviewapps are using sandbox url
      // right now set in backend
      // security.setImpersonateCookie(userId);

      window.location = callbackUrl;
    });
}
