<template>
  <!-- eslint-disable  max-len -->
  <div>
    <tab-body-first-line
      :title="title"
      :buttons="buttons"
    />
    <shop-list-table
      :shops="shops"
      :loading="isLoading"
      :handle-sort-change="handleSortChange"
    />
    <br>
    <b-pagination
      v-if="total > 0"
      :total="total"
      :current="page"
      order="is-right"
      per-page="100"
      @change="value => handlePageChange(value)"
    />
    <badging-modal
      :visible.sync="badgingModalVisible"
      :title="badgingModalTitle"
      :params="badgingModalParams"
      :setting-to-true="setBadgingToTrue"
      :callback="handleBadgingActivated"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </badging-modal>
    <automatic-planning-modal
      :visible.sync="automaticPlanningModalVisible"
      :title="automaticPlanningModalTitle"
      :params="automaticPlanningModalParams"
      :setting-to-true="setAutomaticPlanningToTrue"
      :callback="handleAutomaticPlanningActivated"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </automatic-planning-modal>
    <perms-replication-modal
      :visible.sync="permsReplicationModalVisible"
      :organisation-id="organisation.id"
      :callback="fetchData"
    />
    <pay-export-modal
      :visible.sync="payExportModalVisible"
      :title="payExportModalTitle"
      :base-params="badgingModalParams"
      :exporters="organisation.exporters"
      :integrations="organisation.globalIntegrations"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </pay-export-modal>
    <disable-all-integrations-modal
      :title="payExportModalTitle"
      :baseParams="badgingModalParams"
      :visible.sync="disablePayExportModalVisible"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </disable-all-integrations-modal>
    <new-integration-modal
      v-if="!isLoading"
      :title="newIntegrationModalTitle"
      :visible.sync="newIntegrationModalVisible"
      :params="newIntegrationModalParams"
      action="create"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </new-integration-modal>
    <esignature-modal
      :visible.sync="esignatureModalVisible"
      :title="esignatureModalTitle"
      :setting-to-true="setEsignatureToTrue"
      :params="esignatureModalParams"
      :callback="handleEsignatureActivated"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </esignature-modal>
    <payroll-modal
      :visible.sync="payrollModalVisible"
      :title="payrollModalTitle"
      :activation="isPayrollTrue"
      :params="payrollModalParams"
      :callback="handlePayrollActivated"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </payroll-modal>
    <activate-analytics-or-performance-module-modal
      :visible.sync="performanceModuleModalVisible"
      :title="performanceModuleModalTitle"
      :activating-module="newPerformanceModuleValue"
      :automatic-planning-params="automaticPlanningModalParams"
      :callback="handlePerformanceModuleActivated"
      :organisation="organisation"
      module-type="performance"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </activate-analytics-or-performance-module-modal>
    <activate-analytics-or-performance-module-modal
      :visible.sync="analyticsModuleModalVisible"
      :title="analyticsModuleModalTitle"
      :activating-module="newAnalyticsModuleValue"
      :callback="handleAnalyticsModuleActivated"
      :organisation="organisation"
      module-type="analytics"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </activate-analytics-or-performance-module-modal>
    <activate-silae-hris-integration-modal
      :activating-module="!isSilaeHrisIntegrationActive"
      :organisation="organisation"
      :title="silaeHrisIntegrationModalTitle"
      :visible.sync="silaeHrisIntegrationModalVisible"
      @submit="state => handleSilaeHrisIntegrationModuleActivated(state)"
    >
      <b>
        {{ $t('super_admin_js.organisations.shops_list.all_shops', { name: organisation.name }) }}
      </b>
    </activate-silae-hris-integration-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import httpClient from '@config/http_client';
import DisableAllIntegrationsModal from '@/components/DisableAllIntegrationsModal';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import ShopListTable from '../../components/ShopListTable';
import sortableMixin from '../../mixins/sortableMixin';
import BadgingModal from '../../components/BadgingModal';
import AutomaticPlanningModal from '../../components/AutomaticPlanningModal';
import PayExportModal from '../../components/PayExportModal';
import NewIntegrationModal from '../../components/integration_auto/NewIntegrationModal';
import EsignatureModal from '../../components/EsignatureModal';
import PayrollModal from '../../components/PayrollModal';
import PermsReplicationModal from '../../components/PermsReplicationModal';
import ActivateAnalyticsOrPerformanceModuleModal from '../../components/ActivateAnalyticsOrPerformanceModuleModal';
import ActivateSilaeHrisIntegrationModal from '../../components/ActivateSilaeHrisIntegrationModal';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    TabBodyFirstLine,
    ShopListTable,
    BadgingModal,
    AutomaticPlanningModal,
    PayExportModal,
    NewIntegrationModal,
    PermsReplicationModal,
    DisableAllIntegrationsModal,
    EsignatureModal,
    PayrollModal,
    ActivateAnalyticsOrPerformanceModuleModal,
    ActivateSilaeHrisIntegrationModal,
  },
  mixins: [sortableMixin],
  props: {
    organisation: {
      type: Object,
      default: () => ({}),
    },
    canActivateEsignature: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      badgingModalVisible: false,
      automaticPlanningModalVisible: false,
      payExportModalVisible: false,
      disablePayExportModalVisible: false,
      newIntegrationModalVisible: false,
      permsReplicationModalVisible: false,
      esignatureModalVisible: false,
      payrollModalVisible: false,
      analyticsModuleModalVisible: false,
      performanceModuleModalVisible: false,
      silaeHrisIntegrationModalVisible: false,
      shops: [],
      isLoading: true,
      sort: 'id',
      direction: 'desc',
      page: 1,
      total: 0,
      loadingShops: false,
    };
  },
  computed: {
    ...mapGetters(['isDevFeatureFlagActive']),
    setBadgingToTrue() {
      return !!_.find(this.shops, (shop) => !shop.badging);
    },
    setAutomaticPlanningToTrue() {
      return !!_.find(this.shops, (shop) => !shop.automatic_planning_active);
    },
    setEsignatureToTrue() {
      return !!_.find(this.shops, (shop) => !shop.esignature_active);
    },
    isPayrollTrue() {
      return !!_.find(this.shops, (shop) => !shop.features_states.payroll?.activated);
    },
    isSilaeHrisIntegrationActive() {
      return this.shops.some((shop) => shop.features_states.fortify_silae?.activated);
    },
    setPayExportToTrue() {
      // if at least one shop has an integration -> button deactivates integration on all shops
      return !this.shops.some((shop) => shop.integrations);
    },
    newAnalyticsModuleValue() {
      return !this.organisation.analyticsModule;
    },
    newPerformanceModuleValue() {
      return this.newAnalyticsModuleValue || this.setAutomaticPlanningToTrue;
    },
    title() {
      return `${this.$t('super_admin_js.shops.shop_list')} ${this.organisation.name}`;
    },
    badgingModalTitle() {
      const { name } = this.organisation;
      const activate = this.setBadgingToTrue ? this.$t('super_admin_js.actions.activate') : this.$t('super_admin_js.actions.deactivate');
      return `${activate} ${this.$t('super_admin_js.modals.titles.badging')} ${name}`;
    },
    esignatureModalTitle() {
      const { name } = this.organisation;
      const activate = this.setEsignatureToTrue ? this.$t('super_admin_js.actions.activate') : this.$t('super_admin_js.actions.deactivate');
      return `${activate} ${this.$t('super_admin_js.modals.titles.esignature_all')} ${name}`;
    },
    payrollModalTitle() {
      if (this.isPayrollActivated === 'partiallyActivated') {
        return this.$t('super_admin_js.shops.buttons.activate_payroll_warning', { organisation: this.organisation.name });
      }

      const activate = this.isPayrollActivated === 'notActivated' ? this.$t('super_admin_js.actions.activate') : this.$t('super_admin_js.actions.deactivate');

      return this.$t('super_admin_js.shops.buttons.activate_payroll', { activate, organisation: this.organisation.name });
    },
    analyticsModuleModalTitle() {
      const key = this.newAnalyticsModuleValue
        ? 'super_admin_js.modals.titles.analytics_module.activate'
        : 'super_admin_js.modals.titles.analytics_module.deactivate';
      return this.$t(key, { name: this.organisation.name });
    },
    performanceModuleModalTitle() {
      const key = this.newPerformanceModuleValue
        ? 'super_admin_js.modals.titles.performance_module.activate'
        : 'super_admin_js.modals.titles.performance_module.deactivate';
      return this.$t(key, { name: this.organisation.name });
    },
    automaticPlanningModalTitle() {
      const { name } = this.organisation;
      const activate = this.setAutomaticPlanningToTrue ? this.$t('actions.activate') : this.$t('super_admin_js.actions.deactivate');
      return `${activate} ${this.$t('super_admin_js.modals.titles.smart_planner')} ${name}`;
    },
    payExportModalTitle() {
      const { name } = this.organisation;
      const key = this.setPayExportToTrue ? 'super_admin_js.modals.titles.pay_export.activate' : 'super_admin_js.modals.titles.pay_export.deactivate';
      return this.$t(key, { name });
    },
    silaeHrisIntegrationModalTitle() {
      return this.$t(
        this.isSilaeHrisIntegrationActive
          ? 'super_admin_js.modals.titles.silae_hris_integration.deactivate'
          : 'super_admin_js.modals.titles.silae_hris_integration.activate',
          { name: this.organisation.name },
      );
    },
    newIntegrationModalTitle() {
      const { name } = this.organisation;
      return `${this.$t('super_admin_js.modals.titles.integration')} ${name}`;
    },
    isAnalyticsModuleActivated() {
      return this.organisation.analyticsModule;
    },
    isPayrollActivated() {
      const count = this.shops.filter((shop) => shop.features_states.payroll?.activated).length;

      if (count === this.shops.length) return 'fullyActivated';
      if (count === 0) return 'notActivated';
      return 'partiallyActivated';
    },
    isPerformanceModuleActivated() {
      return this.isAnalyticsModuleActivated && !this.setAutomaticPlanningToTrue;
    },
    badgingModalParams() {
      return {
        badging: String(this.setBadgingToTrue),
        organisation_id: this.organisation.id,
        type: 'organisation',
      };
    },
    esignatureModalParams() {
      return {
        esignature_active: String(this.setEsignatureToTrue),
        organisation_id: this.organisation.id,
        type: 'organisation',
      };
    },
    payrollModalParams() {
      return {
        payroll_active: this.isPayrollTrue,
        organisation_id: this.organisation.id,
        type: 'organisation',
      };
    },
    automaticPlanningModalParams() {
      return {
        automatic_planning_active: String(this.setAutomaticPlanningToTrue),
        organisation_id: this.organisation.id,
        type: 'organisation',
      };
    },
    newIntegrationModalParams() {
      return {
        organisation_id: this.organisation.id,
        first_shop_id: this.shops[0].id,
        type: 'organisation',
      };
    },
    buttons() {
      if (this.loadingShops) return undefined;

      const buttons = [
        {
          iconPack: 'fa',
          icon: 'files-o',
          onClick: this.handlePermsReplicationClick,
          tooltipLabel: this.$t('super_admin_js.modals.perms_replication.button_tooltip'),
        },
        {
          iconPack: 'fa',
          icon: 'magic',
          onClick: this.handleAutomaticPlanningClick,
          activeState: !this.setAutomaticPlanningToTrue,
          tooltipLabel: this.automaticPlanningModalTitle,
        },
        {
          iconPack: 'fa',
          icon: 'bolt',
          onClick: this.handlePerformanceModuleClick,
          tooltipLabel: this.performanceModuleModalTitle,
          activeState: this.isPerformanceModuleActivated,
        },
        {
          iconPack: 'fa',
          icon: 'bar-chart',
          onClick: this.handleAnalyticsModuleClick,
          tooltipLabel: this.analyticsModuleModalTitle,
          activeState: this.isAnalyticsModuleActivated,
        },
        {
          fontPack: 'fa',
          icon: 'cogs',
          onClick: this.handleNewPayExportClick,
          tooltipLabel: this.$t('super_admin_js.modals.titles.integration_all'),
        },
        {
          iconPack: 'fa',
          icon: 'usd',
          onClick: this.handlePayExportClick,
          activeState: !this.setPayExportToTrue,
          tooltipLabel: this.payExportModalTitle,
        },
        {
          iconPack: 'fa',
          icon: 'arrows-h',
          onClick: () => { this.silaeHrisIntegrationModalVisible = true; },
          activeState: this.isSilaeHrisIntegrationActive,
          tooltipLabel: this.silaeHrisIntegrationModalTitle,
        },
      ];

      if (this.canActivateEsignature) {
        buttons.splice(5, 0, {
          fontPack: 'fa',
          icon: 'pencil-square',
          onClick: this.handleEsignatureClick,
          activeState: !this.setEsignatureToTrue,
          tooltipLabel: this.esignatureModalTitle,
        });
      }

      const haveAtLeastOneShopInFrance = this.shops.filter((shop) => shop.french).length > 0;

      if (this.isDevFeatureFlagActive('FEATUREDEV_PAYROLL_OPTION_ENABLED') && haveAtLeastOneShopInFrance) {
        buttons.splice(5, 0, {
          iconPack: 'fa',
          icon: 'handshake-o',
          onClick: this.handlePayrollClick,
          tooltipLabel: this.payrollModalTitle,
          activeState: this.isPayrollActivated !== 'notActivated',
          warning: this.isPayrollActivated === 'partiallyActivated',
        });
      }

      if (!this.shops.some((shop) => shop.features_states?.new_bundle_system?.activated
      && shop.features_states?.timeclock?.activated)) {
        buttons.splice(2, 0, {
          iconPack: 'fa',
          icon: 'clock-o',
          onClick: this.handleBadgingClick,
          activeState: !this.setBadgingToTrue,
          tooltipLabel: this.badgingModalTitle,
        });
      }

      return buttons;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loadingShops = true;
      httpClient
        .get(`/super_admin/api/organisations/${this.$route.params.id}/shops`, {
          params: {
            sort: this.sort,
            direction: this.direction,
            page: this.page,
          },
        })
        .then((response) => {
          const result = response.data;
          this.shops = result.shops;
          this.total = result.total;
          this.isLoading = false;
          this.loadingShops = false;
        })
        .catch((e) => {
          console.error(e);
          this.makeAlertToast(
            this.$t('super_admin_js.modals.perms_replication.button_tooltip'),
          );
        });
    },
    handleBadgingActivated() {
      const newVal = this.setBadgingToTrue;
      this.shops.forEach((s) => { s.badging = newVal; });
    },
    handleAutomaticPlanningActivated() {
      const newVal = this.setAutomaticPlanningToTrue;
      this.shops.forEach((s) => { s.automatic_planning_active = newVal; });
    },
    handleEsignatureActivated() {
      const newVal = this.setEsignatureToTrue;
      this.shops.forEach((s) => { s.esignature_active = newVal; });
    },
    handlePayrollActivated() {
      this.fetchData();
      const newVal = this.isPayrollTrue;

      this.shops.forEach((shop) => {
        shop.features_states.payroll = { activated: newVal };
        shop.features_states.multidocument = { activated: newVal };
        shop.features_states.multicontract = { activated: newVal };
      });
    },
    handleAnalyticsModuleActivated() {
      this.organisation.analyticsModule = !this.organisation.analyticsModule;
    },
    handleSilaeHrisIntegrationModuleActivated(state) {
      this.shops.forEach((shop) => {
        shop.features_states.fortify_silae = { activated: state };
      });
    },
    handlePerformanceModuleActivated() {
      if (this.newPerformanceModuleValue !== this.organisation.analyticsModule) {
        this.handleAnalyticsModuleActivated();
      }

      const newVal = this.setAutomaticPlanningToTrue || this.isPerformanceModuleActivated;
      this.shops.forEach((s) => { s.automatic_planning_active = newVal; });
    },
    handlePayExportClick() {
      if (this.setPayExportToTrue) {
        this.payExportModalVisible = true;
      } else {
        this.disablePayExportModalVisible = true;
      }
    },
    handleNewPayExportClick() {
      this.newIntegrationModalVisible = true;
    },
    handlePermsReplicationClick() {
      this.permsReplicationModalVisible = true;
    },
    handleBadgingClick() {
      this.badgingModalVisible = true;
    },
    handleAutomaticPlanningClick() {
      this.automaticPlanningModalVisible = true;
    },
    handleEsignatureClick() {
      this.esignatureModalVisible = true;
    },
    handlePayrollClick() {
      this.payrollModalVisible = true;
    },
    handleAnalyticsModuleClick() {
      this.analyticsModuleModalVisible = true;
    },
    handlePerformanceModuleClick() {
      this.performanceModuleModalVisible = true;
    },
  },
};
</script>
