export default function enumerate({ items = [], maxItemsToDisplay = 10 }) {
    if (maxItemsToDisplay <= 0) return '';
    const trimmedItems = items.filter((item) => item && item.trim());

    if (!trimmedItems) return '';
    if (trimmedItems.length < maxItemsToDisplay) return trimmedItems.join(', ');

    const itemsBeforeCount = trimmedItems.slice(0, maxItemsToDisplay);
    const itemCount = trimmedItems.slice(maxItemsToDisplay, trimmedItems.length).length;
    return itemCount === 0 ? `${itemsBeforeCount.join(', ')}` : `${itemsBeforeCount.join(', ')} +${itemCount}`;
}
