<template>
  <modal
    :title="title"
    :visible.sync="modalVisible"
    :loading="isLoading"
  >
    <div>
      {{ $t(`super_admin_js.modals.${moduleType}_module.warning.${activationKey}`) }} <slot />
      {{ $t(`super_admin_js.modals.${moduleType}_module.continue_prompt`) }}
    </div>
    <div
      slot="footer"
      class="analytics-or-performance-modal-footer-btns"
    >
      <validate-btn :handle-click="validateActivation" />
      <cancel-btn :handle-click="closeModal" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';

import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

const MODULE_TYPES = ['analytics', 'performance'];

export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    activatingModule: {
      type: Boolean,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    automaticPlanningParams: {
      type: Object,
      required: false,
      default: null,
    },
    organisation: {
      type: Object,
      required: true,
    },
    moduleType: {
      type: String,
      required: true,
      validator: (moduleType) => MODULE_TYPES.includes(moduleType),
    },
  },
  data() {
    return {
      isLoading: false,
      dvore: null,
      yokitup: null,
      analyticsApi: null,
    };
  },
  computed: {
    activationKey() {
      return this.activatingModule ? 'activation' : 'deactivation';
    },
  },
  created() {
    httpClient
      .get(`/super_admin/api/organisations/${this.organisation.id}/applications`)
      .then((response) => {
        response.data.data.forEach((organisationApplication) => {
          if (organisationApplication.attributes.name === 'Dvore') this.dvore = organisationApplication.attributes;
          if (organisationApplication.attributes.name === 'Yokitup') this.yokitup = organisationApplication.attributes;
          if (organisationApplication.attributes.name === 'AnalyticsApi') this.analyticsApi = organisationApplication.attributes;
        });
      })
      .catch((error) => { throw error; });
  },
  methods: {
    validateActivation() {
      if (this.isLoading) return;
      this.loading = true;

      let activations;
      if (this.moduleType === 'analytics') {
        activations = this.activateAnalyticsModule();
      } else {
        activations = Promise.all([
          this.activateAnalyticsModule(),
          this.activateAutomaticPlanning(),
        ]);
      }

      activations
        .then(this.onSuccess)
        .catch(this.onError)
        .finally(() => { this.loading = false; });
    },
    onSuccess() {
      this.displaySuccessToaster();
      if (this.callback) this.callback();
      this.closeModal();
    },
    onError() {
      this.displayErrorToaster();
    },
    displaySuccessToaster() {
      this.makeToast(
        'is-success',
        this.$t(`super_admin_js.modals.${this.moduleType}_module.info.${this.activationKey}`),
      );
    },
    displayErrorToaster() {
      this.makeToast(
        'is-danger',
        this.$t(`super_admin_js.modals.${this.moduleType}_module.error.${this.activationKey}`),
      );
    },
    closeModal() {
      this.modalVisible = false;
    },
    activateAutomaticPlanning() {
      if (this.activatingModule) {
        // case when we activate SP on its own beforehand and then activate performance module,
        // SP has to remain activated and not switch back to off.
        this.automaticPlanningParams.automatic_planning_active = true;
      }

      return httpClient
        .patch('/super_admin/api/automatic_plannings', this.automaticPlanningParams);
    },
    activateAnalyticsModule() {
      if (this.activatingModule === this.organisation.analyticsModule) {
        // already activated
        return Promise.resolve();
      }

      if (!this.activatingModule) {
        // if we're deactivating the module, we must cut access to all the related tools
        if (this.dvore) this.deleteApplication(this.dvore);
        if (this.yokitup) this.deleteApplication(this.yokitup);
        if (this.analyticsApi) this.deleteApplication(this.analyticsApi);
      }

      return httpClient
        .patch(`/super_admin/api/organisations/${this.organisation.id}`, {
          organisation: {
            modules: {
              analytics_module: this.activatingModule,
            },
          },
        });
    },
    deleteApplication(application) {
      return httpClient
        .delete(`/super_admin/api/organisations/${this.organisation.id}/applications/${application.id}`)
        .then(() => {
          if (application.name === 'dvore') {
            this.dvore = null;
          } else if (application.name === 'Yokitup') {
            this.yokitup = null;
          } else if (application.name === 'analyticsApi') {
            this.analyticsApi = null;
          }
        })
        .catch((error) => { throw error; });
    },
  },
};
</script>

<style lang="scss" scoped>
.analytics-or-performance-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
