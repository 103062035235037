<template>
  <!-- eslint-disable  max-len -->
  <div class="pb-wrapper">
    <div class="pb-bubbles-wrapper">
      <div class="pb-step-wrapper">
        <div
          class="pb-step-bubble active pointer"
          @click="comeBack"
        >
          <div class="pb-step-number">
            1
          </div>
        </div>
      </div>
      <div
        :class="secondStepClasses"
        class="pb-step-line"
      />
      <div class="pb-step-wrapper">
        <div
          :class="secondStepClasses"
          class="pb-step-bubble"
        >
          <div class="pb-step-number">
            2
          </div>
        </div>
      </div>
    </div>
    <div class="pb-label-wrapper">
      <div
        :style="{ 'paddingLeft': firstTextPadding + 'px' }"
        class="pb-step-label active pointer"
        @click="comeBack"
        v-html="firstText"
      />
      <div
        :class="secondStepClasses"
        :style="{ 'paddingLeft': secondTextPadding + 'px' }"
        class="pb-step-label"
        v-html="secondText"
      />
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n/index.js';

export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    changeStep: {
      type: Function,
      required: true,
    },
    firstText: {
      type: String,
      required: false,
      default() {
        return this.$t('super_admin_js.ui.progress_bar.accept_refuse');
      },
    },
    secondText: {
      type: String,
      required: false,
      default() {
        return this.$t('super_admin_js.ui.progress_bar.validate');
      },
    },
    firstTextPadding: {
      type: Number,
      required: false,
      default: 0,
    },
    secondTextPadding: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    secondStepClasses() {
      return {
        active: this.step > 0,
      };
    },
  },
  methods: {
    comeBack() {
      this.changeStep(0);
    },
  },
};
</script>

<style lang="scss" scoped>
$bubble-size: 30px;
$active-colour: #1a6efc;
$inactive-colour: #ccc;

.pb-bubbles-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 2em;
}

.pb-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pb-step-line {
  flex: 1;
  height: 5px;
  margin-top: 13px;
  background-color: $inactive-colour;
}

.pb-step-bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height: $bubble-size;
  width: $bubble-size;
  border-radius: 50%;
  background-color: $inactive-colour;
  color: white;
}

.pb-step-label {
  margin-top: 1em;
  min-width: 7em;
  font-size: .8em;
  letter-spacing: .03em;
}

.pb-step-wrapper,
.pb-label-wrapper {
  .pb-step-line {
    &.active {
      background-color: #1a6efc !important;
    }
  }

  .pb-step-bubble {
    &.active {
      background-color: #1a6efc !important;
    }
  }

  .pb-step-label {
    &.active {
      color: #1a6efc !important;
    }
  }
}

.pb-step-number {
  margin-top: 2px;
}

.pointer {
  cursor: pointer;
}
</style>
