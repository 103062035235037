function base(context) {
  return [
    {
      type: 'hoursRange',
      label: context.$t('super_admin_js.labels.shop.settings.opening_hours'),
      from_label: context.$t('super_admin_js.from'),
      to_label: context.$t('super_admin_js.to'),
      start_values: context.hoursRange,
      end_values: context.hoursRange,
      key: 'opening_time', // in order 'shouldShow' to respond true
      keys: ['opening_time', 'closing_time'],
    },
    {
      type: 'select',
      label: context.$t('super_admin_js.labels.shop.settings.paid_vacation_calculation_type'),
      key: 'paid_vacation_calculation_type',
      options: context.paidVacationCalculationTypes,
    },
  ];
}

export default { base };
