<template>
  <!-- eslint-disable  max-len -->
  <div class="field">
    <div
      v-if="field.label"
      class="field-label is-normal"
    >
      <label class="label sk-label">{{ field.label }}</label>
    </div>
    <div class="field-body">
      <b-field grouped>
        <span v-if="field.label_start"> {{ field.label_start }}</span>
        <sa-form-hours-range
          :object.sync="object"
          :field="field.fields[0]"
          :value="value"
        />
        <sa-form-input-number
          :object.sync="object"
          :field="field.fields[1]"
          :value="value"
        />
        <span
          v-if="field.label_end"
          class="label-end"
        > {{ field.label_end }}</span>
      </b-field>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import saFormTypeMixin from '../../../mixins/saFormTypeMixin';
import SaFormHoursRange from './SaFormHoursRange';
import SaFormInputNumber from './SaFormInputNumber';

export default {
  name: 'SaFormGroup',
  components: { SaFormHoursRange, SaFormInputNumber },
  mixins: [saFormTypeMixin],
};
</script>

<style lang="scss" scoped>
  .label-end {
    font-weight: bold;
  }
</style>
