<template>
  <!-- eslint-disable  max-len -->
  <b-table
    ref="table"
    :data="shops"
    :loading="loading"
    striped
    mobile-cards
    backend-sorting
    :default-sort="['id', 'desc']"
    @sort="(field, order) => sort(field, order)"
  >
    <template slot-scope="props">
      <b-table-column
        field="id"
        :label="$t('super_admin_js.shops.table.id')"
        class="shop_column shop_column--id"
        sortable
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        field="name"
        :label="$t('super_admin_js.shops.table.name')"
        class="shop_column shop_column--name"
        sortable
      >
        <router-link :to="linkForShop(props.row)">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column
        field="organisation_id"
        :label="$t('super_admin_js.shops.table.org_id')"
        class="shop_column shop_column--organisation-id"
        sortable
      >
        {{ props.row.organisation_id }}
      </b-table-column>
      <b-table-column
        field="organisation_name"
        :label="$t('super_admin_js.shops.table.org_name')"
        class="shop_column shop_column--organisation-name"
        sortable
      >
        <router-link :to="linkForOrganisation(props.row)">
          {{ props.row.organisation_name }}
        </router-link>
      </b-table-column>
      <b-table-column
        field="referent_organisation_user_name"
        :label="$t('super_admin_js.shops.table.referent_name')"
        class="shop_column shop_column--referent-name"
        sortable
      >
        <router-link :to="linkForUserId(props.row)">
          {{ props.row.referent_organisation_user_name }}
        </router-link>
      </b-table-column>
      <b-table-column
        field="referent_organisation_user_phone"
        :label="$t('super_admin_js.shops.table.referent_phone')"
        class="shop_column shop_column--referent-phone"
      >
        {{ props.row.referent_organisation_user_phone }}
      </b-table-column>
      <b-table-column
        field="referent_organisation_user_email"
        :label="$t('super_admin_js.shops.table.referent_email')"
        class="shop_column shop_column--referent-email"
        sortable
      >
        {{ props.row.referent_organisation_user_email }}
      </b-table-column>
      <b-table-column
        field="referent_bizdev"
        :label="$t('super_admin_js.shops.table.bizdev')"
        class="shop_column shop_column--referent-bizdev"
      >
        {{ props.row.referent_bizdev }}
      </b-table-column>
      <b-table-column
        :label="$t('super_admin_js.shops.table.status')"
        class="shop_column shop_column--status"
      >
        {{ props.row.organisation_status }}
      </b-table-column>
    </template>
  </b-table>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    shops: {
      type: Array,
      required: true,
    },
    handleSortChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    linkForShop(shop) {
      return {
        name: 'shop',
        params: {
          id: shop.id,
        },
      };
    },
    linkForOrganisation(shop) {
      return {
        name: 'organisation',
        params: {
          id: shop.organisation_id,
        },
      };
    },
    linkForUserId(shop) {
      return {
        name: 'user',
        params: {
          id: shop.referent_organisation_user_id,
        },
      };
    },
    sort(field, order) {
      if (this.$refs.table.firstTimeSort) return;
      this.handleSortChange(field, order);
    },
  },
};
</script>

<style lang="scss">
  .shop_column--name,
  .shop_column--convention {
    white-space: nowrap;
  }
</style>
