<template>
  <!-- eslint-disable  max-len -->
  <div>
    <tab-body-first-line
      :buttons="buttons"
      :title="$t('super_admin_js.users.details')"
    />
    <sa-form
      :fields="formFields"
      :object="user"
    />
    <send-pin-modal
      :title="sendPinModalTitle"
      :visible.sync="sendPinModalVisible"
      :base-params="actionParams"
    />
  </div>
</template>

<script>
import httpClient from '@config/http_client';
import { svcCommunicationsClient } from '~/shared/utils/svc-communication-v2-client';

import { mapState } from 'vuex';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import Form from '../../components/Form';
import SendPinModal from '../../components/SendPinModal';
import deleteMixin from '../../mixins/deleteMixin';
import sendInviteMixin from '../../mixins/sendInviteMixin';
import refreshPinMixin from '../../mixins/refreshPinMixin';
import impersonateUser from '../../utils/impersonate_user';

/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: { TabBodyFirstLine, saForm: Form, SendPinModal },
  mixins: [
    sendInviteMixin('singular'),
    refreshPinMixin('singular'),
    deleteMixin("L'utilisateur"),
  ],
  props: ['user', 'refreshUser'],
  data() {
    return {
      isLoading: true,
      sendPinModalVisible: false,
      deleteModalVisible: false,
      actionParams: {
        type: 'user',
        user_id: this.$route.params.id,
      },
      staticButtons: [
        {
          iconPack: 'fa',
          icon: 'user',
          onClick: this.impersonate,
          tooltipLabel: this.$t('super_admin_js.users.details_buttons.see_as'),
        },
        {
          iconPack: 'fa',
          icon: 'envelope-o',
          onClick: this.handleSendInviteClick,
          tooltipLabel: this.$t('super_admin_js.users.details_buttons.resend_invite'),
        },
        {
          iconPack: 'fa',
          icon: 'refresh',
          onClick: this.handleRefreshPinClick,
          tooltipLabel: this.$t(
            'super_admin_js.modals.refresh_pin.button_tooltip',
          ),
        },
        {
          iconPack: 'fa',
          icon: 'paper-plane',
          onClick: this.handleSendPinClick,
          tooltipLabel: this.$t('super_admin_js.users.details_buttons.send_pin'),
        },
      ],
      formFields: [
        {
          type: 'text',
          label: this.$t('super_admin_js.users.table.id'),
          key: 'id',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.users.table.name'),
          key: 'name',
        },
        {
          type: 'email',
          label: this.$t('super_admin_js.users.table.email'),
          key: 'email',
          handleDeleteClick: this.deleteEmail,
          deleteTooltip: this.$t('super_admin_js.modals.delete_email.tooltip'),
        },
        {
          type: 'phone',
          label: this.$t('super_admin_js.users.table.phone'),
          key: 'phoneNumber',
          unBlacklistPhoneTooltip: this.$t('super_admin_js.modals.blacklist_phone.tooltip'),
        },
        {
          type: 'link',
          label: this.$t('super_admin_js.users.table.organisation'),
          key: 'organisation',
        },
        {
          type: 'linkText',
          label: `${this.$t('super_admin_js.users.table.shop')} (${this.$t('super_admin_js.users.table.role')})`,
          key: 'shop',
        },
        {
          type: 'linksTextList',
          label: this.$t('super_admin_js.users.table.other_shops'),
          key: 'shops',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.users.table.hourly_volume'),
          key: 'contractHours',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.users.table.punch_clock_pin'),
          key: 'badgingPin',
        },
        {
          type: 'date',
          label: this.$t('super_admin_js.users.table.date'),
          key: 'invitationAcceptedAt',
        },
        {
          type: 'tag',
          label: this.$t('super_admin_js.users.table.status'),
          key: 'status',
        },
      ],
    };
  },
  computed: {
    ...mapState(['currentUser']),
    sendPinModalTitle() {
      return `${this.$t('super_admin_js.modals.titles.send_pin')} ${this.user.name}`;
    },
    deleteModalTitle() {
      return this.$t('super_admin_js.modals.delete.title', {
        name: this.user.name,
      });
    },
    deleteMessageWarning() {
      return this.$t('super_admin_js.modals.delete.message', {
        name: this.user.name,
      });
    },
    buttons() {
      if (this.currentUser.can_delete_employee) {
        return [
          ...this.staticButtons,
          {
            iconPack: 'fa',
            icon: 'trash',
            iconColor: '#FF5E5F',
            onClick: this.handleDeleteClick,
            tooltipLabel: this.$t('super_admin_js.modals.delete.button_tooltip', {
              entity: this.$t('super_admin_js.users.details_buttons.this_user'),
            }),
          },
        ];
      }

      return this.staticButtons;
    },
    deletePath() {
      return `/super_admin/api/users/${this.$route.params.id}`;
    },
  },
  methods: {
    handlePinRefreshed(response) {
      this.user.badgingPin = response.users[this.user.id];
    },
    handleSendPinClick() {
      this.sendPinModalVisible = true;
    },
    deleteEmail() {
      this.$dialog.confirm({
        title: this.$t('super_admin_js.modals.delete_email.title'),
        message: this.$t('super_admin_js.modals.delete_email.message'),
        confirmText: this.$t('super_admin_js.modals.delete_email.actions.confirm'),
        cancelText: this.$t('super_admin_js.modals.delete_email.actions.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          httpClient
            .patch(`/super_admin/api/users/${this.$route.params.id}/delete_email`)
            .then((r) => {
              this.user.email = null;
              this.makeToast('is-success', this.$t('super_admin_js.modals.delete_email.success'));
            })
            .catch((error) => {
              this.makeToast('is-danger', error.response.data.error);
            });
        },
      });
    },
    impersonate() {
      const userId = this.$route.params.id;
      impersonateUser(userId)
        .catch((error) => {
          const errorType = error.response.status === 403 ? 'user' : 'server';

          this.makeAlertToast(this.$t(`super_admin_js.users.errors.impersonate.${errorType}`));
        });
    },
  },
};
</script>
