<template>
  <!-- eslint-disable  max-len -->
  <modal
    :title="title"
    :visible.sync="modalVisible"
  >
    <div>
      <span class="badging-checkboxes__title">
        {{ $t('super_admin_js.users.send_by') }}:
      </span>
      <div class="badging-checkboxes__input-wrapper">
        <input
          v-model="sendSms"
          type="checkbox"
          class="badging-checkboxes__input"
        >
        SMS
      </div>
      <div class="badging-checkboxes__input-wrapper">
        <input
          v-model="sendMail"
          type="checkbox"
          class="badging-checkboxes__input"
        >
        Mail
      </div>
    </div>
    <div
      slot="footer"
      class="badging-modal-footer-btns"
    >
      <validate-btn :handle-click="handleValidateClick" />
      <cancel-btn :handle-click="handleCancelClick" />
    </div>
  </modal>
</template>

<script>
import httpClient from '@config/http_client';
import Modal from '../Modal';
import ValidateBtn from '../ValidateBtn';
import CancelBtn from '../CancelBtn';
import modalVisibleMixin from '../../mixins/modalVisibleMixin';

export default {
  components: { Modal, ValidateBtn, CancelBtn },
  mixins: [modalVisibleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    baseParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sendSms: true,
      sendMail: false,
    };
  },
  computed: {
    successMessage() {
      const messages = {
        singular: this.$t('super_admin_js.modals.send_pin.success_singular'),
        plural: this.$t('super_admin_js.modals.send_pin.success_plural'),
      };

      return this.accordMessage(messages);
    },
    dialogTitle() {
      const messages = {
        singular: this.$t('super_admin_js.modals.send_pin.send_pin_singular'),
        plural: this.$t('super_admin_js.modals.send_pin.send_pin_plural'),
      };

      return this.accordMessage(messages);
    },
    dialogMessage() {
      const messages = {
        singular: this.$t('super_admin_js.modals.send_pin.confirm_singular'),
        plural: this.$t('super_admin_js.modals.send_pin.confirm_plural'),
      };

      return this.accordMessage(messages);
    },
  },
  methods: {
    accordMessage(messages) {
      const { type } = this.baseParams;
      const messageType = type === 'user' ? 'singular' : 'plural';

      return messages[messageType];
    },

    handleValidateClick() {
      this.$dialog.confirm({
        title: this.dialogTitle,
        message: this.dialogMessage,
        confirmText: this.$t('super_admin_js.modals.send_pin.send'),
        cancelText: this.$t('super_admin_js.modals.send_pin.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: this.submitForm,
      });
    },

    submitForm() {
      const params = {
        send_sms: this.sendSms,
        send_mail: this.sendMail,
      };

      httpClient
        .post('/super_admin/api/pins', { ...this.baseParams, ...params })
        .then((r) => {
          this.makeToast('is-success', this.successMessage);
          this.handleCancelClick();
        })
        .catch((e) => {
          let message = this.$t('super_admin_js.modals.send_pin.send_error');
          if (e.response && e.response.data && e.response.data.error) {
            message = e.response.data.error;
          }
          this.makeToast('is-danger', message);
        });
    },
    handleCancelClick() {
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.badging-modal-footer-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>
